import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setFiltersDevices } from '../../../store/slices/filters.js'
import Filter from '../../Layout/Dialogs/Filter.jsx'
import FilterArea from './Filters/Area.jsx'
import FilterOrganizations from './Filters/Organizations.jsx'
import FilterOthers from './Filters/Others.jsx'
import Count from '../../Shared/Components/Count.jsx'
import {
  setFiltersDefaultNear,
  setFiltersDefaultPosition
} from '../../../store/slices/general.js'

const Filters = ({ filters, itemsCount }) => {
  const dispatch = useDispatch()

  const [selectedArea, setSelectedArea] = useState(false)
  const [selectedOrganizations, setSelectedOrganizations] = useState(false)
  const [selectedOther, setSelectedOther] = useState(false)

  const { credentials, currentOrganization } = useSelector(
    (state) => state.general
  )

  const isSuperAdmin = credentials?.permissions?.isSuperAdmin || false
  const orgPermissions = currentOrganization
    ? credentials?.permissions?.permissions[currentOrganization] || []
    : []

  const handleOnDeleteArea = () => {
    dispatch(setFiltersDefaultNear(false))
    dispatch(setFiltersDefaultPosition({}))
    dispatch(setFiltersDevices({ nearby: null }))
    setSelectedArea(false)
  }

  const handleOnDeleteOrganizations = () => {
    dispatch(
      setFiltersDevices({
        organizationKeys: [],
        searchInSubOrganizations: false
      })
    )
    setSelectedOrganizations(false)
  }

  const handleOnDeleteOther = () => {
    dispatch(
      setFiltersDevices({
        modelIds: [],
        showPublic: null,
        showOffline: null,
        showHidden: null,
        brandIds: [],
        tags: [],
        tagSearchMode: null
      })
    )
    setSelectedOther(false)
  }

  useEffect(() => {
    const {
      brandIds,
      modelIds,
      nearby,
      organizationKeys,
      showHidden,
      showOffline,
      showPublic,
      tagSearchMode,
      tags
    } = filters

    setSelectedArea(!!nearby)

    setSelectedOrganizations(
      !!(organizationKeys && organizationKeys.length > 0)
    )

    setSelectedOther(
      !!(
        (modelIds && modelIds.length > 0) ||
        showOffline !== null ||
        showHidden !== null ||
        showPublic !== null ||
        (brandIds && brandIds.length > 0) ||
        (tags && tags.length > 0) ||
        tagSearchMode !== null
      )
    )
  }, [filters])

  const filterUnits = [
    {
      label: t('filters.devices.area.label'),
      selected: selectedArea,
      onDelete: handleOnDeleteArea,
      content: <FilterArea />,
      count: <Count itemsCount={itemsCount} />
    },
    {
      label: t('filters.devices.organizations.label'),
      selected: selectedOrganizations,
      onDelete: handleOnDeleteOrganizations,
      hidden: !orgPermissions.includes('organizations_r') && !isSuperAdmin,
      content: <FilterOrganizations />,
      count: <Count itemsCount={itemsCount} />
    },
    {
      label: t('common.filters.other'),
      selected: selectedOther,
      onDelete: handleOnDeleteOther,
      content: <FilterOthers />,
      count: <Count itemsCount={itemsCount} />
    }
  ]

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {[...filterUnits].map((e, i) => (
          <Filter
            key={`device-filter-${i}`}
            onClick={e.onClick}
            onDelete={e.onDelete}
            selected={e.selected}
            title={e.label}
            hidden={e.hidden || false}
            content={e.content}
            count={e.count}
          />
        ))}
      </Stack>
    </>
  )
}

Filters.propTypes = {
  filters: PropTypes.shape({
    organizationKeys: PropTypes.array,
    brandIds: PropTypes.array,
    modelIds: PropTypes.array,
    nearby: PropTypes.any,
    showOffline: PropTypes.bool,
    showHidden: PropTypes.bool,
    showPublic: PropTypes.bool,
    tags: PropTypes.array,
    tagSearchMode: PropTypes.any
  })
}

export default Filters
