import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'
import sanitizer from '../../helpers/sanitizer.js'

const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: customFetchBase,
  tagTypes: ['users'],
  endpoints(builder) {
    return {
      fetchUsers: builder.query({
        query: ({ page, pageSize }) => {
          logger.log(`RTK API - Fetch users`)
          return {
            url: `/users`,
            params: {
              page,
              pageSize
            },
            method: 'GET'
          }
        },
        transformResponse: (response, meta) => {
          const count = meta.response.headers.get('total-count')
            ? parseInt(meta.response.headers.get('total-count'))
            : response.length
          return { data: response, count: count }
        },
        providesTags: ['users']
      }),
      fetchUser: builder.query({
        query: (userId) => {
          logger.log(`RTK API - Fetch user`)
          return {
            url: `/users/${userId}`,
            method: 'GET'
          }
        }
      }),
      searchUsers: builder.query({
        query: ({ filters, page, pageSize }) => {
          logger.log(`RTK API - Search users`)
          return {
            url: `/users/search`,
            method: 'POST',
            params: {
              page,
              pageSize
            },
            body: sanitizer(filters)
          }
        },
        transformResponse: (response, meta) => {
          const count = meta.response.headers.get('x-total-count')
            ? parseInt(meta.response.headers.get('x-total-count'))
            : response.length
          return { data: response, count: count }
        },
        providesTags: ['users']
      }),
      updateUserRoleAndOrganization: builder.mutation({
        query: ({ uid, currentOrganizationKey, roleId, organizationKey }) => {
          logger.log(`RTK API - Fetch user by email`)
          return {
            url: `/users/${uid}/organizations/${currentOrganizationKey}`,
            method: 'PUT',
            body: {
              roleId,
              organizationKey
            }
          }
        },
        invalidatesTags: ['users']
      }),
      deleteUser: builder.mutation({
        query: ({ uid, organizationKey }) => {
          logger.log(`RTK API - Delete user`)
          return {
            url: `/users/${uid}/organizations/${organizationKey}`,
            method: 'DELETE'
          }
        },
        invalidatesTags: ['users']
      })
    }
  }
})

export const {
  useFetchUsersQuery,
  useSearchUsersQuery,
  useUpdateUserRoleAndOrganizationMutation,
  useDeleteUserMutation,
  useLazyFetchUserQuery
} = usersApi

export { usersApi }
