import { Stack } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment'
import moment from 'moment-timezone'
import React from 'react'
import ButtonDatePicker from './ButtonDatePicker.jsx'

const TimePicker = ({ onChange, timePicker }) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale="it"
      localeText={{ start: 'dal', end: 'al' }}
    >
      <Stack spacing={1}>
        <ButtonDatePicker
          value={[
            moment(timePicker[0]).startOf('day'),
            moment(timePicker[1]).endOf('day')
          ]}
          onChange={(value) => {
            if (value[0] && value[1]) {
              onChange([
                value[0].startOf('day').toISOString(),
                value[1].endOf('day').toISOString()
              ])
            }
          }}
        />
      </Stack>
    </LocalizationProvider>
  )
}

export default TimePicker
