import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { setCurrentOrganization } from '../slices/general.js'

export const setOrgKey = async (api) => {
  const org = { key: api.getState().general?.currentOrganization || 'a2a' }

  const location = window.location.hostname.split('.')

  if (location.length >= 3 && location[0] != 'www' && location[0] != 'e2e' && !keyRequested) {
    const request = await fetch(`/api/organization-key?domain=${location[0]}`)
    if (request.status !== 200) {
      return org.key
    }

    const response = await request.json()
    org.key = response.key
    api.dispatch(setCurrentOrganization(org.key))
    api.dispatch(clearFilters())
    keyRequested = true
  }

  return org.key
}

export const baseQuery = fetchBaseQuery({
  baseUrl: '/api'
})

export const authenticatedBaseQuery = async (
  args,
  api,
  extraOptions,
  headers,
  accessToken
) => {
  return await baseQuery(
    {
      ...args,
      headers: {
        ...headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined
      },
      credentials: 'include'
    },
    api,
    extraOptions
  )
}

export const refreshBaseQuery = async (api, extraOptions, refreshToken) => {
  return await baseQuery(
    { url: '/refresh', body: { refreshToken }, method: 'POST' },
    api,
    extraOptions
  )
}
