import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'

const rolesApi = createApi({
  reducerPath: 'rolesApi',
  baseQuery: customFetchBase,
  tagTypes: ['roles'],
  endpoints(builder) {
    return {
      fetchRolesStandard: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch roles standard`)
          return {
            url: `/roles/standard`,
            method: 'GET'
          }
        },
        providesTags: ['roles']
      }),
      createRoleStandard: builder.query({
        query: () => {
          logger.log(`RTK API - Create role standard`)
          return {
            url: `/roles/standard`,
            method: 'GET'
          }
        },
        invalidatesTags: ['roles']
      }),
      fetchRolesSuper: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch roles standard`)
          return {
            url: `/roles/super`,
            method: 'GET'
          }
        },
        providesTags: ['roles']
      })
    }
  }
})

export const {
  useFetchRolesStandardQuery,
  useCreateRoleStandardQuery,
  useFetchRolesSuperQuery
} = rolesApi

export { rolesApi }
