export const deviceAdd = {
  model: {
    model: null,
    brand: null,
    provisioningSetup: null
  },
  info: {
    organization: null,
    name: null,
    serial: null,
    description: null,
    tags: []
  },
  position: {
    position: null,
    address: null,
    city: null,
    province: null,
    region: null,
    country: null
  },
  network: {
    networkServer: '',
    networkType: 'OTAA',
    devEui: '',
    joinEui: '',
    appKey: '',
    appSKey: '',
    nwkSKey: '',
    devaddr: '',
    imsi: '',
    wmbusId: ''
  },
  pubblication: {
    isPublic: false,
    isVisible: false
  }
}
