import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'
import sanitizer from '../../helpers/sanitizer.js'

const userProfileApi = createApi({
  reducerPath: 'userProfile',
  baseQuery: customFetchBase,
  tagTypes: ['userProfile'],
  endpoints(builder) {
    return {
      getUserProfile: builder.query({
        query: () => {
          logger.log('RTK API - Get User Profile')
          return {
            url: `/users/self`,
            method: 'GET'
          }
        },
        providesTags: ['userProfile']
      }),
      getEmailChangeRequest: builder.query({
        query: ({ requestId }) => {
          logger.log('RTK API - Get Email change request')
          return {
            url: `/users/email-change-requests/${requestId}`,
            method: 'GET'
          }
        }
      }),
      saveUserProfile: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Save User Profile`)
          return {
            url: `/users/self`,
            method: 'PATCH',
            body: sanitizer(payload)
          }
        },
        invalidatesTags: ['userProfile']
      }),
      deleteUserSelf: builder.mutation({
        query: () => {
          logger.log(`RTK API - Save User Profile`)
          return {
            url: `/users/self`,
            method: 'DELETE'
          }
        },
        invalidatesTags: ['userProfile']
      }),
      changePassword: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Change user password`)
          return {
            url: `/users/self/change-password`,
            method: 'PATCH',
            body: sanitizer(payload)
          }
        },
        invalidatesTags: ['userProfile']
      }),
      changeEmail: builder.mutation({
        query: ({ requestId, payload }) => {
          logger.log(`RTK API - Change user password`)
          return {
            url: `/users/email-change-requests/${requestId}`,
            method: 'PATCH',
            body: sanitizer(payload)
          }
        },
        invalidatesTags: ['userProfile']
      })
    }
  }
})

export const {
  useGetUserProfileQuery,
  useSaveUserProfileMutation,
  useChangePasswordMutation,
  useDeleteUserSelfMutation,
  useGetEmailChangeRequestQuery,
  useChangeEmailMutation
} = userProfileApi

export { userProfileApi }
