import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProtectedRoutes = () => {
  const { credentials } = useSelector((state) => state.general)

  return credentials ? <Outlet /> : <Navigate to="/" />
}

export default ProtectedRoutes
