import { AppBar, Box, CssBaseline } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer/Footer.jsx'
import SuccessAlert from '../Shared/Alerts/SuccessAlert.jsx'
import ErrorAlert from '../Shared/Alerts/ErrorAlert.jsx'
import NavBar from './Navbar/NavBar.jsx'
import ResetPassword from '../Users/Dialogs/ResetPassword.jsx'
import ResetPasswordSuccess from '../Users/Dialogs/ResetPasswordSuccess.jsx'
import Login from '../Users/Dialogs/Login.jsx'

const MainLayout = () => {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          enableColorOnDark
        >
          <NavBar />
        </AppBar>
        <Outlet />
      </Box>
      <Footer />
      <SuccessAlert></SuccessAlert>
      <ErrorAlert></ErrorAlert>
      <Login />
      <ResetPassword />
      <ResetPasswordSuccess />
    </>
  )
}

export default MainLayout
