import { Box, Divider, Slider, Stack, Switch, Typography } from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setFiltersGateways } from '../../../../store/slices/filters.js'
import {
  setFiltersDefaultNear,
  setFiltersDefaultPosition,
  setSuggestions
} from '../../../../store/slices/general.js'
import { positionBrowser as getPosition } from '../../../../store/slices/geolocation.js'
import Adderss from '../Fields/Address.jsx'

const FiltersArea = () => {
  // radius values
  const marks = [
    {
      value: 5,
      label: '5 Km'
    },
    {
      value: 10,
      label: '10 Km'
    },
    {
      value: 15,
      label: '15 Km'
    },
    {
      value: 20,
      label: '20 Km'
    }
  ]

  // if true get the browser coordinates, if folse require serch location
  const [near, setNear] = useState(false)
  // the radius of the search
  const [radius, setRadius] = useState(5)
  // the latitude and longitude of the search centre
  const [position, setPosition] = useState({ latitude: null, longitude: null })

  // the filters
  const { gateways: filters } = useSelector((state) => state.filters)

  // on dialog open set default radius
  const defaultRadius = filters?.nearby?.distance || 5000

  // the browser component position
  const {
    data: localPosition,
    isError,
    isLoading
  } = useSelector((state) => state.geolocation)

  // default value store in general
  const {
    filters: {
      position: {
        entity: positionDefault = {
          address: '',
          latitude: null,
          longitude: null
        },
        near: nearDefault = false
      }
    }
  } = useSelector((state) => state.general)

  const dispatch = useDispatch()

  // set if use near or address position
  const handleChangeNear = (_, v) => {
    setNear(v)
  }

  // set the redious of the search
  const handleRadius = (_, v) => {
    setRadius(v)
  }

  // set the search center through the Address component
  const handleAddressChange = (addressPosition) => {
    if (!near) {
      setPosition(addressPosition)
    }
  }

  // set the search center if the near field change
  useEffect(() => {
    setPosition({ latitude: null, longitude: null })
    dispatch(setSuggestions([]))
    // if true get browser's position
    if (near) {
      dispatch(getPosition())
    }
    // if false get the position form Address component
    /** @TODO add else */

    // set default value for near into store
    dispatch(setFiltersDefaultNear(near))
  }, [near])

  // set search center from browser
  useEffect(() => {
    if (near) setPosition(localPosition)
  }, [localPosition])

  // if position and radios change set the new parameters of search
  useEffect(() => {
    const { latitude = null, longitude = null } = position

    // if latitude and logitude is not null and radius is present set the filters
    if (latitude !== null && longitude !== null && radius)
      dispatch(
        setFiltersGateways({
          ...filters,
          nearby: {
            distance: radius * 1000,
            center: _.omit(position, ['address'])
          }
        })
      )
    // else remove the parameters
    else dispatch(setFiltersGateways({ nearby: null }))

    dispatch(setFiltersDefaultPosition(position))
  }, [position, radius])

  useEffect(() => {
    setNear(nearDefault)
    setPosition(positionDefault)
    // set redius
    setRadius(defaultRadius / 1000)
  }, [])

  return (
    <>
      <Divider />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="left"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Box>
          <Typography
            align="left"
            sx={{ fontWeight: 'bold' }}
          >
            {t('filters.gateways.area.near.browser.title')}
          </Typography>
          <Typography
            align="left"
            sx={{ fontSize: '.7em' }}
          >
            {t('filters.gateways.area.near.browser.subtitle')}
          </Typography>
        </Box>
        <Box>
          <Switch
            checked={near}
            onChange={handleChangeNear}
          />
        </Box>
      </Stack>
      <Divider />
      {!near && (
        <>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={5}
            mt={3}
            mb={3}
          >
            <Box>
              <Typography
                align="center"
                variant="h4"
                sx={{ fontWeight: 'bold' }}
              >
                {t('filters.gateways.area.near.search.title')}
              </Typography>
            </Box>
            <Box>
              <Typography
                align="center"
                sx={{ fontSize: '.7em' }}
              >
                {t('filters.gateways.area.near.search.subtitle')}
              </Typography>
            </Box>
            <Box>
              <Adderss
                data={positionDefault}
                onChange={handleAddressChange}
              />
            </Box>
          </Stack>
        </>
      )}
      <Divider />
      <>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          spacing={2}
          mt={3}
          mb={3}
        >
          <Box>
            <Stack
              direction="column"
              justifyContent="space-evenly"
              spacing={2}
              mt={3}
              mb={3}
            >
              <Box>
                <Typography
                  align="center"
                  variant="h2"
                  sx={{ fontWeight: 'bold' }}
                >
                  {t('filters.gateways.area.near.radius.title')}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="column"
              justifyContent="fix-start"
              spacing={2}
              mt={3}
              mb={3}
            >
              <Box>
                <Typography align="center">
                  {t('filters.gateways.area.near.radius.subtitle')}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="column"
              justifyContent="fix-start"
              spacing={2}
              mt={3}
              mb={3}
            >
              <Box>
                <Typography
                  align="center"
                  variant="h1"
                  sx={{ fontWeight: 'bold' }}
                >
                  {radius} Km
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Slider
              aria-label="Range radius"
              orientation="vertical"
              getAriaValueText={(value) => {
                return `${value} Km`
              }}
              marks={marks}
              step={1}
              min={1}
              max={20}
              valueLabelDisplay="on"
              onChange={handleRadius}
              value={radius}
              disabled={isError && isLoading}
            />
          </Box>
        </Stack>
      </>
    </>
  )
}

export default FiltersArea
