import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'

const provisioningSetupApi = createApi({
  reducerPath: 'provisioningSetupApi',
  baseQuery: customFetchBase,
  tagTypes: ['provisioningSetup'],
  endpoints(builder) {
    return {
      fetchProvisioningSetup: builder.query({
        query: ({ brandEncoded, modelEncoded }) => {
          logger.log(
            `RTK API - Fetch provisioning setup for ${brandEncoded} / ${modelEncoded}`
          )
          return {
            url: `/brands/${brandEncoded}/models/${modelEncoded}/provisioningSetup`,
            method: 'GET'
          }
        },
        providesTags: ['provisioningSetup']
      })
    }
  }
})

export const { useFetchProvisioningSetupQuery } = provisioningSetupApi

export { provisioningSetupApi }
