import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Stack,
  Typography
} from '@mui/material'
import Joi from 'joi'
import React, { useEffect, useState, useRef } from 'react'
import { t } from 'i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useFieldValidation from '../../../helpers/fieldValidation.js'

import Logo from '../../Layout/Logo/Logo.jsx'
import { setCloseDialog, setOpenDialog } from '../../../store/slices/general.js'
import { useResetPasswordMutation } from '../../../store/apis/authentication-api.js'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'
import { useValidationEffect } from '../../../helpers/hooks.js'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
})

const ResetPassword = () => {
  const {
    dialogs: {
      resetPassword: { open: resetPasswordDialog = false }
    }
  } = useSelector((state) => state.general, shallowEqual)

  const [form, setForm] = useState({ email: '' }) // form input
  const [validation, setValidation] = useState({ isValid: false }) // validate form

  const dispatch = useDispatch()

  const [
    resetPassword,
    { isError: isResetPasswordError, isSuccess: isResetPasswordSuccess }
  ] = useResetPasswordMutation()

  const handleCloseResetPassword = () => dispatch(setCloseDialog('resetPassword'))

  const handleReturnToLogin = () => {
    dispatch(setCloseDialog('resetPassword'))
    dispatch(setOpenDialog('login'))
  }

  const handleChange = (prop) => (event) => setForm({ ...form, [prop]: event.target.value })

  const handleSendInstruction = () => resetPassword(form)

  useValidationEffect(() => {
    setValidation(useFieldValidation(form, schema))
  }, [form])

  useEffect(() => {
    if (isResetPasswordSuccess) {
      setForm({ email: '' })
      dispatch(setOpenDialog('resetPasswordSuccess'))
      dispatch(setCloseDialog('resetPassword'))
    }
  }, [isResetPasswordSuccess, isResetPasswordError])

  return (
    <>
    <ZDialog id='reset-password' 
      isOpen={resetPasswordDialog} 
      title={
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Logo />
          </Box>
        } 
        customTitle={
          <Stack
            direction="column"
            spacing={2}
            paddingTop={3}
            marginBottom={5}
          >
            <Typography
              variant="h3"
              align="center"
            >
              <strong>{t('users.resetPasswordForCityEye')}</strong>
            </Typography>
            <Typography
              variant="body2"
              align="center"
            >
              {t('users.resetPasswordForCityEyeDescription')}
            </Typography>
          </Stack>
        } 
        content={
        <Stack
          direction="column"
          py={2}
          spacing={2}
        >
          <FormControl
            required
            variant="standard"
            error={validation?.messages?.email?.length > 0}
          >
            <InputLabel htmlFor="email-input-field">email</InputLabel>
            <Input
              id="email-input-field"
              value={form.email}
              onChange={handleChange('email')}
            />
            <FormHelperText>
              {validation?.messages?.email?.join(', ')}
            </FormHelperText>
          </FormControl>
        </Stack>
        }
        actions={
        <Stack
          direction="column"
          py={2}
          spacing={2}
        >
          <Button
            variant="contained"
            onClick={handleSendInstruction}
            disabled={!validation.isValid}
          >
            {t('users.changePassword')}
          </Button>
          <Button
            variant="text"
            onClick={handleReturnToLogin}
            size="small"
          >
            <Typography variant="body2">{t('users.returnToLogin')}</Typography>
          </Button>
        </Stack>}
        actionsAlign='center'
        onClose={handleCloseResetPassword}
        maxWidth='sm' 
      />
    </>
  )
}

export default ResetPassword
