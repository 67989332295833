import { LOG_LEVEL } from '../../configs/environment.js'

// eslint-disable-next-line no-unused-vars
const NO_OP = (message, ...optionalParams) => {}

function ConsoleLogger(options) {
  const { level } = options || {}

  if (level === 'disabled') {
    this.error = NO_OP
    this.warn = NO_OP
    this.log = NO_OP

    return
  }

  this.error = console.error.bind(console)

  if (level === 'error') {
    this.warn = NO_OP
    this.log = NO_OP

    return
  }

  this.warn = console.warn.bind(console)

  if (level === 'warn') {
    this.log = NO_OP

    return
  }

  this.log = console.log.bind(console)
}

export const logger = new ConsoleLogger({ level: LOG_LEVEL })
