export const acceptInvite = {
  firstName: null,
  lastName: null,
  email: null,
  phoneNumber: null,
  password: null,
  confirmPassword: null,
  privacy: false,
  legal: false,
  marketing: false,
  languageCode: 'it'
}
