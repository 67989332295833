import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  CheckRounded as CheckRoundedIcon,
  ChevronRightRounded as ChevronRightRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  EditRounded as EditRoundedIcon,
  SaveRounded as SaveRoundedIcon,
  CloseRounded as CloseRoundedIcon
} from '@mui/icons-material'
import { t } from 'i18next'
import _ from 'lodash'
import Can from '../../Layout/Can/Can.jsx'

const RoleCard = ({ isSuper, role, type }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [payload, setPayload] = useState(role)

  const entities = _.keys(role.permissions).sort() || []

  const handleOpen = () => {
    setIsOpen(!isOpen)
  }

  const handleEdit = () => {
    setIsEdit(!isEdit)
  }

  const handleChange = (entity, action, value) => {
    setPayload({
      ...payload,
      permissions: {
        ...payload.permissions,
        [entity]: {
          ...payload.permissions[entity],
          [action]: value
        }
      }
    })
  }

  return (
    <>
      <Card sx={{ marginBottom: 3 }}>
        <CardHeader
          title={
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              {!isEdit && (
                <>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {isOpen ? (
                      <ExpandMoreRoundedIcon
                        onClick={() => handleOpen()}
                        aria-expanded={isOpen}
                        aria-label="show more"
                      />
                    ) : (
                      <ChevronRightRoundedIcon
                        onClick={() => handleOpen()}
                        aria-expanded={isOpen}
                        aria-label="show less"
                      />
                    )}
                    <Box>
                      <Typography variant="h4">{role.name}</Typography>
                      {isSuper && (
                        <Typography
                          variant="body2"
                          color="red"
                        >
                          superuser
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                  <ButtonGroup size="small">
                    <Can action={isSuper ? 'superroles_u' : 'roles_u'}>
                      <Button
                        variant="text"
                        key={`${type}-${role.name}-edit`}
                      >
                        <EditRoundedIcon
                          size={16}
                          color="black"
                          onClick={() => handleEdit()}
                        />
                      </Button>
                    </Can>
                  </ButtonGroup>
                </>
              )}
              {isEdit && (
                <Can action={isSuper ? 'superroles_u' : 'roles_u'}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {isOpen ? (
                      <ExpandMoreRoundedIcon
                        onClick={() => handleOpen()}
                        aria-expanded={isOpen}
                        aria-label="show less"
                      />
                    ) : (
                      <ChevronRightRoundedIcon
                        onClick={() => handleOpen()}
                        aria-expanded={isOpen}
                        aria-label="show less"
                      />
                    )}
                    <Box>
                      <Typography variant="h4">{role.name}</Typography>
                      {isSuper && (
                        <Typography
                          variant="body2"
                          color="red"
                        >
                          superuser
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                  <ButtonGroup size="small">
                    <Button
                      variant="text"
                      key={`${type}-${role.name}-delete`}
                    >
                      <SaveRoundedIcon
                        size={16}
                        color="black"
                        onClick={() => handleEdit()}
                      />
                    </Button>
                  </ButtonGroup>
                </Can>
              )}
            </Stack>
          }
        />
        <Collapse
          in={isOpen}
          timeout="auto"
        >
          <CardContent>
            <Table aria-label="role table">
              <TableHead>
                <TableRow key="table-heading">
                  <TableCell align="center">{t('common.entity')}</TableCell>
                  <TableCell align="center">{t('common.create')}</TableCell>
                  <TableCell align="center">{t('common.read')}</TableCell>
                  <TableCell align="center">{t('common.update')}</TableCell>
                  <TableCell align="center">{t('common.delete')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {entities.map((e) => (
                  <TableRow
                    key={`${type}-${e}`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      height: '5rem'
                    }}
                  >
                    <TableCell align="center">
                      <Typography variant="body">{e}</Typography>
                    </TableCell>
                    {!isEdit && (
                      <>
                        <TableCell align="center">
                          {payload.permissions[e].c ? (
                            <CheckRoundedIcon
                              size={32}
                              color="green"
                            />
                          ) : (
                            <CloseRoundedIcon
                              size={24}
                              color="red"
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {payload.permissions[e].r ? (
                            <CheckRoundedIcon
                              size={24}
                              color="green"
                            />
                          ) : (
                            <CloseRoundedIcon
                              size={24}
                              color="red"
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {payload.permissions[e].u ? (
                            <CheckRoundedIcon
                              size={24}
                              color="green"
                            />
                          ) : (
                            <CloseRoundedIcon
                              size={24}
                              color="red"
                            />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {payload.permissions[e].d ? (
                            <CheckRoundedIcon
                              size={24}
                              color="green"
                            />
                          ) : (
                            <CloseRoundedIcon
                              size={24}
                              color="red"
                            />
                          )}
                        </TableCell>
                      </>
                    )}

                    {isEdit && (
                      <>
                        <TableCell align="center">
                          <Checkbox
                            key={`${e}-create`}
                            name={`${e}-create`}
                            checked={payload?.permissions?.[e]?.c}
                            onChange={(_, v) => handleChange(e, 'c', v)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            key={`${e}-read`}
                            name={`${e}-read`}
                            checked={payload?.permissions?.[e]?.r}
                            onChange={(_, v) => handleChange(e, 'r', v)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            key={`${e}-update`}
                            name={`${e}-update`}
                            checked={payload?.permissions?.[e]?.u}
                            onChange={(_, v) => handleChange(e, 'u', v)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Checkbox
                            key={`${e}-delete`}
                            name={`${e}-delete`}
                            checked={payload?.permissions?.[e]?.d}
                            onChange={(_, v) => handleChange(e, 'd', v)}
                          />
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Collapse>
      </Card>
    </>
  )
}

RoleCard.propTypes = {
  role: PropTypes.object.isRequired,
  isSuper: PropTypes.bool,
  type: PropTypes.string
}

export default RoleCard
