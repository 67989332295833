import {
  Box,
  ButtonGroup,
  Chip,
  IconButton,
  Pagination,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  ContentCopyRounded as ContentCopyRoundedIcon,
  CheckRounded as CheckRoundedIcon,
  MailRounded as MailRoundedIcon,
  DeleteRounded as DeleteRoundedIcon
} from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import {
  setErrorAlertMessage,
  setOpenDialog,
} from '../../../store/slices/general.js'
import {
  useFetchInvitesQuery,
  useResendInviteMutation,
  useDeleteInviteMutation
} from '../../../store/index.js'

import { APP_BASE_URL } from '../../../configs/environment.js'
import { useSearchParams } from 'react-router-dom'

const DEFAULT_PAGE_SIZE = 25
const InvitesPendingTable = () => {
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')

  const [copied, setCopied] = useState(null)
  const [page, setPage] = useState(1)


  const dispatch = useDispatch()

  const {
    data: invitesPending = [],
    isError: isInvitePendingError,
    isLoading: isInvitePendingLoading,
    refetch
  } = useFetchInvitesQuery(
    fullText ? { page, pageSize: DEFAULT_PAGE_SIZE, fullText } : { page, pageSize: DEFAULT_PAGE_SIZE }
  )

  const [
    resendInvite,
    {
      isError: isResendInviteError,
      isSuccess: isResendInviteSuccess,
      error: resendInviteError
    }
  ] = useResendInviteMutation()

  useEffect(() => {
    if (resendInviteError?.data) {
      dispatch(setOpenDialog('errorAlert'))

      if (resendInviteError.data.error) {
        dispatch(
          setErrorAlertMessage(
            `${resendInviteError.data.statusCode} ${resendInviteError.data.error}`
          )
        )
      } else if (resendInviteError.error)
        dispatch(setErrorAlertMessage(resendInviteError.error))
    }
  }, [isResendInviteError])

  useEffect(() => {
    if (isResendInviteSuccess) {
      dispatch(setOpenDialog('successAlert'))
    }
  }, [isResendInviteSuccess])

  const [
    deleteInvite,
    {
      isError: isDeleteInviteError,
      isSuccess: isDeleteInviteSuccess,
      error: deleteInviteError
    }
  ] = useDeleteInviteMutation()

  useEffect(() => {
    if (deleteInviteError?.data) {
      dispatch(setOpenDialog('errorAlert'))

      if (deleteInviteError.data.error) {
        dispatch(
          setErrorAlertMessage(
            `${deleteInviteError.data.statusCode} ${deleteInviteError.data.error}`
          )
        )
      } else if (deleteInviteError.error)
        dispatch(setErrorAlertMessage(deleteInviteError.error))
    }
  }, [isDeleteInviteError])

  useEffect(() => {
    if (isDeleteInviteSuccess) {
      dispatch(setOpenDialog('successAlert'))
    }
  }, [isDeleteInviteSuccess])

  // useEffect(() => {
  //   const totalCount = invitesPending.totalCount
  //   dispatch(
  //     setCount({ target: 'invitesPending', value: parseInt(totalCount) })
  //   )
  // }, [invitesPending])

  useEffect(() => {
    if (!fullText) return
    refetch()
  }, [fullText])

  const handleResend = (email, inviteId) => {
    resendInvite({ email, inviteId })
  }

  const handleDelete = (inviteId) => {
    deleteInvite(inviteId)
  }

  const handleContentCopyRounded = (id) => {
    setCopied(id)
    navigator.clipboard.writeText(`${APP_BASE_URL}/invites/${id}`)
  }

  const handleChange = (_, value) => {
    setPage(value)
  }

  return (
    <>
      <Stack
        my={4}
        spacing={2}
      >
        {!isInvitePendingError &&
          !isInvitePendingLoading &&
          invitesPending.data.map((invite) => (
            <Box key={invite?.id}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
                mx={2}
                my={1}
              >
                <Box sx={{ width: '50%' }}>
                  <Typography variant="h5">{invite.email}</Typography>
                  <Typography
                    variant="body2"
                    id={`invite-url-${invite.email}`}
                  >
                    <small>{`${APP_BASE_URL}/invites/${invite.id}`}</small>
                  </Typography>
                </Box>
                <Box sx={{ width: '30%' }}>
                  <Chip
                    color="primary"
                    variant="standard"
                    label={invite.organization?.name}
                  />
                </Box>
                <Box sx={{ width: '10%' }}>
                  <Chip
                    color="primary"
                    variant="standard"
                    label={invite.role.name}
                  />
                </Box>
                <Box sx={{ width: '10%' }}>
                  <ButtonGroup size="small">
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDelete(invite.id)}
                        id={`delete-invite-${invite.email}`}
                      >
                        <DeleteRoundedIcon color="error" fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Resend">
                      <IconButton
                        onClick={() => handleResend(invite.email, invite.id)}
                        id={`resend-invite-${invite.email}`}
                      >
                        <MailRoundedIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Copy link">
                      <IconButton
                        onClick={() => handleContentCopyRounded(invite.id)}
                        id={`copy-link-invite-${invite.email}`}
                      >
                        {copied !== invite.id ? <ContentCopyRoundedIcon color="primary" fontSize="small" /> : <CheckRoundedIcon color="primary" fontSize="small" />}
                      </IconButton>
                    </Tooltip>
                  </ButtonGroup>
                </Box>
              </Stack>
            </Box>
          ))}
      </Stack>
      <Box
        display="flex"
        justifyContent="center"
      >
        {invitesPending &&
          !isInvitePendingError &&
          !isInvitePendingLoading &&
          invitesPending?.totalCount > DEFAULT_PAGE_SIZE && (
            <Pagination
              count={Math.ceil(invitesPending?.totalCount / DEFAULT_PAGE_SIZE)}
              page={page}
              onChange={handleChange}
              showFirstButton
              showLastButton
            />
          )}
      </Box>
    </>
  )
}

export default InvitesPendingTable
