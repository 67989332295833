import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControlLabel,
  Grid2,
  Icon,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import { Tag as TagIcon } from '@mui/icons-material'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchTagsFilterQuery } from '../../../../store'
import { setFiltersDevices } from '../../../../store/slices/filters.js'

const Tags = () => {
  const {
    data: tagsFilterOptions = [],
    isError: isTagsFilterError,
    isLoading: isTagsFilterLoading
  } = useFetchTagsFilterQuery()

  const { devices: filters } = useSelector((state) => state.filters)

  const dispatch = useDispatch()

  const handleChange = (_, v) => {
    if (Array.isArray(v)) {
      dispatch(
        setFiltersDevices({
          tags: v.map((i) => i.id)
        })
      )
    }
  }

  const handleOperatorChange = (_, v) => {
    dispatch(
      setFiltersDevices({
        tagSearchMode: v ? 'AND' : null
      })
    )
  }

  return (
    <>
      <Typography
        align="left"
        sx={{ fontWeight: 'bold' }}
      >
        {t('filters.devices.other.tags.label')}
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <Grid2
          container
          spacing={1}
          display="flex"
          alignItems="center"
        >
          <Grid2 size={10}>
            <Autocomplete
              multiple
              disabled={isTagsFilterLoading && isTagsFilterError}
              id="tags-filter"
              options={tagsFilterOptions}
              onChange={handleChange}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              loading={isTagsFilterLoading}
              getOptionLabel={(option) => option.name}
              value={tagsFilterOptions.filter((i) => {
                const { id } = i
                const { tags = [] } = filters
                if (tags && tags.length > 0) {
                  return tags.includes(id)
                } else return false
              })}
              renderOption={(props, option) => (
                <ListItem
                  {...props}
                  divider
                  key={option.id}
                >
                  <ListItemIcon>
                    <TagIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '0.8rem',
                      fontWeight: 500
                    }}
                    primary={option.name}
                  />
                </ListItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder={t('filters.devices.other.tags.placeholder')}
                  slotProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          color="inherit"
                          edge="end"
                        >
                          {isTagsFilterLoading && (
                            <CircularProgress
                              edge="end"
                              size={20}
                            />
                          )}
                        </Icon>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size="grow">
            <FormControlLabel
              value="top"
              control={
                <Switch
                  onChange={handleOperatorChange}
                  checked={filters.tagSearchMode === 'AND'}
                  disabled={isTagsFilterError || isTagsFilterLoading}
                />
              }
              labelPlacement="top"
              label={filters.tagSearchMode === 'AND' ? 'And' : 'Or'}
            />
          </Grid2>
        </Grid2>
      </Box>
    </>
  )
}

export default Tags
