import { Divider } from '@mui/material'
import React from 'react'
import { ActionTypeFilter, IsUsedInRuleFilter } from './index.js'
import PropTypes from 'prop-types'

const ActionsOthersFilters = ({ filters, onChange = () => {} }) => {
  return (
    <>
      <IsUsedInRuleFilter
        filters={filters}
        onChange={(value) => onChange({ ...filters, isUsedInRule: value })}
      />
      <Divider />
      <ActionTypeFilter
        filters={filters}
        onChange={(value) => onChange({ ...filters, actionTypes: value })}
      />
    </>
  )
}

ActionsOthersFilters.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func
}

export default ActionsOthersFilters
