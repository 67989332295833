import {
  Box,
  Grid2,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
  Switch
} from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'
import { useSearchDownlinksQuery } from '../../../store'
import DynamicForm from '../../Shared/Forms/DynamicForm'
import PropTypes from 'prop-types'
import Show from '../../Layout/Can/Show'

const ConfigureDownlink = ({
  device,
  onChange = () => { },
  downlinkPayload
}) => {
  const [savePreset, setSavePreset] = useState(false)
  const [fieldsPayload, setFieldsPayload] = useState({})
  const [downlink, setDownlink] = useState('')

  const { data: downlinks, isLoading: isDownlinksLoading } =
    useSearchDownlinksQuery({
      brand: device?.brandEncoded,
      model: device?.modelEncoded
    })

  const handleDownlinksChange = (event) => {
    const selectedDownlink = event.target.value
    const defaultFieldsPayload = selectedDownlink.fields
      .map((f) => f.internalName)
      .reduce((acc, internalName) => ({ ...acc, [internalName]: '' }), {})

    setFieldsPayload(defaultFieldsPayload)
    setDownlink(selectedDownlink)

    onChange({
      ...downlinkPayload,
      downlink: selectedDownlink,
      defaultFieldsPayload
    })
  }

  const handleNameChange = (event) => {
    onChange({
      ...downlinkPayload,
      name: event.target.value
    })
  }

  const handleSavePreset = (event) => {
    const {
      target: { checked }
    } = event

    setSavePreset(checked)
    onChange({
      ...downlinkPayload,
      savePreset: checked
    })
  }

  const handleDynamicFormChange = (value, isValid) => {
    setFieldsPayload(value)
    onChange({
      ...downlinkPayload,
      fieldsPayload: value,
      isValid: isValid
    })
  }
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        display="flex"
        alignItems="center"
      >
        <Grid2 size={6}>
          <FormControlLabel
            control={<Switch onClick={handleSavePreset} />}
            label={t('actions.savePreset')}
          />
        </Grid2>

        <Grid2 size={6}>
          <TextField
            variant="standard"
            label={t('common.name')}
            fullWidth
            onChange={handleNameChange}
            disabled={!savePreset}
          />
        </Grid2>
      </Grid2>

      <Typography
        variant="h5"
        align="center"
        my={5}
      >
        {t('actions.parameters')}
      </Typography>

      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <FormControl fullWidth>
            <InputLabel id="downlink-select-label">
              {t('actions.downlink')}
            </InputLabel>
            <Select
              labelId="downlink-select-label"
              id="downlink-select"
              value={downlink}
              label="Downlink"
              onChange={handleDownlinksChange}
            >
              {!isDownlinksLoading &&
                downlinks?.map((downlink, i) => (
                  <MenuItem
                    key={`downlink-${i}`}
                    value={downlink}
                  >
                    {downlink.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid2>

        <Grid2
          display="flex"
          justifyContent="flex-start"
        >
          <Show when={() => downlinkPayload.downlink?.downlinkAckByUplink}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  value={downlinkPayload.downlink?.downlinkAckByUplink}
                />
              }
              label={t('actions.sendUplink')}
            />
          </Show>
        </Grid2>
        <Grid2
          item
          width={'100%'}
        >
          {downlink && (
            <DynamicForm
              fieldsPayload={fieldsPayload}
              fields={downlink.fields}
              onChange={handleDynamicFormChange}
            />
          )}
        </Grid2>
      </Grid2>
    </Box>
  )
}

ConfigureDownlink.propTypes = {
  device: PropTypes.object,
  downlinkPayload: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ConfigureDownlink
