import { Alert, AlertTitle, Slide, Snackbar } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCloseDialog } from '../../../store/slices/general.js'
import { t } from 'i18next'
import Show from '../../Layout/Can/Show.jsx'

const SuccessAlert = () => {
  const dispatch = useDispatch()

  const {
    dialogs: {
      successAlert: { open: isOpen = false }
    }
  } = useSelector((state) => state.general)

  return (
    <Show when={() => isOpen}>
      <Snackbar
        open={isOpen}
        onClose={() => dispatch(setCloseDialog('successAlert'))}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={(props) => (
          <Slide
            {...props}
            direction="down"
          />
        )}
        sx={{
          width: '20%'
        }}
      >
        <Alert
          severity="success"
          sx={{
            width: '100%'
          }}
        >
          <AlertTitle>{t('common.operationCompleted')}</AlertTitle>
          {t('common.operationCompletedSuccessfully')}
        </Alert>
      </Snackbar>
    </Show>
  )
}

export default SuccessAlert
