import {
  Box,
  Button,
  Stack,
  Typography
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDeleteRuleMutation } from '../../../store'
import { t } from 'i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import RuleCard from '../Card/RuleCard.jsx'
import RulesFilters from '../Filters/RulesFilters.jsx'
import ConfirmDialog from '../../Shared/Dialogs/ConfirmDialog'
import { setFiltersRules } from '../../../store/slices/filters'
import Show from '../../Layout/Can/Show'
import {
  useLazyGetRuleQuery,
  useSearchRulesMutation
} from '../../../store/apis/rules-api'
import { useParams, useSearchParams } from 'react-router-dom'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert'
import AddRule from '../Dialogs/AddRule'
import RuleDetail from '../Dialogs/RuleDetail'
import ZList from '../../Shared/Components/ZList'

const DEFAULT_PAGE_SIZE = 25

const AutomationRulesList = () => {
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')

  const dispatch = useDispatch()
  const { tab, entityId } = useParams()
  const { rules: filters } = useSelector((state) => state.filters, shallowEqual)

  const [ruleId, setRuleId] = useState(null)
  const [ruleName, setRuleName] = useState(null)
  const [isAddRuleOpen, setIsAddRuleOpen] = useState(false)
  const [isRuleDetailOpen, setIsRuleDetailOpen] = useState(false)
  const [selectedRule, setSelectedRule] = useState(null)
  const [confirmDeleteRuleIsOpen, setConfirmDeleteRuleIsOpen] = useState(false)

  const [page, setPage] = useState(1)

  const [
    deleteRule,
    {
      error: deleteRuleError,
      isError: isDeleteRuleError,
      isSuccess: isDeleteRuleSuccess
    }
  ] = useDeleteRuleMutation()

  const handleChangePage = (_, value) => setPage(value)

  const [
    searchRules,
    { data: rules, isLoading: isFetchRulesLoading, isError: isFetchRulesError }
  ] = useSearchRulesMutation()

  const [getRule, ruleFromUrl] = useLazyGetRuleQuery()

  const handleOnEdit = (rule) => {
    setSelectedRule(rule)
    setIsRuleDetailOpen(true)
  }

  const handleOnDelete = (rule) => {
    setRuleId(rule.id)
    setRuleName(rule.name)
    setConfirmDeleteRuleIsOpen(true)
  }

  const handleCloseRule = (reload) => {
    if (reload) {
      searchRules({ filters: { ...filters, fullText }, page, pageSize: DEFAULT_PAGE_SIZE })
      setPage(1)
    }
    setSelectedRule(null)
    setIsRuleDetailOpen(false)
    setIsAddRuleOpen(false)
  }

  useEffect(() => {
    if (tab !== 'rules') {
      return
    }

    getRule(entityId)
  }, [tab, entityId])

  useEffect(() => {
    if (ruleFromUrl.data !== undefined) {
      setIsRuleDetailOpen(true)
      setSelectedRule(ruleFromUrl.data)
    }
  }, [ruleFromUrl])

  useEffect(() => {
    searchRules({ filters: { ...filters, fullText }, page, pageSize: DEFAULT_PAGE_SIZE })
    setPage(1)
  }, [filters, fullText, isDeleteRuleSuccess])

  useEffect(() => {
    searchRules({ filters: { ...filters, fullText }, page, pageSize: DEFAULT_PAGE_SIZE })
  }, [page])

  useOpenResultAlert(deleteRuleError, { isError: isDeleteRuleError, isSuccess: isDeleteRuleSuccess })

  const handleFiltersChange = (filters) => {
    dispatch(setFiltersRules(filters))
  }

  const handleOnConfirmDeleteRule = () => {
    deleteRule({ id: ruleId })
    setConfirmDeleteRuleIsOpen(false)
  }

  const rulesList = rules?.data.map((rule, index) => (
    <RuleCard
      index={index}
      key={`rule-${index}`}
      rule={rule}
      onDelete={handleOnDelete}
      onEdit={handleOnEdit}
    />
  ))

  const rulesFilters = (
    <RulesFilters
      filters={filters}
      onChange={handleFiltersChange}
    />)

  return (
    <>
      <Stack
        direction="row"
        marginBottom={3}
      >
        <Typography variant="body1">
          <strong>{t('automations.rules')}</strong>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          id="rules-list-create-new-rule"
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => setIsAddRuleOpen(true)}
        >
          {t('rules.createNew')}
        </Button>
      </Stack>

      <ZList count={rules?.count} filters={rulesFilters} entities={rulesList} page={page} isLoading={isFetchRulesLoading || isFetchRulesError} onPageChange={handleChangePage} />

      <Show when={() => isAddRuleOpen}>
        <AddRule
          isOpen={isAddRuleOpen}
          onClose={handleCloseRule}
        />
      </Show>
      <Show when={() => isRuleDetailOpen && !!selectedRule}>
        <RuleDetail
          isOpen={isRuleDetailOpen}
          onClose={handleCloseRule}
          selectedRule={selectedRule}
          isEdit={true}
        />
      </Show>
      
      <ConfirmDialog
        isOpen={confirmDeleteRuleIsOpen}
        title={t('rules.deleteRule')}
        message={`${t('rules.areYouSureYouWantToDelete')} "${ruleName}"?`}
        onConfirm={handleOnConfirmDeleteRule}
        onClose={() => setConfirmDeleteRuleIsOpen(false)}
      />
    </>
  )
}

export default AutomationRulesList
