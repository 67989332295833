export default {
  translations: {
    actions: {
      action: 'Azione',
      actionName: 'Nome azione',
      actionType: 'Tipologia azione',
      actionUrl: 'URL azione',
      add: 'Aggiungi azione',
      alarm: 'Allarme',
      all: 'Tutte',
      areYouSureYouWantToDelete: "Sei sicuro di voler eliminare l'azione",
      availableSensors: 'Sensori disponibili',
      configureAction: 'Configura azione',
      createNew: 'Crea nuova azione',
      sendNew: 'Invia una nuova azione',
      deleteAction: 'Elimina azione',
      downlink: 'Downlink',
      downlinkAlreadyInQueue: 'Downlink presente in coda',
      downlinkNotSentError: 'Errore, downlink non inviato',
      emailsToNotify: 'Email da notificare',
      findSensor: 'Trova sensore',
      httpHeaders: 'Header HTTP',
      modelNotFound: 'Nessun modello trovato',
      noActionsForSelectedDevice:
        'Non è possibile associare un azione al device selezionato',
      notification: 'Notifica',
      notificationChoices: 'Scelte di notifica',
      notifyAdmin: 'Notifica admin',
      notifyEditor: 'Notifica editor',
      notifyViewer: 'Notifica viewer',
      parameters: 'Parametri',
      port: 'Porta',
      preset: 'Preset delle azioni',
      savePreset: 'Salva come preset',
      reviewAndConfirm: 'Rivedi e conferma',
      searchByBrandAndModel: 'Cerca per marca e modello',
      searchByName: 'Cerca per nome',
      selectAnAction: `Seleziona un'azione`,
      selectDevice: 'Seleziona dispositivo',
      send: 'Invia una azione',
      sendANewAction: 'Invia una nuova azione',
      sendUplink: 'Invia uplink',
      unused: 'Inutilizzate',
      used: 'Utilizzate',
      useState: 'Stato di utilizzo',
      webhook: 'Webhook',
      sendTriggerInformation: 'Invia informazioni trigger'
    },
    automations: {
      actions: 'Azioni',
      add: 'Crea automazione',
      addToFavorites: 'Aggiungi ai preferiti',
      all: 'Tutte',
      disabled: 'Disabilitate',
      enabled: 'Abilitate',
      errors: {
        actionsAndTriggersNotInSameOrg:
          'Attenzione!Le azioni e il trigger selezionato appartengono ad organizzazioni diverse!',
        actionsNotInSameOrg:
          'Attenzione!Le azioni selezionate non appartengono tutte alla stessa organizazzione!',
        downlinkInScheduledTrigger:
          'Attenzione!Non è possibile selezionare azioni diverse da downlink con trigger schedulati.',
        cantSelectAllValues: 'Non è possibile selezionare tutti i valori!'
      },
      linkedRules: 'visualizza regole associate',
      noRecipients: 'Nessun destinatario',
      recipients: 'Destinatari',
      rules: 'Regole',
      triggers: 'Triggers',
      usedInARule: 'Usato in una regola',
      validity: 'Validità',
      viewLinkedActions: 'Visualizza azioni collegate',
      viewLinkedRules: 'Visualizza regole collegate'
    },
    charts: {
      average: 'Media',
      inTheSelectedPeriod: 'Nel periodo selezionato'
    },
    common: {
      actions: 'Azioni',
      active: 'Attiva',
      address: 'Indirizzo',
      alarmed: 'In allarme',
      alarms: 'Allarmi',
      all: 'Tutti',
      apiCalls: 'Chiamate',
      automations: 'Automazioni',
      back: 'Indietro',
      backToList: 'Torna alla lista',
      brand: 'Marca',
      cancel: 'Annulla',
      choose: 'Scegli',
      configuration: 'Configurazione',
      confirm: 'Conferma',
      confirmAndSave: 'Conferma e salva',
      contacts: 'Contatti',
      coordinates: 'Coordinate',
      create: 'Creare',
      createdAt: 'Creato il',
      createdBy: 'Creato da',
      dashboard: 'Dashboard',
      day_one: '{{count}} giorno',
      day_other: '{{count}} giorni',
      delete: 'Elimina',
      description: 'Descrizione',
      device: 'Dispositivo',
      devices: 'Dispositivi',
      disable: 'Disattiva',
      download: 'Scarica',
      edit: 'Modifica',
      email: 'Email',
      enable: 'Attiva',
      enabled: 'Abilitato',
      endedAt: 'Finito il',
      entity: 'Entità',
      error: 'Errore',
      events: 'Eventi',
      filter: 'Filtri',
      filters: {
        other: 'Altri filtri'
      },
      findAddress: 'Cerca indirizzo',
      findCoordinates: 'Inserisci coordinate',
      from: 'Dal',
      fromHour: 'Dalle ore',
      gateways: 'Gateways',
      genericRequestError: 'Si è verificato un errore durante la richiesta',
      hideFilters: 'Nascondi i filtri',
      home: 'Home',
      hours: 'Orario',
      hour_one: '{{count}} ora',
      hour_other: '{{count}} ore',
      interval: 'Intervallo',
      invites: 'Inviti',
      itemsFound: 'Elementi trovati {{count}}',
      key: 'Chiave',
      language: 'Lingua',
      lastMonth: 'Ultimo mese',
      lastTwoWeeks: 'Ultimi 14 giorni',
      lastUpdate: 'Ultimo aggiornamento',
      lastWeek: 'Ultima settimana',
      lastYear: 'Ultimo anno',
      latitude: 'Latitudine',
      login: 'Accedi',
      logout: 'Logout',
      longitude: 'Longitudine',
      management: 'Management',
      measures: 'Misure',
      minute_one: '{{count}} minuto',
      minute_other: '{{count}} minuti',
      mobile: 'Telefono',
      model: 'Modello',
      myDashboard: 'La mia dashboard',
      myProfile: 'Il mio profilo',
      name: 'Nome',
      network: 'Rete',
      next: 'Successivo',
      no: 'No',
      noItemsFound: 'Nessun elemento trovato per la ricerca effettuata',
      noMatch: 'Pagina non trovata',
      noPresent: 'non presente',
      notActive: 'Non attiva',
      notifications: 'Notifiche',
      offline: 'Offline',
      online: 'Online',
      operationCompleted: 'Operazione completata',
      operationCompletedSuccessfully: 'Operazione completata con successo',
      organization: 'Organizzazione',
      organizations: 'Organizzazioni',
      password: 'Password',
      previous: 'Precedente',
      privacy: 'Documenti e Privacy',
      profile: 'Profilo',
      read: 'Leggere',
      reset: 'Reset',
      role: 'Ruolo',
      roles: 'Ruoli',
      rowsPerPage: 'Elementi per pagina',
      rssi: 'rssi',
      rule: 'Regola',
      save: 'Salva',
      search: 'Cerca',
      searchDevice: 'Cerca dispositivo',
      search: 'Ricerca globale',
      serial: 'Seriale',
      second_one: '{{count}} secondo',
      second_other: '{{count}} secondi',
      selectPage: 'Pagina',
      snr: 'snr',
      startedAt: 'Iniziato il',
      statistics: 'Statistiche',
      status: 'Status',
      superroles: 'Super ruoli',
      surname: 'Cognome',
      technology: 'Tecnologia',
      timezone: 'Fuso orario',
      to: 'Al',
      toHour: 'Alle ore',
      today: 'Oggi',
      tolerance: 'Tolleranza',
      triggers: 'Trigger',
      type: 'Tipo',
      typology: 'Tipologia',
      update: 'Modificare',
      updatedAt: 'Aggiornato il',
      updatedBy: 'Aggiornato da',
      url: 'Url',
      user: 'Utente',
      userProfile: 'Profilo Utente',
      users: 'Utenti',
      value: 'Valore',
      vat: 'IVA',
      warning: 'Attenzione',
      webhookErrors: 'Errori webhooks',
      yes: 'Si',
      yesterday: 'Ieri'
    },
    devices: {
      actionsDevice: {
        title: 'Storico azioni'
      },
      activeSince: 'Attivo dal',
      add: 'Aggiungi dispositivo',
      addBrandModel: {
        brand: {
          name: 'Nome',
          nameEncoded: 'Nome encoded',
          title: 'Marca'
        },
        model: {
          file: 'Datasheet (formati ammessi: xls, xml, csv)',
          group: 'Gruppo',
          missingCommunicationTimeout: 'Missing comunication timeout',
          name: 'Nome',
          nameEncoded: 'Nome encoded',
          netserverProfileId: 'Id profilo netserver',
          requiredDownlinksAck: 'Required downlinks ack',
          sourceType: 'Source type',
          title: 'Modello',
          type: 'Tipo'
        },
        title: 'Aggiungi nuova marca-modello'
      },
      added: 'Aggiunto il',
      addInfo: 'Informazioni generali',
      addNetwork: 'Configurazione di rete',
      addParsing: 'Configurazione parser',
      addPosition: 'Posizione dispositivo',
      addPublication: 'Pubblicazione',
      addTags: '+ Aggiungi tag',
      addVendorModel: 'Aggiungi marca / modello',
      alarms: 'Allarmi del dispositivo',
      appKey: 'App Key',
      askNewModel: 'Richiedi nuova marca / modello',
      board: 'Bacheca',
      cantFindModel: 'Non trovi il dispositivo che stai cercando?',
      categories: 'Categorie',
      chooseBrand: 'Scegli il produttore',
      chooseLoraType: 'Scegli la tipologia LoraWAN®',
      chooseModel: 'Scegli il modello',
      chooseNetworkServer: 'Scegli il network server',
      chooseOrganization: "Scegli l'organizzazione",
      deleteDevice: {
        helper: "Incolla qui l'id del dispositivo",
        subtitle: "Per procedere conferma l'id del dispositivo",
        title: 'Vuoi eliminare il dispositivo?'
      },
      description: 'Descrizione',
      deviceAddress: 'Indirizzo sensore',
      deviceAlreadyExists: 'Dispositivo già presente in CityEye',
      deviceEUI: 'Device EUI',
      deviceInfo: 'Informazioni sul sensore',
      deviceInformation: 'Informazioni sensore',
      events: 'Eventi del dispositivo',
      eventsDevice: {
        title: 'Storico eventi'
      },
      exportMeasures: 'Esporta misure',
      exportStatistics: 'Esporta statistiche',
      filterAlarmed: 'Mostra solo dispositivi in allarme',
      found_one: '{{count}} dispositivo trovato',
      found_other: '{{count}} dispositivi trovati',
      group: 'Gruppo',
      hidden: 'Nascosto',
      import: 'Importa dispositivi',
      import_downloadTemplate: 'Scarica il template vuoto',
      import_uploadTemplate: 'Carica il template compilato',
      importDevicesForm: {
        box: {
          error: {
            description:
              'Non è stato possibile completare la richiesta a causa dei seguenti errori:',
            title: 'Ops... Si è verificato un errore!'
          },
          success: {
            description:
              'La richiesta di importazione è avvenuta correttamente, riceverai una mail con il riepilogo dei sensori importati.',
            title: 'Importazione iniziata'
          }
        },
        form: {
          errors: {
            device: 'dispositivo',
            field: 'campo',
            fileEmpty: 'Il file da importare è vuoto',
            notValid: 'valore non valido',
            thresholdsExceeded: "Superamento soglia sensori dopo l'importazione"
          },
          loadTemplate: {
            button: '2 - Carica template compilato',
            label:
              'Carica il template compilato con i dati relativi ai sensori da importare'
          },
          networkServer: {
            description:
              'Scegli un network server a cui associare i sensori importati',
            label: 'Network server',
            placeholder: 'Cerca un network server'
          },
          organization:
            'Scegli una organizzazione alla quale associare i sensori importati'
        }
      },
      inputDescription:
        'Utilizza questo campo per aggiungere informazioni utili ad identificiare il dispositivo',
      inputOrganization:
        "Seleziona l'organizzazione per cui vuoi aggiungere questo dispositivo",
      inputTags:
        'Utilizza questo campo per taggare il dispositivo, puoi inserire uno o più tag',
      joinEUI: 'Join EUI',
      lastUpdate: 'Ultimo agg.',
      listeningGateways: 'Gateway in ascolto',
      loraType: 'Tipo',
      measureName: 'Grandezza',
      measureNameDoc: 'Nome campo API',
      measures: 'Grandezze misurate',
      more_one: '+ {{count}} altra grandezza',
      more_other: '+ {{count}} altre grandezze',
      networkServer: 'Networkserver',
      noCoordinates1: 'Coordinate assenti o non corrette.',
      noCoordinates2: 'La mappa si centrerà sulle coordinate [0, 0]',
      private: 'Privato',
      provisioningSetup: 'Configurazioni per la decodifica',
      public: 'Pubblico',
      publicInfo:
        "Se il dispositivo è pubblico verrà aggiunto alla pagina pubblica dell'organization e quidi sarà consultabile anche da parte di utenti anonimi.",
      quantities: 'Grandezze misurate',
      selectAllDevices: 'Seleziona tutti i dispositivi',
      selectAnApplication: "Seleziona un'applicazione",
      selectAUser: 'Seleziona un utente',
      selectMeasureFromDevice: 'Seleziona misura dal sensore',
      selectModel: 'Seleziona marca e modello',
      serial: 'Numero di serie',
      serialNumber: 'Numero di serie',
      sourceType: {
        http: 'HTTP',
        lorawan: 'LoRaWAN ®',
        nbiot: 'NB-IoT ®',
        wmbus: 'wmbus'
      },
      statistics: 'Statistiche',
      tags: 'Tags',
      update: 'Aggiorna dispositivo',
      visibility: 'Visibilità',
      visible: 'Visibile',
      visibleInfo:
        'Se il sensore non è visibile sarà consultabile esclusivamente dagli utenti con i sufficienti permessi (admin ed editor)',
      wmbusId: 'ID WMBus'
    },
    downlinks: {
      date: 'Data di creazione',
      name: 'Nome',
      noData: 'nessuna informazione',
      state: 'Stato'
    },
    events: {
      dateFrom: 'Dal',
      dateTo: 'Al',
      eventStatus: 'Stato eventi',
      eventType: 'Tipologia evento',
      noEventFoundInTheSelectedPeriod:
        'Nessun evento trovato per il periodo selezionato',
      onlyActive: 'Solo in corso',
      onlyAlarms: 'Solo allarmi'
    },
    filters: {
      devices: {
        alarmed: {
          label: 'Mostra dispositivi in allarme'
        },
        area: {
          label: 'Area',
          near: {
            browser: {
              subtitle:
                'In base alla tua posizione attuale (richiede la geolocalizzazione attiva sul tuo dispositivo)',
              title: 'Ricerca sensori nelle vicinanze'
            },
            radius: {
              subtitle: 'Imposta il raggio in cui effettuare la ricerca',
              title: 'Raggio'
            },
            search: {
              label: 'Regione, provincia, città, indirizzo...',
              subtitle:
                'Inserisci la zona geografica in cui restringere la ricerca dei sensori',
              title: 'Ricerca per area geografica'
            }
          }
        },
        deviceNotFoundByModelAndBrand:
          'Nessun device trovato per marca e modello selezionati',
        followed: {
          label: 'Mostra dispositivi seguiti'
        },
        organizations: {
          label: 'Organizzazioni',
          placeholder: 'seleziona le organizzazioni di tuo interesse'
        },
        other: {
          accessibility: {
            label: 'Accessibilità sensori',
            valueFalse: 'Privati',
            valueTrue: 'Pubblici'
          },
          brands: {
            label: 'Marca',
            placeholder: 'seleziona le marche di tuo interesse'
          },
          label: 'Other filters',
          models: {
            label: 'Modello',
            placeholder: 'seleziona i modelli di tuo interesse'
          },
          status: {
            label: ' Stato dispositivi',
            valueFalse: 'Offline',
            valueTrue: 'Online'
          },
          tags: {
            label: 'Tag',
            placeholder: 'seleziona i tag di tuo interesse'
          },
          visibility: {
            label: 'Visibilità dispositivi',
            valueFalse: 'Nascosti',
            valueTrue: 'Visibili'
          }
        }
      },
      events: {
        active: {
          label: 'Solo in corso'
        },
        alarmed: {
          label: 'Solo allarmi'
        }
      },
      gateways: {
        area: {
          label: 'Area',
          near: {
            browser: {
              subtitle:
                'In base alla tua posizione attuale (richiede la geolocalizzazione attiva sul tuo dispositivo)',
              title: 'Ricerca sensori nelle vicinanze'
            },
            radius: {
              subtitle: 'Imposta il raggio in cui effettuare la ricerca',
              title: 'Raggio'
            },
            search: {
              label: 'Regione, provincia, città, indirizzo...',
              subtitle:
                'Inserisci la zona geografica in cui restringere la ricerca dei sensori',
              title: 'Ricerca per area geografica'
            }
          }
        },
        status: {
          active: 'Attivo',
          deactivated: 'Disattivato',
          discontinued: 'Cessato',
          dual: 'Dual technology',
          failure: 'Guasto',
          inMaintenance: 'In riparazione',
          inProgress: 'In lavorazione',
          inStock: 'In magazzino',
          label: 'Status'
        },
        technology: {
          label: 'Tecnologia',
          lorawan: 'LoraWAN',
          wmbus: 'WmBUS'
        }
      },
      roles: {
        placeholder: 'Seleziona ruoli da filtrare'
      },
      organizations: {
        searchInSubOrganizations: 'Cerca in organizzazioni figlie'
      }
    },
    gateways: {
      antennas: 'Antenne',
      connectivity: 'Connettività',
      found_one: '{{count}} gateway trovato',
      found_other: '{{count}} gateways trovati',
      isDeleted: 'Cancellato',
      gatewayId: 'LoraWAN ID',
      notFound: 'Nessun gateway trovato',
      reachable: 'Online',
      isTested: 'Test in campo',
      tests: {
        good: 'Ottimo',
        performed: 'Test eseguiti',
        poor: 'Scarso',
        testDevice: 'Dispositivo di test',
        tester: 'Operatore'
      },
      wmbusId: 'WmBUS ID'
    },
    invites: {
      acceptCommercialDataUsage:
        "Acconsento all'invio di materiale commerciale",
      acceptDataTreatment: 'Accetto i termini del trattamento dei miei dati',
      accepted: 'Confermati',
      acceptInvite: 'Accetta Invito',
      acceptLegalTerms: 'Accetto i termini legali',
      add: 'Invita utente',
      backToHome: 'Torna alla home page',
      basicInformation: 'Informazioni di base',
      chooseOrganization: 'Scegli una organizzazione',
      chooseRole: 'Scegli il ruolo',
      confirmTheInvite: "Conferma l'invito",
      correspondingPasswordError:
        'La password e la password di conferma non corrispondono',
      editYourPreferences: 'Modifica le tue preferenze',
      notFound: 'Nessun invito trovato',
      passwordError:
        'La password deve contenere, almeno una lettera maiuscola\n, almeno una minuscola, almeno un numero e un carattere\n speciale, lunghezza minima 8 caratteri',
      pending: 'In sospeso',
      refuseInvite: 'Rifiuta Invito',
      yourData: 'I tuoi dati'
    },
    network: {
      seqno: 'Sequence number',
      sf: 'Spreading Factor'
    },
    organizations: {
      add: 'Nuova organizzazione',
      areYouSureYouWantToLeave:
        'Sei sicuro di voler abbandonare questa organizzazione',
      block: "Vuoi bloccare l'organizzazione",
      changeOrganization: 'Cambia organizzazione',
      confirmKeyToProceed:
        "Per procedere conferma la chiave dell'organizzazione",
      delete: "Vuoi eliminare l'organizzazione",
      domain: 'Dominio',
      edit: 'Modifica organizzazione',
      inputOrganization: "Seleziona l'organizzazione madre",
      inputTimezone: 'Seleziona il fuso orario',
      key: 'Chiave univoca',
      leave: 'Abbandona',
      loadLogo: 'Carica logo',
      onceYouQuit:
        'Una volta abbandonata, non potrai più accedere a questa organizzazione',
      onlyFollowed: 'Mostra solo organizzazioni seguite',
      organizationProfile: 'Profilo organizzazione',
      pasteKey: "Incolla qui la chiave dell'organizzazione",
      root: 'Organizzazione madre',
      search: 'Cerca organizzazione',
      subOrganizations: 'organizzazioni figlie',
      uploadLogo: 'Carica logo',
      vat: 'P. IVA',
      loadLogo: "Carica il logo dell'organizzazione",
      maxSize:
        'Dimensioni massime del file 4MB, file accettati: .jpeg, .jpg, .png'
    },
    roles: {
      add: 'Crea ruolo'
    },
    rules: {
      add: 'Aggiungi regola',
      all: 'Tutte',
      areYouSureYouWantToDelete: 'Sei sicuro di voler eliminare la regola',
      createNew: 'Crea nuova regola',
      deleteRule: 'Elimina regola',
      disabled: 'Disabilitate',
      enabled: 'Abilitate',
      inputNamePlaceholder: 'Inserisci nome regola',
      maxDelta: 'Max Delta',
      ruleState: 'Stato regola',
      selectAction: 'Seleziona una azione',
      selectAllValues: 'Seleziona tutti i valori',
      selectAMeasure: 'Scegli una misura',
      selectAtLeastOneValue: 'Seleziona almeno un valore',
      selectOneValue: 'Seleziona un valore',
      selectStatus: 'Seleziona stato',
      selectTrigger: 'Seleziona un trigger'
    },
    superroles: {
      add: 'Crea super ruolo'
    },
    triggers: {
      add: 'Aggiungi trigger',
      addTime: 'Aggiungi orario',
      alarmTolerance: 'Tolleranza allarme',
      applyTime: 'Applica orario',
      areYouSureYouWantToDelete: 'Sei sicuro di voler eliminare il trigger',
      byDevice: 'Per sensore',
      byQuantity: 'Per grandezza',
      change: 'Cambia trigger',
      createNew: 'Crea nuovo trigger',
      default24: 'Default 24/7',
      deleteTrigger: 'Elimina trigger',
      edit: 'Modifica trigger',
      everyday: 'Tutti i giorni',
      filter: 'Filtra trigger',
      filters: {
        brands: 'Marca',
        models: 'Modelli',
        organizations: 'Organizzazioni',
        selectFilters: 'Seleziona un filtro',
        users: 'Utenti'
      },
      findDevice: 'Trova sensore',
      missingCommunication: 'Mancata comunicazione',
      modelsRelatedTo: 'Modelli relativi a',
      numberOfMeasuresAboveThresholdBeforeAlarm:
        'Numero di misure sopra soglia tollerate prima di scattare allarme',
      operator: 'Operatore',
      operators: {
        between: 'Compreso',
        equal: 'Uguale',
        'greater-than': 'Maggiore',
        'greater-than-or-equal-to': 'Maggiore o uguale',
        label: 'Operatori',
        'less-than': 'Minore',
        'less-than-or-equal-to': 'Minore o uguale',
        'not-between': 'Non compreso',
        'not-equal': 'Diverso',
        present: 'Presente',
        notPresent: 'Non presente',
        position: 'Posizione'
      },
      searchInSubOrganizations: 'Cerca anche nelle organizzazioni figlie',
      select: 'Seleziona trigger',
      selectAll: 'Seleziona tutti',
      selectAtLeastAModel: 'Seleziona almeno un modello',
      selectDeviceToPairWithTrigger:
        'Seleziona il sensore da abbinare al trigger',
      selectedDevice: 'Sensore selezionato',
      selectMeasure: 'Seleziona grandezza',
      selectOperator: 'Scegli un operatore',
      selectSubMeasure: 'Seleziona sotto-grandezza',
      selectWeeklyTimes: 'Seleziona orari settimanali (GMT+1)',
      sendReminder: 'Invia promemoria',
      size: 'Grandezza',
      triggerDefinition: 'Definizione trigger',
      triggerName: 'Nome trigger',
      triggerType: 'Tipologia trigger',
      types: {
        'measure-communication': 'Comunicazione misura',
        measureCommunication: 'Comunicazione misura',
        'measure-trigger': 'Trigger per misura',
        measureTrigger: 'Trigger per misura',
        communication: 'Comunicazione',
        delta: 'Delta',
        'scheduled-trigger': 'Trigger schedulato',
        threshold: 'Soglia',
        trigger: 'Trigger'
      },
      weekDays: {
        friday: 'Venerdì',
        monday: 'Lunedì',
        saturday: 'Sabato',
        sunday: 'Domenica',
        thursday: 'Giovedì',
        tuesday: 'Martedì',
        wednesday: 'Mercoledì'
      }
    },
    users: {
      acceptCommercialAgreement:
        "Acconsento all'invio di materiale commerciale",
      add: 'Invita utente',
      changePassword: 'Cambia password',
      checkYourInboxText:
        'Controlla la tua casella di posta e segui le istruzioni per reimpostare la password',
      confirmUserKeyToProceed: 'Per procedere inserisci la chiave utente',
      doYouWantToDeleteTheUser: "Vuoi eliminare l'utente",
      forgotPassword: 'password dimenticata?',
      found_one: '{{count}} utente trovato',
      found_other: '{{count}} utenti trovati',
      handleYourDevices: 'gestisci i tuoi dispositivi',
      invalidOldPassword: 'La password attuale non è corretta',
      loginFail: 'username e/o password errati',
      loginToCityEye: 'Accedi a CityEye',
      memberSince: 'Membro dal',
      notificationFrequencySelection:
        'Scegli ogni quanto vuoi ricevere le notifiche',
      notificationFrequencySelectionText:
        'Puoi selezionare ogni quanto desideri ricevere le notifiche relative agli allarmi dei sensori',
      notifications: {
        daily: 'Giornalmente',
        instant: 'Puntuale',
        monthly: 'Mensilmente',
        selectFrequency: "Seleziona la frequenza d'invio",
        weekly: 'Settimanalmente'
      },
      pasteUserKey: 'Incolla qui la chiave utente',
      personalInformation: 'Informazioni personali',
      preferences: 'Preferenze',
      resetPasswordForCityEye: 'Reimposta password',
      resetPasswordForCityEyeDescription:
        "inserisci l'indirizzo email in cui recevere le istruzioni per il recupero password",
      resetPasswordLinkSent: 'Link per il reset della password inviato',
      returnToLogin: 'Torna al login',
      search: 'Cerca utente',
      selectLanguage: 'Scegli la lingua',
      signIn: 'accedi'
    },
    validation: {
      domain: 'Può contenere esclusivamente numeri, lettere e -',
      email: 'Deve essere una email valida',
      greater: 'Deve essere maggiore di {{from}}',
      hex: 'Deve essere un numero esadecimale',
      integer: 'Deve essere un numero intero',
      length: 'Deve essere di {{length}} caratteri',
      less: 'Deve essere minore di {{from}}',
      mandatory: 'Devi acettare per continuare',
      max: 'Deve essere al massimo {{value}}',
      maxLength: 'Deve essere al massimo di {{length}} caratteri',
      min: 'Deve essere almeno {{value}}',
      minLength: 'Deve essere di almeno {{length}} caratteri',
      number: 'Deve essere un numero',
      only: 'Non consentito',
      password: 'Le password non corrispondono',
      pattern: 'Può contenere solo lettere, numeri e -',
      phone:
        'Devi inserire un numero di telefono valido con prefisso internazionale (+XX) e senza spazi',
      positive: 'Deve essere un numero maggiore di zero',
      required: 'Non puoi lasciare il campo vuoto',
      string: 'Deve essere un testo',
      uri: 'Deve essere un URL valido'
    }
  }
}
