import deepmerge from 'deepmerge'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import { initReactI18next } from 'react-i18next'
import baseEn from './translations/en'
import baseIt from './translations/it'
// import { DEFALUT_EXPIRATION_TIME_TRANSLATIONS } from '../configs/environment'

const base = {
  it: baseIt,
  en: baseEn
}

// get UI translations from local files ./translations/{{lng}}.js
// get quantities translations from townhall-api, endpoint /api/i18n/quantities/{{lng}}
// merge them
i18n
  .use(LanguageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          expirationTime: 10 * 60000
        },
        {
          loadPath: '/api/i18n/quantities/{{lng}}',
          parse: (data, language) => {
            data = JSON.parse(data)
            data = deepmerge(base[language], data)
            return data.translations
          },
          reloadInterval: 5 * 60000 // reload translation quantities every 30 minutes after page load, not useful
        }
      ]
    },
    lng: 'it',
    fallbackLng: ['it', 'en'],
    ns: ['translations'],
    defaultNS: 'translations',
    compatibilityJSON: 'v4'
  })

export default i18n
