import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'

const invitesApi = createApi({
  reducerPath: 'invitesApi',
  baseQuery: customFetchBase,
  tagTypes: ['invites'],
  endpoints(builder) {
    return {
      fetchInvites: builder.query({
        query: ({ page, pageSize, fullText }) => {
          logger.log(`RTK API - Fetch invites`)
          return {
            url: `/invites`,
            params: {
              page,
              pageSize,
              fullText
            },
            method: 'GET'
          }
        },
        transformResponse: (response, meta) => {
          const count = meta.response.headers.get('x-total-count')
            ? parseInt(meta.response.headers.get('x-total-count'))
            : response.length
          return { data: response, totalCount: count }
        },
        providesTags: ['invites']
      }),
      fetchUserInvites: builder.query({
        query: (userId) => {
          logger.log(`RTK API - Fetch user invites`)
          return {
            url: `/users/${userId}/invites`,
            method: 'GET'
          }
        },
        providesTags: ['invites']
      }),
      fetchInvite: builder.query({
        query: (inviteId) => {
          logger.log(`RTK API - Fetch invite by id`)
          return {
            url: `/invites/${inviteId}`,
            method: 'GET'
          }
        },
        providesTags: ['invites']
      }),
      resendInvite: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Resend invite`)
          return {
            url: `/invites/resend`,
            method: 'POST',
            body: payload
          }
        },
        invalidatesTags: ['invites']
      }),
      createInvite: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Create invite`)
          return {
            url: `/invites`,
            method: 'POST',
            body: payload
          }
        },
        invalidatesTags: ['invites']
      }),
      deleteInvite: builder.mutation({
        query: (inviteId) => {
          logger.log(`RTK API - Delete invites`)
          return {
            url: `/invites/${inviteId}`,
            method: 'DELETE'
          }
        },
        invalidatesTags: ['invites']
      })
    }
  }
})

export const {
  useFetchInvitesQuery,
  useFetchUserInvitesQuery,
  useFetchInviteQuery,
  useResendInviteMutation,
  useCreateInviteMutation,
  useDeleteInviteMutation
} = invitesApi

export { invitesApi }
