import { Container } from '@mui/material'
import React from 'react'
import TestsTable from '../Tables/TestsTable.jsx'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'

const GatewaysTests = ({ serial, isOpen, onClose }) => {
  return (
    <ZDialog 
      id={'tests-list'} 
      isOpen={isOpen}  
      maxWidth='md'
      fullWidth={false}
      content={
      <Container maxWidth="md">
        <TestsTable serial={serial} />
      </Container>} 
      onClose={onClose} 
    />
  )
}

export default GatewaysTests
