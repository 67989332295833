import { Box, IconButton, ButtonGroup, CircularProgress, Stack } from '@mui/material'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  ScheduleRounded as ScheduleRoundedIcon,
  DownloadRounded as DownloadRoundedIcon,
  EditRounded as EditRoundedIcon,
  SendRounded as SendRoundedIcon,
  DeleteRounded as DeleteRoundedIcon
} from '@mui/icons-material'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { deviceMeasurementsDownload } from '../../../helpers/download.js'
import Can from '../../Layout/Can/Can.jsx'
import Show from '../../Layout/Can/Show.jsx'

const ActionFooter = ({
  device,
  actionsDisabled = false,
  onDelete,
  onEdit,
  onAction
}) => {
  const { credentials = {}, currentOrganization } = useSelector((state) => state?.general)

  const [downloadInProgress, setDownloadInProgress] = useState(false)

  const handleDownloadEnd = () => setDownloadInProgress(false)

  const handleActions = (device) => onAction(device)

  const handleEdit = (device) => onEdit(device)

  const handleDownload = (id, name, serial, from, to) => {
    deviceMeasurementsDownload({
      id,
      name,
      serial,
      from,
      to,
      credentials,
      currentOrganization,
      onSuccess: handleDownloadEnd,
      onError: handleDownloadEnd
    })
    setDownloadInProgress(true)
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        px={1}
      >
        <Stack
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          direction="row"
          spacing={1}
        >
          <ScheduleRoundedIcon fontSize="small" />{' '}
          <small>
            {t('devices.lastUpdate')}{' '}
            {device.lastMeasurementAt
              ? moment(device.lastMeasurementAt).format('DD.MM.YYYY HH:mm:ss')
              : t('common.noPresent')}
          </small>
        </Stack>
        <Box>
          <ButtonGroup size="small">
            <Can action="devices_d">
              <IconButton
                key="delete"
                onClick={onDelete}
                aria-label="delete"
                color="error"
              >
                <DeleteRoundedIcon fontSize="small" />
              </IconButton>
            </Can>

            <Can action="devices_u">
              <IconButton
                key="actions"
                disabled={actionsDisabled}
                onClick={() => handleActions(device)}
                color="primary"
              >
                <SendRoundedIcon fontSize="small" />
              </IconButton>
            </Can>
            <Can action="devices_r">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Show when={() => downloadInProgress}>
                  <CircularProgress size={20} />
                </Show>
                <Show when={() => !downloadInProgress}>
                  <IconButton
                    variant="text"
                    key="download"
                    color="primary"
                    onClick={() =>
                      handleDownload(
                        device.id,
                        device.name,
                        device.serial,
                        moment().subtract(5, 'days').toISOString(),
                        moment().toISOString()
                      )
                    }
                  >
                    <DownloadRoundedIcon fontSize="small" />
                  </IconButton>
                </Show>
              </Box>
            </Can>
            <Can action="devices_u">
              <IconButton
                key="edit"
                aria-label="edit device"
                color="primary"
                onClick={() => handleEdit(device)}
              >
                <EditRoundedIcon fontSize="small" />
              </IconButton>
            </Can>
          </ButtonGroup>
        </Box>
      </Box>
    </>
  )
}

ActionFooter.propTypes = {
  device: PropTypes.object,
  id: PropTypes.string,
  lastMeasurementAt: PropTypes.string,
  name: PropTypes.string,
  serial: PropTypes.string,
  actionsDisabled: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onAction: PropTypes.func,
  onEvents: PropTypes.func
}

export default ActionFooter
