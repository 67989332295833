import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import DeltaTriggerSelector from './DeltaTriggerSelector'
import { Autocomplete, TextField } from '@mui/material'
import ModelsSelector from './ModelsSelector'
import { useGetQuantitiesQuery } from '../../../../store'

const DeltaRuleForm = ({
  quantityId,
  selectedModels,
  ruleType,
  trigger,
  applyToChildOrganizations,
  isEdit,
  device,
  onDeltaRuleChanged,
  selectedOrganizationKey,
  validation
}) => {
  const sortFn = (a, b) => {
    const label1 = a.label.toLowerCase()
    const label2 = b.label.toLowerCase()
    if (label1 < label2) return -1
    if (label1 > label2) return 1
    return 0
  }

  const { data: quantities = [], isLoading: isQuantitiesLoading } = useGetQuantitiesQuery()
  const [selectedQuantity, setSelectedQuantity] = useState()
  const [selectedSubQuantity, setSelectedSubQuantity] = useState()

  const getSubQuantities = () => device && selectedQuantity
                                  ? device.quantities
                                    .filter((q) => q.measureId === selectedQuantity.id)
                                    .map((q) => ({ id: q.measureName, label: q.measureName }))
                                  : []

  useEffect(() => {
    if (!isQuantitiesLoading && quantities) {
      const selected = quantities.find((q) => q.id === quantityId)
      setSelectedQuantity(selected)
    }
  }, [quantities, quantityId])  

  const handleChangeMeasuere = (_event, value) => {
    onDeltaRuleChanged({ quantity: value })
  }
  const handleChangeSubMeasuere = (_event, value) => {
    const models = [
      {
        id: parseInt(device.modelId, 10),
        measures: [{ name: value.id }]
      }
    ]
    setSelectedSubQuantity(value)
    onDeltaRuleChanged({ models })
  }

  const handleDeltraTriggerSelectorChange = (data) => {
    onDeltaRuleChanged(data)
  }

  const handleModelsChanged = (data) => {
    onDeltaRuleChanged(data)
  }

  const subQuantities = getSubQuantities()

  const filteredQuantities = device
    ? quantities.filter((q) => device.quantities.some((dq) => dq.measureId === q.id) && q.type.isAccumulator).map((fq) => ({ ...fq, label: t(fq.label) })).sort(sortFn)
    : quantities.filter((q) => q.type.isAccumulator).map((fq) => ({ ...fq, label: t(fq.label) })).sort(sortFn)

  return (
    <>
      <Autocomplete
        sx={{
          marginTop: '30px'
        }}
        id="measureSelector"
        name="measureSelector"
        options={filteredQuantities}
        value={
          selectedQuantity
            ? { ...selectedQuantity, label: t(selectedQuantity.label) }
            : ''
        }
        getOptionLabel={(option) => option.label || ''}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={handleChangeMeasuere}
        renderInput={(params) => (
          <TextField
            name="quantity"
            {...params}
            label={t('triggers.selectMeasure')}
            variant="standard"
            error={validation?.messages?.quantity?.length > 0}
            helperText={validation?.messages?.quantity?.join(', ')}
          />
        )}
        disabled={isEdit}
      />
      {subQuantities.length > 0 && (
        <Autocomplete
          sx={{
            marginTop: '30px'
          }}
          py={5}
          id="subMeasureSelector"
          name="subMeasureSelector"
          options={subQuantities}
          getOptionLabel={(option) => t(option.label)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={handleChangeSubMeasuere}
          defaultValue={selectedSubQuantity || ''}
          value={selectedSubQuantity || ''}
          renderInput={(params) => (
            <TextField
              name="quantity"
              {...params}
              label={t('triggers.selectSubMeasure')}
              variant="standard"
            />
          )}
          disabled={isEdit}
        />
      )}

      {selectedQuantity && (
        <>
          {!device && (
            <div className="a2a-alarms-page__formContainer">
              <ModelsSelector
                selectedModels={selectedModels}
                applyToChildOrganizations={applyToChildOrganizations}
                ruleType={ruleType}
                selectedQuantity={selectedQuantity}
                isEdit={isEdit}
                onModelsChanged={handleModelsChanged}
                selectedOrganizationKey={selectedOrganizationKey}
                validation={validation}
              />
            </div>
          )}

          <div className="a2a-alarms-page__formContainer">
            {selectedQuantity.type.name === 'numeric' && (
              <DeltaTriggerSelector
                trigger={trigger}
                onDeltaTriggerSelectorChange={handleDeltraTriggerSelectorChange}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

export default DeltaRuleForm
