import { createSlice } from '@reduxjs/toolkit'
import { logger } from '../../helpers/logger/logger.js'
import { triggerAdd } from '../states'

const defaultScheduler = triggerAdd

const initialState = {
  name: '',
  isForSuperAdmin: false,
  applyToChildOrganizations: false,
  models: [],
  ruleType: 'threshold',
  schedulerType: 'default',
  scheduler: JSON.parse(JSON.stringify(defaultScheduler)),
  sendReminder: false,
  thresholdsCounter: 1,
  triggerType: 'quantity',
  quantity: null,
  subQuantity: null,
  trigger: null,
  interval: null,
  tolerance: null,
  maxDeltaChange: null,
  device: null
}

const triggerAddSlice = createSlice({
  name: 'triggerAdd',
  initialState,
  reducers: {
    setName: (state, action) => {
      logger.log('triggerAdd: set name')
      state.name = action.payload
    },
    setIsForSuperAdmin: (state, action) => {
      logger.log('triggerAdd: set isForSuperAdmin')
      state.isForSuperAdmin = action.payload
    },
    setApplyToChildOrganizations: (state, action) => {
      logger.log('triggerAdd: set applyToChildOrganizations')
      state.applyToChildOrganizations = action.payload
    },
    setModels: (state, action) => {
      logger.log('triggerAdd: set models')
      state.models = action.payload
    },
    setRuleType: (state, action) => {
      logger.log('triggerAdd: set type')
      state.ruleType = action.payload
    },
    setSchedulerType: (state, action) => {
      logger.log('triggerAdd: set schedulerType')
      state.schedulerType = action.payload
    },
    setScheduler: (state, action) => {
      logger.log('triggerAdd: set schedulerType')
      state.scheduler = action.payload
    },
    setSendReminder: (state, action) => {
      logger.log('triggerAdd: set sendReminder')
      state.sendReminder = action.payload
    },
    setThresholdsCounter: (state, action) => {
      logger.log('triggerAdd: set thresholdsCounter')
      state.thresholdsCounter = action.payload
    },
    setTriggerType: (state, action) => {
      logger.log('triggerAdd: set triggerType')
      state.triggerType = action.payload
    },
    setQuantity: (state, action) => {
      logger.log('triggerAdd: set quantity')
      state.quantity = action.payload
    },
    setSubQuantity: (state, action) => {
      logger.log('triggerAdd: sub quantity')
      state.subQuantity = action.payload
    },
    setTrigger: (state, action) => {
      logger.log('triggerAdd: trigger')
      state.trigger = action.payload
    },
    setInterval: (state, action) => {
      logger.log('triggerAdd: interval')
      state.interval = action.payload
    },
    setTolerance: (state, action) => {
      logger.log('triggerAdd: tolerance')
      state.tolerance = action.payload
    },
    setMaxDelta: (state, action) => {
      logger.log('triggerAdd: max delta')
      state.maxDeltaChange = action.payload
    },
    setDevice: (state, action) => {
      logger.log('triggerAdd: max delta')
      state.device = action.payload
    },
    resetTriggerPayload: (state) => {
      logger.log('triggerAdd: reset to initial state')
      const { triggerType, device, scheduler, schedulerType } = state
      return {
        ...initialState,
        triggerType,
        device,
        scheduler,
        schedulerType,
        name: state.name
      }
    },
    resetTriggerPayloadToDefault: () => {
      logger.log('triggerAdd: reset to initial state')
      return initialState
    }
  }
})

export const {
  setName,
  setIsForSuperAdmin,
  setApplyToChildOrganizations,
  setModels,
  setRuleType,
  setSchedulerType,
  setScheduler,
  setSendReminder,
  setThresholdsCounter,
  resetTriggerPayload,
  setTriggerType,
  setQuantity,
  setSubQuantity,
  setTrigger,
  setDevice,
  resetTriggerPayloadToDefault
} = triggerAddSlice.actions

export default triggerAddSlice.reducer
