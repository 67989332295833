import { Alert, AlertTitle, Slide, Snackbar } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCloseDialog } from '../../../store/slices/general.js'
import { t } from 'i18next'
import Show from '../../Layout/Can/Show.jsx'

const ErrorAlert = () => {
  const dispatch = useDispatch()
  const {
    dialogs: {
      errorAlert: { open: isOpen = false, errorMessage: message }
    }
  } = useSelector((state) => state.general)

  return (
    <Show when={() => isOpen}>
      <Snackbar
        open={isOpen}
        onClose={() => dispatch(setCloseDialog('errorAlert'))}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={(props) => (
          <Slide
            {...props}
            direction="down"
          />
        )}
        sx={{
          width: '20%'
        }}
      >
        <Alert
          severity="error"
          sx={{
            width: '400px',
            position: 'fixed',
            bottom: '10px',
            right: '5px',
            zIndex: 999999
          }}
        >
          <AlertTitle>{t('common.error')}</AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </Show>
  )
}

export default ErrorAlert
