import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setFiltersGateways } from '../../../../store/slices/filters.js'

const GatewayStatus = () => {
  const dispatch = useDispatch()

  const {
    gateways: { status }
  } = useSelector((state) => state.filters)

  const attivo = useRef()
  const guasto = useRef()
  const inLavorazione = useRef()
  const inMagazzino = useRef()

  /**
   * handleChange
   */
  const handleChange = (event) => {
    let newStatus
    const { name } = event.target

    if (status.includes(name)) {
      const index = status.indexOf(name)
      newStatus = status.filter((_, id) => id !== index)
    } else {
      newStatus = status.concat(name)
    }

    dispatch(setFiltersGateways({ status: newStatus }))
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.gateways.status.label')}
        </Typography>
        <FormGroup
          aria-label="position"
          row
        >
          <FormControlLabel
            value="Attivo"
            control={
              <Checkbox
                name="Attivo"
                inputRef={attivo}
                onChange={handleChange}
                checked={status.includes('Attivo')}
              />
            }
            label={t('filters.gateways.status.active')}
          />
          <FormControlLabel
            value="Guasto"
            control={
              <Checkbox
                name="Guasto"
                inputRef={guasto}
                onChange={handleChange}
                checked={status.includes('Guasto')}
              />
            }
            label={t('filters.gateways.status.failure')}
          />
          <FormControlLabel
            value="In lavorazione"
            control={
              <Checkbox
                name="In lavorazione"
                inputRef={inLavorazione}
                onChange={handleChange}
                checked={status.includes('In lavorazione')}
              />
            }
            label={t('filters.gateways.status.inProgress')}
          />
          <FormControlLabel
            value="In magazzino"
            control={
              <Checkbox
                name="In magazzino"
                inputRef={inMagazzino}
                onChange={handleChange}
                checked={status.includes('In magazzino')}
              />
            }
            label={t('filters.gateways.status.inStock')}
          />
        </FormGroup>
      </Stack>
    </>
  )
}

GatewayStatus.propTypes = {
  onChange: PropTypes.func,
  filters: PropTypes.object
}

export default GatewayStatus
