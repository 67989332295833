import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'
import sanitizer from '../../helpers/sanitizer.js'

const organizationsApi = createApi({
  refetchOnMountOrArgChange: true,
  reducerPath: 'organizationsApi',
  baseQuery: customFetchBase,
  tagTypes: ['organizations'],
  endpoints(builder) {
    return {
      fetchOrganizationsPaged: builder.query({
        query: ({ page, pageSize }) => {
          logger.log(`RTK API - Fetch organizations paged`)
          return {
            url: `/organizations`,
            method: 'GET',
            params: { page, pageSize }
          }
        },
        providesTags: ['organizations']
      }),
      fetchOrganizationsWithChildren: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch organizations with children paged`)
          return {
            url: `/organizations-with-children`,
            method: 'GET'
          }
        },
        providesTags: ['organizations']
      }),
      fetchOrganizations: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch organizations`)
          return {
            url: `/organizations`,
            method: 'GET',
            params: { page: 1, pageSize: 9999 }
          }
        },
        providesTags: ['organizations']
      }),
      fetchOrganizationKey: builder.query({
        query: ({ domain }) => {
          logger.log(`RTK API - Fetch organization key`)
          return {
            url: `/organization-key`,
            method: 'GET',
            params: { domain: domain }
          }
        },
        providesTags: ['organizations']
      }),
      fetchSelfOrganizations: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch self organizations`)
          return {
            url: `/self/organizations`,
            method: 'GET'
          }
        },
        providesTags: ['organizations-self']
      }),
      fetchOrganization: builder.query({
        query: (organizationId) => {
          logger.log(`RTK API - Fetch organization`)
          return {
            url: `/organizations/${organizationId}`,
            method: 'GET'
          }
        },
        providesTags: ['organizations']
      }),
      fetchNetworkServers: builder.query({
        query: (organizationKey) => {
          logger.log(`RTK API - Fetch organization network-servers`)
          return {
            url: `/organizations/${organizationKey}/network-servers`,
            method: 'GET'
          }
        },
        transformResponse: (response) => {
          return response.map((r) => r.server)
        }
      }),
      fetchTimezones: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch timezones`)
          return {
            url: `/timezones`,
            method: 'GET'
          }
        }
      }),
      fetchAllNetworkServers: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch all network servers`)
          return {
            url: `/network-servers?filter=onlyAvailableUsers`,
            method: 'GET'
          }
        }
      }),
      fetchOrganizationProfiles: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch all network servers`)
          return {
            url: `/organization-profiles`,
            method: 'GET'
          }
        }
      }),
      fetchOrganizationProfile: builder.query({
        query: (profileId) => {
          logger.log(`RTK API - Fetch all network servers`)
          return {
            url: `/organization-profiles/${profileId}`,
            method: 'GET'
          }
        }
      }),
      fetchOrganizationsWithThresholds: builder.query({
        query: ({ fullText, page, pageSize }) => {
          logger.log(`RTK API - Fetch all network servers`)
          return {
            url: `/thresholds`,
            method: 'GET',
            params: fullText ? { fullText, page, pageSize } : { page, pageSize }
          }
        },
        transformResponse: (response, meta) => {
          const count = meta.response.headers.get('x-total-count')
            ? parseInt(meta.response.headers.get('x-total-count'))
            : response.length
          return { data: response, totalCount: count }
        }
      }),
      fetchOrganizationsWithThresholdsAndFilters: builder.query({
        query: ({ filters, fullText, page, pageSize }) => {
          logger.log(`RTK API - Fetch all network servers`)
          return {
            url: `/organizations/search`,
            method: 'POST',
            params: fullText
              ? { fullText, page, pageSize }
              : { page, pageSize },
            body:
              filters.organizationKeys.length > 0
                ? sanitizer({ ...filters, searchInSubOrganizations: true })
                : {}
          }
        },
        transformResponse: (response, meta) => {
          const count = meta.response.headers.get('x-total-count')
            ? parseInt(meta.response.headers.get('x-total-count'))
            : response.length
          return { data: response, totalCount: count }
        }
      }),
      createRootOrganization: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Create new organization`)
          const body = new FormData()
          body.append('Content-Type', 'multipart/form-data')
          body.append('data', JSON.stringify(payload.data))
          body.append('logo', payload.logo)
          return {
            url: `/organizations`,
            method: 'POST',
            body: body
          }
        },
        invalidatesTags: ['organizations', 'organizations-self']
      }),
      createChildOrganization: builder.mutation({
        query: ({ parentOrganization, payload }) => {
          logger.log(`RTK API - Create new organization`)
          const body = new FormData()
          body.append('Content-Type', 'multipart/form-data')
          body.append('data', JSON.stringify(payload.data))
          body.append('logo', payload.logo)
          return {
            url: `/organizations/${parentOrganization}/children`,
            method: 'POST',
            body: body
          }
        },
        invalidatesTags: ['organizations', 'organizations-self']
      }),
      editOrganization: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Fetch organization`)
          const body = new FormData()
          body.append('Content-Type', 'multipart/form-data')
          body.append('data', JSON.stringify(payload.data))
          body.append('logo', payload.logo)
          return {
            url: `/organizations/${payload.organizationId}`,
            method: 'PUT',
            body: body
          }
        },
        invalidatesTags: ['organizations', 'organizations-self']
      }),
      deleteOrganization: builder.mutation({
        query: (id) => {
          logger.log(`RTK API - Delete organization ${id}`)
          return {
            url: `/organizations/${id}`,
            method: 'DELETE'
          }
        },
        invalidatesTags: ['organizations', 'organizations-self']
      }),
      fetchKeySuggestion: builder.mutation({
        query: (name) => {
          logger.log(`RTK API - Get organization key suggestion`)
          return {
            url: `/organization-key-suggestion?name=${name}`,
            method: 'GET'
          }
        },
        providesTags: ['organizations']
      }),
      lockOrganization: builder.mutation({
        query: (organizationId) => {
          logger.log(`RTK API - Lock organization ${organizationId}`)
          return {
            url: `/organizations/${organizationId}/lock`,
            method: 'PATCH'
          }
        },
        invalidatesTags: ['organizations']
      }),
      unlockOrganization: builder.mutation({
        query: (organizationId) => {
          logger.log(`RTK API - Unlock organization ${organizationId}`)
          return {
            url: `/organizations/${organizationId}/unlock`,
            method: 'PATCH'
          }
        },
        invalidatesTags: ['organizations']
      })
    }
  }
})

export const {
  useFetchSelfOrganizationsQuery,
  useFetchOrganizationsPagedQuery,
  useFetchOrganizationsWithChildrenQuery,
  useFetchOrganizationsWithThresholdsQuery,
  useFetchOrganizationsWithThresholdsAndFiltersQuery,
  useFetchOrganizationsQuery,
  useFetchOrganizationQuery,
  useFetchOrganizationKeyQuery,
  useFetchNetworkServersQuery,
  useFetchTimezonesQuery,
  useFetchAllNetworkServersQuery,
  useFetchOrganizationProfilesQuery,
  useFetchOrganizationProfileQuery,
  useCreateRootOrganizationMutation,
  useCreateChildOrganizationMutation,
  useEditOrganizationMutation,
  useDeleteOrganizationMutation,
  useFetchKeySuggestionMutation,
  useLockOrganizationMutation,
  useUnlockOrganizationMutation
} = organizationsApi

export { organizationsApi }
