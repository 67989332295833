import { Box, Button, ButtonGroup, CardContent, Chip } from '@mui/material'
import { lightBlue, red } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import React from 'react'
import { t } from 'i18next'
import OrganizationLogo from '../Logo/Logo.jsx'
import ZCard from '../../Shared/Components/ZCard.jsx'

const StatButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(lightBlue[50]),
  backgroundColor: lightBlue[50],
  '&:hover': {
    backgroundColor: lightBlue[100]
  }
}))

const ApiButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[50]),
  backgroundColor: red[50],
  '&:hover': {
    backgroundColor: red[100]
  }
}))

const ChildOrgContent = ({ organization }) => {
  return (
    <CardContent>
      <ButtonGroup
        variant="text"
        aria-label="text button group"
      >
        <Button>
          <small>
            {`${t('common.devices')} (${organization?.thresholds?.devicesCounter})`}
          </small>
        </Button>
        <Button>
          <small>
            {`${t('common.organizations')} (${organization?.thresholds?.organizationsCounter})`}
          </small>
        </Button>
        <Button>
          <small>
            {`${t('common.apiCalls')} (${organization?.thresholds?.apiCallsCounter})`}
          </small>
        </Button>
      </ButtonGroup>
    </CardContent>
  )
}

const RootOrgContent = ({ organization }) => {
  return (
    <CardContent>
      <Box
        display="flex"
        justifyContent="flex-start"
        align="flex-start"
        gap={1}
        width="100%"
      >
        <StatButton
          variant="contained"
          size="small"
          disableElevation
        >
          <small>
            {`${t('common.devices')} (${organization?.thresholds?.devicesCounter || 0}/${organization?.profile?.maxDevicesCount})`}{' '}
          </small>
        </StatButton>

        <StatButton
          variant="contained"
          size="small"
          disableElevation
        >
          <small>
            {`${t('common.organizations')} (${organization?.thresholds?.organizationsCounter || 0}/${organization?.profile?.maxOrganizationsCount})`}
          </small>
        </StatButton>

        <ApiButton
          variant="contained"
          size="small"
          disableElevation
        >
          <small>
            {`${t('common.apiCalls')} (${organization?.thresholds?.apiCallsCounter || 0}/${organization?.profile?.maxCallsPerMonthCount})`}
          </small>
        </ApiButton>

        <StatButton
          variant="contained"
          size="small"
          disableElevation
        >
          <small>
            {`${t('common.users')} (${organization?.thresholds?.users || 0})`}
          </small>
        </StatButton>
      </Box>
    </CardContent>
  )
}

const OrganizationCard = ({
  index,
  isRoot,
  organization,
  onEdit,
  onDelete,
  onLock
}) => {
  return (
    <ZCard
      name={'organization'}
      key={`organization-card-${index}`}
      id={`organization-card-${index}`}
      title={organization.name}
      variant={isRoot ? 'elevation' : 'outlined'}
      titleImage={<OrganizationLogo organizationKey={organization.key} />}
      titleInfo={
        isRoot ? (
          <Chip
            label="ROOT"
            color="primary"
            variant="outlined"
          />
        ) : null
      }
      subheader={organization.key}
      onDelete={() => onDelete(organization)}
      onEdit={() => onEdit(organization)}
      onLock={() => onLock(organization)}
      isLockVisible={true}
      isEntityLocked={organization.locked}
      isDeleteVisible={organization.children.length === 0}
      content={
        isRoot ? (
          <RootOrgContent organization={organization} />
        ) : (
          <ChildOrgContent organization={organization} />
        )
      }
      footerFields={[
        {
          label: t('organizations.domain'),
          value: organization.domain || 'www.cityeye.it'
        }
      ]}
    />
  )
}

export default OrganizationCard
