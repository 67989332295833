import React from 'react'
import ZCard from './ZCard'
import { CardContent, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
const NotFoundMessage = ({ when = () => false }) => {
  return (
    when() &&
      <ZCard
        sx={{ width: '100%', background: 'rgba(255, 165, 0, 0.1)' }}
        id='not-found-message' 
        content={
          <CardContent>
            <Stack
              justifyContent={'center'}
              alignItems={'center'}
              spacing={2}
            >
              <Typography variant="title">{t('common.warning')}</Typography>
              <Typography variant="body2">
                {t('common.noItemsFound')}
              </Typography>
            </Stack>
          </CardContent>
        }
        isEditVisible={false}  
        isDeleteVisible={false}
      />)
}

export default NotFoundMessage