export const APP_ENV =
  import.meta.env.MODE === 'production' ? 'production' : 'development'

export const LOG_LEVEL = APP_ENV === 'production' ? 'warn' : 'log'

export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

export const MIGRATION_VERSION = import.meta.env.VITE_MIGRATION_VERSION

export const MUI_LICENSE = import.meta.env.VITE_MUI_LICENSE

export const MEASURES_POLLING_RATE = parseInt(
  import.meta.env.VITE_MEASURES_POLLING_RATE
)
export const EVENTS_POLLING_RATE = parseInt(
  import.meta.env.VITE_EVENTS_POLLING_RATE
)
export const NETWORK_POLLING_RATE = parseInt(
  import.meta.env.VITE_NETWORK_POLLING_RATE
)

export const APP_BASE_URL =
  APP_ENV === 'production'
    ? 'https://www.cityeye.it'
    : 'https://www.cityeye.xyz'

export const DEFAULT_PAGE_SIZE =
  'VITE_DEFAULT_PAGE_SIZE' in import.meta.env
    ? parseInt(import.meta.env.VITE_DEFAULT_PAGE_SIZE)
    : 20

export const DEFALUT_EXPIRATION_TIME_TRANSLATIONS =
  'VITE_DEFALUT_EXPIRATION_TIME_TRANSLATIONS' in import.meta.env
    ? parseInt(import.meta.env.VITE_DEFALUT_EXPIRATION_TIME_TRANSLATIONS)
    : 10000 // default 10 seconds
