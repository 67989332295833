export default {
  translations: {
    actions: {
      action: 'Action',
      actionName: 'Action name',
      actionType: 'Action type',
      actionUrl: 'Action URL',
      add: 'Add action',
      alarm: 'Alarm',
      all: 'All',
      areYouSureYouWantToDelete: 'Are you sure you want to delete the action',
      availableSensors: 'Available devices',
      configureAction: 'Configure action',
      createNew: 'Create new action',
      sendNew: 'Send a new action',
      deleteAction: 'Delete action',
      downlink: 'Downlink',
      downlinkAlreadyInQueue: 'Downlink already in queue',
      downlinkNotSentError: 'Error, downlink not sent',
      emailsToNotify: 'Emails to notify',
      findSensor: 'Find device',
      httpHeaders: 'HTTP headers',
      modelNotFound: 'No model found',
      noActionsForSelectedDevice: 'No actions for selected device',
      notification: 'Notification',
      notificationChoices: 'Notification choices',
      notifyAdmin: 'Notify admin',
      notifyEditor: 'Notify editor',
      notifyViewer: 'Notify viewer',
      parameters: 'Parameters',
      port: 'Port',
      preset: 'Action preset',
      savePreset: 'Save as preset',
      reviewAndConfirm: 'Review and confirm',
      searchByBrandAndModel: 'Search by brand and model',
      searchByName: 'Search by name',
      selectAnAction: `Select an action`,
      selectDevice: 'Select Device',
      send: 'Send an action',
      sendANewAction: 'Send a new action',
      sendUplink: 'Send uplink',
      unused: 'Unused',
      used: 'Used',
      useState: 'Use state',
      webhook: 'Webhook',
      sendTriggerInformation: 'Send trigger information'
    },
    automations: {
      actions: 'Actions',
      add: 'Create automation',
      addToFavorites: 'Add to favorites',
      all: 'All',
      disabled: 'Disabled',
      enabled: 'Enabled',
      errors: {
        actionsAndTriggersNotInSameOrg:
          'Warning!Selected actions and triggers do not belong to same organiztion',
        actionsNotInSameOrg:
          'Warning!Selected actions do not belong to same organization',
        downlinkInScheduledTrigger:
          'Warning!It is not possibile to select actions different from downlink in scheduled triggers',
        cantSelectAllValues: `It is not possible to select all values!`
      },
      linkedRules: 'view linked rules',
      noRecipients: 'No recipients',
      recipients: 'Recipients',
      rules: 'Rules',
      triggers: 'Triggers',
      usedInARule: 'Used in a rule',
      validity: 'Validity',
      viewLinkedActions: 'View linked actions',
      viewLinkedRules: 'View linked rules'
    },
    charts: {
      average: 'Average',
      inTheSelectedPeriod: 'In the selected period'
    },
    common: {
      actions: 'Actions',
      active: 'Active',
      address: 'Address',
      alarmed: 'In alarm',
      alarms: 'Alarms',
      all: 'All',
      apiCalls: 'API calls',
      automations: 'Automations',
      back: 'Back',
      backToList: 'Back to list',
      brand: 'Brand',
      cancel: 'Cancel',
      choose: 'Select',
      configuration: 'Configuration',
      confirm: 'Confirm',
      confirmAndSave: 'Confirm and save',
      contacts: 'Contacts',
      coordinates: 'Coordinates',
      create: 'Create',
      createdAt: 'Created at',
      createdBy: 'Created by',
      dashboard: 'Dashboard',
      day_one: '{{count}} day',
      day_other: '{{count}} days',
      delete: 'Delete',
      description: 'Description',
      device: 'Device',
      devices: 'Devices',
      disable: 'Disable',
      download: 'Download',
      edit: 'Edit',
      email: 'Email',
      enable: 'Enable',
      enabled: 'Enabled',
      endedAt: 'Ended at',
      entity: 'Entity',
      error: 'Error',
      events: 'Events',
      filter: 'Filter',
      filters: {
        other: 'Other filters'
      },
      findAddress: 'Find address',
      findCoordinates: 'Insert coordinates',
      from: 'From',
      fromHour: 'From hour',
      gateways: 'Gateways',
      genericRequestError: 'An error occurred during the request',
      hideFilters: 'Hide filters',
      home: 'Home',
      hours: 'Hour',
      hour_one: '{{count}} hour',
      hour_other: '{{count}} hours',
      interval: 'Interval',
      invites: 'Invites',
      itemsFound: 'Items found {{count}}',
      key: 'Key',
      language: 'Language',
      lastMonth: 'Last month',
      lastTwoWeeks: 'Last two weeks',
      lastUpdate: 'Last update',
      lastWeek: 'Last week',
      lastYear: 'Last year',
      latitude: 'Latitude',
      login: 'Login',
      logout: 'Logout',
      longitude: 'Longitude',
      management: 'Management',
      measures: 'Measures',
      minute_one: '{{count}} minute',
      minute_other: '{{count}} minutes',
      mobile: 'Mobile',
      model: 'Model',
      myDashboard: 'My dashboard',
      myProfile: 'My profile',
      name: 'Name',
      network: 'Network',
      next: 'Next',
      no: 'No',
      noItemsFound: 'No items founds for this search',
      noMatch: 'Page not found',
      noPresent: 'not present',
      notActive: 'Not active',
      notifications: 'Notifications',
      offline: 'Offline',
      online: 'Online',
      operationCompleted: 'Operation completed',
      operationCompletedSuccessfully: 'Operation completed successfully',
      organization: 'Organization',
      organizations: 'Organizations',
      password: 'Password',
      previous: 'Previous',
      privacy: 'Documents and Privacy',
      profile: 'Profile',
      read: 'Read',
      reset: 'Reset',
      role: 'Role',
      roles: 'Roles',
      rowsPerPage: 'Items per page',
      rssi: 'rssi',
      rule: 'Rule',
      save: 'Save',
      search: 'Search',
      searchDevice: 'Search device',
      search: 'Global Search',
      serial: 'Serial',
      second_one: '{{count}} second',
      second_other: '{{count}} seconds',
      selectPage: 'Page',
      snr: 'snr',
      startedAt: 'Started at',
      statistics: 'Statistics',
      status: 'Status',
      superroles: 'Super Roles',
      surname: 'Surname',
      technology: 'Technology',
      timezone: 'Timezone',
      to: 'To',
      toHour: 'To hour',
      today: 'Today',
      tolerance: 'Tolerance',
      triggers: 'Triggers',
      type: 'Type',
      typology: 'Typology',
      update: 'Update',
      updatedAt: 'Updated at',
      updatedBy: 'Updated by',
      url: 'Url',
      user: 'User',
      userProfile: 'User Profile',
      users: 'Users',
      value: 'Value',
      vat: 'VAT',
      warning: 'Warning',
      webhookErrors: 'Webhooks errors',
      yes: 'Yes',
      yesterday: 'Yesterday'
    },
    devices: {
      actionsDevice: {
        title: 'Actions list'
      },
      activeSince: 'Active since',
      add: 'Add device',
      addBrandModel: {
        brand: {
          name: 'Name',
          nameEncoded: 'name_encoded',
          title: 'Brand'
        },
        model: {
          file: 'Datasheet (admitted formats: xls, xml, csv)',
          group: 'Group',
          missingCommunicationTimeout: 'Missing communication timeout',
          name: 'Name',
          nameEncoded: 'Name encoded',
          netserverProfileId: 'Netserver profile id',
          requiredDownlinksAck: 'Required downlinks ack',
          sourceType: 'Source type',
          title: 'Model',
          type: 'Type'
        },
        title: 'Add new brand-model'
      },
      added: 'Added',
      addInfo: 'General information',
      addNetwork: 'Network configuration',
      addParsing: 'Parsing configuration',
      addPosition: 'Device position',
      addPublication: 'Publication',
      addTags: '+ Add tags',
      addVendorModel: 'Add vendor / model',
      alarms: 'Device alarms',
      appKey: 'App Key',
      askNewModel: 'Ask for new brand / model',
      board: 'Board',
      cantFindModel: "Can't find the device you're looking for?",
      categories: 'Categories',
      chooseBrand: 'Choose a brand',
      chooseLoraType: 'Choose LoraWAN® type',
      chooseModel: 'Choose a model',
      chooseNetworkServer: 'Choose the network server',
      chooseOrganization: 'Choose organization',
      deleteDevice: {
        helper: 'Paste here the device id',
        subtitle: 'To proceed confirm the device id',
        title: 'Do you want to delete the device?'
      },
      description: 'Description',
      deviceAddress: 'Device address',
      deviceAlreadyExists: 'This device already exists in CityEye',
      deviceEui: 'Device EUI',
      deviceInfo: 'Device information',
      deviceInformation: 'Device information',
      deviceNotFoundByModelAndBrand:
        'Devices not found for selected brand and model',
      events: 'Device events',
      eventsDevice: {
        title: 'Events list'
      },
      exportMeasures: 'Export measures',
      exportStatistics: 'Export statistics',
      filterAlarmed: 'Show only alarmed devices',
      found_one: '{{count}} device found',
      found_other: '{{count}} devices found',
      group: 'Group',
      hidden: 'Hidden',
      import: 'Import devices',
      import_downloadTemplate: 'Download empty template',
      import_uploadTemplate: 'Upload filled template',
      importDevicesForm: {
        box: {
          error: {
            description: `It wasn't possible to complete the request because of the following errors:`,
            title: 'Ops... There was an error!'
          },
          success: {
            description:
              'The import request happened successfully, you will receive an email with details of the imported devices.',
            title: 'Device import started'
          }
        },
        form: {
          errors: {
            device: 'Device',
            field: 'campo',
            fileEmpty: 'The file to import is empty',
            notValid: 'Invalid value',
            thresholdsExceeded: 'Device threshold exceeded after the import!'
          },
          loadTemplate: {
            button: '2 - Upload filled template',
            label: 'Upload the filled template file with device information'
          },
          networkServer: {
            description:
              'Select a network server to pair with the imported devices',
            label: 'Network server',
            placeholder: 'Search a network server'
          },
          organization:
            'Select an organization to pair with the imported devices'
        }
      },
      inputDescription:
        'Use this field to add useful information to identify the device',
      inputOrganization:
        'Select the organization that you want to add this device for',
      inputTags:
        'Use this field to tag the device, you can enter one or more tags',
      joinEui: 'Join EUI',
      lastUpdate: 'Last update',
      listeningGateways: 'Gateway is listening',
      loraType: 'Type',
      measureName: 'Measure name',
      measureNameDoc: 'Field Name API',
      measures: 'Measured quantities',
      more_one: '+ {{length}} other measure',
      more_other: '+ {{length}} other measures',
      networkServer: 'Networkserver',
      noCoordinates1: 'Missing or wrong coordinates.',
      noCoordinates2: 'The map will centre on coordinates [0, 0].',
      private: 'Private',
      provisioningSetup: 'Parsing setup',
      public: 'Public',
      publicInfo:
        'If the device is public, it will be added to the public page of the organization and will be available to anonymous users',
      quantities: 'Quantities',
      selectAllDevices: 'Select all devices',
      selectAnApplication: 'Select an application',
      selectAUser: 'Select a user',
      selectMeasureFromDevice: 'Select a measure from the device',
      selectModel: 'Select brand and model',
      serial: 'Serial number',
      serialNumber: 'Serial number',
      sourceType: {
        http: 'HTTP',
        lorawan: 'LoRaWAN ®',
        nbiot: 'NB-IoT ®',
        wmbus: 'wmbus'
      },
      statistics: 'Statistics',
      tags: 'Tags',
      update: 'Update device',
      visibility: 'Visibility',
      visible: 'Visible',
      visibleInfo:
        'If the device is not visible, it will be accessible only to users with proper permissions (admin and editor)',
      wmbusId: 'WMBus ID'
    },
    downlinks: {
      date: 'Created at',
      name: 'Name',
      noData: 'no data',
      state: 'State'
    },
    events: {
      dateFrom: 'From',
      dateTo: 'To',
      eventStatus: 'Event status',
      eventType: 'Event type',
      noEventFoundInTheSelectedPeriod: 'No event found in the selected period',
      onlyActive: 'Only active',
      onlyAlarms: 'Only alarms'
    },
    filters: {
      devices: {
        alarmed: {
          label: 'Show only alarmed devices'
        },
        area: {
          label: 'Area',
          near: {
            browser: {
              subtitle:
                'Based on your current position (require active geolocation on your device)',
              title: 'Search nearby sensors'
            },
            radius: {
              subtitle: 'Set the radius in which to search',
              title: 'Radius'
            },
            search: {
              label: 'Region, province, city, address ...',
              subtitle:
                'Insert the geographical area in which to restrict the search for sensors',
              title: 'Search by geographical area'
            }
          }
        },
        deviceNotFoundByModelAndBrand:
          'No devices found for this vendor / model',
        followed: {
          label: 'Show only followed devices'
        },
        organizations: {
          label: 'Organizations',
          placeholder: 'Select the organizations of your interest'
        },
        other: {
          accessibility: {
            label: 'Sensors accessibility',
            valueFalse: 'Private',
            valueTrue: 'Public'
          },
          brands: {
            label: 'Brands',
            placeholder: 'Select the brands of your interest'
          },
          label: 'Other filters',
          models: {
            label: 'Models',
            placeholder: 'Select the models of your interest'
          },
          status: {
            label: 'Devices state',
            valueFalse: 'Offline',
            valueTrue: 'Online'
          },
          tags: {
            label: 'Tags',
            placeholder: 'Select the tags of your interest'
          },
          visibility: {
            label: 'Devices visibility',
            valueFalse: 'Hidden',
            valueTrue: 'Visible'
          }
        }
      },
      events: {
        active: {
          label: 'Only ongoing'
        },
        alarmed: {
          label: 'Only alarms'
        }
      },
      gateways: {
        area: {
          label: 'Area',
          near: {
            browser: {
              subtitle:
                'Based on your current position (require active geolocation on your device)',
              title: 'Search nearby sensors'
            },
            radius: {
              subtitle: 'Set the radius in which to search',
              title: 'Radius'
            },
            search: {
              label: 'Region, province, city, address ...',
              subtitle:
                'Insert the geographical area in which to restrict the search for sensors',
              title: 'Search by geographical area'
            }
          }
        },
        status: {
          active: 'Active',
          deactivated: 'Deactivated',
          discontinued: 'Discontinued',
          failure: 'Failure',
          inMaintenance: 'In maintenance',
          inProgress: 'In progress',
          inStock: 'In stock',
          label: 'Status'
        },
        technology: {
          dual: 'Dual technology',
          label: 'Technology',
          lorawan: 'LoraWAN',
          wmbus: 'WmBUS'
        }
      },
      roles: {
        placeholder: 'Seleziona ruoli da filtrare'
      },
      organizations: {
        searchInSubOrganizations: 'Search in sub organizations'
      }
    },
    gateways: {
      antennas: 'Antennas',
      connectivity: 'Connectivity',
      found_one: '{{count}} gateway found',
      found_other: '{{count}} gateways found',
      isDeleted: 'Deleted',
      gatewayId: 'LoraWAN ID',
      notFound: 'No gateways found',
      reachable: 'Online',
      isTested: 'Field Tests',
      tests: {
        good: 'Good',
        performed: 'Tests performed',
        poor: 'Poor',
        testDevice: 'Test device',
        tester: 'Tester'
      },
      wmbusId: 'WmBUS ID'
    },
    invites: {
      acceptCommercialDataUsage: 'I accept to get sent commercial material',
      acceptDataTreatment: 'I accept the terms of the treatment of my data',
      accepted: 'Accepted',
      acceptInvite: 'Accept Invite',
      acceptLegalTerms: 'I accept the legal terms',
      add: 'Invite user',
      backToHome: 'Back to homepage',
      basicInformation: 'Basic information',
      chooseOrganization: 'Choose an organization',
      chooseRole: 'Choose a role',
      confirmTheInvite: 'Confirm the invite',
      correspondingPasswordError: `The password and confirmation password don't match`,
      editYourPreferences: 'Edit your preferences',
      notFound: 'Invite not found',
      passwordError:
        'Password must contain at least an uppercase letter\n, a lowercase letter, a number and a special\n character, minimum length 8 characters',
      pending: 'Pending',
      refuseInvite: 'Refuse Invite',
      yourData: 'Your data'
    },
    network: {
      seqno: 'Sequence number',
      sf: 'Spreading Factor'
    },
    organizations: {
      add: 'Create organization',
      areYouSureYouWantToLeave:
        'Are you sure you want to leave this organization',
      block: 'Do you want to block the organization',
      changeOrganization: 'Change organization',
      confirmKeyToProceed: 'To proceed confirm the organization key',
      delete: 'Do you want to delete the organization',
      domain: 'Domain',
      edit: 'Edit organization',
      inputOrganization: 'Select root organization',
      inputTimezone: 'Select timezone',
      key: 'Unique key',
      leave: 'Leave',
      loadLogo: 'Upload logo',
      onceYouQuit: 'Once you quit you will not be able to join again',
      onlyFollowed: 'Show only followed organizations',
      organizationProfile: 'Organization profile',
      pasteKey: 'Paste here the organization key',
      root: 'Root organization',
      search: 'Search organization',
      subOrganizations: 'Sub organizations',
      uploadLogo: 'Upload logo',
      vat: 'VAT',
      loadLogo: "Load organization's logo",
      maxSize:
        'Maximum file dimension is 4MB, accepted extensions: .jpeg, .jpg, .png'
    },
    roles: {
      add: 'Add role'
    },
    rules: {
      add: 'Add rule',
      all: 'All',
      areYouSureYouWantToDelete: 'Are you sure you want to delete the rule',
      createNew: 'Create new rule',
      deleteRule: 'Delete rule',
      disabled: 'Disabled',
      enabled: 'Enabled',
      inputNamePlaceholder: 'Insert rule name',
      maxDelta: 'Max Delta',
      ruleState: 'Rule state',
      selectAction: 'Select an action',
      selectAllValues: 'Select all values',
      selectAMeasure: 'Select a measure',
      selectAtLeastOneValue: 'Select at least one value',
      selectOneValue: 'Select one value',
      selectStatus: 'Select status',
      selectTrigger: 'Select a trigger'
    },
    superroles: {
      add: 'Add super role'
    },
    triggers: {
      add: 'Add trigger',
      addTime: 'Add time',
      alarmTolerance: 'Alarm tolerance',
      applyTime: 'Apply time',
      areYouSureYouWantToDelete: 'Are you sure you want to delete the trigger',
      byDevice: 'By device',
      byQuantity: 'By quantity',
      change: 'Change trigger',
      createNew: 'Create new trigger',
      default24: 'Default 24/7',
      deleteTrigger: 'Delete trigger',
      edit: 'Edit trigger',
      everyday: 'Everyday',
      filter: 'Filter trigger',
      filters: {
        brands: 'Brands',
        models: 'Models',
        organizations: 'Organizations',
        selectFilters: 'Select a filter',
        users: 'Users'
      },
      findDevice: 'Find device',
      missingCommunication: 'Missing communication',
      modelsRelatedTo: 'Models related to',
      numberOfMeasuresAboveThresholdBeforeAlarm:
        'Number of measures above threshold before starting the alarm',
      operator: 'Operator',
      operators: {
        between: 'Between',
        equal: 'Equal',
        'greater-than': 'Greater than',
        'greater-than-or-equal-to': 'Greater than or equal to',
        label: 'Operators',
        'less-than': 'Less than',
        'less-than-or-equal-to': 'Less than or equal to',
        'not-between': 'Not between',
        'not-equal': 'Not equal',
        present: 'Present',
        notPresent: 'Not present',
        position: 'Postion'
      },
      searchInSubOrganizations: 'Search also in sub-organizations',
      select: 'Select trigger',
      selectAll: 'Select all',
      selectAtLeastAModel: 'Select at least a model',
      selectDeviceToPairWithTrigger: 'Select a device to pair with the trigger',
      selectedDevice: 'Selected device',
      selectMeasure: 'Select measure',
      selectOperator: 'Select an operator',
      selectSubMeasure: 'Select sub measure',
      selectWeeklyTimes: 'Select weekly times (GMT+1)',
      sendReminder: 'Send reminder',
      size: 'Size',
      triggerDefinition: 'Trigger definition',
      triggerName: 'Trigger name',
      triggerType: 'Trigger type',
      types: {
        'measure-communication': 'Measure communication',
        measureCommunication: 'Measure communication',
        measureTrigger: 'Measure trigger',
        'measure-trigger': 'Measure trigger',
        communication: 'Communication',
        delta: 'Delta',
        scheduledTrigger: 'Scheduled trigger',
        threshold: 'Threshold',
        trigger: 'Trigger'
      },
      weekDays: {
        friday: 'Friday',
        monday: 'Monday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        thursday: 'Thursday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday'
      }
    },
    users: {
      acceptCommercialAgreement: 'I accept to get sent commercial material',
      add: 'Invite user',
      changePassword: 'Change password',
      checkYourInboxText:
        'Check your inbox and follow the instructions to reset the password',
      confirmUserKeyToProceed: 'Insert user key to proceed',
      doYouWantToDeleteTheUser: 'Do you want to delete the user',
      forgotPassword: 'forgot password?',
      found_one: '{{count}} user found',
      found_other: '{{count}} users found',
      handleYourDevices: 'handle your devices',
      invalidOldPassword: 'Current password not valid',
      loginFail: 'incorrect username and/or password',
      loginToCityEye: 'Login to CityEye',
      memberSince: 'Member since',
      notificationFrequencySelection:
        'Select how ofter you want to receive notifications',
      notificationFrequencySelectionText: `You can select how often you want to receive notifications relative to the device's alarms`,
      notifications: {
        daily: 'Daily',
        instant: 'Instant',
        monthly: 'Monthly',
        selectFrequency: 'Select notification frequency',
        weekly: 'Weekly'
      },
      pasteUserKey: 'Paste user key here',
      personalInformation: 'Personal information',
      preferences: 'Preferences',
      resetPasswordForCityEye: 'Reset Password',
      resetPasswordForCityEyeDescription:
        'enter the email address where you will receive password recovery instructions',
      resetPasswordLinkSent: 'Reset password link sent',
      returnToLogin: 'Return to login',
      search: 'Search user',
      selectLanguage: 'Select language',
      signIn: 'sign in'
    },
    validation: {
      domain: 'May contain only digits, letters and -',
      email: 'It must be a valid email',
      greater: 'It must be greater of {{from}}',
      hex: 'It must be an hexadecimal number',
      integer: 'It must be an integer number',
      length: 'It must be {{length}} characters length',
      less: 'It must be less of {{from}}',
      mandatory: 'You must accept these conditions to proceed',
      max: 'It must be at most {{value}}',
      maxLength: 'It must be a maximum {{length}} characters length',
      min: 'It must be at least {{value}}',
      minLength: 'It must be at least {{length}} characters length',
      number: 'It must be a number',
      only: 'Not allowed',
      orgKey: 'May contain only digits, letters and -',
      password: 'Passwords does not match',
      phone:
        'You must enter a valid phone number with country code (+XX) and no spaces',
      positive: 'It must be a positive number',
      required: 'Cannot be empty',
      string: 'It must be a test string',
      uri: 'It must be a valid URL'
    }
  }
}
