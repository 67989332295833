import { IconButton } from '@mui/material'
import React from 'react'
import { DarkModeRounded as DarkModeRoundedIcon, LightModeRounded as LightModeRoundedIcon } from '@mui/icons-material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setTheme } from '../../../store/slices/general.js'

const ThemeToggle = () => {
  const { theme } = useSelector((state) => state.general, shallowEqual)
  const dispatch = useDispatch()

  return (
    <IconButton
      aria-label="switch to dark mode"
      onClick={() => dispatch(setTheme(theme === 'light' ? 'dark' : 'light'))}
      color="inherit"
    >
      {theme === 'light' && <DarkModeRoundedIcon fontSize="small" />}
      {theme === 'dark' && <LightModeRoundedIcon fontSize="small" />}
    </IconButton>
  )
}

export default ThemeToggle
