import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import {
  ChevronRightRounded as ChevronRightRoundedIcon,
  LogoutRounded as LogoutRoundedIcon
} from '@mui/icons-material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  authenticationApi,
  useLogoutMutation
} from '../../../store/apis/authentication-api.js'
import { logoutState } from '../../../store/slices/general.js'
import { t } from 'i18next'

import { actionsApi } from '../../../store/apis/actions-api.js'
import { devicesApi } from '../../../store/apis/devices-api.js'
import { eventsApi } from '../../../store/apis/events-api.js'
import { filtersApi } from '../../../store/apis/filters-api.js'
import { invitesApi } from '../../../store/apis/invites-api.js'
import { organizationsApi } from '../../../store/apis/organizations-api.js'
import { rulesApi } from '../../../store/apis/rules-api.js'
import { triggersApi } from '../../../store/apis/triggers-api.js'
import { usersApi } from '../../../store/apis/users-api.js'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert.js'

export default function ProfileMenu({ anchorEl, handleClose }) {
  const navigate = useNavigate()

  const {
    credentials: { refreshToken }
  } = useSelector((state) => state.general, shallowEqual)

  const [logoutUser, { isError: isLogoutError, isSuccess: isLogoutSuccess, error }] = useLogoutMutation()

  const dispatch = useDispatch()

  const open = Boolean(anchorEl)

  useOpenResultAlert(error, { isError: isLogoutError, isSuccess: isLogoutSuccess })

  useEffect(() => {
    if (isLogoutSuccess || isLogoutError) {
      dispatch(logoutState())
      dispatch(organizationsApi.util.resetApiState())
      dispatch(devicesApi.util.resetApiState())
      dispatch(eventsApi.util.resetApiState())
      dispatch(invitesApi.util.resetApiState())
      dispatch(actionsApi.util.resetApiState())
      dispatch(rulesApi.util.resetApiState())
      dispatch(triggersApi.util.resetApiState())
      dispatch(authenticationApi.util.resetApiState())
      dispatch(filtersApi.util.resetApiState())
      dispatch(usersApi.util.resetApiState())
      navigate('/')
    }
  }, [isLogoutSuccess, isLogoutError])

  const handleLogout = () => {
    logoutUser({ refreshToken })
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={{ top: '20px' }}
    >
      <MenuItem
        id="profile-menu-profile"
        component={Link}
        to="/management/profile"
      >
        <ListItemIcon>
          <ChevronRightRoundedIcon />
        </ListItemIcon>
        <ListItemText>{t('common.profile')}</ListItemText>
      </MenuItem>
      {
        //TODO: scommentare una volta che esiste la dashboard
        /* <MenuItem> 
        <ListItemIcon>
          <ChevronRightRoundedIcon />
        </ListItemIcon>
        <ListItemText>{t('common.dashboard')}</ListItemText>
      </MenuItem> */
      }
      <MenuItem
        id="profile-menu-management"
        component={Link}
        to="/management/devices"
      >
        <ListItemIcon>
          <ChevronRightRoundedIcon />
        </ListItemIcon>
        <ListItemText>{t('common.management')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutRoundedIcon />
        </ListItemIcon>
        <ListItemText>{t('common.logout')}</ListItemText>
      </MenuItem>
    </Menu>
  )
}

ProfileMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired
}
