import { IconButton } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material'

const CloseDialogButton = ({ onClose }) => {
  return (
    <IconButton
      id="filters-dialog-close-button"
      aria-label="close"
      sx={{
        position: 'absolute',
        right: 8,
        top: 8
      }}
      onClick={onClose}
    >
      <CloseRoundedIcon />
    </IconButton>
  )
}

CloseDialogButton.propTypes = {
  onClose: PropTypes.func
}

export default CloseDialogButton
