import {
  Box,
  Button,
  Stack,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import TriggerCard from '../Card/TriggerCard.jsx'
import AddTrigger from '../Dialogs/AddTrigger.jsx'
import { resetTriggerPayloadToDefault } from '../../../store/slices/trigger-add'
import {
  useDeleteTriggerMutation,
  useSearchTriggersMutation
} from '../../../store/apis/triggers-api'
import ConfirmDialog from '../../Shared/Dialogs/ConfirmDialog'
import TriggerDetail from '../Dialogs/TriggerDetail'
import TriggersFilters from '../Filters/TriggersFilters'
import { setFiltersTriggers } from '../../../store/slices/filters'
import { useParams, useSearchParams } from 'react-router-dom'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert.js'
import ZList from '../../Shared/Components/ZList.jsx'

const DEFAULT_PAGE_SIZE = 25

const TriggersList = () => {
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')
  const dispatch = useDispatch()

  const { tab, entityId } = useParams()
  const [triggerToDeleteName, setTriggerToDeleteName] = useState(null)
  const [triggerToDeleteId, setTriggerToDeleteId] = useState(null)
  const [selectedTriggerId, setSelectedTriggerId] = useState()
  const [triggerDetailOpen, setTriggerDetailOpen] = useState(false)
  const [isAddTriggerDialogOpen, setIsAddTriggerDialogOpen] = useState(false)
  const [confirmDeleteTriggerIsOpen, setConfirmDeleteTriggerIsOpen] = useState(false)

  const { triggers: filters } = useSelector((state) => state.filters,shallowEqual)

  const [page, setPage] = useState(1)

  const [
    searchTriggers,
    {
      data: triggers,
      isLoading: isFetchTriggersLoading,
      isError: isFetchTriggersError
    }
  ] = useSearchTriggersMutation()

  useEffect(() => {
    searchTriggers({ filters: { ...filters, fullText },
      page,
      pageSize: DEFAULT_PAGE_SIZE
    })
  }, [page])

  useEffect(() => {
    searchTriggers({ filters: { ...filters, fullText },
      page,
      pageSize: DEFAULT_PAGE_SIZE
    })
    setPage(1)
  }, [filters, fullText])

  const handleChangePage = (_, value) => {
    setPage(value)
  }

  const [
    deleteTrigger,
    {
      isError: isDeleteTriggerError,
      isSuccess: isDeleteTriggerSuccess,
      error: deleteTriggerError
    }
  ] = useDeleteTriggerMutation()

  const prepareDeleteTrigger = async (trigger) => {
    setTriggerToDeleteId(trigger.id)
    setTriggerToDeleteName(trigger.name)
    setConfirmDeleteTriggerIsOpen(true)
  }

  useEffect(() => {
    if (isAddTriggerDialogOpen) {
      return
    }
    searchTriggers({ filters: { ...filters, fullText },
      page,
      pageSize: DEFAULT_PAGE_SIZE
    })
    setPage(1)
  }, [isAddTriggerDialogOpen, isDeleteTriggerSuccess])

  useOpenResultAlert(deleteTriggerError, { isError: isDeleteTriggerError, isSuccess: isDeleteTriggerSuccess })

  useEffect(() => {
    if (tab !== 'triggers' || !entityId) {
      return
    }

    setTriggerDetailOpen(true)
    setSelectedTriggerId(entityId)
  }, [tab, entityId])

  const handleFiltersChange = (filters) => {
    dispatch(setFiltersTriggers(filters))
  }

  const handleOnEdit = (triggerId) => {
    setSelectedTriggerId(triggerId)
    setTriggerDetailOpen(true)
  }
  const handleTriggerDetailClose = () => {
    setSelectedTriggerId(null)
    setTriggerDetailOpen(false)
  }

  const handleOnConfirmDeleteTrigger = () => {
    deleteTrigger({ id: triggerToDeleteId })
    setConfirmDeleteTriggerIsOpen(false)
  }

  const triggersList = triggers?.data.map((trigger, index) => (
    <TriggerCard
      index={index}
      key={`trigger-${index}`}
      trigger={trigger}
      onDelete={() => prepareDeleteTrigger(trigger)}
      onEdit={handleOnEdit}
    />
  ))

  const triggersFilters = <TriggersFilters filters={filters} onChange={handleFiltersChange} />

  return (
    <>
      <Stack direction="row">
        <Typography variant="body1">
          <strong>{t('automations.triggers')}</strong>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          id="triggers-list-create-new-trigger"
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => {
            setIsAddTriggerDialogOpen(true)
            dispatch(resetTriggerPayloadToDefault())
          }}
        >
          {t('triggers.createNew')}
        </Button>
      </Stack>

      <ZList count={triggers?.count} filters={triggersFilters} entities={triggersList} page={page} isLoading={isFetchTriggersLoading || isFetchTriggersError} onPageChange={handleChangePage} />

      <AddTrigger
        isOpen={isAddTriggerDialogOpen}
        setIsOpen={setIsAddTriggerDialogOpen}
      />
      {triggerDetailOpen && (
        <TriggerDetail
          open={triggerDetailOpen}
          setIsOpen={setTriggerDetailOpen}
          onClose={handleTriggerDetailClose}
          triggerId={selectedTriggerId}
        />
      )}
      <ConfirmDialog
        isOpen={confirmDeleteTriggerIsOpen}
        title={t('triggers.deleteTrigger')}
        message={`${t('triggers.areYouSureYouWantToDelete')} "${triggerToDeleteName}"?`}
        onConfirm={handleOnConfirmDeleteTrigger}
        onClose={() => setConfirmDeleteTriggerIsOpen(false)}
      />
    </>
  )
}

export default TriggersList
