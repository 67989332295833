import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Divider,
  IconButton,
  Stack,
  Tab,
  Typography
} from '@mui/material'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import {
  PlaceRounded as PlaceRoundedIcon,
  RssFeedRounded as RssFeedRoundedIcon,
  RefreshRounded as RefreshRoundedIcon,
  EditRounded as EditRoundedIcon,
  SendRounded as SendRoundedIcon,
  DeleteRounded as DeleteRoundedIcon
} from '@mui/icons-material'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import userPermission from '../../../helpers/userPermission.js'
import {
  useDeleteDeviceMutation,
  useFetchDeviceQuery,
  useFetchSelfOrganizationsQuery
} from '../../../store/index.js'
import {
  setCurrentDevice,
  setMapCenter,
  setMapZoom,
} from '../../../store/slices/general.js'
import TimePicker from '../../Layout/TimePicker/TimePicker.jsx'
import DeviceStatusBadge from '../StatusBadge/StatusBadge.jsx'
import EventsTab from '../Tabs/EventsTab.jsx'
import MeasuresTab from '../Tabs/MeasuresTab.jsx'
import NetworkTab from '../Tabs/NetworkTab.jsx'
import UpdateDevice from '../Dialogs/UpdateDevice.jsx'
import Can from '../../Layout/Can/Can.jsx'
import { typeToString } from '../../../helpers/lorawanFieldsHelper.js'
import PropTypes from 'prop-types'
import ConfirmWithCheckDialog from '../../Shared/Dialogs/ConfirmWithCheckDialog.jsx'
import Actions from '../Dialogs/Actions.jsx'
import SendAction from '../Dialogs/SendAction.jsx'
import { useNavigate } from 'react-router-dom'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert.js'

const DeviceDetail = ({ deviceId }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [organization, setOrganization] = useState(null)
  const [activeTab, setActiveTab] = useState('measures')
  const [isEditDeviceDialogOpen, setIsEditDeviceDialogOpen] = useState(false)
  const [shouldRefreshGraph, setShouldRefreshGraph] = useState(false)
  const [isConfirmWithCheckOpen, setIsConfirmWithCheckOpen] = useState(false)

  const [isActionDialogOpen, setIsActionDialogOpen] = useState(false)
  const [isSendActionOpen, setIsSendActionOpen] = useState(false)
  const [timePicker, setTimePicker] = useState([
    moment().subtract(3, 'days').startOf('day').toISOString(),
    moment().endOf('day').toISOString()
  ])

  const { credentials } = useSelector((state) => state.general)

  const { data: device, isSuccess } = useFetchDeviceQuery(deviceId)

  const { data: organizations } = useFetchSelfOrganizationsQuery({skip: !credentials || !device})

  const [
    deleteDevice,
    {
      isSuccess: isDeleteDeviceSuccess,
      isError: isDeleteDeviceError,
      error: deleteDeviceError
    }
  ] = useDeleteDeviceMutation()

  useOpenResultAlert(deleteDeviceError, { isError: isDeleteDeviceError, isSuccess: isDeleteDeviceSuccess })

  useEffect(() => {
    if (isDeleteDeviceSuccess) {
      navigate('/')
    }
  }, [isDeleteDeviceSuccess])

  const handleEdit = () => setIsEditDeviceDialogOpen(true)

  const handleDelete = () => setIsConfirmWithCheckOpen(true)

  const handleRefreshGraphs = () => setShouldRefreshGraph(true)

  const handleSetIsOpenEditDevice = (isOpen) => setIsEditDeviceDialogOpen(isOpen)

  const handleCenterMap = (device) => {
    dispatch(setCurrentDevice(device.device.id))
    dispatch(
      setMapCenter({
        lat: device.device.position.latitude,
        lng: device.device.position.longitude
      })
    )
    dispatch(setMapZoom(20))
  }

  const handleChangeTab = (_, activeTab) => setActiveTab(activeTab)

  const handleSetIsOpenActionDialogOpen = (isOpen) => setIsActionDialogOpen(isOpen)

  const handleSendAction = () => {
    setIsActionDialogOpen(false)
    setIsSendActionOpen(true)
  }

  const handleAction = () => setIsActionDialogOpen(true)

  const handleSendActionIsOpen = (isOpen) => setIsSendActionOpen(isOpen)

  const handleTimePickerChange = (values) => setTimePicker(values)

  const handleCloseConfigrWithCheck = () => setIsConfirmWithCheckOpen(false)
  
  const handleConfirmAction = () => {
    deleteDevice(device.device.id)
    setIsConfirmWithCheckOpen(false)
  }

  useEffect(() => {
    setOrganization(organizations?.find((o) => o?.key === device?.device?.organizationKey))
  }, [organizations, device?.device?.organizationKey])

  return (
    <>
      <Stack
        direction="row"
        mb={3}
        justifyContent={'flex-start'}
      >
        <DeviceStatusBadge
          isAlive={device?.device?.isAlive}
          isAlarm={device?.device?.isAlarm}
        />
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems={'flex-end'}
          width="100%"
          px={1}
        >
          <Box>
            <ButtonGroup size="small">
              <Can action="devices_d">
                <IconButton
                  id="device-detail-delete"
                  key="delete"
                  onClick={handleDelete}
                  aria-label="delete"
                  color="error"
                >
                  <DeleteRoundedIcon fontSize="small" />
                </IconButton>
              </Can>
              <Can action="devices_u">
                <IconButton
                  id="device-detail-actions"
                  key="actions"
                  disabled={!device?.actions?.length}
                  onClick={handleAction}
                  color="primary"
                >
                  <SendRoundedIcon fontSize="small" />
                </IconButton>
              </Can>
              <Can action="devices_u">
                <IconButton
                  id="device-detail-edit"
                  key="edit"
                  aria-label="edit device"
                  color="primary"
                  onClick={() => handleEdit(device)}
                >
                  <EditRoundedIcon fontSize="small" />
                </IconButton>
              </Can>
            </ButtonGroup>
          </Box>
        </Box>
      </Stack>

      <Divider />

      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        mt={5}
      >
        <Stack spacing={1}>
          <Typography
            variant="h5"
            color="text.secondary"
            sx={{ textTransform: 'uppercase' }}
          >
            {organization?.name}
          </Typography>
          <Typography
            variant="h2"
            onClick={() => handleCenterMap(device)}
            id={`device-detail-name`}
          >
            {device?.device?.name}
          </Typography>
          <Typography
            variant="body"
            color="text.secondary"
            sx={{ textTransform: 'uppercase' }}
          >
            {device?.device?.brand} {device?.device?.model ? '/' : ''}{' '}
            {device?.device?.model}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        spacing={2}
        mt={5}
      >
        {device?.device?.position?.latitude &&
          device?.device?.position?.longitude && (
            <Card
              variant="standard"
              sx={{ width: '100%', minHeight: '140px' }}
            >
              <CardContent>
                <Stack
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <PlaceRoundedIcon fontSize="small" />
                </Stack>
                <Typography
                  mt={3}
                  sx={{ fontSize: '0.8rem', fontWeight: 500 }}
                >
                  {device?.device?.address}
                </Typography>
                <Typography sx={{ fontSize: '0.8rem', fontWeight: 200 }}>
                  {device?.device?.position?.latitude},{' '}
                  {device?.device?.position?.longitude}
                </Typography>
              </CardContent>
            </Card>
          )}

        {(!device?.device?.position?.latitude ||
          !device?.device?.position?.longitude) && (
            <Card
              variant="standard"
              sx={{
                width: '100%',
                minHeight: '140px',
                background: 'none',
                border: '1px solid red'
              }}
            >
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="25vh"
                >
                  <Box>
                    <Typography
                      variant="h4"
                      align="center"
                    >
                      {t('common.warning')}
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                    >
                      {t('devices.noCoordinates')}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )}

        <Card
          variant="standard"
          sx={{ width: '100%', minHeight: '140px' }}
        >
          <CardContent>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography
                sx={{ fontSize: '0.7rem' }}
                color="success"
              >
                <RssFeedRoundedIcon fontSize="small" /> Join
              </Typography>
              <Typography sx={{ fontSize: '0.7rem', fontWeight: 200 }}>
                {t('devices.added')}{' '}
                {device?.device?.createdAt &&
                  moment(device.device.createdAt).format('DD.MM.YYYY HH:mm:ss')}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Box>
                <Typography
                  mt={3}
                  sx={{ fontSize: '0.8rem', fontWeight: 500 }}
                >
                  {t('devices.lastUpdate')}
                </Typography>
                <Typography sx={{ fontSize: '0.8rem', fontWeight: 200 }}>
                  {device?.device?.lastMeasurementAt &&
                    moment(device.device.lastMeasurementAt).format(
                      'DD.MM.YYYY HH:mm:ss'
                    )}
                </Typography>
              </Box>
              <Box>
                <Typography
                  mt={3}
                  sx={{ fontSize: '0.8rem', fontWeight: 500 }}
                >
                  {t('devices.activeSince')}
                </Typography>
                <Typography sx={{ fontSize: '0.8rem', fontWeight: 200 }}>
                  {device?.device?.activeSince &&
                    moment(device.device.activeSince).format(
                      'DD.MM.YYYY HH:mm:ss'
                    )}
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Stack>

      <Typography
        mt={5}
        mb={2}
        variant="title"
      >
        {t('devices.description')}
      </Typography>
      <Typography variant="body2">{device?.device?.description}</Typography>

      <Typography
        mt={5}
        mb={2}
        variant="title"
      >
        {t('devices.serial')}
      </Typography>
      <Typography variant="body2">{device?.device?.serial}</Typography>

      {device?.sourceData && (
        <Box sx={{ marginTop: '30px' }}>
          <Typography
            mt={5}
            mb={2}
            variant="title"
          >
            {t('devices.deviceInformation')}
          </Typography>
          <Stack
            sx={{ marginTop: '15px' }}
            spacing={3}
          >
            <Stack
              direction={'row'}
              spacing={15}
            >
              <Stack spacing={1}>
                <Typography
                  color={'#C0C0C0'}
                  variant="p"
                >
                  AppKey
                </Typography>
                <Typography variant="body2">
                  {device?.sourceData.device.appKey}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography
                  color={'#C0C0C0'}
                  variant="p"
                >
                  Network Server
                </Typography>
                <Typography variant="body2">
                  {device?.sourceData.networkServerId}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography
                  color={'#C0C0C0'}
                  variant="p"
                >
                  Device Eui
                </Typography>
                <Typography variant="body2">
                  {device?.sourceData.device.devEui}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction={'row'}
              spacing={15}
            >
              <Stack spacing={1}>
                <Typography
                  color={'#C0C0C0'}
                  variant="p"
                >
                  {t('devices.loraType')}
                </Typography>
                <Typography variant="body2">
                  {typeToString(device?.sourceData.device.type)}
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography
                  color={'#C0C0C0'}
                  variant="p"
                >
                  Join Eui
                </Typography>
                <Typography variant="body2">
                  {device?.sourceData.device.joinEui}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      )}

      <Typography
        mt={5}
        mb={2}
        variant="title"
      >
        {t('devices.categories')}
      </Typography>

      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        spacing={2}
      >
        <Card
          variant="standard"
          sx={{
            width: '100%',
            background: 'rgba(179, 126, 221, 0.1)'
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 10 }}>{t('devices.board')}</Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              {device?.device?.public
                ? t('devices.public')
                : t('devices.private')}
            </Typography>
          </CardContent>
        </Card>
        <Card
          variant="standard"
          sx={{
            width: '100%',
            background: 'rgba(136, 204, 211, 0.1)'
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 10 }}>
              {t('devices.visibility')}
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              {device?.device?.isHidden
                ? t('devices.hidden')
                : t('devices.visible')}
            </Typography>
          </CardContent>
        </Card>
        <Card
          variant="standard"
          sx={{
            width: '100%',
            background: 'rgba(255, 165, 0, 0.1)'
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 10 }}>{t('devices.group')}</Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              {device?.device?.group}
            </Typography>
          </CardContent>
        </Card>
        <Card
          variant="standard"
          sx={{
            width: '100%',
            background: 'rgba(229, 94, 194, 0.1)'
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 10 }}>{t('common.type')}</Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              {device?.device?.type}
            </Typography>
          </CardContent>
        </Card>
      </Stack>

      <Typography
        mt={5}
        mb={2}
        variant="title"
      >
        {t('devices.tags')}
      </Typography>

      <Stack
        direction="row"
        spacing={1}
        mb={5}
      >
        {device?.device?.tags?.map((t) => (
          <Chip
            label={t}
            key={t}
            color="primary"
          />
        ))}
      </Stack>

      <Divider />

      <Stack
        direction="row"
        justifyContent="right"
      >
        <TimePicker onChange={handleTimePickerChange} timePicker={timePicker} />

        <Button onClick={handleRefreshGraphs}>
          <RefreshRoundedIcon />
        </Button>
      </Stack>

      <TabContext value={activeTab}>
        <Stack
          direction="row"
          spacing={1}
          mt={4}
        >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChangeTab}
              aria-label="device data tabs"
            >
              <Tab
                label={t('common.measures')}
                value="measures"
              />

              {userPermission('devices_r') && (
                <Tab
                  label={t('common.events')}
                  value="events"
                />
              )}

              {userPermission('devices_r') && device?.sourceData && (
                <Tab
                  label={t('common.network')}
                  value="network"
                />
              )}
            </TabList>
          </Box>
        </Stack>

        {isSuccess && (
          <>
            <TabPanel
              key={'measures-tab'}
              value="measures"
            >
              <MeasuresTab
                device={device.device}
                quantities={device.quantities}
                refresh={shouldRefreshGraph}
                setRefresh={setShouldRefreshGraph}
                timePicker={timePicker}
              />
            </TabPanel>

            <TabPanel
              key={'events-tab'}
              value="events"
            >
              {device && (
                <EventsTab
                  deviceId={device.device.id}
                  refresh={shouldRefreshGraph}
                  setRefresh={setShouldRefreshGraph}
                  timePicker={timePicker}
                />
              )}
            </TabPanel>

            <TabPanel
              key={'network-tab'}
              value="network"
            >
              <NetworkTab
                device={device.device}
                sourceData={device.sourceData}
                refresh={shouldRefreshGraph}
                setRefresh={setShouldRefreshGraph}
                timePicker={timePicker}
              />
            </TabPanel>
          </>
        )}
      </TabContext>

      <Can action="devices_u">
        {isEditDeviceDialogOpen && (
          <UpdateDevice
            deviceId={device.device.id}
            isOpen={isEditDeviceDialogOpen}
            setIsOpen={handleSetIsOpenEditDevice}
          />
        )}
      </Can>

      <Can action="devices_d" expression={() => !!device}>
        <ConfirmWithCheckDialog
          isOpen={isConfirmWithCheckOpen}
          icon={<DeleteRoundedIcon size={60} color="#ef5350" />}
          onClose={handleCloseConfigrWithCheck}
          onConfirmAction={handleConfirmAction}
          questionText={t('devices.deleteDevice.title')}
          subQuestionText={device?.device.name}
          copyText={device?.device.id}
          bodyText={t('devices.deleteDevice.subtitle')}
          inputSuggestionText={t('devices.deleteDevice.helper')}
        />
      </Can>

      <Can
        action="devices_u"
        expression={() => !!device}
      >
        {isActionDialogOpen && (
          <Actions
            onSendAction={handleSendAction}
            isOpen={isActionDialogOpen}
            setIsOpen={handleSetIsOpenActionDialogOpen}
            device={device.device}
          />
        )}
        {isSendActionOpen && (
          <SendAction
            device={device.device}
            isOpen={isSendActionOpen}
            setIsOpen={handleSendActionIsOpen}
          />
        )}
      </Can>
    </>
  )
}

DeviceDetail.propTypes = {
  deviceId: PropTypes.string
}

export default DeviceDetail
