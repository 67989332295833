import { Box, Pagination, Skeleton, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  setCurrentOrganization,
  setErrorAlertMessage,
  setOpenDialog
} from '../../../store/slices/general.js'
import OrganizationCard from '../Card/OrganizationCard.jsx'
import OrganizationEditDialog from '../Dialogs/OrganizationEdit'
import {
  useDeleteOrganizationMutation,
  useFetchOrganizationsWithThresholdsAndFiltersQuery,
  useLockOrganizationMutation,
  useUnlockOrganizationMutation
} from '../../../store/apis/organizations-api'
import ConfirmWithCheckDialog from '../../Shared/Dialogs/ConfirmWithCheckDialog.jsx'
import {
  DeleteRounded as DeleteRoundedIcon,
  LockRounded as LockRoundedIcon
} from '@mui/icons-material'
import { t } from 'i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import OrganizationAddDialog from '../Dialogs/OrganizationAdd.jsx'
import PropTypes from 'prop-types'
import OrganizationsFilters from '../Filters/Filters.jsx'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

const DEFAULT_PAGE_SIZE = 25

const Organizations = ({
  onOrganizationsCountUpdate,
  isAddOrganizationDialogOpen,
  onOrganizationAddDialogClose
}) => {
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')

  const dispatch = useDispatch()
  const { organizationId } = useParams()

  const { organizations: filters } = useSelector(
    (state) => state.filters,
    shallowEqual
  )

  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [confirmDialogData, setConfirmDialogData] = useState(null)
  const [isEditOrganizationDialogOpen, setIsEditOrganizationDialogOpen] =
    useState(false)
  const [isConfirmWithCheckOpen, setIsConfirmWithCheckOpen] = useState(false)

  const [page, setPage] = useState(1)

  const {
    data: organizations,
    isError: isFetchOrganizationsError,
    isLoading: isFetchOrganizationsLoading,
    refetch: refetchOrganizations
  } = useFetchOrganizationsWithThresholdsAndFiltersQuery({
    filters,
    fullText,
    page,
    pageSize: DEFAULT_PAGE_SIZE
  })

  const [
    deleteOrganization,
    {
      isSuccess: isDeleteOrganizationSuccess,
      isError: isDeleteOrganizationError,
      error: deleteOrganizationError
    }
  ] = useDeleteOrganizationMutation()

  const [lockOrganization, { isSuccess: isLockOrganizationSuccess }] =
    useLockOrganizationMutation()

  const [unlockOrganization, { isSuccess: isUnlockOrganizationSuccess }] =
    useUnlockOrganizationMutation()

  const handleCloseConfirmWithCheck = () => setIsConfirmWithCheckOpen(false)

  useEffect(() => {
    refetchOrganizations()
    setIsConfirmWithCheckOpen(false)
  }, [
    isEditOrganizationDialogOpen,
    isDeleteOrganizationSuccess,
    isLockOrganizationSuccess,
    isUnlockOrganizationSuccess,
    fullText
  ])

  useEffect(() => {
    setIsEditOrganizationDialogOpen(true)
  }, [organizationId])

  useEffect(() => {
    if (deleteOrganizationError?.data) {
      dispatch(setOpenDialog('errorAlert'))

      if (deleteOrganizationError.data.error) {
        dispatch(
          setErrorAlertMessage(
            `${deleteOrganizationError.data.statusCode} ${deleteOrganizationError.data.message}`
          )
        )
      } else if (deleteOrganizationError.error)
        dispatch(setErrorAlertMessage(deleteOrganizationError.error))
    }
  }, [isDeleteOrganizationError])

  useEffect(() => {
    if (isDeleteOrganizationSuccess) {
      dispatch(setOpenDialog('successAlert'))
      setPage(1)
    }
  }, [isDeleteOrganizationSuccess])

  useEffect(() => {
    onOrganizationsCountUpdate(organizations?.totalCount || 0)
  }, [organizations])

  const handleChangePage = (_, value) => {
    setPage(value)
  }

  const handleSaveOrganization = () => {
    onOrganizationAddDialogClose()
    refetchOrganizations()
  }

  const openEditDialog = (organization) => {
    setSelectedOrganization(organization)
    setIsEditOrganizationDialogOpen(true)
  }

  const openDeleteDialog = (organization) => {
    setConfirmDialogData({
      icon: (
        <DeleteRoundedIcon
          id='organization-delete-button'
          size={60}
          color="#ef5350"
        />
      ),
      onConfirmAction: () => deleteOrganization(organization.id),
      questionText: t('organizations.delete') + '?',
      subQuestionText: organization.name,
      copyText: organization.key,
      bodyText: t('organizations.confirmKeyToProceed'),
      inputSuggestionText: t('organizations.pasteKey')
    })
    setIsConfirmWithCheckOpen(true)
  }

  const toggleLockOperation = (organization) => {
    if (organization.locked) unlockOrganization(organization.id)
    else {
      setConfirmDialogData({
        icon: (
          <LockRoundedIcon
            size={60}
            color="#ef5350"
          />
        ),
        onConfirmAction: () => lockOrganization(organization.id),
        questionText: t('organizations.block') + '?',
        subQuestionText: organization.name,
        copyText: organization.key,
        bodyText: t('organizations.confirmKeyToProceed'),
        inputSuggestionText: t('organizations.pasteKey')
      })
      setIsConfirmWithCheckOpen(true)
    }
  }

  const executeClose = () => {
    setCurrentOrganization(null)
  }

  return (
    <>
      <Box sx={{ my: 3, ml: 1 }}>
        <OrganizationsFilters filters={filters} />
      </Box>

      {isFetchOrganizationsLoading && (
        <Stack
          spacing={2}
          sx={{ marginBottom: '20px', marginTop: '20px' }}
        >
          {[...Array(DEFAULT_PAGE_SIZE).keys()].map((index) => (
            <Skeleton
              key={`skeleton-${index}`}
              variant="rounded"
              sx={{ height: '230px' }}
            ></Skeleton>
          ))}
        </Stack>
      )}
      {!isFetchOrganizationsError &&
        !isFetchOrganizationsLoading &&
        organizations?.data.map((o) => (
          <OrganizationCard
            key={o.key}
            isRoot={o.isRoot}
            index={o.key}
            organization={o}
            onEdit={openEditDialog}
            onDelete={openDeleteDialog}
            onLock={toggleLockOperation}
          />
        ))}

      <Box
        display="flex"
        justifyContent="center"
      >
        {organizations &&
          !isFetchOrganizationsLoading &&
          !isFetchOrganizationsError &&
          organizations?.totalCount > DEFAULT_PAGE_SIZE && (
            <Pagination
              count={Math.ceil(organizations?.totalCount / DEFAULT_PAGE_SIZE)}
              page={page}
              onChange={handleChangePage}
              showFirstButton
              showLastButton
            />
          )}
      </Box>

      {(selectedOrganization || organizationId) && (
        <OrganizationEditDialog
          isOpen={isEditOrganizationDialogOpen}
          setIsOpen={setIsEditOrganizationDialogOpen}
          organizationId={parseInt(selectedOrganization?.id || organizationId)}
          onClose={executeClose}
        />
      )}

      {confirmDialogData && (
        <ConfirmWithCheckDialog
          onClose={handleCloseConfirmWithCheck}
          isOpen={isConfirmWithCheckOpen}
          icon={confirmDialogData.icon}
          onConfirmAction={confirmDialogData.onConfirmAction}
          questionText={confirmDialogData.questionText}
          subQuestionText={confirmDialogData.subQuestionText}
          copyText={confirmDialogData.copyText}
          bodyText={confirmDialogData.bodyText}
          inputSuggestionText={confirmDialogData.inputSuggestionText}
        />
      )}

      <OrganizationAddDialog
        isOpen={isAddOrganizationDialogOpen}
        onClose={onOrganizationAddDialogClose}
        onSave={handleSaveOrganization}
      />
    </>
  )
}

Organizations.propTypes = {
  onOrganizationsCountUpdate: PropTypes.func,
  isAddOrganizationDialogOpen: PropTypes.bool,
  onOrganizationAddDialogClose: PropTypes.func
}

export default Organizations
