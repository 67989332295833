import React, { useState, useEffect } from 'react'
import {
  Autocomplete,
  Button,
  TextField,
  Stack
} from '@mui/material'
import { t } from 'i18next'
import {
  useFetchRolesStandardQuery,
  useFetchSelfOrganizationsQuery,
  useUpdateUserRoleAndOrganizationMutation
} from '../../../store/index.js'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert.js'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useSelector } from 'react-redux'

const UpdateUser = ({ isOpen, selectedUser, onClose }) => {
  const { currentOrganization } = useSelector((state) => state.general)

  const {
    data: organizationsSuggestions,
    error: isOrganizationsSuggestionsError,
    isLoading: isOrganizationsSuggestionsLoading
  } = useFetchSelfOrganizationsQuery()

  const {
    data: rolesSuggestions = [],
    error: isRolesSuggestionsError,
    isLoading: isRolesSuggestionsLoading
  } = useFetchRolesStandardQuery()

  const [
    updateUserRoleAndOrganization,
    {
      error: updateUserError,
      isError: isUpdateUserError,
      isSuccess: isUpdateUserSuccess
    }
  ] = useUpdateUserRoleAndOrganizationMutation()

  const [user, setUser] = useState({
    organization: selectedUser?.organization,
    role: selectedUser?.role
  })

  const handleChangeOrganization = (_, value) => setUser({ ...user, organization: value })

  const handleChangeRole = (_, value) => setUser({ ...user, role: value })

  const handleSave = () => updateUserRoleAndOrganization({
      uid: selectedUser.uid,
      currentOrganizationKey: selectedUser.organization.key,
      roleId: user.role.id,
      organizationKey: user.organization.key
    })

  useEffect(
    () =>
      setUser({
        organization: selectedUser?.organization,
        role: selectedUser?.role
      }),
    [selectedUser]
  )

  useEffect(() => {
    if (user.organization === null && organizationsSuggestions?.length) {
      setUser({
        ...user,
        organization: organizationsSuggestions.find((o) => o.key === currentOrganization)
      })
    }
  }, [organizationsSuggestions])

  useEffect(() => {
    if (user.role === null && rolesSuggestions.length) {
      setUser({ ...user, role: rolesSuggestions.find((r) => r.name === 'viewer') })
    }
  }, [rolesSuggestions])

  useEffect(() => {
    if (isUpdateUserSuccess) {
      onClose()
    }
  }, [isUpdateUserSuccess])

  useOpenResultAlert(updateUserError, { isError: isUpdateUserError, isSuccess: isUpdateUserSuccess })

  return (
      <ZDialog 
        id={'user'} 
        isOpen={isOpen} 
        title={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
        maxWidth='sm' 
        content={
        <>
          <Autocomplete
            name="organization"
            disableClearable
            loading={isOrganizationsSuggestionsLoading}
            options={organizationsSuggestions || []}
            disabled={isOrganizationsSuggestionsError}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) =>
              option && value && option.key === value.key
            }
            value={
              user.organization ? user.organization : selectedUser?.organization
            }
            onChange={handleChangeOrganization}
            renderInput={(params) => (
              <TextField
                name="organization"
                {...params}
                label={t('invites.chooseOrganization')}
                variant="standard"
                helperText={t('common.organization')}
              />
            )}
          />

          <Autocomplete
            name="role"
            disableClearable
            loading={isRolesSuggestionsLoading}
            options={rolesSuggestions || []}
            disabled={isRolesSuggestionsError}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) =>
              option && value && option.id === value.id
            }
            value={user.role ? user.role : selectedUser?.role}
            onChange={handleChangeRole}
            renderInput={(params) => (
              <TextField
                name="role"
                {...params}
                label={t('invites.chooseRole')}
                variant="standard"
                helperText={t('common.role')}
              />
            )}
            sx={{ marginTop: 3 }}
          />
        </>}
        actions={
        <Stack
          direction="row"
          spacing={2}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ marginTop: 3 }}
          >
            {t('common.save')}
          </Button>
          <Button
            variant="contained"
            onClick={onClose}
          >
            {t('common.cancel')}
          </Button>
        </Stack>}
        onClose={onClose}
      />
  )
}

export default UpdateUser
