import { Box, Typography, Stack, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logoutState } from '../../../store/slices/general'
import ConfirmDialog from '../../Shared/Dialogs/ConfirmDialog'
import { useDeleteUserSelfMutation } from '../../../store/apis/user-profile-api'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert'

const DeleteUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [confirmDeleteUserIsOpen, setConfirmDeleteUserIsOpen] = useState(false)

  const [
    deleteUserSelfRequest,
    {
      isError: isDeleteUserSelfRequestError,
      isSuccess: isDeleteUserSelfRequestSuccess
    }
  ] = useDeleteUserSelfMutation()

  const handleBackToProfile = () => navigate('/management/profile')

  const handleDeleteRequest = () => setConfirmDeleteUserIsOpen(true)

  const handleConfirmDeleteUser = () => {
    deleteUserSelfRequest()
    setConfirmDeleteUserIsOpen(false)
  }

  useOpenResultAlert({ error: 'Errore durante la cancellazione utente' }, { isError: isDeleteUserSelfRequestError, isSuccess: isDeleteUserSelfRequestSuccess })

  useEffect(() => {
    if (isDeleteUserSelfRequestSuccess) {
      dispatch(logoutState())
    }
  }, [isDeleteUserSelfRequestSuccess])

  return (
    <>
      <Box
        marginTop={'66px'}
        height={'71vh'}
        width={'100%'}
        alignContent={'center'}
      >
        <Box
          marginTop={5}
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Typography variant="h1">ELIMINA UTENTE</Typography>
        </Box>
        <Box
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}
          marginTop={10}
        >
          <Stack
            display={'flex'}
            width={'30%'}
            alignContent={'center'}
            justifyContent={'center'}
            spacing={3}
          >
            <Typography textAlign={'center'}>
              Sei sicuro di voler eliminare il tuo utente?
            </Typography>
            <Typography
              textAlign={'center'}
              fontWeight="bold"
            >
              (Questa operazione non è reversibile)
            </Typography>
            <Button
              variant="contained"
              onClick={handleBackToProfile}
            >
              Torna al profilo
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={handleDeleteRequest}
            >
              Elimina
            </Button>
          </Stack>
        </Box>
      </Box>

      <ConfirmDialog
        isOpen={confirmDeleteUserIsOpen}
        title={'Elimina utente'}
        message={`Sei sicuro di voler cancellare il tuo account?`}
        onConfirm={handleConfirmDeleteUser}
        onClose={() => setConfirmDeleteUserIsOpen(false)}
      />
    </>
  )
}

export default DeleteUser
