import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'
import sanitizer from '../../helpers/sanitizer.js'

const acceptInviteApi = createApi({
  reducerPath: 'invites',
  baseQuery: customFetchBase,
  tagTypes: ['invites'],
  endpoints(builder) {
    return {
      getInvite: builder.query({
        query: (inviteId) => {
          logger.log('RTK API - Get Invite')
          return {
            url: `/invites/${inviteId}`,
            method: 'GET'
          }
        },
        providesTags: ['invites']
      }),
      acceptInvite: builder.mutation({
        query: ({ payload, inviteId }) => {
          logger.log(`RTK API - Create user`)
          return {
            url: `/invites/${inviteId}/accept`,
            method: 'POST',
            body: sanitizer(payload)
          }
        },
        invalidatesTags: [
          'organizations',
          'devices',
          'events',
          'provisioningSetup',
          'rules',
          'invites',
          'triggers',
          'userProfile',
          'users'
        ]
      }),
      refuseInvite: builder.mutation({
        query: (inviteId) => {
          logger.log(`RTK API - Refuse user`)
          return {
            url: `/invites/${inviteId}`,
            method: 'DELETE'
          }
        },
        invalidatesTags: ['invites']
      })
    }
  }
})

export const {
  useGetInviteQuery,
  useAcceptInviteMutation,
  useRefuseInviteMutation
} = acceptInviteApi

export { acceptInviteApi }
