import {
  Box,
  Button,
  CircularProgress,
  Grid2,
  Stack,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Download as DownloadIcon } from '@mui/icons-material'
import { t } from 'i18next'
import { shallowEqual, useSelector } from 'react-redux'
import {
  useFetchDeviceNetworkQuery,
  useFetchDeviceStatisticsQuery
} from '../../../store'
import LineChart from '../../Charts/LineChart.jsx'
import LinkStatusChart from '../../Charts/LinkStatusChart.jsx'
import NetworkChart from '../../Charts/NetworkChart.jsx'
import { NETWORK_POLLING_RATE } from '../../../configs/environment.js'
import {
  deviceLinkStatusesDownload,
  deviceStatisticsDownload
} from '../../../helpers/download'
import Can from '../../Layout/Can/Can'
import Show from '../../Layout/Can/Show'
import PropTypes from 'prop-types'

const NetworkTab = ({ device, sourceData, refresh, setRefresh, timePicker }) => {
  const { credentials, currentOrganization } = useSelector((state) => state.general, shallowEqual)

  const isSuperAdmin = credentials?.permissions?.isSuperAdmin || false

  const quantities = [
    {
      chart: 'network',
      hidden: false,
      labels: null,
      measureId: null,
      measureName: 'sf',
      name: 'network.sf',
      summary: false,
      unitOfMeasure: ''
    },
    {
      chart: 'network',
      hidden: false,
      labels: null,
      measureId: null,
      measureName: 'seqno',
      name: 'network.seqno',
      summary: false,
      unitOfMeasure: ''
    }
  ]

  const { data: statisticsChart = {}, refetch: refetchDeviceStatistics } =
    useFetchDeviceStatisticsQuery(
      {
        id: device.id,
        from: timePicker[0],
        to: timePicker[1]
      },
      { pollingInterval: NETWORK_POLLING_RATE, skipPollingIfUnfocused: true }
    )

  const { data: networkChart = [], refetch: refetchDeviceNetwork } =
    useFetchDeviceNetworkQuery(
      {
        id: device.id,
        from: timePicker[0],
        to: timePicker[1]
      },
      { pollingInterval: NETWORK_POLLING_RATE, skipPollingIfUnfocused: true }
    )

  useEffect(() => {
    if (refresh) {
      refetchDeviceStatistics()
      refetchDeviceNetwork()
      setRefresh(false)
    }
  }, [refresh])

  const [downloadInProgress, setDownloadInProgress] = useState(false)

  const handleDownloadEnd = () => {
    setDownloadInProgress(false)
  }

  const handleDownloadLinkStatus = () => {
    setDownloadInProgress(true)
    deviceLinkStatusesDownload({
      id: device.id,
      name: device.name,
      serial: device.serial,
      from: timePicker[0],
      to: timePicker[1],
      credentials,
      currentOrganization,
      onSuccess: handleDownloadEnd,
      onError: handleDownloadEnd
    })
  }

  const handleDownloadStatistics = () => {
    setDownloadInProgress(true)
    deviceStatisticsDownload({
      id: device.id,
      name: device.name,
      serial: device.serial,
      from: timePicker[0],
      to: timePicker[1],
      credentials,
      currentOrganization,
      onSuccess: handleDownloadEnd,
      onError: handleDownloadEnd
    })
  }

  return (
    <>
      <Typography
        mt={5}
        mb={2}
        variant="h4"
      >
        {t('devices.deviceInfo')}
      </Typography>

      {device?.source?.type === 'lorawan' && (
        <Grid2
          container
          spacing={2}
        >
          <Grid2>
            <Typography
              mt={3}
              sx={{ fontSize: '0.8rem', fontWeight: 500 }}
            >
              {t('devices.networkServer')}
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 200 }}>
              {sourceData?.networkServerId}
            </Typography>
          </Grid2>
          <Grid2>
            <Typography
              mt={3}
              sx={{ fontSize: '0.8rem', fontWeight: 500 }}
            >
              {t('devices.deviceEUI')}
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 200 }}>
              {sourceData?.device?.devEui}
            </Typography>
          </Grid2>
          <Grid2>
            <Typography
              mt={3}
              sx={{ fontSize: '0.8rem', fontWeight: 500 }}
            >
              {t('devices.joinEUI')}
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 200 }}>
              {sourceData?.device?.joinEui}
            </Typography>
          </Grid2>
          <Grid2>
            <Typography
              mt={3}
              sx={{ fontSize: '0.8rem', fontWeight: 500 }}
            >
              {t('devices.appKey')}
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 200 }}>
              {sourceData?.device?.appKey}
            </Typography>
          </Grid2>
          <Grid2>
            <Typography
              mt={3}
              sx={{ fontSize: '0.8rem', fontWeight: 500 }}
            >
              {t('devices.loraType')}
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 200 }}>
              {sourceData?.device?.type === 128 ? 'OTAA' : 'ABP'}
            </Typography>
          </Grid2>
        </Grid2>
      )}

      {device?.source?.type === 'wmbus' && (
        <Grid2
          container
          spacing={2}
        >
          <Grid2>
            <Typography
              mt={3}
              sx={{ fontSize: '0.8rem', fontWeight: 500 }}
            >
              {t('devices.wmbusId')}
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 200 }}>
              {device?.source?.key?.wmbusid}
            </Typography>
          </Grid2>
        </Grid2>
      )}

      {device?.source?.type === 'nbiot' && (
        <Grid2
          container
          spacing={2}
        >
          <Grid2>
            <Typography
              mt={3}
              sx={{ fontSize: '0.8rem', fontWeight: 500 }}
            >
              {t('devices.imsi')}
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 200 }}>
              {device?.source?.key?.imsi}
            </Typography>
          </Grid2>
        </Grid2>
      )}

      {device?.source?.type === 'lorawan' &&
        networkChart &&
        statisticsChart && (
          <>
            <Stack>
              <Box
                id="linkstatus"
                mt={4}
              >
                <Typography
                  variant="h5"
                  pb={3}
                  align="center"
                >
                  {t('devices.listeningGateways')}
                </Typography>
                <LinkStatusChart data={networkChart} />
              </Box>

              {quantities.map((q) => (
                <Box
                  key={q.measureName}
                  sx={{ height: '400px' }}
                  align="center"
                >
                  {statisticsChart?.[q.measureName]?.length > 0 && (
                    <>
                      <Typography
                        variant="h5"
                        align="center"
                        pb={3}
                      >
                        {t(q.name)}
                      </Typography>

                      {q.chart === 'line' && (
                        <LineChart
                          data={statisticsChart[q.measureName]}
                          unitOfMeasure={q.unitOfMeasure}
                          measureName={q.measureName}
                        />
                      )}

                      {q.chart === 'network' && (
                        <NetworkChart
                          data={statisticsChart[q.measureName]}
                          unitOfMeasure={q.unitOfMeasure}
                          measureName={q.measureName}
                        />
                      )}
                    </>
                  )}
                </Box>
              ))}
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={2}
            >
              <Can action={'devices_r'}>
                <Show when={() => downloadInProgress}>
                  <CircularProgress size={30} />
                </Show>
                <Show when={() => !downloadInProgress}>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon fontSize="small" />}
                    onClick={handleDownloadStatistics}
                  >
                    {t('common.download')} {t('common.statistics')}
                  </Button>
                </Show>
              </Can>

              {isSuperAdmin && (
                <>
                  <Show when={() => downloadInProgress}>
                    <CircularProgress size={30} />
                  </Show>
                  <Show when={() => !downloadInProgress}>
                    <Button
                      variant="contained"
                      startIcon={<DownloadIcon fontSize="small" />}
                      onClick={handleDownloadLinkStatus}
                    >
                      {t('common.download')} LinkStatus
                    </Button>
                  </Show>
                </>
              )}
            </Stack>
          </>
        )}
    </>
  )
}

NetworkTab.propTypes = {
  device: PropTypes.object,
  sourceData: PropTypes.object,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
}

export default NetworkTab
