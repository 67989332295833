import {
  Autocomplete,
  Box,
  CircularProgress,
  darken,
  Icon,
  InputAdornment,
  lighten,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  TextField,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import {
  SensorsRounded as SensorsRoundedIcon,
  SearchRounded as SearchRoundedIcon,
  SentimentSatisfiedRounded as SentimentSatisfiedRoundedIcon,
  WarningRounded as WarningRoundedIcon,
  HomeRounded as HomeRoundedIcon,
  AccountCircleRounded as AccountCircleRoundedIcon,
  EmailRounded as EmailRoundedIcon,
  SendRounded as SendRoundedIcon,
  CorporateFareRounded as CorporateFareRoundedIcon,
  MenuRounded as MenuRundedIcon,
  TuneRounded as TuneRoundedIcon
} from '@mui/icons-material'

import { t } from 'i18next'
import { useDispatch } from 'react-redux'
import { useSearchMutation } from '../../../store/index.js'
import { setMapCenter, setMapZoom } from '../../../store/slices/general.js'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'

const Search = ({ entityType }) => {
  const [searchParams] = useSearchParams()
  const [fullText, setFullText] = useState(searchParams.get('search') || '')

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const [search, { data, isLoading }] = useSearchMutation()

  const [items, setItems] = useState(data || [])

  const [open, setOpen] = useState(false)

  useEffect(() => {
    setItems(data || [])
  }, [data])

  const handleInputChange = (value) => {
    setFullText(value)
    searchParams.delete('search')
    if (value.length === 0 || value.length < 3) {
      setItems([])
      return
    }
    useResearch(value)
  }

  const debounce = (func, timeout) => {
    let timer
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func.apply(this, args)
      }, timeout)
    }
  }

  const useResearch = useCallback(
    debounce((value) => {
      search({ query: value, entityType })
    }, 400),
    []
  )

  const handleChange = (_, value) => {
    const entityType = value?.entityType || ''
    const payload = value?.payload || null
    const entityId = value?.entityId || null

    if (!payload) return
    if (payload.position?.latitude && payload.position?.longitude) {
      dispatch(
        setMapCenter({
          lat: payload.position.latitude,
          lng: payload.position.longitude
        })
      )
      dispatch(setMapZoom(20))
    }

    switch (entityType) {
      case 'device':
        return navigate(`/detail/${entityId}`)
      case 'user':
        return navigate(`/management/users/${entityId}`)
      case 'invite':
        return navigate(`/management/invites?search=${fullText}`)
      case 'organization':
        return navigate(`/management/organizations/${entityId}`)
      case 'gateway':
        return navigate(`/management/gateways?search=${fullText}`)
      case 'rule':
        return navigate(`/management/automations/rules/${entityId}`)
      case 'action':
        return navigate(
          `/management/automations/actions?search=${fullText}`
        )
      case 'trigger':
        return navigate(`/management/automations/triggers/${entityId}`)
      default:
        return
    }
  }

  const handleReset = () => {
    setFullText('')
    setItems([])
    navigate(location.pathname)
  }

  const handleShowAllClick = (entityType) => {
    switch (entityType) {
      case 'device':
        return navigate(`/management/devices?search=${fullText}`)
      case 'user':
        return navigate(`/management/users?search=${fullText}`)
      case 'invite':
        return navigate(`/management/invites?search=${fullText}`)
      case 'organization':
        return navigate(`/management/organizations?search=${fullText}`)
      case 'gateway':
        return navigate(`/management/gateways?search=${fullText}`)
      case 'rule':
        return navigate(`/management/automations?search=${fullText}`)
      case 'action':
        return navigate(
          `/management/automations/actions?search=${fullText}`
        )
      case 'trigger':
        return navigate(
          `/management/automations/triggers?search=${fullText}`
        )
      default:
        return
    }
  }

  const getName = (item) => {
    switch (item.entityType) {
      case 'device':
        return item.payload.name
      case 'user':
        return `${item.payload.firstName} ${item.payload.lastName}`
      case 'invite':
        return item.payload.email
      case 'gateway':
        return item.payload.serial
      default:
        return item.payload.name
    }
  }

  const getContent = (item) => {
    switch (item.entityType) {
      case 'device':
        return item.payload.type !== 'Tracker' ? item.payload.address : ''
      case 'user':
        return item.payload.email
      case 'invite':
        return item.payload.role.name
      case 'organization':
        return item.payload.key
      case 'gateway':
        return (
          <Stack direction={'column'}>
            <Box>Lora: {item.payload.gatewayId}</Box>
            <Box>WmBUS {item.payload.wmbusId}</Box>
          </Stack>
        )
      default:
        return item.payload.name
    }
  }

  const getIcon = (item) => {
    switch (item.entityType) {
      case 'device':
        return <SensorsRoundedIcon />
      case 'user':
        return <AccountCircleRoundedIcon />
      case 'invite':
        return <EmailRoundedIcon />
      case 'organization':
        return <HomeRoundedIcon />
      case 'action':
        return <SendRoundedIcon />
      case 'rule':
        return <CorporateFareRoundedIcon />
      case 'trigger':
        return <TuneRoundedIcon />
      case 'gateway':
        return item.payload.isTested ? (
          <SentimentSatisfiedRoundedIcon />
        ) : (
          <WarningRoundedIcon />
        )
      default:
        return <></>
    }
  }

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    zIndex: 999,
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8)
  }))

  return (
    <>
      <Autocomplete
        id="shared-search-autocomplete"
        freeSolo
        inputValue={fullText || ''}
        sx={{
          flexGrow: 1,
          '& .MuiAutocomplete-inputRoot': {
            paddingRight: '0 !important'
          }
        }}
        options={items}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        filterOptions={(x) => x}
        onChange={handleChange}
        onInputChange={(_, value, reason) => {
          if (reason === 'input') handleInputChange(value)
          if (reason === 'clear') handleReset()
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loading={isLoading}
        groupBy={(option) => option.entityType}
        getOptionLabel={(option) => getName(option)}
        renderGroup={(params) => (
          <Box key={`${params.key}-${params.group}`}>
            <GroupHeader>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                {params.group}
                <MenuRundedIcon
                  cursor={'pointer'}
                  size={18}
                  onClick={() => handleShowAllClick(params.group)}
                />
              </Stack>
            </GroupHeader>
            {params.children}
          </Box>
        )}
        renderOption={(props, option) => (
          <ListItem
            {...props}
            divider
            key={option?.id}
          >
            <ListItemIcon>{getIcon(option)}</ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: '0.8rem',
                fontWeight: 500
              }}
              primary={getName(option)}
              secondary={getContent(option)}
            />
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('common.search')}
            variant="standard"
            value={fullText}
            slotProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">

                  {isLoading && !fullText && (
                    <Icon
                      color="inherit"
                      edge="end"
                    >
                      <CircularProgress
                        edge="end"
                        size={20}
                      />
                    </Icon>

                  )}
                  {!isLoading && !fullText && (
                    <Icon
                      color="inherit"
                      edge="end"
                    >
                      <SearchRoundedIcon
                        edge="end"
                        size={20}
                      />
                    </Icon>

                  )}
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </>
  )
}

Search.propTypes = {
  entityType: PropTypes.string
}

export default Search
