import {
  Box,
  CardContent,
  Grid2,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { t } from 'i18next'
import Show from '../../Layout/Can/Show'
import ZCard from '../../Shared/Components/ZCard'

const TriggerCardContent = (trigger) => {
  return (
  <Box sx={{ flexGrow: 1 }}>
    <Grid2
      container
      spacing={2}
    >
      <Grid2 size={3}>
        <Typography
          component="p"
          variant="body2"
          sx={{ fontWeight: 'bold' }}
        >
          {t('common.typology')}
        </Typography>
        <Typography
          component="p"
          variant="body2"
        >
          {t(`triggers.types.${trigger.type}`)}
        </Typography>
      </Grid2>
      <Show when={() => trigger.quantityId}>
        <Grid2 size="grow">
          <Typography
            component="p"
            variant="body2"
            sx={{ fontWeight: 'bold' }}
          >
            {t('triggers.size')}
          </Typography>
          <Typography
            component="p"
            variant="body2"
          >
            {t(trigger.quantityId)}
          </Typography>
        </Grid2>
      </Show>
      <Show
        when={() => trigger.trigger &&
          trigger?.type === 'threshold' &&
          !!trigger?.trigger?.operator
        }
      >
        <Grid2 size="grow">
            <Typography
              component="p"
              variant="body2"
              sx={{ fontWeight: 'bold' }}
            >
              {t('triggers.operator')}
            </Typography>
            <Typography
              component="p"
              variant="body2"
            >
              {`${t(`triggers.operators.${trigger?.trigger?.operator}`)} [${trigger?.trigger?.values?.toString()}]`}
            </Typography>
        </Grid2>
      </Show>

      <Grid2 size="grow">
        <Typography
          component="p"
          variant="body2"
          sx={{ fontWeight: 'bold' }}
        >
          {t('automations.validity')}
        </Typography>
        <Typography
          component="p"
          variant="body2"
        >
          {trigger.schedulerType === 'default'
            ? t(`triggers.default24`)
            : 'DA IMPLEMENTARE'}
        </Typography>
      </Grid2>
    </Grid2>
  </Box>)
}

const TriggerCard = ({ index, trigger, onDelete, onEdit, isRuleCard }) => {
  return <ZCard
    name={'trigger'}
    key={`trigger-card-${index}`}
    id={`trigger-card-${index}`}
    title={trigger.name}
    subheader={trigger.models.length && trigger?.models[0].id !== '*' ? `${trigger.models[0].brand.name} / ${trigger.models[0].name}` : ''}
    createdAt={trigger.createdAt}
    updatedAt={trigger.updatedAt}
    footerFields={[{ label: t('common.organization'), value: trigger.organizationKey }]}
    onDelete={onDelete}
    onEdit={() => onEdit(trigger.id)}
    isDeleteVisible={!isRuleCard && !trigger.rules.length}
    isEditVisible={!isRuleCard}
    content={
    <CardContent
      sx={{
        background: 'rgba(30, 250, 150, 0.2)',
        marginX: '20px',
        marginBottom: '15px'
      }}
    >
     {TriggerCardContent(trigger)} 
    </CardContent>
    }/>
}

TriggerCard.propTypes = {
  isRuleCard: PropTypes.bool,
  trigger: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func
}

export default TriggerCard
