import { useEffect } from 'react'
import { setErrorAlertMessage, setOpenDialog } from '../../store/slices/general'
import { useDispatch } from 'react-redux'

export const useOpenResultAlert = (error, { isError, isSuccess }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (isError) {
      if (error?.data) {
        dispatch(setOpenDialog('errorAlert'))

        if (error?.data?.error) {
          dispatch(
            setErrorAlertMessage(`${error.data.statusCode} ${error.data.error}`)
          )
        } else if (error?.error) dispatch(setErrorAlertMessage(error.error))
      }
    }
    if (isSuccess) {
      dispatch(setOpenDialog('successAlert'))
    }
  }, [isError, isSuccess])
}
