import { Typography } from '@mui/material'
import { ResponsiveLine } from '@nivo/line'
import PropTypes from 'prop-types'
import React from 'react'

const buildChartData = (id, data) => {
  return [
    {
      id,
      data
    }
  ]
}

let margin = { top: 10, right: 10, bottom: 150, left: 60 }

let yScale = {
  type: 'linear',
  min: 'auto',
  max: 'auto',
  stacked: true,
  reverse: false,
  clamp: true
}

let xScale = { format: '%Y-%m-%dT%H:%M:%S.%LZ', type: 'time' }

let buildAxisLeft = (unitOfMeasure) => {
  return {
    legend: unitOfMeasure,
    legendOffset: -40,
    legendPosition: 'middle'
  }
}

let axisBottom = {
  orient: 'bottom',
  tickSize: 5,
  tickPadding: 8,
  tickRotation: -90,
  format: '%d/%m %H:%M'
}

function tooltipGenerator(unitOfMeasure) {
  return function tooltip({ point }) {
    return (
      <div
        style={{
          background: 'white',
          padding: '9px 12px',
          border: '1px solid #ccc'
        }}
      >
        <Typography variant="body2">
          <small>
            {point.data.xFormatted}
            <br />
            <strong>{point.data.yFormatted}</strong> {unitOfMeasure}
          </small>
        </Typography>
      </div>
    )
  }
}

const LineChart = ({ data, measureName, unitOfMeasure }) => {
  return (
    <ResponsiveLine
      data={buildChartData(measureName, data)}
      margin={margin}
      tooltip={tooltipGenerator()}
      axisBottom={axisBottom}
      xScale={xScale}
      xFormat="time:%d/%m %H:%M"
      yScale={yScale}
      axisLeft={buildAxisLeft(unitOfMeasure)}
      useMesh={true}
      enableArea={true}
      enablePoints={true}
      pointLabel="y"
      pointSize={7}
      pointBorderWidth={1}
      pointBorderColor="#ff00dd"
      colors={{ scheme: 'paired' }}
      areaOpacity={0.5}
      crosshairType="x"
    />
  )
}

LineChart.propTypes = {
  data: PropTypes.array,
  dateRange: PropTypes.object,
  unitOfMeasure: PropTypes.string,
  measureName: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool
}

export default LineChart
