import {
  Chip,
  Container,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import EventsTable from '../Tables/Table.jsx'
import EventsFilters from '../Filters/Filters.jsx'
import dayjs from 'dayjs'
import BarChart from '../../Charts/BarChart.jsx'
import {
  useFetchEventsSearchSummaryQuery,
  useSearchEventsMutation
} from '../../../store/apis/events-api.js'
import AlarmedFilter from '../Filters/AlarmedFilter.jsx'
import NotFoundMessage from '../../Shared/Components/NotFoundMessage.jsx'
import Show from '../../Layout/Can/Show.jsx'

const DEFAULT_PAGE_SIZE = 25

const Events = () => {
  const [filters, setFilters] = useState({
    fromTo: {
      from: dayjs().add(-8, 'day').toISOString(),
      to: dayjs().toISOString()
    }
  })

  const [page, setPage] = useState(1)

  const [eventsCount, setEventsCount] = useState(0)

  const handlePageChange = (value) => setPage(value)

  const [searchEvents, { data: events, isLoading: isEventsLoading }] = useSearchEventsMutation()

  useEffect(() => {
    searchEvents({
      payload: { ...filters, allowMissingPosition: true },
      page,
      pageSize: DEFAULT_PAGE_SIZE
    })
  }, [filters, page])

  useEffect(() => {
    setPage(1)
  }, [filters])

  const { data: allEvents } = useFetchEventsSearchSummaryQuery(filters)

  const handleFiltersChange = (filters) => setFilters(filters)

  const handleAlarmedFilterChange = (filters) => setFilters(filters)

  useEffect(() => {
    setEventsCount(events?.totalCount)
  }, [events])

  return (
    <Container sx={{ py: 3 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h2">{t('common.events')}</Typography>
          <Chip
            label={eventsCount}
            color="primary"
          />
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={4}
      >
        <AlarmedFilter
          filters={filters}
          onChange={handleAlarmedFilterChange}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={4}
      >
        <EventsFilters
          filters={filters}
          onChange={handleFiltersChange}
        />
      </Stack>
      <Show when={() => isEventsLoading && !events?.results.length}>
        <Stack
          spacing={2}
          sx={{ marginBottom: '20px' }}
        >
          {[...Array(DEFAULT_PAGE_SIZE).keys()].map((index) => (
            <Skeleton
              key={`skeleton-${index}`}
              variant="rounded"
              sx={{ height: '100px' }}
            ></Skeleton>
          ))}
        </Stack>
      </Show>
      <Show when={() => events?.results.length && allEvents?.length}>
          <BarChart data={allEvents} />
          <EventsTable
            events={events}
            onPageChange={handlePageChange}
            paging={{ page, pageSize: DEFAULT_PAGE_SIZE }}
          />
      </Show>
      <NotFoundMessage when={() => !events?.results.length && !allEvents?.length}  />
    </Container>
  )
}

export default Events
