import {
  Box,
  ButtonGroup,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Grid2,
  IconButton,
  Typography
} from '@mui/material'
import React from 'react'
import Show from '../../Layout/Can/Show'
import { t } from 'i18next'
import {
  LockRounded as LockRoundedIcon,
  LockOpenRounded as LockOpenRoundedIcon,
  EditRounded as EditRoundedIcon,
  DeleteRounded as DeleteRoundedIcon
} from '@mui/icons-material'
import moment from 'moment'

const ZCard = ({
  sx = [],
  id,
  title,
  titleImage,
  titleInfo,
  subheader,
  content,
  createdAt,
  updatedAt,
  onDelete,
  onEdit,
  onLock,
  name = '',
  footerFields = [],
  isEditVisible = true,
  isDeleteVisible = true,
  isLockVisible = false,
  isEntityLocked = false,
  variant = 'elevation'
}) => {
  return (
    <Card
      name={name}
      id={id}
      sx={[{ marginBottom: 5 }, ...(Array.isArray(sx) ? sx : [sx])]}
      variant={variant}
    >
      <CardHeader
        name={`${name}-card-header`}
        subheader={
          <Typography
            component="p"
            variant="body2"
          >
            {subheader}
          </Typography>}
        title={
          <Grid2
            container
            justifyContent="space-between"
          >
            <Grid2
              container
              direction="row"
              columnGap={2}
            >
              <Show when={() => titleImage}>{titleImage}</Show>
              <Grid2>
                <Typography component="p">
                  <strong>{title}</strong>
                </Typography>
              </Grid2>
            </Grid2>
            <Grid2>{titleInfo}</Grid2>
          </Grid2>
        }
      />
      {content}
      <Divider variant="middle" />
      <CardActions>
        <Box sx={{ flexGrow: 1, paddingX: 2 }}>
          <Grid2
            container
            spacing={2}
          >
            {footerFields
              .filter((f) => f.value)
              .map(({ label, value }, index) => (
                <Grid2
                  key={`addition-info-${index}`}
                  size={3}
                >
                  <Typography
                    variant="body2"
                    sx={{ color: '#7B8DA0' }}
                  >
                    {label}: <strong>{value}</strong>
                  </Typography>
                </Grid2>
              ))}
            <Grid2 size={3}>
              <Show when={() => createdAt}>
                <Typography variant="body2">
                  {t('common.createdAt')}:{' '}
                  <strong>
                    {moment(createdAt).format('DD.MM.YYYY HH:mm')}
                  </strong>
                </Typography>
              </Show>
            </Grid2>
            <Grid2 size={3}>
              <Show when={() => updatedAt}>
                <Typography variant="body2">
                  {t('common.updatedAt')}:{' '}
                  <strong>
                    {moment(updatedAt).format('DD.MM.YYYY HH:mm:ss')}
                  </strong>
                </Typography>
              </Show>
            </Grid2>
            <Grid2
              size="grow"
              display="flex"
              justifyContent="flex-end"
            >
              <ButtonGroup size="small">
                <Show when={() => isLockVisible}>
                  <IconButton
                    id={`${id}-lock`}
                    variant="text"
                    key="lock"
                    aria-label="lock"
                    color="primary"
                    onClick={onLock}
                  >
                    {isEntityLocked ? (
                      <LockRoundedIcon fontSize="small" />
                    ) : (
                      <LockOpenRoundedIcon fontSize="small" />
                    )}
                  </IconButton>
                </Show>
                <Show when={() => isDeleteVisible}>
                  <IconButton
                    id={`${id}-delete`}
                    variant="text"
                    key="trash"
                    aria-label="delete"
                    onClick={onDelete}
                    color="error"
                  >
                    <DeleteRoundedIcon fontSize="small" />
                  </IconButton>
                </Show>
                <Show when={() => isEditVisible}>
                  <IconButton
                    id={`${id}-edit`}
                    variant="text"
                    key="edit"
                    aria-label="edit"
                    color="primary"
                    onClick={onEdit}
                  >
                    <EditRoundedIcon fontSize="small" />
                  </IconButton>
                </Show>
              </ButtonGroup>
            </Grid2>
          </Grid2>
        </Box>
      </CardActions>
    </Card>
  )
}

export default ZCard
