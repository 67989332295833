import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography
} from '@mui/material'
import React, { useRef } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setFiltersDevices } from '../../../../store/slices/filters.js'

const Accessibility = () => {
  const dispatch = useDispatch()

  const {
    devices: { showPublic }
  } = useSelector((state) => state.filters)

  const isPrivate = useRef()
  const isPublic = useRef()

  /**
   * updateState
   * @param {*} state the state to update
   */
  const updateState = (state) => {
    dispatch(
      setFiltersDevices({
        showPublic: state
      })
    )
  }

  /**
   * handleChange
   */
  const handleChange = () => {
    // if isPrivate and isPublic are both true or both false set the state of the filter to null
    if (
      (isPrivate.current.checked && isPublic.current.checked) ||
      (!isPrivate.current.checked && !isPublic.current.checked)
    ) {
      updateState(null)
      // if isPrivate is set to true then update the state to false
    } else if (isPrivate.current.checked) {
      updateState(false)
      // if isPublic is set to true then update the state to true
    } else if (isPublic.current.checked) {
      updateState(true)
    }
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.devices.other.accessibility.label')}
        </Typography>
        <FormGroup
          aria-label="position"
          row
        >
          <FormControlLabel
            value="public"
            control={
              <Checkbox
                inputRef={isPublic}
                onChange={handleChange}
                checked={showPublic !== null && showPublic}
                disabled={showPublic !== null && !showPublic}
              />
            }
            label={t('filters.devices.other.accessibility.valueTrue')}
            sx={{ width: '100px' }}
          />
          <FormControlLabel
            value="private"
            control={
              <Checkbox
                inputRef={isPrivate}
                onChange={handleChange}
                checked={showPublic !== null && !showPublic}
                disabled={showPublic !== null && showPublic}
              />
            }
            label={t('filters.devices.other.accessibility.valueFalse')}
            sx={{ width: '100px' }}
          />
        </FormGroup>
      </Stack>
    </>
  )
}

export default Accessibility
