import { createSlice } from '@reduxjs/toolkit'
import { logger } from '../../helpers/logger/logger.js'
import { deviceAdd } from '../states/'

const initialState = deviceAdd

const deviceAddSlice = createSlice({
  name: 'deviceAdd',
  initialState,
  reducers: {
    setModel: (state, action) => {
      logger.log('deviceAdd: set model')
      state.model = action.payload
    },
    setInfo: (state, action) => {
      logger.log('deviceAdd: set info')
      state.info = action.payload
    },
    setPosition: (state, action) => {
      logger.log('deviceAdd: set position')
      state.position = action.payload
    },
    setNetwork: (state, action) => {
      logger.log('deviceAdd: set network')
      state.network = action.payload
    },
    setProvisioningSetup: (state, action) => {
      logger.log('deviceAdd: set provisioning setup')
      state.provisioningSetup = action.payload
    },
    setPubblication: (state, action) => {
      logger.log('deviceAdd: set pubblication')
      state.pubblication = action.payload
    },
    resetDevicePayload: () => {
      logger.log('deviceAdd: reset to initial state')
      return initialState
    },
    setBrand: (state, action) => {
      logger.log('deviceAdd: set brand')

      state.model.brand = action.payload
    },
    changeBrand: (state) => {
      logger.log('deviceAdd: change brand and reset status')
      return {
        ...state,
        model: {
          model: null,
          provisioningSetup: null
        },
        network: {
          networkServer: '',
          networkType: 'OTAA',
          devEui: '',
          joinEui: '',
          appKey: '',
          appSKey: '',
          nwkSKey: '',
          devaddr: '',
          imsi: '',
          wmbusId: ''
        },
        provisioningSetup: {}
      }
    }
  }
})

export const {
  resetDevicePayload,
  setModel,
  setInfo,
  setPosition,
  setNetwork,
  setProvisioningSetup,
  setPubblication,
  setBrand,
  changeBrand
} = deviceAddSlice.actions

export default deviceAddSlice.reducer
