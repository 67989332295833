import { FormControl, FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'

const IsUsedInRuleFilter = ({ filters, onChange = () => {} }) => {
  const handleChange = (_, value) => {
    onChange(value)
  }

  return (
    <>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Switch
              onChange={handleChange}
              checked={filters.isUsedInRule}
            />
          }
          label={t('automations.usedInARule')}
          labelPlacement="end"
        />
      </FormControl>
    </>
  )
}

IsUsedInRuleFilter.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func
}

export default IsUsedInRuleFilter
