import { createSlice } from '@reduxjs/toolkit'
import { logger } from '../../helpers/logger/logger.js'
import { updateUser } from '../states'

const initialState = updateUser

const updateUserSlice = createSlice({
  name: 'updateUser',
  initialState,
  reducers: {
    setOpenUpdateUserDialog: (state, action) => {
      logger.log('updateUser: set updateUserDialogIsOpen')
      state.updateUserDialogIsOpen = true
      state.selectedUser = action.payload
    },
    setCloseUpdateUserDialog: (state) => {
      state.updateUserDialogIsOpen = false
      state.selectedUser = null
    },
    resetUpdateUser: (state) => {
      state.updateUserDialogIsOpen = false
      state.selectedUser = null
    }
  }
})

export const {
  setOpenUpdateUserDialog,
  setCloseUpdateUserDialog,
  resetUpdateUser
} = updateUserSlice.actions

export default updateUserSlice.reducer
