import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField
} from '@mui/material'
import Joi from 'joi'
import React, { useState } from 'react'
import { t } from 'i18next'
import useFieldValidation from '../../../../helpers/fieldValidation.js'
import CommunicationRuleForm from './CommunicationRuleForm.jsx'
import ThresholdRuleForm from './ThresholdRuleForm.jsx'
import DeltaRuleForm from './DeltaRuleForm.jsx'
import TriggerRuleForm from './TriggerRuleForm.jsx'
import ScheduledTriggerRuleForm from './ScheduledTriggerRuleForm.jsx'
import { useValidationEffect } from '../../../../helpers/hooks.js'
import { Save as SaveIcon } from '@mui/icons-material'
import Can from '../../../Layout/Can/Can.jsx'
import { useSelector } from 'react-redux'
import { useFetchSelfOrganizationsQuery } from '../../../../store/index.js'
import TriggerRuleType from './TriggerRuleType.jsx'
import MeasureTriggerRuleForm from './MeasureTriggerRuleForm.jsx'
import { schedulerTypes } from '../../utils/utilis.js'
import SchedulerHoursSelectionForm from './SchedulerHoursSelectionForm.jsx'
import DeviceField from './DeviceField.jsx'
import Show from '../../../Layout/Can/Show.jsx'
import ZRadioGroup from './ZRadioGroup.jsx'

const defaultScheduler = {
  monday: {
    selected: false,
    timeSlots: []
  },
  tuesday: {
    selected: false,
    timeSlots: []
  },
  wednesday: {
    selected: false,
    timeSlots: []
  },
  thursday: {
    selected: false,
    timeSlots: []
  },
  friday: {
    selected: false,
    timeSlots: []
  },
  saturday: {
    selected: false,
    timeSlots: []
  },
  sunday: {
    selected: false,
    timeSlots: []
  }
}

const initialState = {
  name: '',
  isForSuperAdmin: false,
  applyToChildOrganizations: false,
  models: [],
  ruleType: 'threshold',
  schedulerType: schedulerTypes.DEFAULT,
  scheduler: JSON.parse(JSON.stringify(defaultScheduler)),
  sendReminder: false,
  thresholdsCounter: 1,
  triggerType: 'quantity',
  quantity: null,
  subQuantity: null,
  trigger: null,
  interval: null,
  tolerance: null,
  maxDeltaChange: null,
  device: null,
  missingComunicationQuantity: null
}

const validationSchema = {
  'measure-trigger': Joi.object({
    quantityId: Joi.string().required(),
    quantity: Joi.object().required(),
    models: Joi.array().min(1)
  }).options({ allowUnknown: true }),

  communication: Joi.object({
    models: Joi.array().min(1)
  }).options({ allowUnknown: true }),

  trigger: Joi.object({
      models: Joi.array().min(1)
  }).options({ allowUnknown: true }),

  'scheduled-trigger': Joi.object().options({ allowUnknown: true }),

  threshold: Joi.object({
    quantityId: Joi.string().required(),
    models: Joi.array().min(1),
    trigger: Joi.object().required()
  }).options({ allowUnknown: true }),

  'measure-communication': Joi.object({
    models: Joi.array().min(1),
    missingComunicationQuantity: Joi.object({ 
      timeDelta: Joi.number().positive().required()
    }).required()
  }).options({ allowUnknown: true }),

  delta: Joi.object({
    quantity: Joi.object().required(),
    models: Joi.array().min(1),
    trigger: Joi.object().required()
  }).options({ allowUnknown: true })
}

const schema = Joi.object({
  name: Joi.string().empty(null).required()
})

const saSchema = Joi.object({
  name: Joi.string().empty(null).required(),
  organizationKey: Joi.string().required()
})

const TriggerForm = ({ triggerData: data, isEdit, onSave }) => {
  const { credentials } = useSelector((state) => state.general)
  const isSuperAdmin = credentials?.permissions?.isSuperAdmin || false

  const [selectedOrganization, setSelectedOrganization] = useState(null)

  const [validation, setValidation] = useState({ isValid: false })
  const [triggerValidation, setTriggerValidation] = useState({ isValid: false })
  const [triggerData, setTriggerData] = useState(initialState)

  const {
    data: organizationsSuggestions = [],
    isLoading: isOrganizationsSuggestionsLoading,
    isSuccess: isOrganizationsSuggestionsLoaded
  } = useFetchSelfOrganizationsQuery({ skip: !isSuperAdmin })

  const {
    triggerType,
    name,
    isForSuperAdmin,
    applyToChildOrganizations,
    models,
    ruleType,
    schedulerType,
    scheduler,
    sendReminder,
    thresholdsCounter,
    trigger,
    device,
    quantity,
    quantityId,
    revision,
    missingComunicationQuantity
  } = triggerData

  const selectedModels = models?.length ? triggerData.models : data?.models

  useValidationEffect(() => {
    setTriggerData(data)
  }, [data])

  useValidationEffect(() => {
    const headerValidation = isSuperAdmin && !isEdit
      ? useFieldValidation({ organizationKey: selectedOrganization?.key, name }, saSchema)
      : useFieldValidation({ name }, schema)

      const triggerValidation = validationSchema[ruleType] ? useFieldValidation(triggerData, validationSchema[ruleType]) : { isValid: true }
      
      setValidation(headerValidation)
      setTriggerValidation(triggerValidation)
  }, [selectedOrganization, triggerData])

  const handleChangeOrganization = (_, organization) => setSelectedOrganization(organization)

  const handleRuleType = (event) => {
    const {
      target: { value }
    } = event
    setTriggerData({
      ...triggerData,
      missingComunicationQuantity: value === 'measure-communication' 
      ? { timeDelta: null } 
      : null,
      schedulerType:
        value === 'scheduled-trigger'
          ? schedulerTypes.SCHEDULED
          : schedulerTypes.DEFAULT,
      ruleType: value
    })
  }

  const handleTriggerType = ({ target: { value } }) => setTriggerData({
      ...initialState,
      scheduler: triggerData.scheduler,
      schedulerType: triggerData.schedulerType,
      name: triggerData.name,
      device: null,
      triggerType: value
    })

  const onSendReminder = (_event, value) => setTriggerData({ ...triggerData, sendReminder: value })

  const handleTriggerName = ({ target: { value } }) => setTriggerData({ ...triggerData, name: value })

  const handleSchedulerType = (type) => {
    resetScheduler()
    setTriggerData({ ...triggerData, schedulerType: type })
  }

  const handleSchedulerChange = (scheduler) => setTriggerData({ ...triggerData, scheduler })

  const resetScheduler = () => {
    const newScheduler = {}
    Object.keys(scheduler).map(
      (key) => (newScheduler[key] = { selected: false, timeSlots: [] })
    )
    setTriggerData({ ...triggerData, scheduler: newScheduler })
  }

  const handleOnClickSaveButton = () => {
    const payload = {
      deviceId: device ? device.id : null,
      name,
      isForSuperAdmin,
      applyToChildOrganizations,
      models: triggerData.models.length ? triggerData.models : selectedModels,
      type: ruleType,
      schedulerType:
        ruleType === 'scheduled-trigger'
          ? schedulerTypes.SCHEDULED
          : schedulerType,
      scheduler,
      sendReminder,
      thresholdsCounter,
      trigger,
      quantityId: quantity?.id || quantityId,
      revision,
      missingComunicationQuantity: missingComunicationQuantity
    }
    isSuperAdmin && !isEdit
      ? onSave({ ...payload, organizationKey: selectedOrganization.key })
      : onSave(payload)
  }

  const handleThresholdChanged = (thresholdData) => setTriggerData({ ...triggerData, ...thresholdData })

  const handleMeasureTriggerChanged = (measureTriggerData) => setTriggerData({ ...triggerData, ...measureTriggerData })

  const handleDeltaChanged = (deltaData) => setTriggerData({ ...triggerData, ...deltaData })

  const handleCommunicationChanged = (communicationData) => setTriggerData({ ...triggerData, ...communicationData })

  const handleTriggerChanged = (triggerRuleData) => setTriggerData({ ...triggerData, ...triggerRuleData })

  const handleDeviceSelected = (device) => setTriggerData({
      ...initialState,
      scheduler: triggerData.scheduler,
      schedulerType: triggerData.schedulerType,
      name: triggerData.name,
      triggerType: triggerData.triggerType,
      models: [{ id: parseInt(device.modelId) }],
      device: device
    })

  return (
    <>
      <Can action={'devices_u'} expression={() => !isEdit && isSuperAdmin && isOrganizationsSuggestionsLoaded} >
        <Autocomplete
          id="trigger-form-organizations-autocomplete"
          name="organizationKey"
          disableClearable
          loading={isOrganizationsSuggestionsLoading}
          options={organizationsSuggestions}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          value={selectedOrganization}
          onChange={handleChangeOrganization}
          fullWidth
          renderInput={(params) => (
            <TextField
              name="organizationKey"
              {...params}
              label={t('devices.chooseOrganization')}
              required={true}
              variant="standard"
              error={validation?.messages?.organization?.length > 0}
              helperText={validation?.messages?.organization?.join(', ')}
            />
          )}
        />
      </Can>
      <TextField
        id="trigger-name"
        label={t('triggers.triggerName')}
        name="triggerName"
        variant="standard"
        fullWidth
        value={triggerData.name}
        error={validation?.messages?.name?.length > 0}
        helperText={validation?.messages?.name?.join(', ')}
        onChange={handleTriggerName}
        disabled={isEdit}
      />

      <Divider
        orientation="horizontal"
        sx={{ my: 3 }}
      />

      <ZRadioGroup 
        id={'trigger-form-type-radio'} 
        title={t('triggers.triggerDefinition')} 
        disabled={isEdit}
        values={[
          { id: 'trigger-form-type-quantity-radio', value: 'quantity', label: t('triggers.byQuantity') },
          { id: 'trigger-form-type-device-radio', value: 'device', label: t('triggers.byDevice') }
        ]}
        defaultValue={'quantity'}
        selectedValue={triggerType}
        onChange={handleTriggerType}
      />

      <Divider
        orientation="horizontal"
        sx={{ my: 3 }}
      />

      <Show when={() => triggerType === 'device'}>
        <DeviceField device={device} isEdit={isEdit} onChange={handleDeviceSelected} />
      </Show>

      <Card sx={{ marginBottom: '40px' }} >
        <CardContent sx={{ marginX: '20px', marginBottom: '15px' }}>
          <TriggerRuleType onChange={handleRuleType} isEdit={isEdit} ruleType={ruleType} />

          <Show when={() => ruleType === 'measure-communication'}>
            <CommunicationRuleForm
              applyToChildOrganizations={applyToChildOrganizations}
              ruleType={ruleType}
              isEdit={isEdit}
              device={device}
              onCommunicationChanged={handleCommunicationChanged}
              selectedOrganizationKey={triggerData?.organizationKey || selectedOrganization?.key}
              comunicationRule={{ selectedModels, missingComunicationQuantity }}
              validation={triggerValidation}
              quantityId={quantity?.id || quantityId}
            />
          </Show>
          
          <Show when={() => ruleType === 'communication'}>
            <CommunicationRuleForm
              applyToChildOrganizations={applyToChildOrganizations}
              ruleType={ruleType}
              isEdit={isEdit}
              device={device}
              quantityId={quantity?.id || quantityId}
              onCommunicationChanged={handleCommunicationChanged}
              selectedOrganizationKey={triggerData?.organizationKey || selectedOrganization?.key}
              comunicationRule={{ selectedModels }}
              validation={triggerValidation}
            />
          </Show>

          <Show when={() => ruleType === 'threshold'}>
            <ThresholdRuleForm
              quantityId={quantity?.id || quantityId}
              device={device}
              thresholdsCounter={thresholdsCounter}
              selectedModels={selectedModels}
              applyToChildOrganizations={applyToChildOrganizations}
              ruleType={ruleType}
              trigger={trigger}
              isEdit={isEdit}
              onThresholdChanged={handleThresholdChanged}
              selectedOrganizationKey={triggerData?.organizationKey || selectedOrganization?.key}
              validation={triggerValidation}
            />
          </Show>

          <Show when={() => ruleType === 'delta'}>
            <DeltaRuleForm
              quantityId={quantity?.id || quantityId}
              selectedModels={selectedModels}
              trigger={trigger}
              isEdit={isEdit}
              onDeltaRuleChanged={handleDeltaChanged}
              device={device}
              selectedOrganizationKey={triggerData?.organizationKey || selectedOrganization?.key}
              validation={triggerValidation}
            />
          </Show>

          <Show when={() => !device && ruleType === 'trigger'}>
            <TriggerRuleForm
              applyToChildOrganizations={applyToChildOrganizations}
              ruleType={ruleType}
              selectedModels={selectedModels}
              isEdit={isEdit}
              onTriggerChanged={handleTriggerChanged}
              selectedOrganizationKey={triggerData?.organizationKey || selectedOrganization?.key}
            />
          </Show>

          <Show when={() => ruleType === 'scheduled-trigger'}>
            <ScheduledTriggerRuleForm />
          </Show>

          <Show when={() => ruleType === 'measure-trigger'}>
            <MeasureTriggerRuleForm 
              applyToChildOrganizations={applyToChildOrganizations}
              selectedModels={selectedModels}
              quantityId={quantity?.id || quantityId}
              ruleType={ruleType}
              isEdit={isEdit}
              onMeasureTriggerChanged={handleMeasureTriggerChanged}
              selectedOrganizationKey={triggerData?.organizationKey || selectedOrganization?.key}
              validation={triggerValidation}
            /> 
          </Show>

          <SchedulerHoursSelectionForm ruleType={ruleType} schedulerType={schedulerType} scheduler={scheduler} onChangeType={handleSchedulerType} onChangeScheduler={handleSchedulerChange} />

          <Divider orientation="horizontal" sx={{ my: 5 }} />

          <Show when={() => ruleType !== 'scheduled-trigger'}>
            <FormControlLabel
              label={t('triggers.sendReminder')}
              control={
                <Checkbox
                  id="trigger-form-send-reminder-checkbox"
                  onChange={onSendReminder}
                />
              }
            />
          </Show>
        </CardContent>
      </Card>

      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
        <Button
          variant="contained"
          id="trigger-form-save"
          endIcon={<SaveIcon />}
          disabled={(!validation.isValid || !triggerValidation.isValid) && !isEdit}
          onClick={handleOnClickSaveButton}
        >
          {t('common.save')}
        </Button>
      </Box>
    </>
  )
}

export default TriggerForm
