import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import Joi from 'joi'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import useFieldValidation from '../../../helpers/fieldValidation.js'
import { useFetchNetworkServersQuery } from '../../../store'
import { Deveui, Appeui, Appkey, Appskey, Nwkskey, Devaddr } from './Fields'
import {
  useFetchNetworkServerApplicationsQuery,
  useFetchNetworkServerUsersQuery
} from '../../../store/apis/devices-api.js'
import PropTypes from 'prop-types'

let schema = Joi.object()

const otaa = Joi.object({
  joinEui: Joi.string().hex().length(16).required(),
  appKey: Joi.string().hex().length(32).required()
}).unknown()

const abp = Joi.object({
  appSKey: Joi.string().hex().length(32).required(),
  nwkSKey: Joi.string().hex().length(32).required(),
  devaddr: Joi.string().hex().length(8).required()
}).unknown()

const lorawan = Joi.object({
  networkServer: Joi.string().required(),
  networkType: Joi.string().allow('OTAA', 'ABP').required(),
  devEui: Joi.string().hex().length(16).required().invalid('0000000000000000')
}).unknown()

const Network = ({ model, setIsValid, onChange }) => {
  const { currentOrganization } = useSelector((state) => state.general)
  const { credentials } = useSelector((state) => state.general)

  const [network, setNetwork] = useState({})
  const [validation, setValidation] = useState({ isValid: false })

  const isSuperAdmin = credentials?.permissions?.isSuperAdmin

  const { data: networkServers = [] } =
    useFetchNetworkServersQuery(currentOrganization)

  const { data: networkServerUsers } = useFetchNetworkServerUsersQuery(
    { networkServer: network.networkServer },
    { skip: !network?.networkServer }
  )

  const { data: networkServerApplications } =
    useFetchNetworkServerApplicationsQuery(
      { networkServer: network?.networkServer, nsUserId: network?.userId },
      { skip: !network?.networkServer || !network?.userId }
    )

  switch (model.sourceType) {
    case 'nbiot':
      schema = Joi.object({
        imsi: Joi.string()
          .pattern(/^[0-9]+$/)
          .length(16)
      }).unknown()
      break

    case 'wmbus':
      schema = Joi.object({
        wmbusId: Joi.string().hex().length(16)
      }).unknown()
      break

    default:
      schema =
        network.networkType === 'OTAA'
          ? lorawan.concat(otaa)
          : lorawan.concat(abp)
      break
  }

  const handleChange = (event) => {
    let nwkparams = {}

    if (event.target.name === 'networkType' && event.target.value === 'OTAA') {
      nwkparams = { appSKey: '', nwkSKey: '', devaddr: '' }
    } else if (
      event.target.name === 'networkType' &&
      event.target.value === 'ABP'
    ) {
      nwkparams = { joinEui: '', appKey: '' }
    }

    setNetwork({
      ...network,
      [event.target.name]: event.target.value,
      ...nwkparams
    })
  }

  const handleChangeNetworkServer = (event) => {
    const networkServer = event.target.value
    setNetwork({
      ...network,
      networkServer: networkServer
    })
  }

  const handleChangeApplication = (_, value) => {
    if (!value) return
    setNetwork({
      ...network,
      appId: value.id
    })
  }

  const handleChangeUser = (_, value) => {
    if (!value) return
    setNetwork({
      ...network,
      userId: value.userId
    })
  }

  useEffect(() => {
    const newValidation = useFieldValidation(network, schema)
    setValidation(newValidation)
    setIsValid(newValidation.isValid)
    onChange(network)
  }, [network])

  const userToString = (user) =>
    `${user.isCityEyeUser ? `[CityEye] ${user.user}` : user.user}`

  return (
    <Stack
      direction="column"
      spacing={5}
    >
      <Typography
        variant="h2"
        py={1}
      >
        {t('devices.addNetwork')}
      </Typography>

      {model.sourceType === 'lorawan' && (
        <>
          <Stack
            direction="row"
            spacing={2}
            my={5}
          >
            <FormControl fullWidth>
              <InputLabel htmlFor="Network-NetworkServer">
                {t('devices.chooseNetworkServer')}
              </InputLabel>
              {networkServers.length > 0 && (
                <Select
                  id="Network-NetworkServer"
                  name="networkServer"
                  variant="standard"
                  value={network?.networkServer}
                  onChange={handleChangeNetworkServer}
                  error={validation?.messages?.networkServer?.length > 0}
                >
                  {networkServers?.map((o, i) => (
                    <MenuItem
                      id="Network-NetworkServer-menu-item"
                      value={o}
                      key={i}
                    >
                      {o}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <FormHelperText>
                {validation?.messages?.networkServer?.join(', ')}
              </FormHelperText>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel htmlFor="Network-NetworkType">
                {t('devices.chooseLoraType')}
              </InputLabel>
              <Select
                id="Network-NetworkType"
                name="networkType"
                variant="standard"
                value={network?.networkType}
                onChange={handleChange}
                error={validation?.messages?.networkType?.length > 0}
              >
                <MenuItem id="Network-NetworkType-menu-item-OTAA" value="OTAA">OTAA</MenuItem>
                <MenuItem id="Network-NetworkType-menu-item-ABP" value="ABP">ABP</MenuItem>
              </Select>
              <FormHelperText>
                {validation?.messages?.networkType?.join(', ')}
              </FormHelperText>
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
          >
            <TextField
              id="Network-devEUI"
              name="devEui"
              fullWidth
              label="Device EUI"
              variant="standard"
              required
              onChange={handleChange}
              onBlur={(event) => {
                if (parseInt(event.target.value) !== 0) {
                  setNetwork({
                    ...network,
                    [event.target.name]: event.target.value
                      .padStart(16, 0)
                      .toLowerCase()
                  })
                }
              }}
              value={network?.devEui}
              error={validation?.messages?.devEui?.length > 0}
              helperText={validation?.messages?.devEui?.join(', ')}
              slotProps={{ inputComponent: Deveui }}
            />
          </Stack>

          {network?.networkType === 'OTAA' && (
            <Stack
              direction="row"
              spacing={2}
              mt={5}
            >
              <TextField
                id="Network-joinEUI"
                name="joinEui"
                fullWidth
                label="Join EUI / Application EUI"
                variant="standard"
                required
                onChange={handleChange}
                onBlur={(event) => {
                  if (parseInt(event.target.value) !== 0) {
                    setNetwork({
                      ...network,
                      [event.target.name]: event.target.value
                        .padStart(16, 0)
                        .toLowerCase()
                    })
                  }
                }}
                value={network?.joinEui}
                error={validation?.messages?.joinEui?.length > 0}
                helperText={validation?.messages?.joinEui?.join(', ')}
                slotProps={{ inputComponent: Appeui }}
              />
              <TextField
                id="Network-appKey"
                name="appKey"
                fullWidth
                label="Application KEY"
                variant="standard"
                required
                onChange={handleChange}
                onBlur={(event) => {
                  if (parseInt(event.target.value) !== 0) {
                    setNetwork({
                      ...network,
                      [event.target.name]: event.target.value
                        .padStart(32, 0)
                        .toLowerCase()
                    })
                  }
                }}
                value={network?.appKey}
                error={validation?.messages?.appKey?.length > 0}
                helperText={validation?.messages?.appKey?.join(', ')}
                slotProps={{ inputComponent: Appkey }}
              />
            </Stack>
          )}

          {network?.networkType === 'ABP' && (
            <>
              <Stack
                direction="row"
                spacing={2}
                mt={5}
              >
                <TextField
                  id="Network-appSKey"
                  name="appSKey"
                  fullWidth
                  label="Application session KEY"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={(event) => {
                    if (parseInt(event.target.value) !== 0) {
                      setNetwork({
                        ...network,
                        [event.target.name]: event.target.value
                          .padStart(32, 0)
                          .toLowerCase()
                      })
                    }
                  }}
                  value={network?.appSKey}
                  error={validation?.messages?.appSKey?.length > 0}
                  helperText={validation?.messages?.appSKey?.join(', ')}
                  slotProps={{ inputComponent: Appskey }}
                />
                <TextField
                  id="Network-nwkSKey"
                  name="nwkSKey"
                  fullWidth
                  label="Network session KEY"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={(event) => {
                    if (parseInt(event.target.value) !== 0) {
                      setNetwork({
                        ...network,
                        [event.target.name]: event.target.value
                          .padStart(32, 0)
                          .toLowerCase()
                      })
                    }
                  }}
                  value={network?.nwkSKey}
                  error={validation?.messages?.nwkSKey?.length > 0}
                  helperText={validation?.messages?.nwkSKey?.join(', ')}
                  slotProps={{ inputComponent: Nwkskey }}
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
              >
                <TextField
                  id="Network-devaddr"
                  name="devaddr"
                  sx={{ width: 420 }}
                  label={t('devices.deviceAddress')}
                  variant="standard"
                  onChange={handleChange}
                  onBlur={(event) => {
                    if (parseInt(event.target.value) !== 0) {
                      setNetwork({
                        ...network,
                        [event.target.name]: event.target.value
                          .padStart(8, 0)
                          .toLowerCase()
                      })
                    }
                  }}
                  value={network?.devaddr}
                  error={validation?.messages?.devaddr?.length > 0}
                  helperText={validation?.messages?.devaddr?.join(', ')}
                  slotProps={{ inputComponent: Devaddr }}
                />
              </Stack>
            </>
          )}

          {isSuperAdmin && (
            <Stack
              direction={'row'}
              spacing={2}
              mt={5}
            >
              <Autocomplete
                id="netowrkServerUserAutocomplete"
                name="networkServerUserAutocomplete"
                sx={{ width: '50%' }}
                options={networkServerUsers || []}
                getOptionLabel={userToString}
                onChange={handleChangeUser}
                renderInput={(params) => (
                  <TextField
                    name="networkServerUserAutocompleteText"
                    {...params}
                    label={t('devices.selectAUser')}
                    variant="standard"
                  />
                )}
              />
              <Autocomplete
                id="netowrkServerApplicationAutocomplete"
                name="netowrkServerApplicationAutocomplete"
                sx={{ width: '50%' }}
                options={networkServerApplications || []}
                getOptionLabel={(option) => `[${option.id}] ${option.label}`}
                onChange={handleChangeApplication}
                renderInput={(params) => (
                  <TextField
                    name="netowrkServerApplicationText"
                    {...params}
                    label={t('devices.selectAnApplication')}
                    variant="standard"
                  />
                )}
              />
            </Stack>
          )}
        </>
      )
      }

      {
        model.sourceType === 'wmbus' && (
          <Stack
            direction="row"
            spacing={2}
            mt={5}
          >
            <TextField
              id="Network-wmbus-id"
              name="wmbusId"
              sx={{ width: 420 }}
              label="Wmbus ID"
              variant="standard"
              onChange={handleChange}
              error={validation?.messages?.wmbusId?.length > 0}
              helperText={validation?.messages?.wmbusId?.join(', ')}
              value={network.wmbusId}
            />
          </Stack>
        )
      }

      {
        model.sourceType === 'nbiot' && (
          <Stack
            direction="row"
            spacing={2}
            mt={5}
          >
            <TextField
              id="Network-imsi"
              name="imsi"
              sx={{ width: 420 }}
              label="imsi"
              variant="standard"
              onChange={handleChange}
              error={validation?.messages?.imsi?.length > 0}
              helperText={validation?.messages?.imsi?.join(', ')}
              value={network.imsi}
            />
          </Stack>
        )
      }
    </Stack >
  )
}

export default Network

Network.propTypes = {
  model: PropTypes.object,
  setIsValid: PropTypes.func,
  onChange: PropTypes.func
}
