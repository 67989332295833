import store from '../store/index.js'

const useUserPermission = (action) => {
  const {
    general: { currentOrganization, credentials }
  } = store.getState()

  if (
    credentials?.permissions?.permissions?.[currentOrganization]?.includes(
      action
    )
  ) {
    return true
  } else {
    return false
  }
}

export default useUserPermission
