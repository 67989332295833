import { Box, Stack } from '@mui/material'
import React from 'react'
import OrganizationsFilter from '../Fields/Organizations.jsx'

const FiltersOrganizations = () => {
  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Box>
          <OrganizationsFilter />
        </Box>
      </Stack>
    </>
  )
}

export default FiltersOrganizations
