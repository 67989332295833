import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography
} from '@mui/material'
import React, { useRef } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setFiltersDevices } from '../../../../store/slices/filters.js'

const State = () => {
  const dispatch = useDispatch()

  const {
    devices: { showOffline }
  } = useSelector((state) => state.filters)

  const online = useRef()
  const offline = useRef()

  /**
   * updateState
   * @param {*} state the state to update
   */
  const updateState = (state) => {
    dispatch(
      setFiltersDevices({
        showOffline: state
      })
    )
  }

  /**
   * handleChange
   */
  const handleChange = () => {
    // if online and offline are both true or both false set the state of the filter to null
    if (
      (online.current.checked && offline.current.checked) ||
      (!online.current.checked && !offline.current.checked)
    ) {
      updateState(null)
      // if online is set to true then update the state to false
    } else if (online.current.checked) {
      updateState(false)
      // if offline is set to true then update the state to true
    } else if (offline.current.checked) {
      updateState(true)
    }
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.devices.other.status.label')}
        </Typography>
        <FormGroup
          aria-label="position"
          row
        >
          <FormControlLabel
            value="online"
            control={
              <Checkbox
                inputRef={online}
                onChange={handleChange}
                checked={showOffline !== null && !showOffline}
                disabled={showOffline !== null && showOffline}
              />
            }
            label={t('filters.devices.other.status.valueTrue')}
            sx={{ width: '100px' }}
          />
          <FormControlLabel
            value="offline"
            control={
              <Checkbox
                inputRef={offline}
                onChange={handleChange}
                checked={showOffline !== null && showOffline}
                disabled={showOffline !== null && !showOffline}
              />
            }
            label={t('filters.devices.other.status.valueFalse')}
            sx={{ width: '100px' }}
          />
        </FormGroup>
      </Stack>
    </>
  )
}

export default State
