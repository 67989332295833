export const triggerAdd = {
  monday: {
    selected: false,
    timeSlots: []
  },
  tuesday: {
    selected: false,
    timeSlots: []
  },
  wednesday: {
    selected: false,
    timeSlots: []
  },
  thursday: {
    selected: false,
    timeSlots: []
  },
  friday: {
    selected: false,
    timeSlots: []
  },
  saturday: {
    selected: false,
    timeSlots: []
  },
  sunday: {
    selected: false,
    timeSlots: []
  }
}
