import {
  Autocomplete,
  CircularProgress,
  Icon,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { CorporateFareRounded as CorporateFareRoundedIcon } from '@mui/icons-material'
import { t } from 'i18next'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useFetchOrganizationsFilterQuery } from '../../../../store'
import { setFiltersOrganizations } from '../../../../store/slices/filters.js'

const Organizations = ({ onChange = () => {} }) => {
  const dispatch = useDispatch()

  const { organizations: filters } = useSelector(
    (state) => state.filters,
    shallowEqual
  )

  const {
    data: organizationFilterOptions = [],
    isError: isOrganizationFilterError,
    isLoading: isOrganizationFilterLoading
  } = useFetchOrganizationsFilterQuery()

  const handleChange = (_, v) => {
    const organizationKeys = v.map((i) => i.key)
    dispatch(
      setFiltersOrganizations({
        ...filters,
        ...(organizationKeys.length > 0
          ? { organizationKeys: organizationKeys }
          : { organizationKeys: undefined, searchInSubOrganizations: false })
      })
    )
    onChange(organizationKeys)
  }

  const { organizationKeys = [] } = filters

  return (
    <>
      <Autocomplete
        multiple
        disabled={isOrganizationFilterError || isOrganizationFilterLoading}
        id="brands-filter"
        options={organizationFilterOptions}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        loading={isOrganizationFilterLoading}
        getOptionLabel={(option) => option.value}
        value={organizationFilterOptions.filter(({ key }) =>
          organizationKeys.includes(key)
        )}
        renderOption={(props, option) => (
          <ListItem
            {...props}
            divider
            key={option.key}
          >
            <ListItemIcon>
              <CorporateFareRoundedIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: '0.8rem',
                fontWeight: 500
              }}
              primary={option.value}
            />
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            // variant="standard"
            placeholder={t('filters.devices.organizations.placeholder')}
            slotProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    color="inherit"
                    edge="end"
                  >
                    {isOrganizationFilterLoading && (
                      <CircularProgress
                        edge="end"
                        size={20}
                      />
                    )}
                  </Icon>
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </>
  )
}

Organizations.propTypes = {
  onChange: PropTypes.func
}

export default Organizations
