import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'

const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: customFetchBase,
  tagTypes: ['events'],
  endpoints(builder) {
    return {
      searchEvents: builder.mutation({
        query: ({ payload, page, pageSize }) => {
          logger.log(`RTK API - Search events for filter: ${payload.fullText}`)
          return {
            url: `/events/search`,
            method: 'POST',
            params: {
              page: !page ? 1 : page,
              pageSize
            },
            body: payload
          }
        },
        providesTags: ['events']
      }),
      fetchEvents: builder.query({
        query: ({ deviceId, from, to }) => {
          logger.log('RTK API - Search events')
          return {
            url: '/events',
            params: {
              from,
              to,
              deviceId
            },
            method: 'GET'
          }
        },
        providesTags: ['events']
      }),
      fetchAllEvents: builder.query({
        query: (filters) => {
          logger.log('RTK API - Search events')
          return {
            url: '/events/searchAll',
            body: { ...filters, allowMissingPosition: true },
            method: 'POST'
          }
        },
        providesTags: ['events']
      }),
      fetchEventsSearchSummary: builder.query({
        query: (filters) => {
          logger.log('RTK API - Search events')
          return {
            url: '/events/search/summary',
            body: { ...filters, allowMissingPosition: true },
            method: 'POST'
          }
        },
        providesTags: ['events']
      })
    }
  }
})

export const {
  useFetchEventsSearchSummaryQuery,
  useFetchEventsQuery,
  useFetchAllEventsQuery,
  useSearchEventsMutation
} = eventsApi

export { eventsApi }
