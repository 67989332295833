import { Autocomplete, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'

const filterOptions = [
  { label: 'Comunicazione misura', value: 'measure-communication' },
  { label: 'Comunicazione', value: 'communication' },
  { label: 'Soglia', value: 'threshold' },
  { label: 'Delta', value: 'delta' },
  { label: 'Trigger', value: 'trigger' },
  { label: 'Trigger per misura', value: 'measure-trigger' },
  { label: 'Trigger schedulato', value: 'scheduled-trigger' }
]

const EventTypeFilter = ({ filters, onChange = () => {} }) => {
  const handleChange = (_, data) => {
    onChange(data ? [data.value] : undefined)
  }
  
  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('events.eventType')}
        </Typography>
        <Autocomplete
          id="events-type-filter"
          options={filterOptions}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.value === value}
          getOptionLabel={(option) => option?.label}
          value={
            filters?.triggerTypes
              ? filterOptions.find((f) => f.value === filters?.triggerTypes[0])
              : null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
            />
          )}
        />
      </Stack>
    </>
  )
}

EventTypeFilter.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func
}

export default EventTypeFilter
