import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import EventsTable from '../../Events/Tables/Table'
import BarChart from '../../Charts/BarChart'
import {
  useFetchEventsQuery,
  useFetchEventsSearchSummaryQuery
} from '../../../store/apis/events-api'
import PropTypes from 'prop-types'
import { EVENTS_POLLING_RATE } from '../../../configs/environment.js'
import NotFoundMessage from '../../Shared/Components/NotFoundMessage'

const EventsTab = ({ deviceId, refresh, setRefresh, timePicker }) => {
  //TODO capire a cosa e se serve
  const [, setEventsCount] = useState(0)

  const {
    refetch: refetchEvents,
    data: events,
    isLoading: isEventsLoading
  } = useFetchEventsQuery(
    {
      from: timePicker[0],
      to: timePicker[1],
      deviceId: deviceId
    },
    { pollingInterval: EVENTS_POLLING_RATE, skipPollingIfUnfocused: true }
  )

  const { data: allEvents } = useFetchEventsSearchSummaryQuery(
    {
      fromTo: {
        from: timePicker[0],
        to: timePicker[1]
      },
      deviceIds: [deviceId]
    },
    { pollingInterval: EVENTS_POLLING_RATE, skipPollingIfUnfocused: true }
  )

  useEffect(() => {
    if (!isEventsLoading) {
      setEventsCount(events?.totalCount)
    }
  }, [events])

  useEffect(() => {
    refetchEvents()
  }, [timePicker])

  useEffect(() => {
    if (refresh) {
      refetchEvents()
      setRefresh(false)
    }
  }, [refresh])

  return (
    <>
      {allEvents?.length && events?.results?.length ? (
        <Stack spacing={20}>
          <Box
            key={'events'}
            id="events"
            sx={{ height: '400px' }}
          >
            <Typography
              variant="h5"
              align="center"
              mb={2}
            >
              {t('devices.events')}
            </Typography>
            <Card variant="standard">
              <CardContent>
                <BarChart data={allEvents} />
              </CardContent>
            </Card>
          </Box>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            my={4}
          >
            <EventsTable
              events={events}
              paging={{ page: 1, pageSize: 999 }}
            />
          </Stack>
        </Stack>
      ) : (
        <NotFoundMessage when={() => !allEvents?.length || !events?.results?.length}  />
      )}
    </>
  )
}

EventsTab.propTypes = {
  deviceId: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
}

export default EventsTab
