export const filters = {
  devices: {
    organizationKeys: [],
    showPublic: null,
    showOffline: null,
    showHidden: null,
    brandIds: [],
    modelIds: [],
    tags: [],
    tagSearchMode: null,
    searchInSubOrganizations: false
  },
  events: {
    deviceIds: []
  },
  gateways: {
    status: [],
    technology: [],
    nearby: null,
    position: null
  },
  triggers: {
    organizationKeys: [],
    brandIds: [],
    modelIds: [],
    isUsedInRule: false,
    triggerTypes: []
  },
  actions: {
    organizationKey: null,
    actionTypes: [],
    isUsedInRule: false
  },
  rules: {
    actionTypes: [],
    triggerTypes: [],
    enabled: null,
    modelIds: []
  },
  organizations: {
    organizationKeys: []
  }
}
