import {
  Box,
  Button,
  Stack,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useSearchActionsQuery } from '../../../store'
import ActionCard from '../Card/ActionCard.jsx'
import ActionsFilters from '../Filters/ActionsFilters.jsx'
import AddAction from '../Dialogs/AddAction'
import ConfirmDialog from '../../Shared/Dialogs/ConfirmDialog'
import { useDeleteActionMutation } from '../../../store/apis/actions-api'
import { useSearchParams } from 'react-router-dom'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert'
import ZList from '../../Shared/Components/ZList'

const DEFAULT_PAGE_SIZE = 25

const ActionsList = () => {
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')

  const [actionId, setActionId] = useState(null)
  const [actionName, setActionName] = useState(null)
  const [actionType, setActionType] = useState(null)
  const [isAddActionDialogOpen, setIsAddActionDialogOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [confirmDeleteActionIsOpen, setConfirmDeleteActionIsOpen] = useState(false)
  const [filters, setFilters] = useState({})

  const [
    deleteAction,
    {
      error: deleteActionError,
      isError: isDeleteActionError,
      isSuccess: isDeleteActionSuccess
    }
  ] = useDeleteActionMutation()

  useOpenResultAlert(deleteActionError, { isError: isDeleteActionError, isSuccess: isDeleteActionSuccess })

  const handleFiltersChange = (filters) => {
    setFilters(filters)
  }

  const {
    data: actions,
    isLoading: isSearchActionsLoading,
    isError: isSearchActionsError,
    refetch
  } = useSearchActionsQuery(
    fullText
      ? {
          page,
          pageSize: DEFAULT_PAGE_SIZE,
          organizationKeys: filters.organizationKeys,
          fullText
        }
      : { page, pageSize: DEFAULT_PAGE_SIZE, organizationKeys: filters.organizationKeys }
  )

  useEffect(() => {
    if (!fullText || !fullText.length) return
    refetch()
  }, [fullText])

  const handleChangePage = (_, value) => {
    setPage(value)
  }

  const selectFilter = (action) => {
    const filterFunctions = {
      actionTypes: (action) =>
        !filters.actionTypes?.length ||
        filters.actionTypes?.includes(action.type),
      isUsedInRule: (action) => !filters.isUsedInRule || action.rules.length,
      default: () => true
    }

    return Object.keys(filters).reduce((acc, curr) => {
      const func = filterFunctions[curr] || filterFunctions['default']
      return acc && func(action)
    }, true)
  }

  const handleDeleteAction = (action) => {
    setActionId(action.id)
    setActionName(action.name)
    setActionType(action.type)
    setConfirmDeleteActionIsOpen(true)
  }

  const handleOnConfirmDeleteAction = () => {
    deleteAction({ id: actionId, type: actionType })
    setConfirmDeleteActionIsOpen(false)
  }

  const actionsList = actions?.data
    .filter((action) => selectFilter(action))
    .map((action, index) => (
      <ActionCard
        index={index}
        key={`action-${index}`}
        action={action}
        onDelete={() => handleDeleteAction(action)}
      />
    ))

  const actionsFilters = (
    <ActionsFilters
      filters={filters}
      onChange={handleFiltersChange}
    />)
  
  return (
    <>
      <Stack direction="row">
        <Typography variant="body1">
          <strong>{t('actions.preset')}</strong>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          id="actions-list-create-new-action"
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => setIsAddActionDialogOpen(true)}
        >
          {t('actions.createNew')}
        </Button>
      </Stack>

      <ZList count={actions?.count} filters={actionsFilters} entities={actionsList} page={page} isLoading={isSearchActionsError || isSearchActionsLoading} onPageChange={handleChangePage} />

      {isAddActionDialogOpen && (
        <AddAction
          isOpen={isAddActionDialogOpen}
          setIsOpen={setIsAddActionDialogOpen}
        />
      )}

      <ConfirmDialog
        isOpen={confirmDeleteActionIsOpen}
        title={t('actions.deleteAction')}
        message={`${t('actions.areYouSureYouWantToDelete')} ${actionName}?`}
        onConfirm={handleOnConfirmDeleteAction}
        onClose={() => setConfirmDeleteActionIsOpen(false)}
      />
    </>
  )
}

export default ActionsList
