import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Pagination,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel
} from '@mui/material'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  WarningRounded as WarningRoundedIcon,
  SentimentSatisfiedAltRounded as SentimentSatisfiedAltRoundedIcon,
  EditRounded as EditRoundedIcon,
  DeleteRounded as DeleteRoundedIcon,
  ListRounded as ListRoundedIcon,
  LinkRounded as LinkRoundedIcon
} from '@mui/icons-material'
import GatewayTests from '../Dialogs/GatewayTests.jsx'
import { t } from 'i18next'
import Barcode from 'react-barcode'
import BarcodeDialog from '../Dialogs/Barcode.jsx'
import NotFoundMessage from '../../Shared/Components/NotFoundMessage.jsx'

const DEFAULT_PAGE_SIZE = 25

const GatewaysTable = ({ page, sorting, onPageChange, onSortingChange, isLoading, gateways }) => {
  const { direction, sort } = sorting

  const [selectedSerial, setSelectedSerial] = useState()
  const [gatewayTestsOpen, setGatewayTestsOpen] = useState(false)
  const [barcodeOpen, setBarcodeOpen] = useState(false)

  const handleChangeSorting = (field) => onSortingChange({sort: field, direction: direction === 'asc' ? 'desc' : 'asc' })

  const handleGatewaysTestsOpen = (serial) => {
    setSelectedSerial(serial)
    onPageChange(1)
    setGatewayTestsOpen(true)
  }

  const handleGatewayTestsClose = () => {
    setSelectedSerial(null)
    setGatewayTestsOpen(false)
  }

  const handleBarcodeOpen = (serial) => {
    setSelectedSerial(serial)
    setBarcodeOpen(true)
  }

  const handleBarcodeClose = () => {
    setSelectedSerial(null)
    setBarcodeOpen(false)
  }

  const headerCells = [
    {
      isSortable: false,
      custom: false,
      customControl: null,
      name: 'reachable',
      label: t('gateways.reachable')
    },
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'gatewayId',
      label: t('gateways.gatewayId')
    },
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'wmbusId',
      label: t('gateways.wmbusId')
    },
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'serial',
      label: t('common.serial')
    },
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'status',
      label: t('common.status')
    },
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'technology',
      label: t('common.technology')
    },
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'address',
      label: t('common.address')
    },
    {
      isSortable: true,
      custom: false,
      customControl: null,
      name: 'isTested',
      label: t('common.actions')
    }
  ]

  const createCell = (cell) => cell.isSortable 
    ? <TableSortLabel
        active={sort === cell.name}
        direction={sort === cell.name ? direction : 'asc'}
        onClick={() => handleChangeSorting(cell.name)}
      >
        {cell.label}
      </TableSortLabel>
    : cell.label

  const tableHead = (headerCells) => headerCells.map((cell, i) => (
      <TableCell key={i}>
        {cell.custom ? cell.customControl : createCell(cell)}
      </TableCell>
    ))

  return (
    <>
      {isLoading && (
        <Stack
          spacing={2}
          sx={{ marginBottom: '20px' }}
        >
          {[...Array(DEFAULT_PAGE_SIZE).keys()].map((index) => (
            <Skeleton
              key={`skeleton-${index}`}
              variant="rounded"
              sx={{ height: '100px' }}
            ></Skeleton>
          ))}
        </Stack>
      )}

      {!isLoading && (
        <TableContainer component={Box}>
          <Table aria-label="gateways table">
            <TableHead>
              <TableRow>{tableHead(headerCells)}</TableRow>
            </TableHead>
            <TableBody>
              {gateways?.data.map((row) => (
                <TableRow
                  key={row.serial}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {!row.reachable && (
                      <WarningRoundedIcon
                        size={16}
                        color="red"
                      />
                    )}

                    {row.reachable && (
                      <SentimentSatisfiedAltRoundedIcon
                        size={16}
                        color="green"
                      />
                    )}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                  >
                    <Typography variant="p">{row.gatewayId}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="p">{row.wmbusId}</Typography>
                  </TableCell>

                  <TableCell>
                    <Button variant="text" onClick={() => handleBarcodeOpen(row.serial)}>
                      <Barcode
                        value={row.serial}
                        width={1.4}
                        height={30}
                      />
                    </Button>
                  </TableCell>

                  <TableCell>
                    <Typography variant="p">{row.status}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="p">{row.technology}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="p">{row.address}</Typography>
                  </TableCell>

                  <TableCell>
                    <ButtonGroup size="small">
                      <IconButton
                        key="edit"
                        aria-label="edit device"
                        color="primary"
                        disabled={!!row.salesforceId}
                      >
                        <EditRoundedIcon size={16} />
                      </IconButton>

                      <IconButton
                        key="delete"
                        aria-label="delete"
                        color="error"
                        disabled={!!row.salesforceId}
                      >
                        <DeleteRoundedIcon size={16} />
                      </IconButton>

                      <IconButton
                        key="show tests"
                        disabled={row.isTested < 1}
                        onClick={() => handleGatewaysTestsOpen(row.serial)}
                        color="primary"
                      >
                        <ListRoundedIcon size={16} />
                      </IconButton>

                      <IconButton
                        key="view in Salesforce"
                        disabled={!row.salesforceId}
                        href={`https://a2a-crm-b2b.lightning.force.com/${row.salesforceId}`} target="_blank" rel="noopener noreferrer"
                        color="primary"
                      >
                        <LinkRoundedIcon size={16} />
                      </IconButton>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table >
        </TableContainer >
      )}

      {gateways?.count > DEFAULT_PAGE_SIZE && (
        <Box
          display="flex"
          justifyContent="center"
        >
          <Pagination
            count={Math.ceil(gateways?.count / DEFAULT_PAGE_SIZE)}
            page={page}
            onChange={onPageChange}
            showFirstButton
            showLastButton
          />
        </Box>
      )}

      <NotFoundMessage when={() => !isLoading && !gateways?.data.length}  />

      {gatewayTestsOpen && (
        <GatewayTests
          isOpen={gatewayTestsOpen}
          onClose={handleGatewayTestsClose}
          serial={selectedSerial}
        />
      )}

      {barcodeOpen && (
        <BarcodeDialog
          isOpen={barcodeOpen}
          onClose={handleBarcodeClose}
          serial={selectedSerial}
        />
      )}
    </>
  )
}

GatewaysTable.propTypes = {
  gateways: PropTypes.object
}

export default GatewaysTable
