import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'
import OrganizationForm from '../Form/OrganizationForm.jsx'
import {
  setErrorAlertMessage,
  setOpenDialog
} from '../../../store/slices/general.js'
import {
  useCreateRootOrganizationMutation,
  useCreateChildOrganizationMutation
} from '../../../store/index.js'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'
import PropTypes from 'prop-types'

const OrganizationAddDialog = ({ isOpen, onClose, onSave }) => {

  const dispatch = useDispatch()

  const [orgData, setOrgData] = useState(null)
  const [isValid, setIsValid] = useState(false)

  const [
    createRootOrganization,
    { isSuccess: isSuccessRoot, isError: isErrorRoot, error: rootError }
  ] = useCreateRootOrganizationMutation()

  const [
    createChildOrganization,
    { isSuccess: isSuccessChild, isError: isErrorChild, error: childError }
  ] = useCreateChildOrganizationMutation()

  useEffect(() => {
    if (!isSuccessChild) {
      return
    }
    onSave()
    dispatch(setOpenDialog('successAlert'))
  }, [isSuccessChild])

  useEffect(() => {
    if (!isSuccessRoot) {
      return
    }
    onSave()
    dispatch(setOpenDialog('successAlert'))
  }, [isSuccessRoot])

  useEffect(() => {
    if (!rootError) return
    if (rootError.data) {
      dispatch(setOpenDialog('errorAlert'))

      if (rootError.data.error) {
        dispatch(
          setErrorAlertMessage(
            `${rootError.data.statusCode} ${rootError.data.message}`
          )
        )
      }
    } else {
      dispatch(setErrorAlertMessage(rootError.error))
    }
  }, [isErrorRoot])

  useEffect(() => {
    if (!childError) return
    if (childError.data) {
      dispatch(setOpenDialog('errorAlert'))

      if (childError.data.error) {
        dispatch(
          setErrorAlertMessage(
            `${childError.data.statusCode} ${childError.data.message}`
          )
        )
      }
    } else {
      dispatch(setErrorAlertMessage(childError.error))
    }
  }, [isErrorChild])

  const handleClose = () => {
    onClose(false)
  }

  const handleOrganizationFormChange = (orgData, isValid) => {
    setIsValid(isValid)
    setOrgData(orgData)
  }

  const handleSaveOrganization = () => {
    if (orgData.parentOrganization.key === 'root') {
      const payload = {
        data: {
          key: orgData.organizationKey,
          name: orgData.organizationName,
          vatNumber: orgData.vatNumber,
          domain: orgData.domain,
          networkServerUsers: [
            {
              server: orgData.networkServer.server,
              user: orgData.networkServer.user,
              id: orgData.networkServer.userId
            }
          ],
          orgProfileId: orgData.profile.id,
          timeZone: orgData.timezone
        },
        logo: orgData.file
      }
      createRootOrganization(payload)
    } else {
      const payload = {
        data: {
          key: orgData.organizationKey,
          name: orgData.organizationName,
          vatNumber: orgData.vatNumber,
          domain: orgData.domain,
          networkServerUsers: [
            {
              server: orgData.networkServer.server,
              user: orgData.networkServer.user,
              id: orgData.networkServer.userId
            }
          ],
          timeZone: orgData.timezone
        },
        logo: orgData.file
      }
      createChildOrganization({
        parentOrganization: orgData.parentOrganization.key,
        payload: payload
      })
    }
  }

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      onClose={handleClose}
      scroll="body"
      aria-labelledby="add-device-modal-title"
      aria-describedby="add-device-modal-description"
    >
      <DialogTitle
        variant="h4"
        display="flex"
        justifyContent="center"
      >
        {t('organizations.add')}
      </DialogTitle>

      <CloseDialogButton onClose={handleClose} />

      <DialogContent>
        <OrganizationForm onChange={handleOrganizationFormChange} />
      </DialogContent>

      <DialogActions>
        <Button
          id='addOrganization-confirm-save-button'
          variant="contained"
          onClick={handleSaveOrganization}
          disabled={!isValid}
        >
          {t('common.confirmAndSave')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

OrganizationAddDialog.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}

export default OrganizationAddDialog
