import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Download as DownloadIcon } from '@mui/icons-material'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { useFetchDeviceMeasurementsQuery } from '../../../store'
import BarChart from '../../Charts/BarChart.jsx'
import LineChart from '../../Charts/LineChart.jsx'
import RSSIWmBusChart from '../../Charts/RSSIWmBusChart.jsx'
import ChartReferenceValues from '../../Charts/ChartReferenceValues'
import { formatDateExtendedIT } from '../../../helpers/date-formatter.js'
import { deviceMeasurementsDownload } from '../../../helpers/download'
import Show from '../../Layout/Can/Show'
import { MEASURES_POLLING_RATE } from '../../../configs/environment.js'
import PropTypes from 'prop-types'

const MeasuresTab = ({
  device,
  timePicker,
  quantities = [],
  refresh = false,
  setRefresh
}) => {
  const {
    credentials = {},
    currentOrganization,
    theme
  } = useSelector((state) => state.general)

  const visibleQuantities = quantities.filter((q) => !q.hidden)
  const { data: chart = [], refetch: refetchMeasurements } =
    useFetchDeviceMeasurementsQuery(
      {
        id: device.id,
        measureNames: visibleQuantities.map((q) => q.measureName),
        from: timePicker[0],
        to: timePicker[1]
      },
      {
        skip: !device,
        pollingInterval: MEASURES_POLLING_RATE,
        skipPollingIfUnfocused: true
      }
    )

  function getLastMeasure(data) {
    let orderedData = data.slice()
    orderedData.sort((a, b) => a.x - b.x)

    return orderedData[orderedData.length - 1]
  }

  const [downloadInProgress, setDownloadInProgress] = useState(false)

  const handleDownloadEnd = () => {
    setDownloadInProgress(false)
  }

  const handleDownloadMeasures = () => {
    setDownloadInProgress(true)
    deviceMeasurementsDownload({
      id: device.id,
      name: device.name,
      serial: device.serial,
      from: timePicker[0],
      to: timePicker[1],
      credentials,
      currentOrganization,
      onSuccess: handleDownloadEnd,
      onError: handleDownloadEnd
    })
  }

  useEffect(() => {
    if (refresh) {
      refetchMeasurements()
      setRefresh(false)
    }
  }, [refresh])

  return (
    <>
      <Stack
        direction="column"
        spacing={5}
      >
        {visibleQuantities?.map(
          (q, i) =>
            chart[q.measureName]?.length > 0 && (
              <Box key={i}>
                <Box
                  key={q.measureName}
                  id={q.measureName}
                >
                  <Card variant="standard">
                    <CardHeader
                      title={t(q.name)}
                      subheader={`${getLastMeasure(chart[q.measureName]).y} ${q.unitOfMeasure
                        }`}
                      action={
                        <Typography>
                          {formatDateExtendedIT(
                            getLastMeasure(chart[q.measureName]).x
                          )}
                        </Typography>
                      }
                    />

                    <CardContent
                      sx={{
                        height: '400px'
                      }}
                    >
                      {q.chart === 'stepped' && (
                        <LineChart
                          data={chart[q.measureName]}
                          unitOfMeasure={q.unitOfMeasure}
                          measureName={q.measureName}
                          theme={theme}
                          curve={'step'}
                        />
                      )}

                      {q.chart === 'line' && (
                        <LineChart
                          data={chart[q.measureName]}
                          unitOfMeasure={q.unitOfMeasure}
                          measureName={q.measureName}
                          theme={theme}
                          curve={'linear'}
                        />
                      )}

                      {q.chart === 'bar' && (
                        <BarChart
                          data={chart[q.measureName]}
                          unitOfMeasure={q.unitOfMeasure}
                          measureName={q.measureName}
                        />
                      )}

                      {q.chart === 'RSSIWmBus' && (
                        <RSSIWmBusChart
                          data={chart[q.measureName]}
                          unitOfMeasure={q.unitOfMeasure}
                          measureName={q.measureName}
                        />
                      )}
                    </CardContent>

                    <CardActions>
                      <ChartReferenceValues
                        data={chart[q.measureName]}
                        unitOfMeasure={q.unitOfMeasure}
                      ></ChartReferenceValues>
                    </CardActions>
                  </Card>
                </Box>
              </Box>
            )
        )}
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        align="center"
        marginTop={3}
      >
        <Show when={() => downloadInProgress}>
          <CircularProgress size={30} />
        </Show>
        <Show when={() => !downloadInProgress}>
          <Button
            variant="contained"
            startIcon={<DownloadIcon fontSize="small" />}
            onClick={handleDownloadMeasures}
          >
            {t('common.download')} XLSX
          </Button>
        </Show>
      </Stack>
    </>
  )
}

MeasuresTab.propTypes = {
  device: PropTypes.object,
  quantities: PropTypes.array,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
}

export default MeasuresTab
