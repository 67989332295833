import { Card, CardContent, Stack, Typography } from '@mui/material'
import React from 'react'
import Show from '../../Layout/Can/Show'
import PropTypes from 'prop-types'

const InlineMessage = ({ message, title, severity = 'warning', when = () => true }) => {
  const backgroundColor = severity === 'warning' ? '#FFF5E8' : '#EDF7ED'
  return (
    when && <Card
      variant="standard"
      sx={{
        width: '100%',
        background: backgroundColor,
        marginBottom: '15px',
        marginTop: '10px'
      }}
    >
      <CardContent>
        <Stack
          justifyContent={'center'}
          alignItems={'center'}
          spacing={1}
        >
          <Show when={() => !!title}>
            <Typography variant="p">{title}</Typography>
          </Show>
          <Typography
            variant="body"
            py={0}
          >
            {message}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}

InlineMessage.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  severity: PropTypes.string
}

export default InlineMessage
