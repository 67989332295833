import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { useCreateRuleMutation } from '../../../store/index.js'
import { Save as SaveIcon } from '@mui/icons-material'
import RuleForm from '../Form/Rule/RuleForm.jsx'
import PropTypes from 'prop-types'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert.js'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'

const AddRule = ({ isOpen, onClose }) => {
  const { currentOrganization } = useSelector((state) => state.general)
  const [rule, setRule] = useState({
    trigger: null,
    actions: [],
    enabled: true,
    name: ''
  })

  const [
    createRule,
    {
      error: createRuleError,
      isError: isCreateRuleError,
      isSuccess: isCreateRuleSuccess
    }
  ] = useCreateRuleMutation()

  const handleOnClose = (reload) => {
    setRule({
      trigger: null,
      actions: [],
      enabled: true,
      name: ''
    })
    onClose(reload)
  }

  const handleRuleFormChange = (rule) => {
    setRule(rule)
  }

  const handleSaveRule = async () => {
    createRule({
      organizationKey: rule.organizationKey || currentOrganization,
      name: rule.name,
      triggerId: rule.trigger.id,
      actions: rule.actions.map((x) => ({
        actionId: x.id,
        actionType: x.type
      }))
    })
  }

  useOpenResultAlert(createRuleError, { isError: isCreateRuleError, isSuccess: isCreateRuleSuccess })

  useEffect(() => {
    if (isCreateRuleSuccess) {
      handleOnClose({ reload: isCreateRuleSuccess })
    }
  }, [isCreateRuleSuccess])

  return (
    <ZDialog
      id={'add-rule-dialog'}
      isOpen={isOpen}
      onClose={() => handleOnClose(false)}
      title={t('rules.add')}
      content={<RuleForm
        onChange={handleRuleFormChange}
        rule={rule}
        isEdit={false}
      />}
      actions={<Button
                id="add-rule-dialog-button-save"
                variant="contained"
                endIcon={<SaveIcon />}
                onClick={handleSaveRule}
                disabled={
                  rule.actions.length == 0 ||
                  !rule.trigger ||
                  !rule.name ||
                  (rule.trigger?.type === 'scheduled-trigger' &&
                    rule.actions.filter((a) => a.type !== 'downlink').length > 0)
                }
              >
                {t('common.save')}
      </Button>}
    /> 
  )
}

AddRule.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default AddRule
