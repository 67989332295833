import {
  Box,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid2,
  Stack,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { t } from 'i18next'
import ZCard from '../../Shared/Components/ZCard'

const ActionCard = ({ index, action, onDelete, isRuleCard }) => {
  return (
    <ZCard
      name={'action'}
      key={`action-card-${index}`}
      id={`action-card-${index}`}
      title={action.name}
      subheader={action.brandEncoded && action.modelEncoded ? `${action.brandEncoded} / ${action.modelEncoded}`: ' '}
      createdAt={action.createdAt}
      updatedAt={action.updatedAt}
      footerFields={[{ label: t('common.organization'), value: action.organizationKey }, { label: t('common.createdBy'), value: action.createdBy }]}
      onDelete={onDelete}
      isDeleteVisible={isRuleCard || action.rules?.length === 0}
      isEditVisible={false}
      content={
        <CardContent
          sx={{
            background: 'rgba(30, 250, 150, 0.2)',
            marginX: '20px',
            marginBottom: '15px'
          }}
        >
      <Box sx={{ flexGrow: 1 }}>
        <Grid2
          container
          spacing={2}
        >
          <Grid2 size={3}>
            <Typography 
              component="p"
              variant="body2"
              sx={{ fontWeight: 'bold' }}>
              {t('common.typology')}
            </Typography>
            <Typography component="p" variant="body2">
              {action.type}
            </Typography>
          </Grid2>
          {action.type === 'downlink' && (
            <Grid2 size="grow">
              <Stack spacing={3} direction={'row'}>
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{t('actions.actionName')}</Typography>
                  <Typography variant="body2">{action.actionName}</Typography>
                </Box>
              </Stack>
            </Grid2>
          )}
          {action.type === 'webhook' && (
            <Grid2 size="grow">
              {action.webhookUrlAction && (
                <>
                  <Typography variant="h5">{t('common.url')}</Typography>

                  <Typography variant="body2">
                    {action.webhookUrlAction}
                  </Typography>

                  {action?.webhookHttpHeaders && Object.keys(action?.webhookHttpHeaders).length > 0 && (
                    <Box mt={2}>
                      <Typography variant="h5">Headers</Typography>
                      {Object.keys(action.webhookHttpHeaders).map((k, i) => (
                        <Typography
                          variant="body2"
                          key={i}
                        >
                          <strong>{k} :</strong>{' '}
                          {action.webhookHttpHeaders[k]}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </>
              )}
            </Grid2>
          )}
          {action.type === 'notification' && (
            <Grid2 size="grow">
              <Stack direction={'row'}>
                <FormControlLabel
                  disabled
                  control={<Checkbox checked={action.notifyAdmin} />}
                  label="Admin"
                />
                <FormControlLabel
                  disabled
                  control={<Checkbox checked={action.notifyEditor} />}
                  label="Editor"
                />
                <FormControlLabel
                  disabled
                  control={<Checkbox checked={action.notifyViewer} />}
                  label="Viewer"
                />
              </Stack>
              <Typography variant="body">
                {t('automations.recipients')}
              </Typography>
              {!action.emailsToNotify.length && (
                <Typography variant="body2">
                  {t('automations.noRecipients')}
                </Typography>
              )}
              {action.emailsToNotify.map((mail) => (
                <Typography
                  variant="body2"
                  key={'mail' + mail}
                >
                  {mail}
                </Typography>
              ))}
            </Grid2>
          )}
      </Grid2>
    </Box>
    </CardContent>}/>
  )
}

ActionCard.propTypes = {
  action: PropTypes.object,
  onDelete: PropTypes.func,
  isEdit: PropTypes.bool
}

export default ActionCard
