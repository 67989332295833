import { saveAs } from 'file-saver'

const exportDevicesMeasurementsDownload = ({
  payload,
  credentials,
  currentOrganization,
  onSuccess = () => {},
  onError = () => {}
}) => {
  fetch(`/api/devices/measurements-export`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${credentials?.accessToken || ''}`,
      organizationKey: `${currentOrganization}`
    }
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Unable to download the measures`)
      }
      return response.blob()
    })
    .then((blob) => {
      saveAs(
        blob,
        `measurements-export - ${payload.measuresFilters.from}-${payload.measuresFilters.to}.xlsx`
      )
      onSuccess()
    })
    .catch((error) => {
      onError()
      throw error
    })
}

const exportDevicesStatisticsDownload = ({
  payload,
  credentials,
  currentOrganization,
  onSuccess = () => {},
  onError = () => {}
}) => {
  fetch(`/api/devices/statistics-export`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${credentials?.accessToken || ''}`,
      organizationKey: `${currentOrganization}`
    }
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Unable to download the statistics`)
      }
      return response.blob()
    })
    .then((blob) => {
      saveAs(
        blob,
        `statistics-export - ${payload.measuresFilters.from}-${payload.measuresFilters.to}.xlsx`
      )
      onSuccess()
    })
    .catch((error) => {
      onError()
      throw error
    })
}

const deviceMeasurementsDownload = ({
  id,
  name,
  serial,
  from,
  to,
  credentials,
  currentOrganization,
  onSuccess = () => {},
  onError = () => {}
}) => {
  fetch(`/api/devices/${id}/measurements/excel?from=${from}&to=${to}`, {
    method: 'GET',
    headers: {
      Authorization: credentials?.accessToken
        ? `Bearer ${credentials?.accessToken}`
        : undefined,
      organizationKey: `${currentOrganization || 'a2a'}`
    }
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Unable to download the measures from device ${id}`)
      }
      return response.blob()
    })
    .then((blob) => {
      saveAs(blob, `${name}${serial} - ${from}-${to}.xlsx`)
      onSuccess()
    })
    .catch((error) => {
      onError()
      throw error
    })
}

const deviceStatisticsDownload = ({
  id,
  name,
  serial,
  from,
  to,
  credentials,
  currentOrganization,
  onSuccess = () => {},
  onError = () => {}
}) => {
  fetch(`/api/devices/${id}/statistics/excel?from=${from}&to=${to}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${credentials?.accessToken || ''}`,
      organizationKey: `${currentOrganization}`
    }
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Unable to download the statistics from device ${id}`)
      }
      return response.blob()
    })
    .then((blob) => {
      saveAs(blob, `${name}${serial} - ${from}-${to}.xlsx`)
      onSuccess()
    })
    .catch((error) => {
      onError()
      throw error
    })
}

const deviceLinkStatusesDownload = ({
  id,
  name,
  serial,
  from,
  to,
  credentials,
  currentOrganization,
  onSuccess = () => {},
  onError = () => {}
}) => {
  fetch(`/api/devices/${id}/link-status/excel?from=${from}&to=${to}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${credentials?.accessToken || ''}`,
      organizationKey: `${currentOrganization}`
    }
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Unable to download the statistics from device ${id}`)
      }
      return response.blob()
    })
    .then((blob) => {
      saveAs(blob, `${name}${serial} - ${from}-${to}.xlsx`)
      onSuccess()
    })
    .catch((error) => {
      onError()
      throw error
    })
}

const downloadImportTemplate = ({
  modelInfo,
  credentials,
  currentOrganization,
  onSuccess = () => {},
  onError = () => {}
}) => {
  fetch(
    `/api/devices/brands/${modelInfo.model.brandNameEncoded}/models/${modelInfo.model.nameEncoded}/bulk-import-template-file`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${credentials?.accessToken || ''}`,
        organizationKey: `${currentOrganization}`
      }
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Unable to download devices template`)
      }
      return response.blob()
    })
    .then((blob) => {
      saveAs(blob, `devices_bulk_import.xlsx`)
      onSuccess()
    })
    .catch((error) => {
      onError()
      throw error
    })
}

export {
  deviceMeasurementsDownload,
  deviceStatisticsDownload,
  deviceLinkStatusesDownload,
  exportDevicesMeasurementsDownload,
  exportDevicesStatisticsDownload,
  downloadImportTemplate
}
