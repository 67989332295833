import { Stack } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setFiltersOrganizations } from '../../../store/slices/filters.js'
import Filter from '../../Layout/Dialogs/Filter.jsx'
import FilterOrganizations from './Filters/Organizations.jsx'

const Filters = ({ filters }) => {
  const dispatch = useDispatch()

  const [selectedOrganizations, setSelectedOrganizations] = useState(false)

  const { credentials, currentOrganization } = useSelector(
    (state) => state.general
  )

  const isSuperAdmin = credentials?.permissions?.isSuperAdmin || false
  const orgPermissions = currentOrganization
    ? credentials?.permissions?.permissions[currentOrganization] || []
    : []

  const handleOnDeleteOrganizations = () => {
    dispatch(setFiltersOrganizations({ organizationKeys: [] }))
    setSelectedOrganizations(false)
  }

  useEffect(() => {
    const { organizationKeys } = filters

    setSelectedOrganizations(
      !!(organizationKeys && organizationKeys.length > 0)
    )
  }, [filters])

  const filterUnits = [
    {
      label: t('filters.devices.organizations.label'),
      selected: selectedOrganizations,
      onDelete: handleOnDeleteOrganizations,
      hidden: !orgPermissions.includes('organizations_r') && !isSuperAdmin,
      content: <FilterOrganizations />
    }
  ]

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {[...filterUnits].map((e, i) => (
          // <Can
          //   action="devices_r"
          //   key={`device-filter-${i}`}
          // >
          <Filter
            id='button-filter-organization'
            key={`device-filter-${i}`}
            onClick={e.onClick}
            onDelete={e.onDelete}
            selected={e.selected}
            title={e.label}
            hidden={e.hidden || false}
            content={e.content}
            count={e.count}
          />
          // </Can>
        ))}
      </Stack>
    </>
  )
}

Filters.propTypes = {
  filters: PropTypes.shape({
    organizationKeys: PropTypes.array,
    brandIds: PropTypes.array,
    modelIds: PropTypes.array,
    nearby: PropTypes.any,
    showOffline: PropTypes.bool,
    showHidden: PropTypes.bool,
    showPublic: PropTypes.bool,
    tags: PropTypes.array,
    tagSearchMode: PropTypes.any
  })
}

export default Filters
