import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import { LanguageRounded as LanguageRoundedIcon } from '@mui/icons-material'
import LanguageMenu from '../LanguageMenu/LanguageMenu.jsx'

const LanguageToggle = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-label="switch language"
        color="inherit"
        onClick={handleClick}
      >
        <LanguageRoundedIcon fontSize="small" />
      </IconButton>
      <LanguageMenu
        handleClose={handleClose}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        open={open}
      />
    </>
  )
}

export default LanguageToggle
