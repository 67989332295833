import PropTypes from 'prop-types'
import React from 'react'
import { Box } from '@mui/material'

const Invites = ({ main: MainComponent }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
    >
      {MainComponent}
    </Box>
  )
}

Invites.propTypes = {
  main: PropTypes.element
}

export default Invites
