import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'
import sanitizer, { sanitizeFilters } from '../../helpers/sanitizer.js'

const rulesApi = createApi({
  reducerPath: 'rules',
  baseQuery: customFetchBase,
  tagTypes: ['rules'],
  endpoints(builder) {
    return {
      searchRules: builder.mutation({
        query: ({ page, pageSize, filters }) => {
          logger.log('RTK API - Get all rules')
          return {
            url: '/rules/search',
            method: 'POST',
            params: { page, pageSize },
            body: sanitizeFilters(filters)
          }
        },
        transformResponse: (response, meta) => {
          const count = parseInt(meta.response.headers.get('x-total-count'))
          return { data: response, count: count }
        },
        providesTags: ['rules']
      }),
      createRule: builder.mutation({
        query: (payload) => {
          logger.log('RTK API - Create rule')
          logger.log(payload)
          return {
            url: '/rules',
            method: 'POST',
            body: sanitizer(payload)
          }
        },
        invalidatesTags: ['triggers', 'rules', 'actions']
      }),
      getRule: builder.query({
        query: (id) => {
          logger.log(`RTK API - Get rule ${id}`)
          return {
            url: `/rules/${id}`,
            method: 'GET'
          }
        },
        invalidatesTags: ['triggers', 'rules', 'actions']
      }),
      updateRule: builder.mutation({
        query: ({ id, payload }) => {
          logger.log(`RTK API - Update rule ${payload}`)
          return {
            url: `/rules/${id}`,
            method: 'PUT',
            body: sanitizer(payload)
          }
        },
        invalidatesTags: ['triggers', 'rules', 'actions']
      }),
      deleteRule: builder.mutation({
        query: ({ id }) => {
          logger.log(`RTK API - Delete rule ${id}`)
          return {
            url: `/rules/${id}`,
            method: 'DELETE'
          }
        },
        invalidatesTags: ['triggers', 'rules', 'actions']
      })
    }
  }
})

export const {
  useSearchRulesMutation,
  useCreateRuleMutation,
  useUpdateRuleMutation,
  useDeleteRuleMutation,
  useLazyGetRuleQuery
} = rulesApi

export { rulesApi }
