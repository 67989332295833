import { Box, Stack, Typography } from '@mui/material'
import { ResponsiveRadar } from '@nivo/radar'
import PropTypes from 'prop-types'
import React from 'react'

const LinkStatusChart = ({ data = [] }) => {
  const chartData = data.slice(0, 6).reduce(
    (acc, prev) => {
      return {
        count: [...acc.count, { gateway: prev.gateway, count: prev.count }],
        snr: [...acc.snr, { gateway: prev.gateway, snr: prev.snr }],
        rssi: [...acc.rssi, { gateway: prev.gateway, rssi: prev.rssi }]
      }
    },
    { count: [], snr: [], rssi: [] }
  )

  return (
    <Stack
      spacing={5}
      alignItems={'center'}
    >
      <Box sx={{ width: '80%', height: '350px' }}>
        <Typography
          variant="h5"
          align="center"
          pb={3}
        >
          Count
        </Typography>
        <ResponsiveRadar
          data={chartData.count}
          margin={{ top: 50, right: 10, bottom: 100, left: 10 }}
          keys={['count']}
          indexBy="gateway"
          tooltip={({ point }) => {
            return (
              <div
                style={{
                  background: 'white',
                  padding: '9px 12px',
                  border: '1px solid #ccc'
                }}
              >
                <Typography variant="body2">
                  <small>
                    {point.data.xFormatted}
                    <br />
                    <strong>{point.data.yFormatted}</strong>
                  </small>
                </Typography>
              </div>
            )
          }}
          colors={{ scheme: 'paired' }}
          borderColor={{ from: 'color' }}
          gridLabelOffset={36}
          dotColor={{ theme: 'background' }}
          dotLabelYOffset={3}
          dotSize={32}
          enableDotLabel
          dotBorderWidth={2}
          blendMode="multiply"
          motionConfig="wobbly"
          fillOpacity={0}
        />
      </Box>

      <Box sx={{ width: '80%', height: '350px' }}>
        <Typography
          variant="h5"
          align="center"
          pb={3}
        >
          SNR
        </Typography>
        <ResponsiveRadar
          data={chartData.snr}
          maxValue={10}
          margin={{ top: 50, right: 10, bottom: 100, left: 10 }}
          keys={['snr']}
          indexBy="gateway"
          valueFormat=">-.2f"
          tooltip={({ point }) => {
            return (
              <div
                style={{
                  background: 'white',
                  padding: '9px 12px',
                  border: '1px solid #ccc'
                }}
              >
                <Typography variant="body2">
                  <small>
                    {point.data.xFormatted}
                    <br />
                    <strong>{point.data.yFormatted}</strong>
                  </small>
                </Typography>
              </div>
            )
          }}
          colors={{ scheme: 'paired' }}
          borderColor={{ from: 'color' }}
          gridLabelOffset={36}
          dotColor={{ theme: 'background' }}
          dotLabelYOffset={3}
          dotSize={32}
          enableDotLabel
          dotBorderWidth={2}
          blendMode="multiply"
          motionConfig="wobbly"
          fillOpacity={0}
        />
      </Box>

      <Box sx={{ width: '80%', height: '350px' }}>
        <Typography
          variant="h5"
          align="center"
          pb={3}
        >
          RSSI
        </Typography>
        <ResponsiveRadar
          data={chartData.rssi}
          maxValue={100}
          margin={{ top: 50, right: 10, bottom: 150, left: 10 }}
          keys={['rssi']}
          indexBy="gateway"
          valueFormat=">-.2f"
          tooltip={({ point }) => {
            return (
              <div
                style={{
                  background: 'white',
                  padding: '9px 12px',
                  border: '1px solid #ccc'
                }}
              >
                <Typography variant="body2">
                  <small>
                    {point.data.xFormatted}
                    <br />
                    <strong>{point.data.yFormatted}</strong>
                  </small>
                </Typography>
              </div>
            )
          }}
          colors={{ scheme: 'paired' }}
          borderColor={{ from: 'color' }}
          gridLabelOffset={36}
          dotColor={{ theme: 'background' }}
          dotLabelYOffset={3}
          dotSize={32}
          enableDotLabel
          dotBorderWidth={2}
          blendMode="multiply"
          motionConfig="wobbly"
          fillOpacity={0}
        />
      </Box>
    </Stack>
  )
}

LinkStatusChart.propTypes = {
  data: PropTypes.array
}

export default LinkStatusChart
