import { createSlice } from '@reduxjs/toolkit'
import { logger } from '../../helpers/logger/logger.js'
import i18n from '../../i18n/index.js'
import { general } from '../states'

const initialState = general

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setOpenDrawer: (state, action) => {
      const target = action.payload
      logger.log(`open drawer ${target} page`)
      state.drawers[target] = true
    },
    setCloseDrawer: (state, action) => {
      const target = action.payload
      logger.log(`close drawer ${target} page`)
      state.drawers[target] = false
    },

    setOpenDialog: (state, action) => {
      const target = action.payload
      logger.log(`open ${target} dialog`)
      state.dialogs[target].open = true
    },

    setCloseDialog: (state, action) => {
      const target = action.payload
      logger.log(`close ${target} dialog`)
      state.dialogs[target].open = false
    },

    setCurrentDevice: (state, action) => {
      logger.log('set current device', action.payload)
      state.currentDevice = action.payload
    },

    setCurrentOrganization: (state, action) => {
      logger.log('set current organization', action.payload)
      state.currentOrganization = action.payload
    },

    setMapCenter: (state, action) => {
      logger.log('set map center', action.payload)
      state.map.center = action.payload
    },
    setMapZoom: (state, action) => {
      logger.log('set map zoom', action.payload)
      state.map.zoom = action.payload
    },
    setTheme: (state, action) => {
      logger.log('toggle theme', action.payload)
      state.theme = action.payload
    },
    setLanguage: (state, action) => {
      logger.log('set language', action.payload)
      i18n.changeLanguage(action.payload)
      state.language = action.payload
    },

    setActiveTab: (state, action) => {
      const { target, value } = action.payload
      logger.log(`set active tab ${value} for ${target} tab`)
      state.tabs[target] = value
    },

    setFiltersDefaultNear: (state, action) => {
      logger.log('set default filters near')
      state.filters.position.near = action.payload
    },

    setFiltersDefaultPosition: (state, action) => {
      logger.log('set default filters position')
      state.filters.position.entity = action.payload
    },

    setSuggestions: (state, action) => {
      logger.log('set default suggestions')
      state.filters.position.suggestions = action.payload
    },

    setPaginator: (state, action) => {
      const { target, value } = action.payload
      logger.log(`set page ${value} for ${target} page`)
      state.paginators[target] = value
    },
    setPaginatorSize: (state, action) => {
      const { target, value } = action.payload
      logger.log(`set page size ${value} for ${target} page`)
      state.paginatorSize[target] = value
    },

    setDeleteDeviceInfo: (state, action) => {
      const { id, name } = action.payload
      logger.log(`Open delete device dialog for the device ${id} - ${name}`)
      state.dialogs.deleteDevice.id = id
      state.dialogs.deleteDevice.name = name
    },

    setUpdateDeviceInfo: (state, action) => {
      const { id, name } = action.payload
      logger.log(`Open update device dialog for the device ${id} - ${name}`)
      state.dialogs.updateDevice.id = id
      state.dialogs.updateDevice.name = name
    },

    setEventsDeviceInfo: (state, action) => {
      const { id, name } = action.payload
      logger.log(`Open events device dialog for the device ${id} - ${name}`)
      state.dialogs.eventsDevice.id = id
      state.dialogs.eventsDevice.name = name
    },

    setActionsDeviceInfo: (state, action) => {
      const { id, name } = action.payload
      logger.log(`Open actions device dialog for the device ${id} - ${name}`)
      state.dialogs.actionsDevice.id = id
      state.dialogs.actionsDevice.name = name
    },

    setSendActionInfo: (state, action) => {
      const { id, name } = action.payload
      logger.log(`Open actions device dialog for the device ${id} - ${name}`)
      state.dialogs.sendAction.id = id
      state.dialogs.sendAction.name = name
    },

    setFromTo: (state, action) => {
      logger.log(`Set From To for time picker - ${action.payload}`)
      state.timePicker = action.payload
    },

    setCredentials: (state, action) => {
      logger.log('Set credentials object', state)
      state.credentials = action.payload
    },

    setCount: (state, action) => {
      const { target, value } = action.payload
      logger.log(`set ${value} for ${target} counter `)
      state.count[target] = value
    },
    setSearchDeviceModel: (state, action) => {
      const { value } = action.payload
      logger.log(`set search devicemodel `)
      state.dialogs.searchDevices.model = value
    },
    setSearchDevicesActiveTab: (state, action) => {
      logger.log(`set active tab in searchDevice `)
      state.dialogs.searchDevices.tab = action.payload
    },
    setErrorAlertMessage: (state, action) => {
      logger.log(`set errorAlert message`)
      state.dialogs.errorAlert.errorMessage = action.payload
    },
    logoutState: () => {
      logger.log('Clear credentials object')
      return initialState
    },
    changeOrgState: (state) => {
      logger.log('Clear credentials object')
      return { ...initialState, credentials: state.credentials }
    }
  }
})

export const {
  closeProfileMenu,
  openProfileMenu,
  setActionsDeviceInfo,
  setActiveTab,
  setCloseDialog,
  setCloseDrawer,
  setCount,
  setCredentials,
  setCurrentDevice,
  setCurrentOrganization,
  setDeleteDeviceInfo,
  setUpdateDeviceInfo,
  setDeviceActiveTab,
  setEventsDeviceInfo,
  setFiltersDefaultNear,
  setFiltersDefaultPosition,
  setSuggestions,
  setFromTo,
  setHomePaginator,
  setInvitationsAcceptedPaginator,
  setInvitationsActiveTab,
  setInvitationsPendingPaginator,
  setLanguage,
  setMapCenter,
  setMapDestination,
  setMapZoom,
  setOpenDialog,
  setOpenDrawer,
  setPaginator,
  setPaginatorSize,
  setSendActionInfo,
  setTheme,
  setSearchDeviceModel,
  setSearchDevicesActiveTab,
  setErrorAlertMessage,
  logoutState,
  changeOrgState
} = generalSlice.actions

export default generalSlice.reducer
