import { Box, Typography, Grid2 } from '@mui/material'
import React from 'react'
import { t } from 'i18next'

const ReviewActionAlarm = ({ actionName }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        marginY={2}
        spacing={2}
      >
        <Grid2 size={6} py={1}>
          <Typography variant="h5" align="right">{t('actions.actionName')}:</Typography>
        </Grid2>
        <Grid2 size={6} py={1}>
          <Typography sx={{ lineHeight: "1.2" }}>{actionName}</Typography>
        </Grid2>
      </Grid2>
    </Box >
  )
}

export default ReviewActionAlarm
