import { Box } from '@mui/material'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import LogoColor from '../../../images/cityeyedataviz.svg'
import LogoWhite from '../../../images/cityeyedatawhite.svg'

const Logo = () => {
  const { theme, currentOrganization } = useSelector(
    (state) => state.general,
    shallowEqual
  )

  const [found, setFound] = useState(true)
  const render = found

  return (
    <>
      {render && (
        <img
          src={`/api/logos/${currentOrganization}`}
          onError={() => setFound(false)}
          alt="Organization Logo"
          style={{ maxWidth: '100%', maxHeight: '70px' }}
        />
      )}
      {!render && theme === 'light' && (
        <Box
          component="img"
          sx={{ maxWidth: '100%', maxHeight: '70px' }}
          src={LogoColor}
        />
      )}
      {!render && theme === 'dark' && (
        <Box
          component="img"
          sx={{ maxWidth: '100%', maxHeight: '70px' }}
          src={LogoWhite}
        />
      )}
    </>
  )
}

export default Logo
