import { IconButton, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { CalendarMonthRounded as CalendarMonthRoundedIcon } from '@mui/icons-material'
import { t } from 'i18next'

const ButtonField = forwardRef((props, ref) => {
  const { inputProps: { 'aria-label': ariaLabel } = {}, setOpen, value } = props

  return (
    <Stack
      direction="row"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Typography
        variant="caption"
        onClick={() => setOpen?.((prev) => !prev)}
        sx={{
          cursor: 'pointer',
          textTransform: 'uppercase',
          textAlign: 'right'
        }}
      >
        <strong>{t('common.from')}: </strong> {value[0].format('DD.MM.yyyy')}
        <br />
        <strong>{t('common.to')}: </strong> {value[1].format('DD.MM.yyyy')}
      </Typography>
      <IconButton
        aria-label={ariaLabel}
        color="primary"
        onClick={() => setOpen?.((prev) => !prev)}
        ref={ref}
      >
        <CalendarMonthRoundedIcon fontSize="large" />
      </IconButton>
    </Stack>
  )
})

ButtonField.displayName = 'ButtonField'

ButtonField.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputProps: PropTypes.shape({
    'aria-label': PropTypes.string
  }),
  InputProps: PropTypes.shape({
    endAdornment: PropTypes.node,
    startAdornment: PropTypes.node
  }),
  value: PropTypes.array,
  setOpen: PropTypes.func
}

export default ButtonField
