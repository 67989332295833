import { Typography, Paper, Box } from '@mui/material'
import { ResponsiveLine } from '@nivo/line'
import PropTypes from 'prop-types'
import React from 'react'
import nivoDarkTeme from '../../themes/nivo-dark.js'
import nivoLightTheme from '../../themes/nivo-light.js'

const buildLineChartData = (id, data) => {
  return [
    {
      id,
      data
    }
  ]
}

let margin = { top: 5, right: 5, bottom: 80, left: 50 }

let yScale = {
  type: 'linear',
  min: 'auto',
  max: 'auto',
  stacked: true,
  reverse: false,
  clamp: true
}

let xScale = { format: '%Y-%m-%dT%H:%M:%S.%LZ', type: 'time' }

let buildAxisLeft = (unitOfMeasure) => {
  return {
    legend: unitOfMeasure,
    legendOffset: -40,
    legendPosition: 'middle'
  }
}

let axisBottom = {
  orient: 'bottom',
  tickSize: 5,
  tickPadding: 8,
  tickRotation: -90,
  format: '%d/%m %H:%M'
}

function tooltipGenerator(unitOfMeasure) {
  return function tooltip({ point }) {
    return (
      <Paper>
        <Box p={1}>
          <Typography variant="body2">
            <small>
              {point.data.xFormatted}
              <br />
              <strong>{point.data.yFormatted}</strong> {unitOfMeasure}
            </small>
          </Typography>
        </Box>
      </Paper>
    )
  }
}

const LineChart = ({ data, measureName, unitOfMeasure, theme, curve }) => {
  return (
    <ResponsiveLine
      data={buildLineChartData(measureName, data)}
      margin={margin}
      tooltip={tooltipGenerator(unitOfMeasure)}
      axisBottom={axisBottom}
      xScale={xScale}
      xFormat="time:%d/%m %H:%M"
      yScale={yScale}
      axisLeft={buildAxisLeft(unitOfMeasure)}
      useMesh={true}
      enableArea={true}
      enablePoints={true}
      pointLabel="y"
      pointSize={7}
      pointBorderWidth={1}
      pointBorderColor="#ff00dd"
      colors={{ scheme: 'paired' }}
      areaOpacity={0.5}
      crosshairType="x"
      theme={theme === 'dark' ? nivoDarkTeme : nivoLightTheme}
      curve={curve}
    />
  )
}

LineChart.propTypes = {
  data: PropTypes.array,
  dateRange: PropTypes.object,
  unitOfMeasure: PropTypes.string,
  measureName: PropTypes.string,
  title: PropTypes.string,
  loading: PropTypes.bool,
  curve: PropTypes.string,
  theme: PropTypes.string
}

export default LineChart
