import {
  Box,
  Grid2,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'
import { useSearchDownlinksQuery } from '../../../../store'
import SelectDevice from './SelectDevice'
import DynamicForm from '../../../Shared/Forms/DynamicForm'
import PropTypes from 'prop-types'
import Joi from 'joi'
import useFieldValidation from '../../../../helpers/fieldValidation'
import { useValidationEffect } from '../../../../helpers/hooks'
import InlineMessage from '../../../Shared/Components/InlineMessage'
import Show from '../../../Layout/Can/Show'

const schema = Joi.object({
  selectedDevice: Joi.object().required(),
  downlinkPayload: Joi.object({
    downlink: Joi.object().required(),
    name: Joi.string().required(),
    sendUplink: Joi.boolean().optional(),
    fields: Joi.any().optional()
  }).required()
})

const ConfigureActionDownlink = ({ onChange = () => { } }) => {
  const [downlinkPayload, setDownlinkPayload] = useState({})
  const [downlinkFields, setDownlinkFields] = useState({})
  const [selectedDevice, setSelectedDevice] = useState({
    brandEncoded: '',
    modelEncoded: ''
  })

  const [validation, setValidation] = useState({ isValid: false })

  const { data: downlinks = [], isLoading: isDownlinksLoading } =
    useSearchDownlinksQuery(
      {
        brand: selectedDevice?.brandEncoded,
        model: selectedDevice?.modelEncoded
      },
      {
        skip: !selectedDevice?.brandEncoded || !selectedDevice?.modelEncoded
      }
    )

  useValidationEffect(() => {
    const fieldValidation = useFieldValidation(
      { downlinkPayload, selectedDevice },
      schema
    )
    setValidation(fieldValidation)
    onChange(
      { downlink: downlinkPayload, selectedDevice: selectedDevice },
      fieldValidation.isValid
    )
  }, [downlinkPayload])

  const handleDownlinksChange = (event) => {
    const value = event.target.value
    setDownlinkPayload({
      ...downlinkPayload,
      downlink: value,
      sendUplink: value.downlinkAckByUplink
    })
  }

  const handleNameChange = (event) => {
    setDownlinkPayload({ ...downlinkPayload, name: event.target.value })
  }

  const handleDynamicFormChange = (value) => {
    setDownlinkFields(value)
    setDownlinkPayload({ ...downlinkPayload, fields: value })
  }

  const handleDeviceChange = (device) => {
    setSelectedDevice(device)
  }

  return (
    <Box>
      <Typography
        variant="h5"
        fontWeight="bold"
        marginY={5}
      >
        {t('actions.selectDevice')}
      </Typography>
      <Box marginBottom={10}>
        <SelectDevice
          onChange={handleDeviceChange}
          selectedDevice={selectedDevice}
        />
      </Box>

      {selectedDevice.modelEncoded !== '' && !downlinks.length && (
        <InlineMessage message={t('actions.noActionsForSelectedDevice')} />
      )}

      {selectedDevice.modelEncoded !== '' && downlinks.length > 0 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid2
            container
            spacing={2}
            marginBottom={5}
          >

            <Grid2 size={6}>
              <TextField
                id="configure-action-name"
                variant="standard"
                label={t('actions.actionName')}
                fullWidth
                onChange={handleNameChange}
                error={validation.messages?.name?.length > 0}
                helperText={validation.messages?.name?.join(', ')}
              />
            </Grid2>

            <Grid2
              size={6}
              display="flex"
              justifyContent="flex-start"
            >
              <FormControl fullWidth>
                <InputLabel id="downlink-select-label">
                  {t('actions.parameters')}
                </InputLabel>
                <Select
                  labelId="downlink-select-label"
                  id="configure-action-select"
                  value={downlinkPayload.downlink || ''}
                  label="Downlink"
                  onChange={handleDownlinksChange}
                  error={validation.messages?.downlink?.length > 0}
                >
                  {!isDownlinksLoading &&
                    downlinks.map((downlink, i) => (
                      <MenuItem
                        id={`configure-action-select-downlink-${i}`}
                        key={`downlink-${i}`}
                        value={downlink}
                      >
                        {downlink.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid2>

            <Grid2
              size={12}
              display="flex"
              justifyContent="flex-start"
            >
              <Show when={() => downlinkPayload.downlink?.downlinkAckByUplink}>
                <FormControlLabel
                  fullWidth
                  control={
                    <Checkbox
                      disabled
                      value={downlinkPayload.downlink?.downlinkAckByUplink}
                    />
                  }
                  label={t('actions.sendUplink')}
                />
              </Show>
            </Grid2>

            <Grid2
              size={12}
              display="flex"
              justifyContent="flex-start"
            >
              {downlinkPayload.downlink && (
                <DynamicForm
                  fieldsPayload={downlinkFields}
                  fields={downlinkPayload.downlink.fields}
                  onChange={handleDynamicFormChange}
                />
              )}
            </Grid2>
          </Grid2>
        </Box>
      )}
    </Box>
  )
}

ConfigureActionDownlink.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default ConfigureActionDownlink
