import {
  Button,
  Chip,
  Container,
  Grid2,
  Stack,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import UpdateUser from '../Dialogs/UpdateUser.jsx'
import UserCard from '../Card/UserCard.jsx'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useSearchUsersQuery } from '../../../store/index.js'
import InviteUser from '../../Invites/Dialogs/InviteUser.jsx'
import UserFilters from '../Filters/Filters.jsx'
import ConfirmWithCheckDialog from '../../Shared/Dialogs/ConfirmWithCheckDialog.jsx'
import { DeleteRounded as DeleteRoundedIcon } from '@mui/icons-material'
import {
  useDeleteUserMutation,
  useLazyFetchUserQuery
} from '../../../store/apis/users-api.js'
import { useParams, useSearchParams } from 'react-router-dom'
import ZList from '../../Shared/Components/ZList.jsx'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert.js'

const DEFAULT_PAGE_SIZE = 25

const UsersList = () => {
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')

  const { entityId } = useParams()

  const [filters, setFilters] = useState({})
  const [confirmDialogData, setConfirmDialogData] = useState(null)
  const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false)
  const [isConfirmWithCheckOpen, setIsConfirmWithCheckOpen] = useState(false)
  const [isUpdateUserDialogOpen, setIsUpdateUserDialogOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const [page, setPage] = useState(1)

  const {
    data: users,
    isError: isFetchUsersError,
    isLoading: isFetchUsersLoading,
    refetch
  } = useSearchUsersQuery({
    filters: { ...filters, fullText },
    page,
    pageSize: DEFAULT_PAGE_SIZE
  })

  const [
    deleteUser,
    {
      isSuccess: isDeleteUserSuccess,
      isError: isDeleteUserError,
      error: deleteUserError
    }
  ] = useDeleteUserMutation()

  const [getUser, userFromUrl] = useLazyFetchUserQuery()

  useOpenResultAlert(deleteUserError, { isError: isDeleteUserError, isSuccess: isDeleteUserSuccess })

  useEffect(() => {
    if (!entityId) {
      return
    }

    getUser(entityId, false)
  }, [entityId])

  useEffect(() => {
    if (userFromUrl.data !== undefined) {
      setSelectedUser(userFromUrl.data)
      setIsUpdateUserDialogOpen(true)
    }
  }, [userFromUrl])

  useEffect(() => {
    if (!fullText) return
    refetch()
  }, [fullText])

  const handleChangePage = (_, value) => setPage(value)

  const handleInviteUser = () => setIsInviteUserDialogOpen(true)

  const handleOnEdit = (user) => {
    setSelectedUser(user)
    setIsUpdateUserDialogOpen(true)
  }

  const handleCloseUpdateUser = () => {
    setIsUpdateUserDialogOpen(false)
    setSelectedUser(false)
  }

  const openDeleteDialog = (user) => {
    setConfirmDialogData({
      icon: (
        <DeleteRoundedIcon
          size={60}
          color="#ef5350"
        />
      ),
      questionText: t('users.doYouWantToDeleteTheUser') + '?',
      subQuestionText: `${user.firstName} ${user.lastName}`,
      copyText: user.uid,
      bodyText: t('users.confirmUserKeyToProceed'),
      inputSuggestionText: t('users.pasteUserKey')
    })
    setIsConfirmWithCheckOpen(true)
  }

  const handleCloseConfigrWithCheck = () => setIsConfirmWithCheckOpen(false)

  const handleFiltersChange = (filters) => setFilters(filters)
  
  const handleConfirmAction = () => {
    deleteUser({ uid: user.uid, organizationKey: user.organization.key })
    setIsConfirmWithCheckOpen(false)
  }

  const usersList = users?.data.map((user, i) => <UserCard
        index={i}
        key={`user-${i}`}
        user={user}
        onDelete={openDeleteDialog}
        onEdit={handleOnEdit}
      />
  )

  const usersFilters = <UserFilters onChange={handleFiltersChange} filters={filters} />

  return (
      <Container
        maxWidth="lg"
        sx={{ py: 3 }}
      >
        <Grid2
          container
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid2>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Typography variant="h2">{t('common.users')}</Typography>
              <Chip
                label={users?.count}
                color="primary"
              />
            </Stack>
          </Grid2>
          <Button
            variant="contained"
            color="primary"
            onClick={handleInviteUser}
          >
            {t('invites.add')}
          </Button>
        </Grid2>

        <ZList count={users?.count} entities={usersList} filters={usersFilters} page={page} onPageChange={handleChangePage} isLoading={isFetchUsersError || isFetchUsersLoading} />
        
        <InviteUser
          isOpen={isInviteUserDialogOpen}
          setIsOpen={setIsInviteUserDialogOpen}
        />
        <UpdateUser isOpen={isUpdateUserDialogOpen} onClose={handleCloseUpdateUser} selectedUser={selectedUser} />

        {confirmDialogData && (
          <ConfirmWithCheckDialog
            isOpen={isConfirmWithCheckOpen}
            onClose={handleCloseConfigrWithCheck}
            onConfirmAction={handleConfirmAction}
            icon={confirmDialogData.icon}
            questionText={confirmDialogData.questionText}
            subQuestionText={confirmDialogData.subQuestionText}
            copyText={confirmDialogData.copyText}
            bodyText={confirmDialogData.bodyText}
            inputSuggestionText={confirmDialogData.inputSuggestionText}
          />
        )}
      </Container >    
  )
}

export default UsersList
