import { Box, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useProvisioningSetupValidation from '../../../helpers/provisioningSetupValidation.js'
import DynamicInput from './DynamicInput.jsx'
import PropTypes from 'prop-types'
import useFieldValidation from '../../../helpers/fieldValidation.js'

const DynamicForm = ({ fields, fieldsPayload, onChange = () => {} }) => {
  const [validation, setValidation] = useState({ isValid: false })
  const [dynamicFormState, setDynamicFormState] = useState(fieldsPayload)

  useEffect(() => {
    const validationSchema = useProvisioningSetupValidation(fields)
    const schemaValidation = useFieldValidation(
      dynamicFormState,
      validationSchema
    )
    setValidation(schemaValidation)
    onChange(dynamicFormState, schemaValidation.isValid)
  }, [dynamicFormState])

  const handleOnDynamicInputChange = ({ internalName, value }) => setDynamicFormState({ ...fieldsPayload, [internalName]: value })

  return (
    <Box sx={{ marginTop: '10px', width: '100%' }}>
      {(fields || [])?.map((field, index) => (
        <Stack
          sx={{ width: '100%' }}
          direction="row"
          key={`${field.internalName}-${field.type}-${index}`}
        >
          <DynamicInput
            field={field}
            value={fieldsPayload ? fieldsPayload[field.internalName] : null}
            onChange={handleOnDynamicInputChange}
            validation={validation}
          />
        </Stack>
      ))}
    </Box>
  )
}

DynamicForm.propTypes = {
  fields: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  fieldsPayload: PropTypes.object.isRequired
}

export default DynamicForm
