import { createSlice } from '@reduxjs/toolkit'
import { logger } from '../../helpers/logger/logger.js'
import { filters } from '../states'

const initialState = filters

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFiltersDevices: (state, action) => {
      logger.log('set devices filters ', action.payload)
      state.devices = { ...state.devices, ...action.payload }
    },
    setFiltersOrganizations: (state, action) => {
      logger.log('set organizations filters ', action.payload)
      state.organizations = { ...state.organizations, ...action.payload }
    },
    setFiltersEvents: (state, action) => {
      logger.log('set events filters', action.payload)
      state.events = { ...state.events, ...action.payload }
    },
    setFiltersGateways: (state, action) => {
      logger.log('set gateways filters', action.payload)
      state.gateways = { ...state.gateways, ...action.payload }
    },
    setFiltersTriggers: (state, action) => {
      logger.log('set triggers filters ', action.payload)
      state.triggers = { ...state.triggers, ...action.payload }
    },
    setFiltersActions: (state, action) => {
      logger.log('set actions filters ', action.payload)
      state.actions = { ...state.actions, ...action.payload }
    },
    setFiltersRules: (state, action) => {
      logger.log('set rules filters ', action.payload)
      state.rules = { ...state.rules, ...action.payload }
    },
    clearFilters: () => {
      logger.log('Clear filters')
      return { ...initialState }
    }
  }
})

export const {
  setFiltersDevices,
  setFiltersEvents,
  setFiltersGateways,
  setFiltersTriggers,
  setFiltersActions,
  setFiltersRules,
  clearFilters,
  setFiltersOrganizations
} = filtersSlice.actions

export default filtersSlice.reducer
