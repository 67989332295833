import moment from 'moment-timezone'
import React from 'react'
import { t } from 'i18next'
import { Typography } from '@mui/material'

const EventDuration = ({ start, end }) => {
  const startDate = moment(start)
  const endDate = moment(end)

  const duration = parseInt(endDate.diff(startDate, 'seconds'))
  let translation

  if (duration > 0 && duration <= 60) {
    translation = { string: 'common.second', count: duration }
  } else if (duration > 60 && duration <= 3600) {
    translation = {
      string: 'common.minute',
      count: Math.round(duration / 60)
    }
  } else if (duration > 3600 && duration <= 86400) {
    translation = {
      string: 'common.hour',
      count: Math.round(duration / 3600)
    }
  } else {
    translation = {
      string: 'common.day',
      count: Math.round(duration / 86400)
    }
  }

  return (
    <Typography variant="body2">
      <small>{t(translation.string, { count: translation.count })}</small>
    </Typography>
  )
}

export default EventDuration
