import {
  Autocomplete,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  Container,
  ListItem,
  ListItemText
} from '@mui/material'
import React, { useEffect } from 'react'
import { t } from 'i18next'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'
import { useSearchTriggersMutation } from '../../../store/apis/triggers-api'
import PropTypes from 'prop-types'

const TriggerSelector = ({ isOpen, onClose, onChange, organization }) => {
  const [searchTriggers, { data: triggers, isLoading: isTriggersLoading }] =
    useSearchTriggersMutation({ page: 1, pageSize: 100 })

  const handleClose = () => {
    onClose()
  }

  const handleChangeSelectedTrigger = (_event, value) => {
    onChange(value)
    onClose()
  }

  useEffect(() => {
    if (organization) {
      const filters = { organizationKeys: [organization.key] }
      searchTriggers({ page: 1, pageSize: 100, filters })
    }
  }, [organization])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      scroll="body"
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle
        display="flex"
        justifyContent="center"
        variant="h4"
      >
        {t('rules.selectTrigger')}
      </DialogTitle>

      <CloseDialogButton onClose={handleClose} />

      <DialogContent
        style={{
          paddingTop: '30px',
          minHeight: '15vh',
          maxHeight: '15vh'
        }}
      >
        <Container maxWidth="md">
          <Autocomplete
            id="trigger-select"
            loading={isTriggersLoading}
            options={triggers?.data.filter((t) => !t.rules.length) || []}
            label="Trigger"
            onChange={handleChangeSelectedTrigger}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                name="trigger-select-label"
                {...params}
                label={t('rules.selectTrigger')}
                variant="standard"
              />
            )}
            renderOption={(props, option) => (
              <ListItem
                {...props}
                divider
                key={option.id}
              >
                <ListItemText
                  primary={option.name}
                  secondary={option.organizationKey}
                />
              </ListItem>
            )}
          />
        </Container>
      </DialogContent>
    </Dialog>
  )
}

TriggerSelector.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onChange: PropTypes.func,
  organization: PropTypes.object
}

export default TriggerSelector
