import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import WeekDay from './WeekDay'
import TimeField from 'react-simple-timefield'
import { t } from 'i18next'
import Show from '../../../Layout/Can/Show'
import PropTypes from 'prop-types'

const Scheduler = ({ onChange, defaultScheduler, hideEndHour }) => {
  const [startHour, setStartHour] = useState('00:00')
  const [endHour, setEndHour] = useState('23:59')

  const [scheduler, setScheduler] = useState(defaultScheduler)

  useEffect(() => {
    onChange(scheduler)
  }, [scheduler])

  const handleChangeStartHour = (event) => {
    const {
      target: { value }
    } = event

    setStartHour(value)
  }

  const handleChangeEndHour = (event) => {
    const {
      target: { value }
    } = event

    setEndHour(value)
  }

  const handleReset = () => {
    const newScheduler = {}
    Object.keys(scheduler).map(
      (key) => (newScheduler[key] = { selected: false, timeSlots: [] })
    )
    setScheduler(newScheduler)
  }

  const handleApply = () => {
    const newScheduler = {}
    Object.keys(scheduler).map(
      (key) =>
      (newScheduler[key] = {
        selected: true,
        timeSlots: [!hideEndHour ? { startHour, endHour } : { startHour }]
      })
    )
    setScheduler(newScheduler)
  }

  const handleWeekDayChange = (newScheduler) => {
    setScheduler(newScheduler)
  }

  return (
    <>
      <Stack
        p={3}
        mb={5}
        justifyContent="space-between"
      >
        <Box>
          <Stack
            direction="row"
            spacing={3}
            alignItems={'center'}
            justifyContent="space-between"
          >
            <Typography
              variant="h5"
              mb={5}
            >
              {t('triggers.everyday')}
            </Typography>
            <Stack
              direction="column"
              spacing={3}
              alignItems={'center'}
              justifyContent="space-between"
            >
              <Stack
                key={`time-slot-all`}
                direction="row"
                spacing={3}
                alignItems={'center'}
                justifyContent="space-between"
              >
                <TimeField
                  id="automations-form-trigger-scheduler-time-field-all-days"
                  value={'00:00'}
                  onChange={handleChangeStartHour}
                  input={
                    <TextField
                      value={'00:00'}
                      type="text"
                      label={
                        !hideEndHour ? t('common.fromHour') : t('common.hours')
                      }
                    />
                  }
                />
                <Show when={() => !hideEndHour}>
                  <TimeField
                    value={'23:59'}
                    onChange={handleChangeEndHour}
                    input={
                      <TextField
                        value={'23:59'}
                        type="text"
                        label={t('common.toHour')}
                      />
                    }
                  />
                </Show>
              </Stack>
            </Stack>
            <Button
              id="automations-form-trigger-scheduler-reset-all-days"
              onClick={handleReset}
            >{t('common.reset')}</Button>
            <Button
              id="automations-form-trigger-scheduler-apply-all-days"
              onClick={handleApply}
            >{t('triggers.applyTime')}</Button>
          </Stack>
          <Divider
            orientation="horizontal"
            sx={{ my: 3 }}
          />
        </Box>
        {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => 
          <WeekDay
            hideEndHour={hideEndHour}
            scheduler={scheduler}
            onChange={handleWeekDayChange}
            name={day}
          />
        )}
      </Stack>
    </>
  )
}

Scheduler.propTypes = {
  onChange: PropTypes.func,
  defaultScheduler: PropTypes.object,
  hideEndHour: PropTypes.bool
}

export default Scheduler
