import {
  Autocomplete,
  CircularProgress,
  Icon,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField
} from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { CorporateFareRounded as CorporateFareRoundedIcon } from '@mui/icons-material'
import { t } from 'i18next'
import { useFetchOrganizationsFilterQuery } from '../../../../store'

const FiltersOrganizations = ({ onChange = () => { }, filters }) => {
  const {
    data: organizationFilterOptions = [],
    isError: isOrganizationFilterError,
    isLoading: isOrganizationFilterLoading
  } = useFetchOrganizationsFilterQuery()

  const handleChange = (_, value) => {
    const organizationKeys = value.length ? value.map((i) => i.key) : undefined
    onChange({ organizationKeys })
  }

  const { organizationKeys = [] } = filters

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Autocomplete
          multiple
          disabled={isOrganizationFilterError || isOrganizationFilterLoading}
          id="autocomplete-filter-organizations"
          options={organizationFilterOptions}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          loading={isOrganizationFilterLoading}
          getOptionLabel={(option) => option.value}
          value={organizationFilterOptions.filter(({ key }) =>
            organizationKeys.includes(key)
          )}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              divider
              key={option.key}
            >
              <ListItemIcon>
                <CorporateFareRoundedIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: '0.8rem',
                  fontWeight: 500
                }}
                primary={option.value}
              />
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('filters.devices.organizations.placeholder')}
              slotProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      color="inherit"
                      edge="end"
                    >
                      {isOrganizationFilterLoading && (
                        <CircularProgress
                          edge="end"
                          size={20}
                        />
                      )}
                    </Icon>
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </Stack>
    </>
  )
}

FiltersOrganizations.propTypes = {
  onChange: PropTypes.func,
  filters: PropTypes.object
}

export default FiltersOrganizations
