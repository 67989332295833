import {
  Autocomplete,
  CircularProgress,
  Icon,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField
} from '@mui/material'
import React from 'react'
import { CorporateFareRounded as CorporateFareRoundedIcon } from '@mui/icons-material'
import { t } from 'i18next'
import { useFetchRolesStandardQuery } from '../../../../store'

const RolesFilter = ({ onChange = () => { }, filters }) => {
  const {
    data: roles = [],
    isError: isFetchRolesError,
    isLoading: isFetchRolesLoading
  } = useFetchRolesStandardQuery()

  const handleChange = (_, value) => {
    const roleIds = value.map((i) => i.key)
    const rolesIdsFilter = roleIds.length > 0 ? { roleIds: roleIds } : { roleIds: undefined }
    onChange({ ...filters, ...rolesIdsFilter })
  }
  const { roleIds = [] } = filters

  return (
    <>
      <Autocomplete
        multiple
        disabled={isFetchRolesError || isFetchRolesLoading}
        id="roles-filter"
        options={roles.map((role) => ({ key: role.id, value: role.name}))}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        loading={isFetchRolesLoading}
        getOptionLabel={(option) => option.value}
        value={roles.filter(({ id }) => roleIds.includes(id)).map((role) => ({ key: role.id, value: role.name}))}
        renderOption={(props, option) => (
          <ListItem
            {...props}
            divider
            key={props.key}
          >
            <ListItemIcon>
              <CorporateFareRoundedIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: '0.8rem',
                fontWeight: 500
              }}
              primary={option.value}
            />
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t('filters.roles.placeholder')}
            slotProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    color="inherit"
                    edge="end"
                  >
                    {isFetchRolesLoading && (
                      <CircularProgress
                        edge="end"
                        size={20}
                      />
                    )}
                  </Icon>
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </>
  )
}

export default RolesFilter
