import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { useFetchPlacesMutation } from '../../../../store'
import { setSuggestions } from '../../../../store/slices/general.js'
import { isEqual } from 'lodash'

const filterOptions = (option) => option

const AddressFilter = ({ data = {}, onChange = () => { } }) => {
  const dispatch = useDispatch()

  const [position, setPosition] = useState({
    address: data?.address || null,
    latitude: null,
    longitude: null
  })

  const { filters: { position: { suggestions = [] } = {} } = {} } = useSelector(
    (state) => state.general
  )

  const [fetchPlaces, { data: places = [], isLoading: isPlacesLoading }] =
    useFetchPlacesMutation()

  const prevSuggestions = useRef([])

  useEffect(() => {
    onChange(position)
  }, [position])

  const onInputChange = (_, value, reason) => {
    switch (reason) {
      case 'clear':
        setPosition({
          address: null,
          latitude: null,
          longitude: null
        })
        dispatch(setSuggestions([]))
        prevSuggestions.current = []
        break
      default:
        if (value?.length > 3) {
          fetchPlaces(value)
        }
        break
    }
  }

  useEffect(() => {
    if (!isPlacesLoading) {
      if (!isEqual(places, prevSuggestions.current)) {
        prevSuggestions.current = places
        dispatch(setSuggestions(places))
      }
    }
  }, [places])

  const onChangeAddress = (_, value) => {
    if (value) {
      const {
        address = '',
        lat: latitude = null,
        lng: longitude = null
      } = value

      setPosition({
        address,
        latitude,
        longitude
      })
    }
  }

  return (
    <>
      <Autocomplete
        id="address-field"
        filterOptions={filterOptions}
        onChange={onChangeAddress}
        onInputChange={onInputChange}
        options={suggestions}
        getOptionLabel={(option) => option.address || position.address}
        isOptionEqualToValue={(option, value) => option.address === value}
        value={position?.address}
        loading={isPlacesLoading}
        clearOnBlur={true}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('common.address')}
            variant="standard"
            slotProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isPlacesLoading && (
                    <CircularProgress
                      color="inherit"
                      size={20}
                    />
                  )}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </>
  )
}

AddressFilter.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func
}

export default AddressFilter
