import { createSlice } from '@reduxjs/toolkit'
import { logger } from '../../helpers/logger/logger.js'
import { users } from '../states'

const initialState = users

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      logger.log('set users filters ', action.payload)
      state.filters = { ...state.filters, ...action.payload }
    }
  }
})

export const { setFilters: setFilters } = usersSlice.actions

export default usersSlice.reducer
