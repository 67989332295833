import { Box, Typography, Stack, Button } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logoutState } from '../../../store/slices/general'
import {
  useChangeEmailMutation,
  useGetEmailChangeRequestQuery
} from '../../../store/apis/user-profile-api'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert'

const DeleteUser = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { changeId } = useParams()

  const { data: emailChangeRequest } = useGetEmailChangeRequestQuery({
    requestId: changeId
  })

  const [
    changeEmail,
    { isError: isChangeEmailError, isSuccess: isChangeEmailSuccess }
  ] = useChangeEmailMutation()

  const handleBackToProfile = () => navigate('/')

  const handleConfirmEmailChange = () => changeEmail({
      requestId: changeId,
      payload: { email: emailChangeRequest.newEmail }
    })

  useOpenResultAlert({ error: 'Errore durante il cambio email' }, { isError: isChangeEmailError, isSuccess: isChangeEmailSuccess })

  useEffect(() => {
    if (isChangeEmailSuccess) {
      dispatch(logoutState())
      navigate('/')
    }
  }, [isChangeEmailSuccess])

  return (
    <>
      <Box
        marginTop={'66px'}
        height={'71vh'}
        width={'100%'}
        alignContent={'center'}
      >
        <Box
          marginTop={5}
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Typography variant="h1">CAMBIA INDRIZZO E-MAIL</Typography>
        </Box>
        <Box
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}
          marginTop={10}
        >
          <Stack
            display={'flex'}
            width={'30%'}
            alignContent={'center'}
            justifyContent={'center'}
            spacing={3}
          >
            <Typography textAlign={'center'}>
              Email precedente: {emailChangeRequest?.oldEmail}
            </Typography>
            <Typography textAlign={'center'}>
              Nuova email: {emailChangeRequest?.newEmail}
            </Typography>
            <Button
              variant="contained"
              onClick={handleConfirmEmailChange}
            >
              Conferma
            </Button>
            <Button
              variant="outlined"
              onClick={handleBackToProfile}
            >
              Indietro
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  )
}

export default DeleteUser
