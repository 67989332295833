import React from 'react'
import PropTypes from 'prop-types'

const Show = ({ children, when = () => false }) => {
  if (!when()) {
    return <></>
  }

  return <>{children}</>
}

Show.propTypes = {
  children: PropTypes.node,
  when: PropTypes.func
}

export default Show
