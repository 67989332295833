import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { logger } from '../../helpers/logger/logger.js'
import { geolocation } from '../states'

const initialState = geolocation

export const positionBrowser = createAsyncThunk(
  'geolocation/retrieve',
  async () => {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject)
    })

    const { coords = {} } = position
    const { latitude = null, longitude = null } = coords

    return { latitude, longitude }
  }
)

const Slice = createSlice({
  name: 'position',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(positionBrowser.fulfilled, (state, action) => {
        logger.log('RTK - Retrieve browser position')
        state.data = action.payload
        state.status = 'successful'
      })
      .addCase(positionBrowser.pending, (state) => {
        logger.log('retrieve browser position: pending')
        state.status = 'pending'
      })
      .addCase(positionBrowser.rejected, (state) => {
        logger.log('retrieve browser position: error')
        state.error = action.error.message
        state.status = 'failed'
      })
  }
})

export default Slice.reducer
