import { Stack } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers-pro'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import React from 'react'
import PropTypes from 'prop-types'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from 'dayjs'
import { t } from 'i18next'

const DateRangeFilter = ({
  data = {
    fromTo: {
      from: dayjs(),
      to: dayjs().add(-8, 'day')
    }
  },
  onChange = () => { }
}) => {
  const handleOnDateFromChange = (value) => {
    onChange({ fromTo: { ...data.fromTo, from: value.toISOString() } })
  }

  const handleOnDateToChange = (value) => {
    onChange({ fromTo: { ...data.fromTo, to: value.toISOString() } })
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="left"
          spacing={2}
          mt={3}
          mb={3}
        >
          <DatePicker
            label={t('events.dateFrom')}
            format="DD/MM/YYYY"
            onChange={handleOnDateFromChange}
            defaultValue={dayjs(data.fromTo.from)}
            slotProps={{
              textField: {
                id: 'events-filter-date-from',
              },
            }}
          />
          <DatePicker
            label={t('events.dateTo')}
            format="DD/MM/YYYY"
            onChange={handleOnDateToChange}
            defaultValue={dayjs(data.fromTo.to)}
            slotProps={{
              textField: {
                id: 'events-filter-date-to',
              },
            }}
          />
        </Stack>
      </LocalizationProvider>
    </>
  )
}

DateRangeFilter.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func
}

export default DateRangeFilter
