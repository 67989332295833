import { Divider } from '@mui/material'
import React from 'react'
import {
  Accessibility,
  Brands,
  Models,
  State,
  Tags,
  Visibility
} from '../Fields/index.js'

const FormFilters = () => {
  return (
    <>
      <State />
      <Divider />
      <Visibility />
      <Divider />
      <Accessibility />
      <Divider />
      <Brands />
      <Divider hidden={true} />
      <Models />
      <Divider hidden={true} />
      <Tags />
    </>
  )
}

export default FormFilters
