import { createSlice } from '@reduxjs/toolkit'
import { logger } from '../../helpers/logger/logger.js'
import { ruleAdd } from '../states'

const initialState = ruleAdd

const ruleAddSlice = createSlice({
  name: 'ruleAdd',
  initialState,
  reducers: {
    setName: (state, action) => {
      logger.log('ruleAdd: set name')
      state.rule.name = action.payload
    },
    setCurrentRule: (state, action) => {
      logger.log('ruleAdd: set current rule')
      state.rule = action.payload
    },
    setTrigger: (state, action) => {
      logger.log('ruleAdd: set trigger')
      state.rule.trigger = action.payload
    },
    addAction: (state, action) => {
      logger.log('ruleAdd: set actions')
      state.rule.actions.push(action.payload)
    },
    removeAction: (state, action) => {
      logger.log('ruleAdd: remove action')
      const newActions = state.rule.actions.slice()
      newActions.splice(action.payload, 1)
      state.rule.actions = newActions
    },
    removeTrigger: (state) => {
      logger.log('ruleAdd: remove trigger')
      state.rule.trigger = null
    },
    setEnabled: (state, action) => {
      logger.log('ruleAdd: set enabled')
      state.rule.enabled = action.payload
    },
    resetRulePayload: () => {
      logger.log('ruleAdd: reset to initial state')
      return initialState
    }
  }
})

export const {
  setName,
  setTrigger,
  addAction,
  resetRulePayload,
  setEnabled,
  removeAction,
  removeTrigger,
  setCurrentRule
} = ruleAddSlice.actions

export default ruleAddSlice.reducer
