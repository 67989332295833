import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Automations from './components/Automations/Automations.jsx'
import Devices from './components/Devices/Pages/Management.jsx'
import Events from './components/Events/Pages/Management.jsx'
import Gateways from './components/Gateways/Pages/Management.jsx'
import Invites from './components/Invites/Pages/Invites.jsx'
import AcceptInvite from './components/Invites/Pages/AcceptInvite.jsx'
import MainLayout from './components/Layout/MainLayout.jsx' //QUI
import FrontPageTemplate from './components/Layout/Templates/FrontPage.jsx'
import ManagementTemplate from './components/Layout/Templates/Management.jsx'
import ResetPasswordTemplate from './components/Layout/Templates/ResetPassword.jsx'
import ChangeEmail from './components/Users/Page/ChangeEmail.jsx'
import InvitesTemplate from './components/Layout/Templates/Invites.jsx'
import NoMatch from './components/Layout/Templates/NoMatch.jsx'
import Organizations from './components/Organizations/Pages/Organizations.jsx'
import Roles from './components/Roles/Pages/Management.jsx'
import UsersList from './components/Users/Page/UsersList.jsx'
import UserProfile from './components/Users/Page/UserProfile.jsx'
import ProtectedRoute from './components/Layout/ProtectedRoute/ProtectedRoute.jsx'
import { CircularProgress, Box, Stack, Button, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import { organizationsApi } from './store/apis/organizations-api.js'
import { eventsApi } from './store/apis/events-api.js'
import { invitesApi } from './store/apis/invites-api.js'
import { actionsApi } from './store/apis/actions-api.js'
import { acceptInviteApi } from './store/apis/accept-invite.js'
import { rulesApi } from './store/apis/rules-api.js'
import { triggersApi } from './store/apis/triggers-api.js'
import { filtersApi } from './store/apis/filters-api.js'
import { usersApi } from './store/apis/users-api.js'
import { devicesApi } from './store/apis/devices-api.js'

import { clearFilters } from './store/slices/filters.js'
import InlineMessage from './components/Shared/Components/InlineMessage.jsx'
import { LicenseInfo } from '@mui/x-license'
import { MUI_LICENSE } from './configs/environment.js'
import DeleteUser from './components/Layout/Templates/DeleteUser.jsx'

const App = () => {
  const dispatch = useDispatch()
  LicenseInfo.setLicenseKey(MUI_LICENSE)

  return (
    <Suspense
      fallback={
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
          <Box>
            <Typography>Loading ...</Typography>
          </Box>
        </Box>
      }
    >
      <ErrorBoundary
        onError={() => {
          dispatch(clearFilters())
          dispatch(organizationsApi.util.resetApiState())
          dispatch(eventsApi.util.resetApiState())
          dispatch(invitesApi.util.resetApiState())
          dispatch(acceptInviteApi.util.resetApiState())
          dispatch(actionsApi.util.resetApiState())
          dispatch(rulesApi.util.resetApiState())
          dispatch(triggersApi.util.resetApiState())
          dispatch(filtersApi.util.resetApiState())
          dispatch(usersApi.util.resetApiState())
          dispatch(devicesApi.util.resetApiState())
        }}
        fallback={
          <Stack
            alignContent={'center'}
            alignItems={'center'}
          >
            <InlineMessage message={'Ops!!!Something went wrong!!!'} />
            <Button
              size="small"
              sx={{ height: '38px' }}
              component="label"
              variant="contained"
              onClick={() => (window.location.href = '/')}
            >
              Click to reload the page
            </Button>
          </Stack>
        }
      >
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<MainLayout />}
            >
              <Route
                index
                element={<FrontPageTemplate />}
              />
              <Route
                path="*"
                element={<NoMatch />}
              />
            </Route>

            <Route
              path="/public"
              element={<MainLayout />}
            >
              <Route
                path=":organizationKey"
                element={<FrontPageTemplate />}
              />
            </Route>

            <Route
              path="/invites"
              element={<MainLayout />}
            >
              <Route
                path=":inviteId"
                element={<InvitesTemplate main={<AcceptInvite />} />}
              />
            </Route>

            <Route
              path="/detail"
              element={<MainLayout />}
            >
              <Route
                path=":deviceId"
                element={<FrontPageTemplate />}
              />
            </Route>

            <Route
              path="/password-reset"
              element={<MainLayout />}
            >
              <Route
                path=":requestId"
                element={<ResetPasswordTemplate />}
              />
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route
                path="/delete-account"
                element={<MainLayout />}
              >
                <Route
                  index
                  element={<DeleteUser />}
                />
              </Route>

              <Route
                path="/email-confirmation/:changeId"
                element={<MainLayout />}
              >
                <Route
                  index
                  element={<ChangeEmail />}
                />
              </Route>
              <Route
                path="/management"
                element={<MainLayout />}
              >
                <Route
                  path="organizations"
                  element={<ManagementTemplate main={<Organizations />} />}
                />
                <Route
                  path="organizations/:organizationId"
                  element={<ManagementTemplate main={<Organizations />} />}
                />
                <Route
                  path="profile"
                  element={<ManagementTemplate main={<UserProfile />} />}
                />
                <Route
                  path="users"
                  element={<ManagementTemplate main={<UsersList />} />}
                />
                <Route
                  path="users/:entityId"
                  element={<ManagementTemplate main={<UsersList />} />}
                />
                <Route
                  path="events"
                  element={<ManagementTemplate main={<Events />} />}
                />
                <Route
                  path="gateways"
                  element={<ManagementTemplate main={<Gateways />} />}
                />
                <Route
                  path="invites"
                  element={<ManagementTemplate main={<Invites />} />}
                />
                <Route
                  path="roles"
                  element={<ManagementTemplate main={<Roles />} />}
                />
                <Route
                  path="devices"
                  element={<ManagementTemplate main={<Devices />} />}
                />
                <Route
                  path="automations"
                  element={<ManagementTemplate main={<Automations />} />}
                />
                <Route
                  path="automations/:tab"
                  element={<ManagementTemplate main={<Automations />} />}
                />
                <Route
                  path="automations/:tab/:entityId"
                  element={<ManagementTemplate main={<Automations />} />}
                />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </Suspense>
  )
}

export default App
