import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import {
  useFetchBrandsQuery,
  useFetchModelsMutation,
  useFetchNetworkServersQuery,
  useFetchSelfOrganizationsQuery
} from '../../../store/index.js'
import useFieldValidation from '../../../helpers/fieldValidation.js'
import Joi from 'joi'
import { downloadImportTemplate } from '../../../helpers/download.js'
import { useImportDevicesMutation } from '../../../store/apis/devices-api.js'
import DeviceSummary from '../Form/DeviceSummary.jsx'
import PropTypes from 'prop-types'
import { useValidationEffect } from '../../../helpers/hooks.js'
import { QueryStatus } from '@reduxjs/toolkit/query'
import {
  UploadRounded as UploadRoundedIcon,
  CloudUploadRounded as CloudUploadRoundedIcon
} from '@mui/icons-material'
import Show from '../../Layout/Can/Show.jsx'
import InlineMessage from '../../Shared/Components/InlineMessage.jsx'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert.js'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import ZCard from '../../Shared/Components/ZCard.jsx'

const schema = Joi.object({
  brand: Joi.object().empty(null).required(),
  model: Joi.object().empty(null).required(),
  organizationKey: Joi.string().required(),
  sourceType: Joi.string().required(),
  networkServerId: Joi.string().when('sourceType', {
    is: 'lorawan',
    then: Joi.required(),
    otherwise: Joi.allow(null)
  })
})

const importDevicesErrorFunction = (importData) => {
  if (!importData || !importData.data) return

  const { keys } = importData.data

  const errorMessage = keys.length ? keys[0] : null

  if (!errorMessage) return

  if (errorMessage.split('/').length === 1) {
    return errorMessage
  }

  if (
    errorMessage === 'file/empty' ||
    errorMessage === 'organizationKey/thresholdsExceeded'
  ) {
    return errorMessage === 'file/empty'
      ? t(`devices.importDevicesForm.form.errors.fileEmpty`)
      : t(`devices.importDevicesForm.form.errors.thresholdsExceeded`)
  } else {
    const groupedErrors = keys.reduce((acc, error) => {
      const devEui = error.split('_')[1].trim()
      const errorParts = error.split('/')
      const field = errorParts[1]
      const reason =
        errorParts.length > 3
          ? t(`devices.importDevicesForm.form.errors.${errorParts[2]}`)
          : t(`devices.importDevicesForm.form.errors.notValid`)
      const errorInfo = { field, reason }
      if (acc[devEui]) {
        acc[devEui].push(errorInfo)
      } else {
        acc[devEui] = [errorInfo]
      }
      return acc
    }, {})
    return (
      <>
        {Object.keys(groupedErrors).map((devEui, index1) => (
          <li key={`error-import-${devEui}-${index1}`}>
            {t(`devices.importDevicesForm.form.errors.device`)} {devEui}
            <ul className="a2a-user-area-add-device-page__group-box-message--error-list">
              {groupedErrors[devEui].map((err, index2) => (
                <li key={`error-import-${devEui}-${err.field}-${index2}`}>
                  {t(`devices.importDevicesForm.form.errors.field`)}{' '}
                  <i>{err.field}</i> : {err.reason}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </>
    )
  }
}

const ImportDevices = ({ isOpen, setIsOpen }) => {
  const { currentOrganization, credentials } = useSelector((state) => state.general)

  const [model, setModel] = useState({ model: null })

  const [validation, setValidation] = useState({ isValid: false })
  const [activateProggressBar, setActivateProgressBar] = useState(false)

  const [importData, setImportData] = useState({
    modelId: -1,
    organizationKey: '',
    networkServerId: null,
    importFile: null
  })

  const [importFile, setImportFile] = useState(null)

  const onImportFileLoaded = (e) => {
    setImportFile(e.target.files[0])
    setImportData({ ...importData, importFile: e.target.files[0] })
  }

  const { data: brands = [], isLoading: isBrandsLoading } =
    useFetchBrandsQuery()

  const [
    importDevices,
    {
      isError: isImportDevicesInError,
      isSuccess: isImportDevicesSuccess,
      status: importStatus,
      error: importResultDataError
    }
  ] = useImportDevicesMutation()

  const [fetchModels, { data: models = [], isLoading: isModelsLoading }] = useFetchModelsMutation()

  const onChangeBrand = (_, value) => {
    setModel({
      ...model,
      brand: value,
      model: null
    })
  }

  const onChangeModel = (_, value) => {
    setModel({
      ...model,
      model: value
    })

    setImportData({ ...importData, modelId: value.id })
  }

  useValidationEffect(() => {
    const validate = useFieldValidation(
      {
        brand: model?.brand,
        model: model?.model,
        sourceType: model?.model?.sourceType,
        organizationKey: importData.organizationKey,
        networkServerId:
          model?.model?.sourceType === 'lorawan'
            ? importData.networkServerId
            : null
      },
      schema
    )
    setValidation(validate)
  }, [importData])

  useOpenResultAlert(importResultDataError, { isError: isImportDevicesInError, isSuccess: isImportDevicesSuccess })

  useEffect(() => {
    if (isImportDevicesInError) {
      setActivateProgressBar(false)
    }
  }, [isImportDevicesInError])

  useEffect(() => {
    if (model?.brand?.id) {
      fetchModels(model.brand.id)
    }
  }, [model])

  useEffect(() => {
    if (isImportDevicesSuccess) {
      setActivateProgressBar(false)
    }
  }, [isImportDevicesSuccess])

  const { data: networkServers = [] } = useFetchNetworkServersQuery(currentOrganization)

  const {
    data: organizationsSuggestions,
    isLoading: isOrganizationsSuggestionsLoading
  } = useFetchSelfOrganizationsQuery()

  const handleChangeOrganization = (_, value) => {
    if (!value || !value.key) return
    setImportData({ ...importData, organizationKey: value.key })
  }

  const handleChangeNetworkServer = (_, value) => setImportData({ ...importData, networkServerId: value })

  const handleClose = () => {
    setValidation({ isValid: false })
    setImportFile(null)
    setModel(null)
    setIsOpen(false)
  }

  const handleDownloadTemplate = () => downloadImportTemplate({
      modelInfo: model,
      credentials: credentials,
      currentOrganization: currentOrganization
    })

  const handleImport = () => {
    const formData = new FormData()
    formData.append('file', importData.importFile)
    formData.append('modelId', importData.modelId)
    formData.append('organizationKey', importData.organizationKey)
    formData.append('networkServerId', importData.networkServerId)
    importDevices(formData)
    setActivateProgressBar(true)
  }

  const isImportDisabled = () => {
    return (
      !validation.isValid ||
      !importFile ||
      importStatus === QueryStatus.pending ||
      isImportDevicesSuccess
    )
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
  })

  return (
    <>
      <ZDialog 
       id={'import-device-dialog'}
       isOpen={isOpen}
       onClose={handleClose}
       title={t('devices.import')}
       content={
       <>
        {activateProggressBar && <LinearProgress />}
        <Box sx={{ flexGrow: 1 }}>
          <Grid2
            container
            rowSpacing={5}
            columnSpacing={5}
          >
            <Grid2
              size={12}
            >
              <Show when={() => isImportDevicesInError}>
                <Card
                  variant="standard"
                  sx={{
                    width: '100%',
                    background: 'rgba(229, 94, 194, 0.1)'
                  }}
                >
                  <CardContent>
                    <Stack
                      justifyContent={'center'}
                      alignItems={'center'}
                      spacing={2}
                    >
                      <Typography
                        variant="title"
                        py={1}
                      >
                        {t('devices.importDevicesForm.box.error.description')}
                      </Typography>
                      <ul>
                        {importDevicesErrorFunction(importResultDataError)}
                      </ul>
                    </Stack>
                  </CardContent>
                </Card>
              </Show>

              <Show when={() => isImportDevicesSuccess}>
                <InlineMessage
                  title={t('devices.importDevicesForm.box.success.title')}
                  message={t(
                    'devices.importDevicesForm.box.success.description'
                  )}
                  severity="success"
                ></InlineMessage>
              </Show>
            </Grid2>

            <Grid2
              size={12}
              display="flex"
              justifyContent="center"
              key="select-model-title"
            >
              <Typography variant="h5">
                {t('devices.selectModel')}
              </Typography>
            </Grid2>

            <Grid2
              size={6}
              key="select-brand"
            >
              <Autocomplete
                id="chooseModel-Brand"
                name="brand"
                fullWidth
                disableClearable
                loading={isBrandsLoading}
                options={brands}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={model?.brand}
                onChange={onChangeBrand}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('devices.chooseBrand')}
                    variant="standard"
                    required
                    error={validation?.messages?.brand?.length > 0}
                    helperText={validation?.messages?.brand?.join(', ')}
                  />
                )}
              />
            </Grid2>

            <Grid2
              size={6}
              key="select-model"
            >
              <Autocomplete
                id="chooseModel-Model"
                name="model"
                fullWidth
                disableClearable
                disabled={!model?.brand}
                loading={isModelsLoading}
                options={models}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterOptions={(x) => x}
                value={model?.model}
                onChange={onChangeModel}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('devices.chooseModel')}
                    variant="standard"
                    required
                    error={validation?.messages?.model?.length > 0}
                    helperText={validation?.messages?.model?.join(', ')}
                  />
                )}
              />
            </Grid2>

            <Grid2
              size={12}
              key="device-summary"
            >
              {model?.brand && model?.model && (
                <DeviceSummary
                  model={model?.brand?.name}
                  brand={model?.model?.name}
                  type={model?.model?.typename}
                  sourceType={model?.model?.sourceType}
                  measurements={model?.model?.quantities}
                />
              )}
            </Grid2>

            <Grid2
              size={6}
              key="select-org"
            >
              <Autocomplete
                fullWidth
                id="device-import-choose-organization"
                name="organizationKey"
                disableClearable
                loading={isOrganizationsSuggestionsLoading}
                options={organizationsSuggestions}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                onChange={handleChangeOrganization}
                renderInput={(params) => (
                  <TextField
                    name="organizationKey"
                    label={t('devices.chooseOrganization')}
                    {...params}
                    variant="standard"
                    required
                    error={validation?.messages?.organizationKey?.length > 0}
                    helperText={validation?.messages?.organizationKey?.join(
                      ', '
                    )}
                  />
                )}
              />
            </Grid2>

            {model && model.model && model.model.sourceType === 'lorawan' && (
              <Grid2
                size={6}
                key="select-netServer"
              >
                {networkServers.length > 0 && (
                  <Autocomplete
                    fullWidth
                    id="Info-NetworkServerAutocomplete"
                    name="networkServer"
                    disableClearable
                    options={networkServers}
                    onChange={handleChangeNetworkServer}
                    renderInput={(params) => (
                      <TextField
                        name="networkServer"
                        label={t('devices.chooseNetworkServer')}
                        {...params}
                        variant="standard"
                        required
                        error={validation?.messages?.networkServerId?.length > 0}
                        helperText={validation?.messages?.networkServerId?.join(
                          ', '
                        )}
                      />
                    )}
                  />
                )}
              </Grid2>
            )}

            <Grid2
              size={12}
              marginTop={5}
            >
              <Show when={() => model?.brand && model?.model}>
                <ZCard 
                  id={'import-device-upload-template'} 
                  isDeleteVisible={false}
                  isEditVisible={false}
                  title={t('devices.import_uploadTemplate')} 
                  content={ 
                    <CardContent>
                    <Stack
                      direction={'column'}
                      justifyContent={'center'}
                      spacing={3}
                      alignItems={'center'}
                    >
                      <Button
                        size="small"
                        sx={{ height: '38px' }}
                        component="label"
                        variant="contained"
                        onClick={handleDownloadTemplate}
                      >
                        {t('devices.import_downloadTemplate')}
                      </Button>
                      <Button
                        sx={{ height: '38px' }}
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadRoundedIcon />}
                      >
                        {t('devices.import_uploadTemplate')}
                        <VisuallyHiddenInput
                          onChange={onImportFileLoaded}
                          type="file"
                        />
                      </Button>
                    </Stack>
                </CardContent>}
              />
              </Show>
            </Grid2>
          </Grid2>
        </Box>
        </>}
        actions={<Button
        variant="contained"
        onClick={handleImport}
        disabled={isImportDisabled()}
        endIcon={<UploadRoundedIcon />}
      >
        {t('devices.import')}
      </Button>}
      />
    </>
  )
}

ImportDevices.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default ImportDevices
