import Joi from 'joi'

const fiedlsSchemaFn = {
  string: () => Joi.string(),
  float: () => Joi.number(),
  integer: () => Joi.number().integer(),
  bool: () => Joi.bool(),
  password: () => Joi.string(),
  object: () => Joi.object(),
  uri: () => Joi.string().uri(),
  selectMeasureFromDevice: () =>
    Joi.object({
      deviceId: Joi.string().required(true),
      deviceName: Joi.string(),
      quantityName: Joi.string().required(true),
      downlinkQuantityName: Joi.string().required(true)
    }).required(true)
}

const useProvisioningSetupValidation = (fields) => {
  const keys = fields.reduce((acc, f) => {
    let fieldSchema = fiedlsSchemaFn[f.type]
      ? fiedlsSchemaFn[f.type]()
      : Joi.any()

    if (typeof f.maxValue !== 'undefined') {
      fieldSchema = fieldSchema.max(f.maxValue)
    }
    if (typeof f.minValue !== 'undefined') {
      fieldSchema = fieldSchema.min(f.minValue)
    }

    if (f.required) {
      fieldSchema = fieldSchema.required()
    } else {
      fieldSchema = fieldSchema.allow('')
    }

    return { ...acc, [f.internalName]: fieldSchema }
  }, {})

  return Joi.object().keys(keys)
}

export default useProvisioningSetupValidation
