import React from 'react'
import { IMaskInput } from 'react-imask'
import PropTypes from 'prop-types'

const Nwkskey = React.forwardRef(function Nwkskey(props, ref) {
  const { onChange, ...other } = props

  return (
    <IMaskInput
      {...other}
      mask={/^[0-9a-f]{0,32}$/i}
      inputRef={ref}
      onAccept={(value) => {
        onChange({
          target: {
            name: props.name,
            value: value.toLowerCase()
          }
        })
      }}
      overwrite
      prepareChar={(str) => {
        return str.toUpperCase()
      }}
    />
  )
})

Nwkskey.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Nwkskey
