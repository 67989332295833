import {
  Autocomplete,
  CircularProgress,
  Icon,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { isEqual } from 'lodash'
import React, { useEffect, useRef } from 'react'
import { CompassCalibrationRounded as CompassCalibrationRoundedIcon } from '@mui/icons-material'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchModelsFilterQuery } from '../../../../store'
import { setFiltersDevices } from '../../../../store/slices/filters.js'

const Models = () => {
  const dispatch = useDispatch()

  const {
    devices: { brandIds = [], modelIds }
  } = useSelector((state) => state.filters)

  const {
    data: modelsFiltersOptions = [],
    isError: isModelsFilterError,
    isLoading: isModelsFilterLoading
  } = useFetchModelsFilterQuery(brandIds, { skip: !brandIds.length })

  const prevBrandIds = useRef(brandIds)

  const handleChange = (_, v) => {
    const modelIds = v.map((i) => i.id)
    dispatch(setFiltersDevices({ modelIds: modelIds }))
  }

  useEffect(() => {
    if (!isEqual(prevBrandIds.current, brandIds)) {
      const modelsResult = modelsFiltersOptions
        .filter((model) => brandIds.includes(model.brand_id))
        .map((model) => model.id)

      const newModelIds = modelIds.filter((modelId) =>
        modelsResult.includes(modelId)
      )

      dispatch(
        setFiltersDevices({
          modelIds: newModelIds
        })
      )
      prevBrandIds.current = brandIds
    }
  }, [brandIds])

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="left"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.devices.other.models.label')}
        </Typography>
        <Autocomplete
          multiple
          disabled={isModelsFilterError || brandIds?.length <= 0}
          id="models-filter"
          options={modelsFiltersOptions}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          groupBy={(option) => option.brand_name}
          loading={isModelsFilterLoading}
          getOptionLabel={(option) => option.name}
          value={modelsFiltersOptions.filter((i) => {
            const { id } = i
            if (modelIds && modelIds.length > 0) {
              return modelIds.includes(id)
            } else return false
          })}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              divider
              key={option.id}
            >
              <ListItemIcon>
                <CompassCalibrationRoundedIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: '0.8rem',
                  fontWeight: 500
                }}
                primary={option.name}
              />
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={t('filters.devices.other.models.placeholder')}
              slotProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon
                      color="inherit"
                      edge="end"
                    >
                      {isModelsFilterLoading && (
                        <CircularProgress
                          edge="end"
                          size={20}
                        />
                      )}
                    </Icon>
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </Stack>
    </>
  )
}

export default Models
