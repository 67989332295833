import React, { useEffect, useState } from 'react'
import ModelsSelector from './ModelsSelector'
import { Autocomplete, Stack, TextField } from '@mui/material'
import { useGetQuantitiesQuery } from '../../../../store'
import { t } from 'i18next'
import { useValidationEffect } from '../../../../helpers/hooks'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'

const sortFn = (a, b) => {
  const label1 = a.label.toLowerCase()
  const label2 = b.label.toLowerCase()
  if (label1 < label2) return -1
  if (label1 > label2) return 1
  return 0
}

const NumericFormatCustom = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        decimalSeparator=','
        allowedDecimalSeparators={[',', '.']}
      />
    )
  },
);


const CommunicationRuleForm = ({
  device,
  applyToChildOrganizations,
  ruleType,
  isEdit,
  onCommunicationChanged,
  comunicationRule,
  selectedOrganizationKey,
  validation,
  quantityId
}) => {
  const { credentials } = useSelector((state) => state.general)
  const isSuperAdmin = credentials?.permissions?.isSuperAdmin || false

  const { selectedModels, missingComunicationQuantity } = comunicationRule

  const { data: quantities = [] } = useGetQuantitiesQuery()

  const [selectedQuantity, setSelectedQuantity] = useState(() => quantities.find(q => q.id === quantityId))

  useEffect(() => {
    if (quantities) {
      setSelectedQuantity(quantities.find((q) => q.id === quantityId))
    }
  }, [quantities])

  useValidationEffect(() => {
    onCommunicationChanged({ ...comunicationRule, quantity: selectedQuantity, quantityId: selectedQuantity?.id })
  }, [selectedQuantity])

  const handleModelsChanged = (data) => {
    onCommunicationChanged({ ...comunicationRule, ...data })
  }

  const handleSelectedQuantity = (_data, value) => {
    setSelectedQuantity(value)
  }

  const handleTimeDeltaChange = (event) => {
    const timeDelta = isSuperAdmin ? parseFloat(event.target.value) : parseInt(event.target.value)
    onCommunicationChanged({
      ...comunicationRule,
      missingComunicationQuantity: { ...missingComunicationQuantity, timeDelta }
    })
  }

  const filteredQuantities =
    quantities?.filter(({ models }) =>
      models.filter((m) => selectedModels.includes(m.id))
    ) || []

  return (
    <>
      {ruleType === 'measure-communication' && missingComunicationQuantity && (
        <Stack
          direction={'column'}
          spacing={2}
        >
          <Autocomplete
            sx={{
              marginTop: '30px'
            }}
            id="comunication-trigger-form-measure-selector"
            name="measureSelector"
            options={filteredQuantities
              .map((fq) => ({ ...fq, label: t(fq.label) }))
              .sort(sortFn)}
            value={
              selectedQuantity
                ? { ...selectedQuantity, label: t(selectedQuantity.label) }
                : ''
            }
            getOptionLabel={(option) => option.label || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleSelectedQuantity}
            renderInput={(params) => (
              <TextField
                required
                name="quantity"
                {...params}
                label={t('triggers.selectMeasure')}
                variant="standard"
                error={validation?.messages?.quantityId?.length > 0}
                helperText={validation?.messages?.quantityId?.join(', ')}
              />
            )}
            disabled={isEdit}
          />
          {isSuperAdmin ?
            <TextField
              id="comunication-trigger-form-delta-hours"
              label={'Ore trascorse da ultima misura'}
              name="deltaHours"
              variant="standard"
              fullWidth
              required
              value={missingComunicationQuantity?.timeDelta}
              error={validation?.messages?.timeDelta?.length > 0}
              helperText={validation?.messages?.timeDelta?.join(', ')}
              onChange={handleTimeDeltaChange}
              disabled={isEdit}
              slotProps={{
                input: {
                  inputComponent: NumericFormatCustom,
                },
              }}
            />: 
            <TextField
              id="comunication-trigger-form-delta-hours"
              label={'Ore trascorse da ultima misura'}
              name="deltaHours"
              variant="standard"
              type="number"
              fullWidth
              required
              value={missingComunicationQuantity?.timeDelta}
              error={validation?.messages?.timeDelta?.length > 0}
              helperText={validation?.messages?.timeDelta?.join(', ')}
              onChange={handleTimeDeltaChange}
              disabled={isEdit}
            />}
        </Stack>
      )}
      {!device && (
        <ModelsSelector
          applyToChildOrganizations={applyToChildOrganizations}
          ruleType={ruleType}
          selectedModels={selectedModels}
          selectedQuantity={selectedQuantity}
          isEdit={isEdit}
          onModelsChanged={handleModelsChanged}
          selectedOrganizationKey={selectedOrganizationKey}
          validation={validation}
        />
      )}
    </>
  )
}

export default CommunicationRuleForm
