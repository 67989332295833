import {
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Stack,
  TextField,
  Grid2,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Divider,
  Dialog,
  Button,
  Card,
  CardContent,
  Alert
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import useFieldValidation from '../../../helpers/fieldValidation.js'
import PropTypes from 'prop-types'
import {
  setFirstName,
  setLastName,
  setPhoneNumber,
  setPassword,
  setPrivacy,
  setLegal,
  setMarketing,
  setLanguageCode,
  resetInvitePayloadToDefault,
  setConfirmPassword
} from '../../../store/slices/accept-invite.js'
import Joi from 'joi'
import {
  useAcceptInviteMutation,
  useGetInviteQuery,
  useLoginMutation
} from '../../../store/index.js'
import { useNavigate, useParams } from 'react-router-dom'
import {
  setCredentials,
  setCurrentOrganization,
  setOpenDialog
} from '../../../store/slices/general.js'
import { useValidationEffect } from '../../../helpers/hooks.js'
import {
  useRefuseInviteMutation,
  acceptInviteApi
} from '../../../store/apis/accept-invite.js'
import Show from '../../Layout/Can/Show.jsx'
import InlineMessage from '../../Shared/Components/InlineMessage.jsx'
import LoginForm from '../../Shared/Forms/LoginForm.jsx'
import { devicesApi } from '../../../store/apis/devices-api'
import { organizationsApi } from '../../../store/apis/organizations-api.js'
import { eventsApi } from '../../../store/apis/events-api.js'
import { invitesApi } from '../../../store/apis/invites-api.js'
import { actionsApi } from '../../../store/apis/actions-api.js'
import { rulesApi } from '../../../store/apis/rules-api.js'
import { triggersApi } from '../../../store/apis/triggers-api.js'

const schema = Joi.object({
  firstName: Joi.string().empty(null).required(),
  lastName: Joi.string().empty(null).required(),
  phoneNumber: Joi.string().empty(null).required(),
  password: Joi.string()
    .empty(null)
    .pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/)
    .message(`La passowrd non rispetta gli standard di sicurezza`)
    .required(),
  confirmPassword: Joi.string()
    .empty(null)
    .pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/)
    .message(`La passowrd non rispetta gli standard di sicurezza`)
    .required()
})

/*
Almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale, lunghezza minima 8 caratteri
*/

const AcceptInvite = () => {
  const {
    firstName,
    lastName,
    phoneNumber,
    password,
    confirmPassword,
    privacy,
    legal,
    marketing,
    languageCode
  } = useSelector((state) => state.acceptInvite)

  const navigate = useNavigate()

  const { inviteId } = useParams()

  const [acceptInvite, { isSuccess: isSaveSuccess }] = useAcceptInviteMutation()
  const [
    refuseInvite,
    { isSuccess: isRefuseSuccess, isError: isRefuseInError }
  ] = useRefuseInviteMutation()

  const [isAccepted, setIsAccepted] = useState(false)
  const [isRefused, setIsRefused] = useState(false)

  const {
    data,
    isLoading,
    isError: isInviteInError
  } = useGetInviteQuery(inviteId)

  const [
    loginUser,
    { data: loginData, error: loginError, isSuccess: isLoginSuccess }
  ] = useLoginMutation()

  useEffect(() => {
    if (isLoginSuccess && loginData) {
      if (isAccepted) {
        acceptInvite({ inviteId, payload: {} })
      }

      if (isRefused) {
        refuseInvite(inviteId)
      }

      const {
        permissions: { permissions, isSuperAdmin }
      } = loginData
      const orgs = isSuperAdmin
        ? Object.keys(permissions).filter((key) => key === 'a2a')
        : Object.keys(permissions)
      const orgKey = orgs[0] ? orgs[0] : null
      dispatch(setCredentials(loginData))
      dispatch(setCurrentOrganization(orgKey))
      dispatch(devicesApi.util.resetApiState())
      dispatch(organizationsApi.util.resetApiState())
      dispatch(eventsApi.util.resetApiState())
      dispatch(invitesApi.util.resetApiState())
      dispatch(acceptInviteApi.util.resetApiState())
      dispatch(actionsApi.util.resetApiState())
      dispatch(rulesApi.util.resetApiState())
      dispatch(triggersApi.util.resetApiState())
      navigate('/')
    }
  }, [isLoginSuccess, loginData])

  const dispatch = useDispatch()

  const [validation, setValidation] = useState({ isValid: false })
  const [loginFormIsValid, setLoginFormIsValid] = useState(false)
  const [loginForm, setLoginForm] = useState({})

  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [isOpen, setIsOpen] = useState(true)

  useEffect(() => {
    if (isSaveSuccess) {
      resetInvitePayloadToDefault()
      setIsOpen(false)
      dispatch(setOpenDialog('login'))
      dispatch(invitesApi.util.resetApiState())
      dispatch(acceptInviteApi.util.resetApiState())
      navigate('/')
    }
  }, [isSaveSuccess])

  useEffect(() => {
    if (isRefuseSuccess || isRefuseInError) {
      resetInvitePayloadToDefault()
      setIsOpen(false)
      dispatch(acceptInviteApi.util.resetApiState())
      dispatch(invitesApi.util.resetApiState())
      navigate('/')
    }
  }, [isRefuseSuccess, isRefuseInError])

  useValidationEffect(() => {
    const validateFields = useFieldValidation(
      { firstName, lastName, phoneNumber, password, confirmPassword },
      schema
    )

    setValidation(validateFields)
  }, [firstName, lastName, phoneNumber, password, confirmPassword])

  const handleLoginFormChange = (loginForm, isValid) => {
    setLoginForm(loginForm)
    setLoginFormIsValid(isValid)
  }

  const handleLoginAndAccept = () => {
    setIsAccepted(true)
    loginUser(loginForm)
  }
  const handleLoginAndRefuse = () => {
    setIsRefused(true)
    loginUser(loginForm)
  }

  const handleFirstName = (event) => {
    const {
      target: { value }
    } = event

    dispatch(setFirstName(value))
  }

  const handleLastName = (event) => {
    const {
      target: { value }
    } = event

    dispatch(setLastName(value))
  }

  const handlePassword = (event) => {
    const {
      target: { value }
    } = event

    const passwordMatchValue = value === confirmPassword
    setPasswordsMatch(passwordMatchValue)
    dispatch(setPassword(value))
  }

  const handleConfirmPassword = (event) => {
    const {
      target: { value }
    } = event

    const passwordMatchValue = value === password
    setPasswordsMatch(passwordMatchValue)

    dispatch(setConfirmPassword(value))
  }

  const handlePhoneNumber = (event) => {
    const {
      target: { value }
    } = event

    dispatch(setPhoneNumber(value))
  }

  const handlePrivacy = (_event, value) => {
    dispatch(setPrivacy(value))
  }

  const handleLegal = (_event, value) => {
    dispatch(setLegal(value))
  }

  const handleMarketing = (_event, value) => {
    dispatch(setMarketing(value))
  }

  const handleLanguageCode = (_event, languageValue) => {
    dispatch(setLanguageCode(languageValue.value))
  }

  const handleRefuseInvite = () => {
    refuseInvite(inviteId)
  }

  const handleSave = () => {
    const payload = {
      firstName,
      lastName,
      email: data.email,
      phone: phoneNumber,
      password,
      gdprConsents: {
        privacy,
        legal,
        marketing
      },
      languageCode
    }

    acceptInvite({
      payload,
      inviteId
    })

    dispatch(setPrivacy(false))
    dispatch(setMarketing(false))
    dispatch(setLegal(false))
    dispatch(setPhoneNumber(''))
    dispatch(setConfirmPassword(''))
    dispatch(setPassword(''))
    dispatch(setLastName(''))
    dispatch(setFirstName(''))
  }

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      aria-labelledby="add-device-modal-title"
      aria-describedby="add-device-modal-description"
    >
      <DialogTitle
        variant="h4"
        display="flex"
        justifyContent="center"
      >
        {t('invites.confirmTheInvite')}
      </DialogTitle>

      <DialogContent>
        <Show when={() => !data && isInviteInError}>
          <InlineMessage message={t('invites.notFound')} />
          <Button
            variant="contained"
            onClick={() => navigate('/')}
          >
            {t('invites.backToHome')}
          </Button>
        </Show>
        <Show when={() => !!data && !isInviteInError}>
          <Stack
            marginTop={'20px'}
            spacing={2}
            justifyContent="center"
          >
            {!isLoading && !isRefuseInError && (
              <Box>
                <Typography variant="h5">
                  {t('invites.yourData')}
                </Typography>
                <Stack direction="row">
                  <Typography variant="h6">
                    <strong>{t('common.organization')}:</strong>
                  </Typography>
                  <Typography variant="h6">
                    {data?.organization?.name}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Typography variant="h6">
                    <strong>{t('common.email')}:</strong>
                  </Typography>
                  <Typography variant="h6">
                    {data?.email}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Typography variant="h6">
                    <strong>{t('common.role')}:</strong>
                  </Typography>
                  <Typography variant="h6">
                    {data?.role?.name}
                  </Typography>
                </Stack>
              </Box>
            )}
            <Show when={() => !!data && !isInviteInError && !data.userExists}>
              <Divider orientation="horizontal" />
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h5">
                  {t('invites.basicInformation')}
                </Typography>
                <Grid2
                  container
                  spacing={2}
                >
                  <Grid2 size={6}>
                    <TextField
                      id="create-user-name"
                      label="Nome"
                      name="firstName"
                      variant="standard"
                      required
                      error={validation?.messages?.firstName?.length > 0}
                      helperText={validation?.messages?.firstName?.join(', ')}
                      onChange={handleFirstName}
                      fullWidth
                    ></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField
                      id="create-user-surname"
                      label="Cognome"
                      name="lastName"
                      variant="standard"
                      required
                      error={validation?.messages?.lastName?.length > 0}
                      helperText={validation?.messages?.lastName?.join(', ')}
                      onChange={handleLastName}
                      fullWidth
                    ></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField
                      id="create-user-phone-number"
                      label="Telefono"
                      name="phoneNumber"
                      variant="standard"
                      required
                      error={validation?.messages?.phoneNumber?.length > 0}
                      helperText={validation?.messages?.phoneNumber?.join(
                        ', '
                      )}
                      onChange={handlePhoneNumber}
                      fullWidth
                    ></TextField>
                  </Grid2>

                  <Grid2 size={12}>
                    <Divider sx={{ py: 3 }} />
                  </Grid2>

                  <Grid2 size={6}>
                    <TextField
                      id="create-user-password"
                      label="Password"
                      name="password"
                      variant="standard"
                      type="password"
                      required
                      error={validation?.messages?.password?.length > 0}
                      helperText={validation?.messages?.password?.join(', ')}
                      onChange={handlePassword}
                      fullWidth
                    />
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField
                      id="create-user-confirm-password"
                      label="Conferma Password"
                      name="confirmPassword"
                      variant="standard"
                      type="password"
                      required
                      error={validation?.messages?.confirmPassword?.length > 0}
                      helperText={validation?.messages?.confirmPassword?.join(
                        ', '
                      )}
                      onChange={handleConfirmPassword}
                      fullWidth
                    />
                  </Grid2>

                  {validation?.messages?.password?.length && (
                    <Grid2 size={12}>
                      <Card
                        variant="standard"
                        sx={{
                          width: '100%',
                          background: 'rgba(229, 94, 194, 0.1)',
                          marginTop: '10px'
                        }}
                      >
                        <CardContent>
                          <Stack
                            justifyContent={'center'}
                            alignItems={'center'}
                            spacing={2}
                          >
                            <Typography
                              variant="body2"
                              py={1}
                            >
                              {t('invites.passwordError')}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid2>
                  )}
                  {!passwordsMatch && (
                    <Grid2 size={12}>
                      <Card
                        variant="standard"
                        sx={{
                          width: '100%',
                          background: 'rgba(229, 94, 194, 0.1)',
                          marginTop: '10px'
                        }}
                      >
                        <CardContent>
                          <Stack
                            justifyContent={'center'}
                            alignItems={'center'}
                            spacing={2}
                          >
                            <Typography
                              variant="body2"
                              py={1}
                            >
                              {t('invites.correspondingPasswordError')}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </Card>
                    </Grid2>
                  )}

                  <Grid2 size={12}>
                    <Divider sx={{ py: 3 }} />
                  </Grid2>

                  <Grid2 size={6}>
                    <Autocomplete
                      py={1}
                      disablePortal
                      onChange={handleLanguageCode}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      defaultValue={{ label: 'It', value: 'it' }}
                      options={[
                        { label: 'It', value: 'it' },
                        { label: 'En', value: 'en' }
                      ]}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('common.language')}
                          variant="standard"
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={12}>
                    <Typography
                      variant="h5"
                      pt={5}
                    >
                      {t('invites.editYourPreferences')}
                    </Typography>

                    <Stack
                      spacing={1}
                      py={1}
                    >
                      <FormControlLabel
                        control={<Checkbox onChange={handlePrivacy} />}
                        label={
                          <Typography
                            id="accept-data-treatment"
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {t('invites.acceptDataTreatment')}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox onChange={handleLegal} />}
                        label={
                          <Typography
                            id="accept-legal-terms"
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {t('invites.acceptLegalTerms')}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox onChange={handleMarketing} />}
                        label={
                          <Typography
                            id="accept-commercial-data-usage"
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            {t('invites.acceptCommercialDataUsage')}
                          </Typography>
                        }
                      />
                    </Stack>
                  </Grid2>
                </Grid2>
              </Box>
            </Show>
          </Stack>
        </Show>
        <Show when={() => !!data && !isInviteInError && data.userExists}>
          <Stack
            marginTop={'15px'}
            direction="column"
            spacing={2}
          >
            <Typography variant="title">
              Conferma la tua identià prima di procedere
            </Typography>
            <LoginForm
              onChange={handleLoginFormChange}
              onLogin={() => { }}
            />
            {loginError && (
              <Alert severity="error">{t('users.loginFail')}</Alert>
            )}
            <Stack
              marginTop={'20px'}
              spacing={2}
              direction={'row'}
            >
              <Button
                variant="contained"
                disabled={!loginFormIsValid}
                onClick={handleLoginAndAccept}
              >
                {t('invites.acceptInvite')}
              </Button>
              <Button
                variant="contained"
                disabled={!loginFormIsValid}
                onClick={handleLoginAndRefuse}
              >
                {t('invites.refuseInvite')}
              </Button>
            </Stack>
          </Stack>
        </Show>
      </DialogContent >

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Show when={() => !!data && !isInviteInError && !data.userExists}>
          <Button
            id="accept-creation-user-button"
            variant="contained"
            disabled={
              !validation.isValid || !privacy || !legal || !passwordsMatch
            }
            onClick={handleSave}
          >
            {t('invites.acceptInvite')}
          </Button>
          <Button
            id="refuse-creation-user-button"
            variant="contained"
            color="error"
            onClick={handleRefuseInvite}
          >
            {t('invites.refuseInvite')}
          </Button>
        </Show>
      </DialogActions>
    </Dialog >
  )
}

AcceptInvite.propTypes = {
  params: PropTypes.any
}

export default AcceptInvite
