import { Box, TextField, Stack, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import Show from '../../Layout/Can/Show'
import PropTypes from 'prop-types'

const Publication = ({ onChange }) => {
  const [publication, setPublication] = useState()

  const {
    credentials: { permissions }
  } = useSelector((state) => state.general)

  const handleChange = (event) => {
    setPublication({
      ...publication,
      [event.target.name]: event.target.checked
    })
  }

  const handleOdlCodeChange = (event) => {
    setPublication({
      ...publication,
      odlCode: event.target.value
    })
  }

  const handleLocationCodeChange = (event) => {
    setPublication({
      ...publication,
      locationCode: event.target.value
    })
  }

  useEffect(() => {
    onChange(publication)
  }, [publication])

  return (
    <Stack
      direction="column"
      spacing={5}
    >
      <Typography
        variant="h2"
        py={1}
      >
        {t('devices.addPublication')}
      </Typography>

      <Show when={() => permissions.isSuperAdmin == true}>
        <Stack direction="column">
          <Typography variant="h4">ODL Code</Typography>
          <TextField
            type="string"
            variant="standard"
            value={publication?.odlCode}
            onChange={handleOdlCodeChange}
            sx={{ mb: 5 }}
          />
          <Typography variant="h4">Location Code</Typography>
          <TextField
            type="string"
            variant="standard"
            value={publication?.locationCode}
            onChange={handleLocationCodeChange}
            sx={{ mb: 5 }}
          />
        </Stack>
      </Show>

      <Stack direction="row">
        <Box width="100%">
          <Typography variant="h3">{t('devices.public')}</Typography>
          <Typography variant="body2">{t('devices.publicInfo')}</Typography>
        </Box>
        <Switch
          name="isPublic"
          value={publication?.isPubllic}
          onChange={handleChange}
        />
      </Stack>
    </Stack>
  )
}

Publication.propTypes = {
  onChange: PropTypes.func
}

export default Publication
