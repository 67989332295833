import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'

const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: customFetchBase,
  endpoints(builder) {
    return {
      login: builder.mutation({
        query: (data) => {
          logger.log('RTK API - Login')
          return {
            url: '/login',
            body: data,
            method: 'POST',
            credentials: 'include'
          }
        },
        invalidatesTags: [
          'authentication',
          'organizations',
          'devices',
          'events',
          'provisioningSetup',
          'rules',
          'invites',
          'triggers',
          'userProfile',
          'users'
        ]
      }),
      logout: builder.mutation({
        query: (data) => {
          logger.log('RTK API - Logout')
          return {
            url: '/logout',
            body: data,
            method: 'POST',
            credentials: 'include'
          }
        },
        invalidatesTags: [
          'authentication',
          'organizations',
          'devices',
          'events',
          'provisioningSetup',
          'rules',
          'invites',
          'triggers',
          'userProfile',
          'users'
        ]
      }),
      resetPassword: builder.mutation({
        query: (data) => {
          logger.log('RTK API - Reset password')
          return {
            url: '/users/reset-password',
            body: data,
            method: 'POST',
            credentials: 'include'
          }
        },
        invalidatesTags: [
          'authentication',
          'organizations',
          'devices',
          'events',
          'provisioningSetup',
          'rules',
          'invites',
          'triggers',
          'userProfile',
          'users'
        ]
      }),
      resetPasswordRequest: builder.mutation({
        query: ({ token, data }) => {
          logger.log('RTK API - Reset password')
          return {
            url: `/users/reset-password/${token}`,
            body: { password: data },
            method: 'PATCH',
            credentials: 'include'
          }
        },
        invalidatesTags: [
          'authentication',
          'organizations',
          'devices',
          'events',
          'provisioningSetup',
          'rules',
          'invites',
          'triggers',
          'userProfile',
          'users'
        ]
      })
    }
  }
})

export const {
  useLoginMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useResetPasswordRequestMutation
} = authenticationApi
export { authenticationApi }
