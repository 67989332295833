import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import NoLogo from '../../../images/nologo.svg'

const Logo = ({ organizationKey }) => {
  const [found, setFound] = useState(true)
  const render = found

  return (
    <>
      <Box>
        {render && (
          <img
            src={`/api/logos/${organizationKey}`}
            onError={() => setFound(false)}
            alt="Organization Logo"
            style={{ width: '50px' }}
          />
        )}
        {!render && (
          <Box
            component="img"
            sx={{ width: '100px' }}
            src={NoLogo}
          />
        )}
      </Box>
    </>
  )
}

Logo.propTypes = {
  organizationKey: PropTypes.string
}

export default Logo
