import { Divider } from '@mui/material'
import React from 'react'
import EventStatusFilter from '../Fields/EventStatus.jsx'
import EventTypeFilter from '../Fields/EventType.jsx'
import PropTypes from 'prop-types'

const FiltersOthers = ({ filters, onChange = () => {} }) => {
  return (
    <>
      <EventStatusFilter
        filters={filters}
        onChange={(value) => onChange({ ...filters, status: value })}
      />
      <Divider />
      <EventTypeFilter
        filters={filters}
        onChange={(value) => onChange({ ...filters, triggerTypes: value })}
      />
    </>
  )
}

FiltersOthers.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func
}

export default FiltersOthers
