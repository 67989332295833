import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import sanitizer from '../../helpers/sanitizer.js'
import customFetchBase from './custom-fetch-base.js'

const devicesApi = createApi({
  reducerPath: 'devicesApi',
  baseQuery: customFetchBase,
  tagTypes: ['devices', 'measurements', 'actions'],
  endpoints(builder) {
    return {
      searchDevices: builder.mutation({
        query: ({ payload }) => {
          logger.log(`RTK API - Search devices for filter: ${payload.fullText}`)
          return {
            url: '/devices/search',
            params: {
              sort: 'name:ASC'
            },
            body: sanitizer(payload),
            method: 'POST'
          }
        }
      }),
      fetchDevices: builder.query({
        query: ({ filters, sort, direction, page, pageSize }) => {
          logger.log('RTK API - Search devices')
          return {
            url: '/devices/search',
            params: {
              page,
              pageSize,
              sort: sort ? `${sort}:${direction}` : undefined
            },
            body: sanitizer(filters),
            method: 'POST'
          }
        },
        transformResponse: (response, meta) => {
          const count = parseInt(meta.response.headers.get('x-total-count'))
          return { data: response, count: count }
        },
        providesTags: ['devices']
      }),
      fetchAutomationDevices: builder.mutation({
        query: ({ filters, page, pageSize }) => {
          logger.log('RTK API - Search devices')
          return {
            url: '/devices/search',
            params: {
              page,
              pageSize
            },
            body: sanitizer(filters),
            method: 'POST'
          }
        },
        transformResponse: (response, meta) => {
          response['count'] = parseInt(
            meta.response.headers.get('x-total-count')
          )
          return response
        },
        providesTags: ['devices']
      }),
      fetchDevice: builder.query({
        query: (id) => {
          logger.log(`RTK API - Fetch device by id ${id}`)
          return {
            url: `/devices/${id}`,
            method: 'GET'
          }
        },
        providesTags: ['devices']
      }),
      createDevice: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Create device ${payload.device.name}`)
          return {
            url: '/devices',
            method: 'POST',
            body: payload
          }
        },
        invalidatesTags: ['devices']
      }),
      importDevices: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Import devices`)
          return {
            url: '/devices/bulk-import',
            method: 'POST',
            body: payload
          }
        },
        invalidatesTags: ['devices']
      }),
      deleteDevice: builder.mutation({
        query: (id) => {
          logger.log(`RTK API - Delete device ${id}`)
          return {
            url: `/devices/${id}`,
            method: 'DELETE'
          }
        },
        invalidatesTags: ['devices']
      }),
      updateDevice: builder.mutation({
        query: ({ id, payload }) => {
          logger.log(`RTK API - Update device ${payload.device.name}`)
          return {
            url: `/devices/${id}`,
            method: 'PUT',
            body: payload
          }
        },
        invalidatesTags: ['devices']
      }),
      fetchDeviceMeasurements: builder.query({
        query: ({ id, measureNames, from, to }) => {
          logger.log('RTK API - Fetch device measurements by id ')
          return {
            url: `/devices/${id}/measurements/chart`,
            method: 'POST',
            body: {
              from,
              to,
              limit: 90,
              measureNames: measureNames
            }
          }
        },
        providesTags: ['measurements'],
        keepUnusedDataFor: 10
      }),
      fetchDeviceStatistics: builder.query({
        query: ({ id, from, to }) => {
          logger.log('RTK API - Fetch device statistics by id ')
          return {
            url: `/devices/${id}/statistics/chart`,
            method: 'POST',
            body: {
              from,
              to,
              limit: 90
            }
          }
        },
        providesTags: ['statistics'],
        keepUnusedDataFor: 10
      }),
      fetchDeviceNetwork: builder.query({
        query: ({ id, from, to }) => {
          logger.log('RTK API - Fetch device network by id ')
          return {
            url: `/devices/${id}/network/chart`,
            method: 'POST',
            body: {
              from,
              to
            }
          }
        },
        providesTags: ['network'],
        keepUnusedDataFor: 10
      }),
      fetchMarkers: builder.query({
        query: ({ filters }) => {
          logger.log('RTK API - Fetch markers')
          return {
            url: '/devices/markers',
            body: sanitizer(filters),
            method: 'POST'
          }
        },
        providesTags: ['devices']
      }),
      fetchPlaces: builder.mutation({
        query: (place) => {
          logger.log(`RTK API - Geocode postal address: ${place}`)
          return {
            url: '/devices/geocoder',
            body: {
              address: place
            },
            method: 'POST'
          }
        },
        providesTags: ['places']
      }),
      fetchBrands: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch brands`)
          return {
            url: `/brands`,
            method: 'GET'
          }
        }
      }),
      fetchModels: builder.mutation({
        query: (brandId) => {
          logger.log(`RTK API - Fetch models brandId: ${brandId}`)
          return {
            url: `/models`,
            method: 'GET',
            params: { brandId }
          }
        }
      }),
      fetchDownlinks: builder.query({
        query: (id) => {
          logger.log(`RTK API - Fetch downlinks for device ${id}`)
          return {
            url: `/devices/${id}/downlinks`,
            method: 'GET'
          }
        },
        providesTags: ['actions']
      }),
      fetchDeviceGroups: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch groups for device`)
          return {
            url: `/device-groups`,
            method: 'GET'
          }
        }
      }),
      fetchDeviceTypes: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch types for device`)
          return {
            url: `/device-types`,
            method: 'GET'
          }
        }
      }),
      fetchNetworkServerUsers: builder.query({
        query: ({ networkServer }) => {
          logger.log(`RTK API - Fetch types for device`)
          return {
            url: `/${networkServer}/users`,
            method: 'GET'
          }
        }
      }),
      fetchNetworkServerApplications: builder.query({
        query: ({ nsUserId, networkServer }) => {
          logger.log(`RTK API - Fetch types for device`)
          return {
            url: `${networkServer}/${nsUserId}/applications`,
            method: 'GET'
          }
        }
      })
    }
  }
})

export const {
  useFetchDevicesQuery,
  useLazyFetchDevicesQuery,
  useSearchDevicesMutation,
  useFetchMarkersQuery,
  useFetchPlacesMutation,
  useFetchDeviceQuery,
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
  useFetchDeviceMeasurementsQuery,
  useFetchDeviceStatisticsQuery,
  useFetchDeviceNetworkQuery,
  useFetchBrandsQuery,
  useFetchModelsMutation,
  useFetchDownlinksQuery,
  useLazyFetchDownlinksQuery,
  useFetchDeviceGroupsQuery,
  useFetchDeviceTypesQuery,
  useFetchAutomationDevicesMutation,
  useUpdateDeviceMutation,
  useImportDevicesMutation,
  useFetchNetworkServerUsersQuery,
  useFetchNetworkServerApplicationsQuery
} = devicesApi

export { devicesApi }
