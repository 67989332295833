import { Box, Divider, Stack, Toolbar } from '@mui/material'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Logo from '../Logo/Logo.jsx'
import Can from '../Can/Can.jsx'
import LanguageToggle from '../LanguageToggle/LanguageToggle.jsx'
import NotificationsToggle from '../NotificationsToggle/NotificationsToggle.jsx'
import ProfileBadge from '../ProfileBadge/ProfileBadge.jsx'
import ThemeToggle from '../ThemeToggle/ThemeToggle.jsx'
import ChangeOrganizationToggle from '../ChangeOrganizationToggle/ChangeOrganizationToggle'
import Search from '../../Shared/Search/Search.jsx'

const NavBar = () => {
  const { credentials } = useSelector((state) => state.general, shallowEqual)

  const isSuperAdmin = credentials?.permissions?.isSuperAdmin

  return (
    <>
      <Toolbar>
        <Link to="/">
          <Box sx={{ width: '140px', margin: 'auto' }}>
            <Logo />
          </Box>
        </Link>

        <Box sx={{ flexGrow: 1 }} />

        <Search />

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          spacing={3}
          edge="end"
          sx={{ padding: '5px 16px' }}
        >
          <Can action="notifications_r">
            <NotificationsToggle />
          </Can>
          <ThemeToggle />
          <LanguageToggle />

          <Can
            action="organizations_r"
            expression={() => !isSuperAdmin}
          >
            <ChangeOrganizationToggle />
          </Can>
        </Stack>
        <Divider
          orientation="vertical"
          flexItem
        />
        <ProfileBadge />
      </Toolbar>
    </>
  )
}

export default NavBar
