import { Mutex } from 'async-mutex'
import { logger } from '../../helpers/logger/logger.js'
import {
  logoutState,
  setCredentials,
  setCurrentOrganization
} from '../slices/general.js'
import { clearFilters } from '../slices/filters.js'
import { authenticatedBaseQuery, refreshBaseQuery } from './helper.js'

const mutex = new Mutex()
let keyRequested = false

const setOrgKey = async (api) => {
  const org = { key: api.getState().general?.currentOrganization || 'a2a' }

  const location = window.location.hostname.split('.')

  if (
    location.length >= 3 &&
    location[0] !== 'www' &&
    location[0] !== 'e2e' &&
    !keyRequested
  ) {
    const request = await fetch(`/api/organization-key?domain=${location[0]}`)
    if (request.status !== 200) {
      return org.key
    }

    const response = await request.json()
    org.key = response.key
    api.dispatch(setCurrentOrganization(org.key))
    api.dispatch(clearFilters())
    keyRequested = true
  }

  return org.key
}

const customFetchBase = async (args, api, extraOptions) => {
  let accessToken = api.getState().general?.credentials?.accessToken

  const organizationKey = await setOrgKey(api)

  if (args.url.includes('logout')) {
    keyRequested = false
  }

  const headers = { organizationkey: organizationKey }

  await mutex.waitForUnlock()

  let result = await authenticatedBaseQuery(
    args,
    api,
    extraOptions,
    headers,
    accessToken
  )

  if (result.error?.status === 401 && accessToken) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      const refreshToken = api.getState().general?.credentials?.refreshToken

      try {
        const refreshResult = await refreshBaseQuery(
          api,
          extraOptions,
          refreshToken
        )

        if (refreshResult.data) {
          logger.log('RTK - Access token refreshed')
          api.dispatch(setCredentials(refreshResult.data))
          accessToken = api.getState().general?.credentials?.accessToken

          // dopo la chiamata di refresh, il refresToken non fiene aggiornato nel body della chiamata facendo fallire il logout
          if (args.url === '/logout')
            args['body']['refreshToken'] =
              api.getState().general?.credentials?.refreshToken

          result = await authenticatedBaseQuery(
            args,
            api,
            extraOptions,
            headers,
            accessToken
          )
        } else {
          logger.log('RTK - Unable to refresh token')
          api.dispatch(logoutState())
          window.location.href = '/'
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
      accessToken = api.getState().general?.credentials?.accessToken
      result = await authenticatedBaseQuery(
        args,
        api,
        extraOptions,
        headers,
        accessToken
      )
    }
  }

  if (result.error?.status === 500) {
    api.dispatch(clearFilters())
  }

  return result
}

export default customFetchBase
