import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import TriggerForm from '../Form/Trigger/TriggerForm.jsx'
import { useCreateTriggerMutation } from '../../../store/apis/triggers-api.js'
import PropTypes from 'prop-types'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert.js'

const defaultScheduler = {
  monday: {
    selected: false,
    timeSlots: []
  },
  tuesday: {
    selected: false,
    timeSlots: []
  },
  wednesday: {
    selected: false,
    timeSlots: []
  },
  thursday: {
    selected: false,
    timeSlots: []
  },
  friday: {
    selected: false,
    timeSlots: []
  },
  saturday: {
    selected: false,
    timeSlots: []
  },
  sunday: {
    selected: false,
    timeSlots: []
  }
}

const AddTrigger = ({ isOpen, setIsOpen }) => {
  const [triggerData] = useState({
    name: '',
    isForSuperAdmin: false,
    applyToChildOrganizations: false,
    models: [],
    ruleType: 'threshold',
    schedulerType: 'default',
    scheduler: JSON.parse(JSON.stringify(defaultScheduler)),
    sendReminder: false,
    thresholdsCounter: 1,
    triggerType: 'quantity',
    quantity: null,
    subQuantity: null,
    trigger: null,
    interval: null,
    tolerance: null,
    maxDeltaChange: null,
    device: null
  })

  const [
    newTrigger,
    {
      isSuccess: isCreateTriggerSuccess,
      error: createTriggerError,
      isError: isCreateTriggerError
    }
  ] = useCreateTriggerMutation()

  const handleOnSave = (trigger) => {
    newTrigger(trigger)
  }

  useOpenResultAlert(createTriggerError, { isError: isCreateTriggerError, isSuccess: isCreateTriggerSuccess })

  useEffect(() => {
    if (isCreateTriggerSuccess) {
      setIsOpen(false)
    }
  }, [isCreateTriggerSuccess])

  const handleClose = () => {
    setIsOpen(false)
  }

  return (<ZDialog
    id={'add-trigger-dialog'}
    isOpen={isOpen} 
    title={t('triggers.add')} 
    content={<TriggerForm
              triggerData={triggerData}
              isEdit={false}
              onSave={handleOnSave}
            />} 
    onClose={handleClose}/>)
}

AddTrigger.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default AddTrigger
