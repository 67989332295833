import {
  Avatar,
  Chip,
  Stack,
  Typography
} from '@mui/material'
import { lightBlue, yellow } from '@mui/material/colors'
import React from 'react'
import {
  EmailRounded as EmailRoundedIcon,
  SmartphoneRounded as SmartphoneRoundedIcon,
} from '@mui/icons-material'
import { t } from 'i18next'
import moment from 'moment'
import ZCard from '../../Shared/Components/ZCard'

const UserCard = ({ index, user, onDelete, onEdit }) => {
  return (
    <ZCard 
      key={`user-card-${index}`}
      id={`user-card-${index}`}
      name='user'
      title={`${user.firstName} ${user.lastName}`}
      titleInfo={
      <Chip
        label={user.organization.name}
        color="primary"
        size="small"
        variant="standard"
      />}
      subheader={
        <Typography
          component="span"
          color='red'
        >
          {user?.role?.name}
        </Typography> 
      }
      content={ 
      <Stack
        direction="column"
        alignItems="left"
        spacing={2}
        padding={'16px'}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Avatar sx={{ bgcolor: lightBlue[50] }}>
            <EmailRoundedIcon
              size={16}
              color="grey"
            />
          </Avatar>
          <Typography variant="body2">{user.email}</Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Avatar sx={{ bgcolor: yellow[50] }}>
            <SmartphoneRoundedIcon
              size={16}
              color="grey"
            />
          </Avatar>
          <Typography variant="body2">{user.phone}</Typography>
        </Stack>
      </Stack>}
      onDelete={() => onDelete(user)}
      onEdit={() => onEdit(user)}
      footerFields={[{ label: t('users.memberSince'), value: moment(user.createdAt).format('D/MM/YYYY') }]}
      variant = 'elevation'
    />
  )
}

export default UserCard
