import { createSlice } from '@reduxjs/toolkit'
import { logger } from '../../helpers/logger/logger.js'
import { ruleAddTriggerAdd } from '../states'

const initialState = ruleAddTriggerAdd

const ruleAddTriggerAddSlice = createSlice({
  name: 'ruleAddTriggerAdd',
  initialState,
  reducers: {
    setSelectedTrigger: (state, trigger) => {
      state.selectedTrigger = trigger
    },
    resetTriggerPayload: () => {
      logger.log('ruleAddAction: reset to initial state')
      return initialState
    }
  }
})

export const { setSelectedTrigger, resetRulePayload } =
  ruleAddTriggerAddSlice.actions

export default ruleAddTriggerAddSlice.reducer
