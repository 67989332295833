import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material'
import React from 'react'

const ZRadioGroup = ({ id, row = true, direction = 'row', title = '', disabled = false, values = [], defaultValue, selectedValue, onChange }) => {
    return (
      <Stack
        direction={direction}
        spacing={2}
        justifyContent="space-between"
      >
        <Typography variant="h4">{title}</Typography>
        <RadioGroup
          id={id}
          row={row}
          aria-labelledby="radio-group"
          name={id}
          defaultValue={defaultValue}
        >
          {values.map(({ id, label, value }, index) => 
            <FormControlLabel
              key={`form-control-label-${index}`}
              value={value}
              control={
                <Radio
                  id={id}
                  onChange={onChange}
                  checked={value === selectedValue}
                  disabled={disabled}
                />
              }
              label={label}
            />
          )}
        </RadioGroup>
      </Stack>
    )
}

export default ZRadioGroup