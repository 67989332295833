import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  Typography,
  Step,
  StepLabel,
  Stepper,
  Grid2
} from '@mui/material'
import React, { useState } from 'react'
import { ArrowBackRounded as ArrowBackRoundedIcon, ArrowForwardRounded as ArrowForwardRoundedIcon } from '@mui/icons-material'
import { t } from 'i18next'
import ConfigureDownlink from './ConfigureDownlink.jsx'
import ReviewAction from './ReviewAction.jsx'
import {
  useSendDownlinkActionMutation,
  useEncodeFieldsMutation
} from '../../../store/apis/actions-api.js'
import PropTypes from 'prop-types'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'

const SendAction = ({ device, isOpen, setIsOpen }) => {
  const [sendDownlinkAction] = useSendDownlinkActionMutation()

  const [
    encodeFields,
    { error: encodeFieldsError, isError: isEncodeFieldsInError }
  ] = useEncodeFieldsMutation()
  const [currentPage, setCurrentPage] = useState(0)
  const [downlinkPayload, setDownlinkPayload] = useState({})
  const [encodedPayload, setEncodedPayload] = useState({})
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const handleChangeCurrentPage = async (value) => {
    switch (value) {
      case 0:
        break
      case 1:
        const { data } = await encodeFields({
          modelEncoded: device?.modelEncoded,
          brandEncoded: device?.brandEncoded,
          payload: {
            deviceId: device.id,
            saveAs: downlinkPayload.name,
            downlink: {
              actionName: downlinkPayload.downlink.name,
              downlinkAckByUplink: downlinkPayload.downlink.downlinkAckByUplink,
              fields: downlinkPayload.fieldsPayload,
              port: 0,
              payload: ''
            }
          },
          actionName: downlinkPayload.downlink.name
        })

        setEncodedPayload(data)
        break
    }
    setCurrentPage(value)
  }

  const handleConfigureDownlinkChange = (value) => setDownlinkPayload(value)

  const handleSteps = () => {
    switch (currentPage) {
      case 0:
        return (
          <ConfigureDownlink
            device={device}
            onChange={handleConfigureDownlinkChange}
            downlinkPayload={downlinkPayload}
          />
        )
      case 1:
        return (
          <ReviewAction
            downlink={downlinkPayload}
            error={isEncodeFieldsInError ? encodeFieldsError : null}
          />
        )
    }
  }

  const handleSaveDownlink = async () => {
    const payloadToSend = {
      payload: {
        downlinkAckByUplink: downlinkPayload.sendUplink || false,
        actionName: downlinkPayload.downlink.name,
        fields: downlinkPayload.fieldsPayload,
        port: encodedPayload.port,
        payload: encodedPayload.payload
      },
      deviceId: device?.id
    }
    const res = await sendDownlinkAction(payloadToSend)

    if (res.error) {
      setShowErrorMessage(true)
      return
    }

    handleClose()
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      scroll="body"
    >
      <DialogTitle
        variant="h4"
        display="flex"
        justifyContent="center"
      >
        {t('actions.sendNew')}
      </DialogTitle>

      <DialogTitle>
        <Stepper
          activeStep={currentPage}
          alternativeLabel
        >
          <Step key="configureAction">
            <StepLabel>{t('actions.configureAction')}</StepLabel>
          </Step>
          <Step key="reviewAndSave">
            <StepLabel>{t('actions.reviewAndConfirm')}</StepLabel>
          </Step>
        </Stepper>
      </DialogTitle>

      <CloseDialogButton onClose={handleClose} />

      <DialogContent
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        {handleSteps()}

        {encodedPayload?.alreadyInQueue && (
          <Typography
            marginTop={3}
            color="red"
          >
            {t('actions.downlinkAlreadyInQueue')}!
          </Typography>
        )}

        {showErrorMessage && (
          <Typography
            marginTop={3}
            color="red"
          >
            {t('actions.downlinkNotSentError')}!
          </Typography>
        )}

        <Box sx={{ flexGrow: 1 }}>
          <Grid2
            container
            mt={8}
          >
            <Grid2>

            </Grid2>
          </Grid2>
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          aria-label="add to favorites"
          variant="text"
          onClick={() => handleChangeCurrentPage(currentPage - 1)}
          disabled={currentPage === 0}
        >
          <ArrowBackRoundedIcon />
          {t('common.previous')}
        </Button>

        {currentPage === 0 && (
          <Button
            aria-label="add to favorites"
            variant="text"
            disabled={!downlinkPayload.isValid}
            onClick={() => handleChangeCurrentPage(currentPage + 1)}
          >
            {t('common.next')}
            <ArrowForwardRoundedIcon />
          </Button>
        )}

        {currentPage === 1 && (
          <Button
            variant="contained"
            onClick={handleSaveDownlink}
          >
            {t('common.confirmAndSave')}
          </Button>
        )}
      </DialogActions >
    </Dialog >
  )
}

SendAction.propTypes = {
  device: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default SendAction
