import { Box, Typography, Grid2 } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import { useSelector } from 'react-redux'

const ReviewActionNotification = ({ actionName, notificationConfig }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        marginY={2}
        spacing={2}
      >
        <Grid2 size={6} py={1}>
          <Typography variant="h5" align="right">{t('actions.actionName')}</Typography>
        </Grid2>
        <Grid2 size={6} py={1}>
          <Typography>{actionName}</Typography>
        </Grid2>

        <Grid2 size={6} py={1}>
          <Typography variant="h5" align="right">
            {t('actions.emailsToNotify')}
          </Typography>
        </Grid2>
        <Grid2 size={6} py={1}>
          <Typography>
            {notificationConfig.emailsToNotify.map((mail) => `${mail} `)}
          </Typography>
        </Grid2>

        <Grid2 size={6} py={1}>
          <Typography variant="h5" align="right">
            {t('actions.notifyAdmin')}
          </Typography>
        </Grid2>
        <Grid2 size={6} py={1}>
          <Typography>
            {notificationConfig.notifyAdmin ? 'Si' : 'No'}
          </Typography>
        </Grid2>

        <Grid2 size={6} py={1}>
          <Typography variant="h5" align="right">
            {t('actions.notifyEditor')}
          </Typography>
        </Grid2>
        <Grid2 size={6} py={1}>
          <Typography>
            {notificationConfig.notifyEditor ? 'Si' : 'No'}
          </Typography>
        </Grid2>

        <Grid2 size={6} py={1}>
          <Typography variant="h5" align="right">
            {t('actions.notifyEditor')}
          </Typography>
        </Grid2>
        <Grid2 size={6} py={1}>
          <Typography>
            {notificationConfig.notifyViewer ? 'Si' : 'No'}
          </Typography>
        </Grid2>
      </Grid2>
    </Box >
  )
}

export default ReviewActionNotification
