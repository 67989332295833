import {
  Container,
  Button,
  Grid2,
  Stack,
  Chip,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'
import PendingList from '../Tables/Pending.jsx'
import { useFetchInvitesQuery } from '../../../store/index.js'
import InviteUser from '../Dialogs/InviteUser.jsx'

const Invites = () => {
  const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false)

  const { data: invitesPending } = useFetchInvitesQuery({
    page: 1,
    pageSize: 999
  })

  const handleInviteUser = () => {
    setIsInviteUserDialogOpen(true)
  }

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{ py: 3 }}
      >
        <Grid2
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid2>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Typography
                id="invites-header"
                variant="h2">{t('common.invites')}</Typography>
              <Chip
                label={invitesPending?.totalCount}
                color="primary"
              />
            </Stack>
          </Grid2>

          <Grid2>
            <Button
              id="invite-user-button"
              variant="contained"
              color="primary"
              onClick={handleInviteUser}
            >
              {t('invites.add')}
            </Button>
          </Grid2>
        </Grid2>
        <PendingList />
      </Container>
      <InviteUser
        isOpen={isInviteUserDialogOpen}
        setIsOpen={setIsInviteUserDialogOpen}
      />
    </>
  )
}

export default Invites
