import {
  Button,
  Container,
  Step,
  StepLabel,
  Stepper,
  DialogTitle,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  ChevronRightRounded as ChevronRightRoundedIcon,
  ChevronLeftRounded as ChevronLeftRoundedIcon,
  SaveRounded as SaveRoundedIcon
} from '@mui/icons-material'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import SelectAction from '../Form/CreateAction/SelectAction.jsx'
import ConfigureActionDownlink from '../Form/CreateAction/ConfigureActionDownlink.jsx'
import ConfigureActionAlarm from '../Form/CreateAction/ConfigureActionAlarm.jsx'
import ConfigureActionNotification from '../Form/CreateAction/ConfigureActionNotification.jsx'
import ConfigureActionWebhook from '../Form/CreateAction/ConfigureActionWebhook.jsx'
import ReviewActionDownlink from '../Form/CreateAction/ReviewActionDownlink.jsx'
import ReviewActionAlarm from '../Form/CreateAction/ReviewActionAlarm.jsx'
import ReviewActionNotification from '../Form/CreateAction/ReviewActionNotification.jsx'
import ReviewActionWebhook from '../Form/CreateAction/ReviewActionWebhook.jsx'
import {
  useCreateDownlinkActionMutation,
  useCreateAlarmActionMutation,
  useCreateNotificationActionMutation,
  useCreateWebhookActionMutation
} from '../../../store/apis/actions-api.js'
import PropTypes from 'prop-types'
import Show from '../../Layout/Can/Show.jsx'
import InlineMessage from '../../Shared/Components/InlineMessage.jsx'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert.js'

const AddAction = ({ isOpen, setIsOpen }) => {
  const { credentials } = useSelector((state) => state.general)
  const isSuperAdmin = credentials?.permissions?.isSuperAdmin || false

  const [currentPage, setCurrentPage] = useState(0)
  const [organization, setOrganization] = useState()
  const [isCurrentPageValid, setIsCurrentPageValid] = useState(false)
  const [downlinkConfiguration, setDownlinkConfiguration] = useState({})
  const [action, setAction] = useState(null)
  const [actionName, setActionName] = useState() 
  const [notificationConfig, setNotificationConfig] = useState({
    notifyAdmin: false,
    notifyEditor: false,
    notifyViewer: false,
    emailsToNotify: []
  })
  const [webhookConfig, setWebhookConfig] = useState({
    urlMeasures: null,
    urlDelete: null,
    urlAction: null,
    httpHeaders: null
  })

  const [
    createDownlinkAction,
    {
      error: createDownlinkError,
      isError: isCreateDownlinkError,
      isSuccess: isCreateDownlinkSuccess
    }
  ] = useCreateDownlinkActionMutation()

  const [
    createAlarmAction,
    {
      error: createAlarmError,
      isError: isCreateAlarmError,
      isSuccess: isCreateAlarmSuccess
    }
  ] = useCreateAlarmActionMutation()

  const [
    createNotificationAction,
    {
      error: createNotificationError,
      isError: isCreateNotificationError,
      isSuccess: isCreateNotificationSuccess
    }
  ] = useCreateNotificationActionMutation()

  const [
    createWebhookAction,
    {
      error: createWebhookError,
      isError: isCreateWebhookError,
      isSuccess: isCreateWebhookSuccess
    }
  ] = useCreateWebhookActionMutation()

  useEffect(() => {
    if (isCreateWebhookSuccess || isCreateAlarmSuccess || isCreateDownlinkSuccess || isCreateNotificationSuccess) {
      handleClose()
    }
  }, [isCreateWebhookSuccess, isCreateAlarmSuccess, isCreateDownlinkSuccess, isCreateNotificationSuccess])

  useOpenResultAlert(createAlarmError, { isError: isCreateAlarmError, isSuccess: isCreateAlarmSuccess })
  useOpenResultAlert(createDownlinkError, { isError: isCreateDownlinkError, isSuccess: isCreateDownlinkSuccess })
  useOpenResultAlert(createNotificationError, { isError: isCreateNotificationError, isSuccess: isCreateNotificationSuccess })
  useOpenResultAlert(createWebhookError, { isError: isCreateWebhookError, isSuccess: isCreateWebhookSuccess })

  const handleClose = () => {
    setIsOpen(false)
    setIsCurrentPageValid(false)
    setCurrentPage(0)
    setNotificationConfig({
      notifyAdmin: false,
      notifyEditor: false,
      notifyViewer: false,
      emailsToNotify: []
    })
    setWebhookConfig({
      urlMeasures: null,
      urlDelete: null,
      urlAction: null,
      httpHeaders: null
    })
  }

  const handleChangeCurrentPage = (_event, value) => {
    setIsCurrentPageValid(false)
    setCurrentPage(value)
  }

  const handleActionDownlinkChange = (value, isValid) => {
    setIsCurrentPageValid(isValid)
    setDownlinkConfiguration(value)
  }

  const handleActionAlarmChange = (isValid) => setIsCurrentPageValid(isValid)

  const handleActionNotificationChange = (isValid) => setIsCurrentPageValid(isValid)

  const handleActionWebhookChange = (isValid) => setIsCurrentPageValid(isValid)

  const handleSelectionOrganization = (organization) => setOrganization(organization)

  const handleSelectionAction = (action) => setAction(action)

  const handleChangeActionName = (name) => setActionName(name)

  const handleNotificationConfigChange = (notificationConfiguration) => setNotificationConfig(notificationConfiguration)

  const handleChangeWebhookConfig = (webhookConfig) => setWebhookConfig(webhookConfig)

  const handleButtonDisabled = () => {
    switch (currentPage) {
      case 0:
        if (!action || (isSuperAdmin && !organization)) {
          return true
        } else {
          return false
        }
      case 1:
        if (!isCurrentPageValid) {
          return true
        } else {
          return false
        }
      default:
        return false
    }
  }

  const handleSteps = () => {
    switch (currentPage) {
      case 0:
        return <SelectAction action={action} onChangeAction={handleSelectionAction} onChangeOrganization={handleSelectionOrganization} />
      case 1:
        switch (action) {
          case 'downlink':
            return (
              <ConfigureActionDownlink onChange={handleActionDownlinkChange} />
            )
          case 'alarm':
            return <ConfigureActionAlarm onChangeIsValid={handleActionAlarmChange} onChangeActionName={handleChangeActionName} />
          case 'notification':
            return <ConfigureActionNotification notificationConfig={notificationConfig} actionName={actionName} onChangeIsValid={handleActionNotificationChange} onNameChange={handleChangeActionName} onNotificationConfigChange={handleNotificationConfigChange} />
          case 'webhook':
            return (
              <ConfigureActionWebhook actionName={actionName} webhookConfig={webhookConfig} onChangeActionName={handleChangeActionName} onChangeIsValid={handleActionWebhookChange} onChangeWebhookConfig={handleChangeWebhookConfig} />
            )
        }
      case 2:
        switch (action) {
          case 'downlink':
            return (
              <>
                <ReviewActionDownlink downlinkConfiguration={downlinkConfiguration} />
                <Show when={() => isCreateDownlinkError && createDownlinkError?.data?.error?.error}>
                  <InlineMessage message={createDownlinkError?.data?.error?.error} />
                </Show>
              </>
            )
          case 'alarm':
            return <ReviewActionAlarm actionName={actionName} />
          case 'notification':
            return <ReviewActionNotification actionName={actionName} notificationConfig={notificationConfig} />
          case 'webhook':
            return <ReviewActionWebhook actionName={actionName} webhookConfig={webhookConfig} />
        }
    }
  }

  const handleSaveAction = async () => {
    switch (action) {
      case 'downlink':
        const downlinkPayload = {
          downlinkAckByUplink:
            downlinkConfiguration.downlink.sendUplink || false,
          name: downlinkConfiguration.downlink.name,
          fields: downlinkConfiguration.downlink.fields,
          brandEncoded: downlinkConfiguration.selectedDevice.brandEncoded,
          modelEncoded: downlinkConfiguration.selectedDevice.modelEncoded,
          actionName: downlinkConfiguration.downlink.downlink.name,
          deviceId: downlinkConfiguration.selectedDevice.id
        }
        isSuperAdmin
          ? await createDownlinkAction({
            ...downlinkPayload,
            organizationKey: organization.key
          })
          : await createDownlinkAction(downlinkPayload)
        break
      case 'alarm':
        const alarmPayload = {
          name: actionName
        }
        isSuperAdmin
          ? await createAlarmAction({
            ...alarmPayload,
            organizationKey: organization.key
          })
          : await createAlarmAction(alarmPayload)
        break
      case 'notification':
        const notificationPayload = {
          name: actionName,
          notifyAdmin: notificationConfig.notifyAdmin,
          notifyEditor: notificationConfig.notifyEditor,
          notifyViewer: notificationConfig.notifyViewer,
          emailsToNotify: notificationConfig.emailsToNotify
        }
        isSuperAdmin
          ? await createNotificationAction({
            ...notificationPayload,
            organizationKey: organization.key
          })
          : await createNotificationAction(notificationPayload)
        break
      case 'webhook':
        const webhookPayload = {
          name: actionName,
          webhookUrlMeasures: webhookConfig.urlMeasures,
          webhookUrlDelete: webhookConfig.urlDelete,
          webhookUrlAction: webhookConfig.urlAction,
          webhookHttpHeaders: webhookConfig.httpHeaders,
          sendTriggerInformation: webhookConfig.sendTriggerInformation
        }
        isSuperAdmin
          ? await createWebhookAction({
            ...webhookPayload,
            organizationKey: organization.key
          })
          : await createWebhookAction(webhookPayload)
        break
    }
  }

  return (<ZDialog
    id={'add-action-dialog'}
    isOpen={isOpen}
    onClose={() => handleClose(false)}
    title={t('actions.add')}
    customTitle={
      <DialogTitle marginBottom={5}>
        <Stepper
          activeStep={currentPage}
          alternativeLabel
        >
          <Step key="selectAction">
            <StepLabel>{t('actions.selectAnAction')}</StepLabel>
          </Step>
          <Step key="configureAction">
            <StepLabel>{t('actions.configureAction')}</StepLabel>
          </Step>
          <Step key="reviewAndSave">
            <StepLabel>{t('actions.reviewAndConfirm')}</StepLabel>
          </Step>
        </Stepper>
      </DialogTitle>}
    content={<Container maxWidth="md">{handleSteps()}</Container>}
    actions={
      <>
        <Button
          onClick={(event) => handleChangeCurrentPage(event, currentPage - 1)}
          disabled={currentPage === 0}
          startIcon={<ChevronLeftRoundedIcon />}
        >
          {t('common.back')}
        </Button>

        {2 > currentPage ? (
          <Button
            id="add-action-dialog-button-next"
            variant="text"
            disabled={handleButtonDisabled()}
            onClick={(event) => handleChangeCurrentPage(event, currentPage + 1)}
            endIcon={<ChevronRightRoundedIcon />}
          >
            {t('common.next')}
          </Button>
        ) : (
          <Button
            id="add-action-dialog-button-save"
            variant="contained"
            onClick={handleSaveAction}
            endIcon={<SaveRoundedIcon />}
          >
            {t('common.save')}
          </Button>
        )}
      </>}
  /> )
}

AddAction.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
}

export default AddAction
