import React, { useEffect, useState } from 'react'
import ModelsSelector from './ModelsSelector'
import { useGetQuantitiesQuery } from '../../../../store'
import { Autocomplete, TextField } from '@mui/material'
import { t } from 'i18next'
import { useValidationEffect } from '../../../../helpers/hooks'

const sortFn = (a, b) => {
  const label1 = a.label.toLowerCase()
  const label2 = b.label.toLowerCase()
  if (label1 < label2) return -1
  if (label1 > label2) return 1
  return 0
}

const MeasureTriggerRuleForm = ({
  applyToChildOrganizations,
  ruleType,
  selectedModels,
  quantityId,
  isEdit,
  onMeasureTriggerChanged,
  selectedOrganizationKey,
  validation
}) => {
  const { data: quantities = [], isLoading: isQuantitiesLoading } =
    useGetQuantitiesQuery()

  const [selectedQuantity, setSelectedQuantity] = useState(() => quantities.find(q => q.id === quantityId))

  useEffect(() => {
    if (!isQuantitiesLoading && quantities) {
      const selected = quantities.find((q) => q.id === quantityId)
      setSelectedQuantity(selected)
    }
  }, [quantities])

  useValidationEffect(() => {
    onMeasureTriggerChanged({ quantity: selectedQuantity, quantityId: selectedQuantity?.id, models: [] })
  }, [selectedQuantity])

  const handleModelsChanged = (data) => {
    onMeasureTriggerChanged(data)
  }

  const handleSelectedQuantity = (_data, value) => {
    setSelectedQuantity(value)
  }

  const filteredQuantities = quantities?.filter(({ models }) => models.filter(m => selectedModels.includes(m.id))) || []

  return (
    <>
      <Autocomplete
          sx={{
            marginTop: '30px'
          }}
          id="comunication-trigger-form-measure-selector"
          name="measureSelector"
          options={filteredQuantities
            .map((fq) => ({ ...fq, label: t(fq.label) }))
            .sort(sortFn)}
          value={
            selectedQuantity
              ? { ...selectedQuantity, label: t(selectedQuantity.label) }
              : ''
          }
          getOptionLabel={(option) => option.label || ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={handleSelectedQuantity}
          renderInput={(params) => (
            <TextField
              required
              name="quantity"
              {...params}
              label={t('triggers.selectMeasure')}
              variant="standard"
              error={validation?.messages?.quantityId?.length > 0}
              helperText={validation?.messages?.quantityId?.join(', ')}
            />
          )}
          disabled={isEdit}
        />
        <ModelsSelector
          applyToChildOrganizations={applyToChildOrganizations}
          ruleType={ruleType}
          selectedModels={selectedModels}
          selectedQuantity={selectedQuantity}
          isEdit={isEdit}
          onModelsChanged={handleModelsChanged}
          selectedOrganizationKey={selectedOrganizationKey}
          validation={validation}
        />
    </>
  )
}

export default MeasureTriggerRuleForm
