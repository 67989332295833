import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useFieldValidation from '../../../helpers/fieldValidation.js'

import {
  Autocomplete,
  Button,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  TextField
} from '@mui/material'
import { t } from 'i18next'

import {
  setErrorAlertMessage,
  setOpenDialog
} from '../../../store/slices/general.js'
import {
  useFetchSelfOrganizationsQuery,
  useFetchRolesStandardQuery,
  useCreateInviteMutation
} from '../../../store/index.js'
import Joi from 'joi'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  organization: Joi.object().required(),
  role: Joi.object().required()
}).unknown()

const InviteUser = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch()

  const { currentOrganization } = useSelector((state) => state.general)

  const {
    data: organizationsSuggestions,
    error: isOrganizationsSuggestionsError,
    isLoading: isOrganizationsSuggestionsLoading
  } = useFetchSelfOrganizationsQuery({ skip: !isOpen })

  const {
    data: rolesSuggestions = [],
    error: isRolesSuggestionsError,
    isLoading: isRolesSuggestionsLoading
  } = useFetchRolesStandardQuery({ skip: !isOpen })

  const [
    createInvite,
    {
      error: createInviteError,
      isError: isCreateInviteError,
      isSuccess: isCreateInviteSuccess
    }
  ] = useCreateInviteMutation()

  useEffect(() => {
    if (createInviteError?.data) {
      dispatch(setOpenDialog('errorAlert'))

      if (createInviteError.data.error) {
        dispatch(
          setErrorAlertMessage(
            `${createInviteError.data.statusCode} ${createInviteError.data.error}`
          )
        )
      } else if (createInviteError.error)
        dispatch(setErrorAlertMessage(createInviteError.error))
    }
  }, [isCreateInviteError])

  useEffect(() => {
    if (isCreateInviteSuccess) {
      dispatch(setOpenDialog('successAlert'))
      handleClose()

      setInvite({
        email: '',
        role: null,
        organization: null
      })
    }
  }, [isCreateInviteSuccess])

  const [validation, setValidation] = useState({ isValid: false })
  const [invite, setInvite] = useState({
    email: '',
    role: null,
    organization: null
  })

  const handleClose = () => setIsOpen(false)

  const handleChangeOrganization = (_, value) => setInvite({ ...invite, organization: value })

  const handleChangeRole = (_, value) => setInvite({ ...invite, role: value })

  const handleChangeEmail = (event) => setInvite({ ...invite, email: event.target.value })

  const handleCreateInvite = () => createInvite({
    email: invite.email,
    roleId: invite.role.id,
    organizationKey: invite.organization.key
  })

  useEffect(() => {
    if (invite.organization === null && organizationsSuggestions?.length) {
      setInvite({
        ...invite,
        organization: organizationsSuggestions.find(
          (o) => o.key === currentOrganization
        )
      })
    }
  }, [organizationsSuggestions])

  useEffect(() => {
    if (invite.role === null && rolesSuggestions.length) {
      setInvite({
        ...invite,
        role: rolesSuggestions.find((r) => r.name === 'viewer')
      })
    }
  }, [rolesSuggestions])

  // prevent execution of validation on first render
  useEffect(() => {
    setValidation(useFieldValidation(invite, schema))
  }, [invite])

  return (
    <>
      <ZDialog
        id="invite-dialog"
        isOpen={isOpen}
        onClose={handleClose}
        title={t('invites.add')}
        content={
          <>
            <Autocomplete
              id="InviteUser-ChooseOrganization"
              name="organization"
              disableClearable
              loading={isOrganizationsSuggestionsLoading}
              options={organizationsSuggestions}
              disabled={isOrganizationsSuggestionsError}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              value={invite.organization || { name: '' }}
              onChange={handleChangeOrganization}
              renderInput={(params) => (
                <TextField
                  name="organization"
                  {...params}
                  label={t('invites.chooseOrganization')}
                  variant="standard"
                  helperText={t('common.organization')}
                />
              )}
            />

            <Autocomplete
              id="InviteUser-ChooseRole"
              name="role"
              disableClearable
              loading={isRolesSuggestionsLoading}
              options={rolesSuggestions}
              disabled={isRolesSuggestionsError}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              value={invite.role}
              onChange={handleChangeRole}
              renderInput={(params) => (
                <TextField
                  name="role"
                  {...params}
                  label={t('invites.chooseRole')}
                  variant="standard"
                  helperText={t('common.role')}
                />
              )}
              sx={{ marginTop: 3 }}
            />

            <FormControl
              fullWidth
              sx={{ marginTop: 3 }}
            >
              <InputLabel htmlFor="InviteUser-Email">
                {t('common.email')}
              </InputLabel>

              <Input
                id="InviteUser-Email"
                name="email"
                onChange={handleChangeEmail}
              />

              <FormHelperText>
                {validation?.messages?.email?.join(', ')}
              </FormHelperText>
            </FormControl>
          </>
        }
        actions={
          <Button
            id='confirm-create-invite-user-button'
            variant="contained"
            color="primary"
            onClick={handleCreateInvite}
            sx={{ marginTop: 3 }}
          >
            {t('invites.add')}
          </Button>
        }
        centerActions={true}
      />
    </>
  )
}

export default InviteUser
