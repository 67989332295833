import {
  Button,
  Divider,
  Stack,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'
import SearchDevices from '../../Dialogs/SearchDevices'
const DeviceField = ({ device, isEdit, onChange }) => {
  const [isOpenDeviceSearchDialog, setIsOpenDeviceSearchDialog] = useState(false)
  
  return (
    <>
      {!isEdit && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <Typography variant="h4">
            {t('triggers.selectDeviceToPairWithTrigger')}
          </Typography>

          <Button
            id="trigger-form-find-device"
            variant="contained"
            onClick={() => setIsOpenDeviceSearchDialog(true)}
          >
            {t('triggers.findDevice')}
          </Button>
        </Stack>
      )}

      <Divider
        orientation="horizontal"
        sx={{ my: 3 }}
      />

      {device && (
        <>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
          >
            <Typography variant="h4">
              {t('triggers.selectedDevice')}:
            </Typography>
            <Stack justifyContent={'start'}>
              <Typography variant="title">{device.name}</Typography>
              <Typography variant="body">
                {device.brand} / {device.model}
              </Typography>
            </Stack>
          </Stack>

          <Divider
            orientation="horizontal"
            sx={{ my: 3 }}
          />
        </>
      )}

        <SearchDevices
          onSelected={onChange}
          isOpen={isOpenDeviceSearchDialog}
          onClose={() => setIsOpenDeviceSearchDialog(false)}
        />
    </>
  )
}

export default DeviceField