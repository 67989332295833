import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'
import { sanitizeFilters } from '../../helpers/sanitizer.js'

const triggersApi = createApi({
  reducerPath: 'triggers',
  baseQuery: customFetchBase,
  tagTypes: ['triggers'],
  endpoints(builder) {
    return {
      fetchTriggers: builder.query({
        query: ({ page, pageSize }) => {
          //TODO 21/11/2024 rimuvere perchè non viene piu usata
          logger.log('RTK API - Search triggers')
          return {
            url: '/automanTriggers',
            method: 'GET',
            params: { page, pageSize }
          }
        },
        providesTags: ['triggers']
      }),
      searchTriggers: builder.mutation({
        query: ({ filters, page, pageSize }) => {
          logger.log('RTK API - Search triggers')
          return {
            url: '/automanTriggers/search',
            method: 'POST',
            params: { page, pageSize },
            body: sanitizeFilters(filters)
          }
        },
        transformResponse: (response, meta) => {
          const count = parseInt(meta.response.headers.get('x-total-count'))
          return { data: response, count: count }
        },
        providesTags: ['triggers']
      }),
      getTrigger: builder.query({
        query: (id) => {
          logger.log('RTK API - Get Trigger')
          return {
            url: `/automanTriggers/${id}`,
            method: 'GET'
          }
        },
        providesTags: ['triggers']
      }),
      getQuantities: builder.query({
        query: () => {
          logger.log('RTK API - Search triggers')
          return {
            url: '/devices/quantities',
            method: 'GET'
          }
        },
        providesTags: ['triggers']
      }),
      getOrganizationModels: builder.query({
        query: ({ searchInChildrenOrganizations, organizationKey }) => {
          logger.log('RTK API - get organizations models')
          return {
            url: '/devices/models/summary',
            params: organizationKey
              ? {
                  searchInChildrenOrganizations,
                  organizationKey
                }
              : {
                  searchInChildrenOrganizations
                },
            method: 'GET'
          }
        },
        providesTags: ['triggers']
      }),
      getModelsByQuantity: builder.query({
        query: ({
          quantityId,
          searchInChildrenOrganizations,
          organizationKey
        }) => {
          logger.log('RTK API - get models by quantity')
          return {
            url: `/devices/quantities/${quantityId}/models`,
            params: organizationKey
              ? {
                  searchInChildrenOrganizations,
                  organizationKey
                }
              : {
                  searchInChildrenOrganizations
                },
            method: 'GET'
          }
        },
        providesTags: ['triggers']
      }),
      getNumericComparisonOperators: builder.query({
        query: () => {
          logger.log('RTK API - get numeric comparison operators')
          return {
            url: `numeric-comparison-operators`,
            method: 'GET'
          }
        },
        providesTags: ['triggers']
      }),
      createTrigger: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Create trigger ${payload.name}`)
          return {
            url: '/automanTriggers',
            method: 'POST',
            body: payload
          }
        },
        invalidatesTags: ['triggers', 'rules', 'actions']
      }),
      updateTrigger: builder.mutation({
        query: ({ payload, triggerId }) => {
          logger.log(`RTK API - Update trigger ${triggerId}`)
          return {
            url: `/automanTriggers/${triggerId}`,
            method: 'PATCH',
            body: payload
          }
        },
        invalidatesTags: ['triggers', 'rules', 'actions']
      }),
      deleteTrigger: builder.mutation({
        query: ({ id }) => {
          logger.log(`RTK API - Delete trigger ${id}`)
          return {
            url: `/automanTriggers/${id}`,
            method: 'DELETE'
          }
        },
        invalidatesTags: ['triggers', 'rules', 'actions']
      })
    }
  }
})

export const {
  useFetchTriggersQuery,
  useSearchTriggersMutation,
  useGetOrganizationModelsQuery,
  useGetModelsByQuantityQuery,
  useCreateTriggerMutation,
  useGetQuantitiesQuery,
  useGetNumericComparisonOperatorsQuery,
  useDeleteTriggerMutation,
  useGetTriggerQuery,
  useUpdateTriggerMutation
} = triggersApi

export { triggersApi }
