import { Badge, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { NotificationsRounded as NotificationsRoundedIcon } from '@mui/icons-material'
import NotificationsMenu from '../NotificationsMenu/NotificationMenu.jsx'

const NotificationsToggle = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-label="show notifications"
        color="inherit"
        onClick={handleClick}
      >
        <Badge
          badgeContent={4}
          color="primary"
        >
          <NotificationsRoundedIcon fontSize="small" />
        </Badge>
      </IconButton>
      <NotificationsMenu
        handleClose={handleClose}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        open={open}
      />
    </>
  )
}

export default NotificationsToggle
