import {
  Box,
  Divider,
  IconButton,
  Pagination,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  AddCircleOutlineRounded as AddCircleOutlineRoundedIcon,
  UploadRounded as UploadRoundedIcon,
  DeleteRounded as DeleteRoundedIcon
} from '@mui/icons-material'
import { shallowEqual, useSelector } from 'react-redux'
import { useDeleteDeviceMutation, useFetchDevicesQuery } from '../../../store'
import Can from '../../Layout/Can/Can.jsx'
import DeviceCard from '../Card/DeviceCard.jsx'
import DevicesCount from '../../Shared/Components/Count.jsx'
import Actions from '../Dialogs/Actions.jsx'
import AddDevice from '../Dialogs/AddDevice.jsx'
import UpdateDevice from '../Dialogs/UpdateDevice.jsx'
import SendAction from '../Dialogs/SendAction.jsx'
import DeviceAlarmedFilter from '../Filters/AlarmedFilter.jsx'
import Filters from '../Filters/Filters.jsx'
import ImportDevices from '../Dialogs/ImportDevices'
import ConfirmWithCheckDialog from '../../Shared/Dialogs/ConfirmWithCheckDialog'
import { t } from 'i18next'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert'

const DEFAULT_PAGE_SIZE = 7

const List = () => {
  const [selectedDevice, setSelectedDevice] = useState(null)
  const [isAddDeviceDialogOpen, setIsAddDeviceDialogOpen] = useState(false)
  const [isImportDeviceDialogOpen, setIsImportDeviceDialogOpen] = useState(false)
  const [isEditDeviceDialogOpen, setIsEditDeviceDialogOpen] = useState(false)
  const [isActionDialogOpen, setIsActionDialogOpen] = useState(false)
  const [isSendActionOpen, setIsSendActionOpen] = useState(false)
  const [isConfirmWithCheckOpen, setIsConfirmWithCheckOpen] = useState(false)

  const [page, setPage] = useState(1)

  const { devices: filters } = useSelector(
    (state) => state.filters,
    shallowEqual
  )

  const { credentials } = useSelector((state) => state.general, shallowEqual)

  const {
    data: devices,
    isError: isDevicesError,
    isLoading: isDevicesLoading,
    refetch: fetchDevices
  } = useFetchDevicesQuery({ filters, page, pageSize: DEFAULT_PAGE_SIZE })

  const [
    deleteDevice,
    {
      isSuccess: isDeleteDeviceSuccess,
      isError: isDeleteDeviceError,
      error: deleteDeviceError
    }
  ] = useDeleteDeviceMutation()

  useEffect(() => {
    fetchDevices({ filters, page, pageSize: DEFAULT_PAGE_SIZE })
    setPage(1)
  }, [filters, isDeleteDeviceSuccess])

  useEffect(() => {
    fetchDevices({ filters, page, pageSize: DEFAULT_PAGE_SIZE })
  }, [page])

  useOpenResultAlert(deleteDeviceError, { isError: isDeleteDeviceError, isSuccess: isDeleteDeviceSuccess })

  const handleChange = (_, value) => {
    setPage(value)
  }

  const handleDelete = (device) => {
    setSelectedDevice(device)
    setIsConfirmWithCheckOpen(true)
  }

  const handleEditDevice = (device) => {
    setSelectedDevice(device)
    setIsEditDeviceDialogOpen(true)
  }

  const handleEvents = (device) => {
    setSelectedDevice(device)
    setIsEventsDialogOpen(true)
  }

  const handleSetIsOpenEditDevice = (isOpen) => {
    setIsEditDeviceDialogOpen(isOpen)
    if (!isOpen) {
      setSelectedDevice(null)
    }
  }

  const handleAction = (device) => {
    setIsActionDialogOpen(true)
    setSelectedDevice(device)
  }

  const handleSetIsOpenActionDialogOpen = (isOpen) => {
    setIsActionDialogOpen(isOpen)
    if (!isOpen) {
      setSelectedDevice(null)
    }
  }

  const handleSendAction = (device) => {
    setIsActionDialogOpen(false)
    setIsSendActionOpen(true)
    setSelectedDevice(device)
  }

  const handleSendActionIsOpen = (isOpen) => {
    setIsSendActionOpen(isOpen)
    if (!isOpen) {
      setSelectedDevice(null)
    }
  }

  const handleCloseConfigrWithCheck = () => setIsConfirmWithCheckOpen(false)
  
  const handleConfirmAction = () => {
    deleteDevice(selectedDevice.id)
    setIsConfirmWithCheckOpen(false)
  }

  useEffect(() => {
    fetchDevices({ filters, page, pageSize: DEFAULT_PAGE_SIZE })
  }, [credentials])

  return (
    <Box id="device-list">
      <Stack direction="row">
        <Box sx={{ flexGrow: 1 }} />

        <Can action="devices_c">
          <IconButton
            id="devices-list-add-device"
            color="primary"
            onClick={() => setIsAddDeviceDialogOpen(true)}
          >
            <AddCircleOutlineRoundedIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => setIsImportDeviceDialogOpen(true)}
          >
            <UploadRoundedIcon />
          </IconButton>
        </Can>
      </Stack>

      <Divider />

      <Box>
        <Typography variant="h5">
          <DevicesCount itemsCount={devices?.count} />
        </Typography>
      </Box>

      <Can action="alarms_r">
        <Divider />

        <Box py={2}>
          <DeviceAlarmedFilter />
        </Box>

        <Divider />
      </Can>

      <Stack
        direction="row"
        spacing={1}
        my={4}
      >
        <Filters filters={filters} itemsCount={devices?.count} />
      </Stack>

      {isDevicesLoading && (
        <Stack
          spacing={2}
          sx={{ marginBottom: '20px' }}
        >
          {[...Array(DEFAULT_PAGE_SIZE).keys()].map((index) => (
            <Skeleton
              key={`skeleton-${index}`}
              variant="rounded"
              sx={{ height: '230px' }}
            ></Skeleton>
          ))}
        </Stack>
      )}

      {!isDevicesLoading &&
        !isDevicesError &&
        devices?.data.map((device, i) => (
          <DeviceCard
            key={`device-${i}`}
            id={`device-card-${i}`}
            device={device}
            onDelete={() => handleDelete(device)}
            onEdit={handleEditDevice}
            onAction={handleAction}
            onEvents={handleEvents}
          />
        ))}

      <Stack
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        {!isDevicesLoading && !!devices?.count && (
          <Pagination
            count={Math.ceil(devices.count / DEFAULT_PAGE_SIZE)}
            page={page}
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        )}
      </Stack>

      <Can action="devices_c">
        {isAddDeviceDialogOpen && (
          <AddDevice
            isOpen={isAddDeviceDialogOpen}
            onClose={() => setIsAddDeviceDialogOpen(false)}
          />
        )}
        {isImportDeviceDialogOpen && (
          <ImportDevices
            isOpen={isImportDeviceDialogOpen}
            setIsOpen={setIsImportDeviceDialogOpen}
          />
        )}
      </Can>

      <Can expression={() => !!selectedDevice} action="devices_d">
        <ConfirmWithCheckDialog
          isOpen={isConfirmWithCheckOpen}
          icon={<DeleteRoundedIcon size={60} color="#ef5350"/>}
          onClose={handleCloseConfigrWithCheck}
          onConfirmAction={handleConfirmAction}
          questionText={t('devices.deleteDevice.title')}
          subQuestionText={selectedDevice?.name}
          copyText={selectedDevice?.id}
          bodyText={t('devices.deleteDevice.subtitle')}
          inputSuggestionText={t('devices.deleteDevice.helper')}
        />
      </Can>

      <Can action="devices_u">
        {isActionDialogOpen && (
          <Actions
            onSendAction={handleSendAction}
            isOpen={isActionDialogOpen}
            setIsOpen={handleSetIsOpenActionDialogOpen}
            device={selectedDevice}
          />
        )}
        {isSendActionOpen && (
          <SendAction
            device={selectedDevice}
            isOpen={isSendActionOpen}
            setIsOpen={handleSendActionIsOpen}
          />
        )}
        {isEditDeviceDialogOpen && (
          <UpdateDevice
            deviceId={selectedDevice.id}
            isOpen={isEditDeviceDialogOpen}
            setIsOpen={handleSetIsOpenEditDevice}
          />
        )}
      </Can>
    </Box>
  )
}

export default List
