import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'
import sanitizer from '../../helpers/sanitizer.js'

const actionsApi = createApi({
  reducerPath: 'actions',
  baseQuery: customFetchBase,
  tagTypes: ['actions'],
  endpoints(builder) {
    return {
      searchActions: builder.query({
        query: ({ page, pageSize, organizationKeys, fullText }) => {
          logger.log('RTK API - Search actions')
          return {
            url: '/all-actions',
            method: 'GET',
            params: sanitizer({
              page,
              pageSize,
              organizationKeys,
              fullText
            })
          }
        },
        transformResponse: (response, meta) => {
          const count = meta.response.headers.get('x-total-count')
            ? parseInt(meta.response.headers.get('x-total-count'))
            : response.length
          return { data: response, count: count }
        },
        providesTags: ['actions']
      }),
      searchDownlinks: builder.query({
        query: (payload) => {
          logger.log('RTK API - Search downlinks')
          return {
            url: `/brands/${payload.brand}/models/${payload.model}/actions?withFields=true`,
            method: 'GET'
          }
        },
        providesTags: ['actions']
      }),
      deleteAction: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Delete action ${payload.id}`)
          return {
            url: `/action/${payload.type}/${payload.id}`,
            method: 'DELETE'
          }
        },
        invalidatesTags: ['triggers', 'rules', 'actions']
      }),
      createDownlinkAction: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Create action ${payload.name}`)
          return {
            url: '/downlinks/actions',
            method: 'POST',
            body: payload
          }
        },
        invalidatesTags: ['triggers', 'rules', 'actions']
      }),
      createAlarmAction: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Create action ${payload.name}`)
          return {
            url: '/events/actions',
            method: 'POST',
            body: sanitizer(payload)
          }
        },
        invalidatesTags: ['triggers', 'rules', 'actions']
      }),
      createNotificationAction: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Create action ${payload.name}`)
          return {
            url: '/notifications/actions',
            method: 'POST',
            body: sanitizer(payload)
          }
        },
        invalidatesTags: ['triggers', 'rules', 'actions']
      }),
      createWebhookAction: builder.mutation({
        query: (payload) => {
          logger.log(`RTK API - Create action ${payload.name}`)
          return {
            url: '/webhooks',
            method: 'POST',
            body: sanitizer(payload)
          }
        },
        invalidatesTags: ['triggers', 'rules', 'actions']
      }),
      encodeFields: builder.mutation({
        query: ({ payload, brandEncoded, modelEncoded, actionName }) => {
          logger.log('RTK API - Encode fields')
          return {
            url: `/brands/${brandEncoded}/models/${modelEncoded}/actions/${actionName}`,
            method: 'PUT',
            body: payload
          }
        },
        invalidatesTags: ['actions']
      }),
      sendDownlinkAction: builder.mutation({
        query: ({ payload, deviceId }) => {
          logger.log('RTK API - Send downlink', payload)
          return {
            url: `devices/${deviceId}/downlinks`,
            method: 'POST',
            body: payload
          }
        },
        invalidatesTags: ['actions']
      })
    }
  }
})

export const {
  useSearchActionsQuery,
  useSearchDownlinksQuery,
  useCreateDownlinkActionMutation,
  useCreateAlarmActionMutation,
  useCreateNotificationActionMutation,
  useCreateWebhookActionMutation,
  useDeleteActionMutation,
  useEncodeFieldsMutation,
  useSendDownlinkActionMutation
} = actionsApi

export { actionsApi }
