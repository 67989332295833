import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

const Can = ({ children, action, expression = () => true } ) => {
  const { credentials, currentOrganization } = useSelector((state) => state.general)

  if (!credentials?.permissions?.permissions?.[currentOrganization]?.includes(action) || !expression()) {
    return null
  }

  return <Fragment>{children}</Fragment>
}

Can.propTypes = {
  children: PropTypes.node,
  action: PropTypes.string,
  expression: PropTypes.func
}

export default Can
