import { Grid2, Stack, Box, Typography } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import { useFetchDevicesQuery } from '../../../../store'
import { SensorsRounded as SensorsRoundedIcon } from '@mui/icons-material'
import moment from 'moment'
import PropTypes from 'prop-types'

const DevicesList = ({ onChange, selectedDevice }) => {
  const {
    data: devices = { data: [], count: 0 },
    isError: isDevicesError,
    isLoading: isDevicesLoading
  } = useFetchDevicesQuery(
    {
      filters: {
        modelIds: [selectedDevice?.modelId],
        brandIds: [selectedDevice?.brandId]
      },
      page: 1,
      pageSize: 100
    },
    { skip: !selectedDevice?.modelId || !selectedDevice?.modelId }
  )

  const handleChangeSelectedDevice = (_, device) => {
    onChange(device)
  }

  return (
    <>
      {!isDevicesLoading &&
        !isDevicesError &&
        devices.data.map((device, i) => (
          <Grid2
            id={`create-action-device-${i}`}
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
            py={2}
            px={1}
            key={`device-${i}`}
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              backgroundColor:
                device.id == selectedDevice?.id
                  ? 'rgb(176, 222, 242, 0.3)'
                  : '#fff'
            }}
            onClick={(event) => handleChangeSelectedDevice(event, device)}
          >
            <Grid2 size={1}>
              <Box
                mt={1}
                pl={2}
              >
                <SensorsRoundedIcon sx={{ color: '#5CDE9A' }} />
              </Box>
            </Grid2>
            <Grid2 size="grow">
              <Stack>
                <Typography>{device.name}</Typography>
                <Typography variant="body2">{device.address}</Typography>
              </Stack>
            </Grid2>
            <Grid2 size={3}>
              <Typography variant="body2">
                {t('common.lastUpdate')}:{' '}
                {device.lastMeasurementAt
                  ? moment(device.lastMeasurementAt).format(
                    'DD.MM.YYYY HH:mm:ss'
                  )
                  : t('common.noPresent')}
              </Typography>
            </Grid2>
          </Grid2>
        ))}
    </>
  )
}

DevicesList.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedDevice: PropTypes.object.isRequired
}

export default DevicesList
