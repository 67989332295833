const formatDateExtendedIT = (dateString) => {
  const date = new Date(dateString)

  const dayOfWeekIT = ['dom', 'lun', 'mar', 'mer', 'gio', 'ven', 'sab']

  const day = dayOfWeekIT[date.getDay()]
  let dayNumber = date.getDate()
  let month = date.getMonth() + 1
  const year = date.getFullYear()
  let hour = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  dayNumber = dayNumber < 10 ? '0' + dayNumber : dayNumber
  month = month < 10 ? '0' + month : month
  hour = hour < 10 ? '0' + hour : hour
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return `${day} ${dayNumber}.${month}.${year} ${hour}:${minutes}:${seconds}`
}

const formatDateShort = (dateString) => {
  const date = new Date(dateString)

  let dayNumber = date.getDate()
  let month = date.getMonth() + 1
  let hour = date.getHours()
  let minutes = date.getMinutes()

  dayNumber = dayNumber < 10 ? '0' + dayNumber : dayNumber
  month = month < 10 ? '0' + month : month
  hour = hour < 10 ? '0' + hour : hour
  minutes = minutes < 10 ? '0' + minutes : minutes

  return `${dayNumber}/${month} - ${hour}:${minutes}`
}

export { formatDateExtendedIT, formatDateShort }
