import { Stack, TextField, Typography } from '@mui/material'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import React, { useCallback, useEffect, useState } from 'react'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { GOOGLE_MAPS_API_KEY } from '../../../configs/environment.js'
import PropTypes from 'prop-types'
import Joi from 'joi'
import useFieldValidation from '../../../helpers/fieldValidation.js'

const schema = Joi.object({
  position: {
    latitude: Joi.number().required(),
    longitude: Joi.number().required()
  }
})

const defaultCenter = {
  lat: 45.745,
  lng: 9.823
}

const containerStyle = {
  width: '100%',
  height: '280px'
}

const mapOptions = {
  mapTypeControl: false
}

const UpdatePosition = ({ deviceData, onChange }) => {
  const [updatedPositionData, setUpdatedDeviceData] = useState({
    position: deviceData.device.position
  })

  useEffect(() => {
    onChange(updatedPositionData)
  }, [updatedPositionData])

  const { isLoaded } = useJsApiLoader({
    id: 'cityeye-map',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  })

  const { language } = useSelector((state) => state.general)

  const [, setMap] = useState(null)
  const [validation, setValidation] = useState(false)

  const onLoad = useCallback((map) => {
    setMap(map)
  }, [])

  const onUnmount = useCallback(() => {
    setMap(null)
  }, [language])

  const onChangeLatitude = (event) => {
    setUpdatedDeviceData({
      ...updatedPositionData,
      position: {
        ...updatedPositionData.position,
        latitude: parseFloat(event.target.value)
      }
    })
  }

  const onChangeLongitude = (event) => {
    setUpdatedDeviceData({
      ...updatedPositionData,
      position: {
        ...updatedPositionData.position,
        longitude: parseFloat(event.target.value)
      }
    })
  }

  useEffect(() => {
    setValidation(useFieldValidation(updatedPositionData, schema))
  }, [updatedPositionData])

  return (
    <>
      <Stack
        direction="column"
        spacing={5}
      >
        <Typography
          variant="h2"
          py={1}
        >
          {t('devices.addPosition')}
        </Typography>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          <TextField
            label={t('common.latitude')}
            name={'latitude'}
            variant="standard"
            type="number"
            defaultValue={
              updatedPositionData.position?.latitude || defaultCenter.lat
            }
            fullWidth
            onChange={onChangeLatitude}
            error={validation?.messages?.position?.latitude?.length > 0}
            helperText={validation?.messages?.position?.latitude?.join(', ')}
          />

          <TextField
            label={t('common.longitude')}
            name={'longitude'}
            variant="standard"
            type="number"
            defaultValue={
              updatedPositionData.position?.longitude || defaultCenter.lng
            }
            fullWidth
            onChange={onChangeLongitude}
          />
        </Stack>

        {isLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{
              lat: updatedPositionData.position?.latitude || defaultCenter.lat,
              lng: updatedPositionData.position?.longitude || defaultCenter.lng
            }}
            zoom={18}
            options={mapOptions}
            onUnmount={onUnmount}
            onLoad={onLoad}
          >
            <Marker
              position={{
                lat:
                  updatedPositionData.position?.latitude || defaultCenter.lat,
                lng:
                  updatedPositionData.position?.longitude || defaultCenter.lng
              }}
            />
          </GoogleMap>
        )}
      </Stack>
    </>
  )
}

UpdatePosition.propTypes = {
  deviceData: PropTypes.object,
  onChange: PropTypes.func
}

export default UpdatePosition
