import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid2,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { t } from 'i18next'
import {
  useFetchSelfOrganizationsQuery,
  useFetchTimezonesQuery,
  useFetchAllNetworkServersQuery,
  useFetchOrganizationProfilesQuery,
  useFetchKeySuggestionMutation,
  useFetchOrganizationProfileQuery
} from '../../../store'
import PropTypes from 'prop-types'
import useFieldValidation from '../../../helpers/fieldValidation'
import Joi from 'joi'
import { CloudUploadRounded as CloudUploadRoundedIcon } from '@mui/icons-material'
import { useValidationEffect } from '../../../helpers/hooks'
import { useSelector } from 'react-redux'

const createSchema = Joi.object({
  parentOrganization: Joi.object().empty(null).required(),
  timezone: Joi.string().empty(null).required(),
  organizationName: Joi.string().empty(null).required(),
  vatNumber: Joi.string().optional().allow(null, '').default(null),
  organizationKey: Joi.string().empty(null).required(),
  networkServer: Joi.object().empty(null).required(),
  domain: Joi.string().empty(null).required(),
  profile: Joi.alternatives().conditional('parentOrganization.key', {
    is: 'root',
    then: Joi.object().empty(null).required(),
    otherwise: Joi.object().empty(null)
  }),
  file: Joi.object().allow(null, '').default(null)
})

const editSchema = Joi.object({
  parentOrganization: Joi.object().empty(null),
  timezone: Joi.string().empty(null).required(),
  organizationName: Joi.string().empty(null).required(),
  vatNumber: Joi.string().optional().allow(null, '').default(null),
  organizationKey: Joi.string().empty(null).required(),
  domain: Joi.string().allow(null, '').default(null).required(),
  networkServer: Joi.string().allow(null, '').default(null),
  profile: Joi.alternatives().conditional('parentOrganization.key', {
    is: Joi.any().valid(null, '', 'root'),
    then: Joi.object().empty(null).required(),
    otherwise: Joi.object().empty(null)
  }),
  file: Joi.object().allow(null, '').default(null)
})

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

const OrganizationForm = ({ onChange, organization }) => {
  const [validation, setValidation] = useState({ isValid: false })
  const [networkServerUserOptions, setNetworkServerOptions] = useState([])
  const firstTime = useRef(true)

  const { credentials } = useSelector((state) => state.general)

  const isSuperAdmin = credentials?.permissions?.isSuperAdmin || false

  const [fetchKeySuggestion] = useFetchKeySuggestionMutation()
  const { data: organizations, isLoading: isOrganizationsLoading } =
    useFetchSelfOrganizationsQuery()
  const { data: timezones, isLoading: isTimezonesLoading } =
    useFetchTimezonesQuery()
  const { data: networkServers, isLoading: isNetworkServersLoading } =
    useFetchAllNetworkServersQuery()
  const {
    data: organizationProfiles,
    isLoading: isOrganizationProfilesLoading
  } = useFetchOrganizationProfilesQuery()

  const organizationsOptions =
    organizations && isSuperAdmin
      ? [{ key: 'root', name: 'root' }, ...organizations]
      : organizations

  useEffect(() => {
    if (networkServers)
      setNetworkServerOptions([
        {
          server: networkServers[0].id,
          user: 'newUser',
          userId: 'newUser'
        },
        {
          server: 'nsproduction',
          user: 'Nessuno',
          userId: 'withoutUser'
        },
        ...networkServers[0].users.map((u) => ({
          server: networkServers[0].id,
          user: u.name,
          userId: u.id
        }))
      ])
  }, [networkServers])

  const { data: organizationProfile } = useFetchOrganizationProfileQuery(
    organization?.orgProfileId,
    {
      skip: !organization?.orgProfileId
    }
  )

  const [organizationData, setOrganizationData] = useState({
    organizationName: ''
  })

  useEffect(() => {
    setOrganizationData({
      parentOrganization: organization
        ? organization.parentOrganization || { key: 'root' }
        : null,
      timezone: organization?.timeZone || null,
      organizationName: organization?.name || '',
      vatNumber: organization?.vatNumber || '',
      organizationKey: organization?.key || '',
      domain: organization?.domain || '',
      networkServer: organization?.networkServerUsers?.length
        ? organization?.networkServerUsers[0].user
        : '',
      file: organization?.file || null,
      profile: organizationProfile
    })
  }, [organizationProfile])

  useValidationEffect(() => {
    const newValidation = useFieldValidation(
      organizationData,
      organization ? editSchema : createSchema
    )
    setValidation(newValidation)
    onChange(organizationData, newValidation.isValid)
  }, [organizationData])

  const handleParentOrganizationChange = (_, value) => {
    if (value === null || value.key == 'root')
      setOrganizationData({
        ...organizationData,
        parentOrganization: value,
        profile: null
      })
    else
      setOrganizationData({
        ...organizationData,
        parentOrganization: value
      })
  }

  const handleTimezoneChange = (_, value) => {
    setOrganizationData({
      ...organizationData,
      timezone: value
    })
  }

  const changeDomainAndKey = async (newOrgName) => {
    const keySuggestionResponse = await fetchKeySuggestion(newOrgName)
    setOrganizationData({
      ...organizationData,
      domain: keySuggestionResponse.data.suggestion,
      organizationKey: keySuggestionResponse.data.suggestion
    })
  }

  const changeDomain = async (newOrgName) => {
    const keySuggestionResponse = await fetchKeySuggestion(newOrgName)
    setOrganizationData({
      ...organizationData,
      domain: keySuggestionResponse.data.suggestion
    })
  }

  useEffect(() => {
    if (organizationData == null || organizationData.organizationName == '') {
      return
    }

    if (firstTime.current) {
      firstTime.current = false
      return
    }

    if (!organization) {
      const debounce = setTimeout(() => {
        changeDomainAndKey(organizationData.organizationName)
      }, 500)
      return () => clearTimeout(debounce)
    } else {
      const debounce = setTimeout(() => {
        changeDomain(organizationData.organizationName)
      }, 500)
      return () => clearTimeout(debounce)
    }
  }, [organizationData.organizationName])

  const handleNameChange = async (event) => {
    const newOrgName = event.target.value
    setOrganizationData({ ...organizationData, organizationName: newOrgName })
  }

  const handleVatNumberChange = (event) => {
    setOrganizationData({
      ...organizationData,
      vatNumber: event.target.value
    })
  }

  const handleOrganizationKeyChange = (event) => {
    setOrganizationData({
      ...organizationData,
      organizationKey: event.target.value
    })
  }

  const handleDomainChange = (event) => {
    setOrganizationData({
      ...organizationData,
      domain: event.target.value
    })
  }

  const handleNetworkServerUserChange = (_, value) => {
    setOrganizationData({
      ...organizationData,
      networkServer: value
    })
  }

  const handleProfileChange = (_, value) => {
    setOrganizationData({
      ...organizationData,
      profile: value
    })
  }

  const handleUploadedFiles = (event) => {
    setOrganizationData({
      ...organizationData,
      file: event.target.files[0]
    })
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        py={2}
        container
        spacing={2}
      >
        <Grid2
          size={6}
          py={1}
        >
          <Typography>{t('organizations.root')}</Typography>
        </Grid2>

        <Grid2
          size={6}
          py={1}
        >
          {organization ? (
            <TextField
              variant="standard"
              disabled
              fullWidth
              value={organizationData?.parentOrganization?.name || 'root'}
            />
          ) : (
            <Autocomplete
              id="AddOrganization-RootOrganization"
              loading={isOrganizationsLoading}
              getOptionLabel={(option) => option.name}
              onChange={handleParentOrganizationChange}
              isOptionEqualToValue={(o1, o2) => o1.key === o2.key}
              fullWidth
              options={organizationsOptions || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t('common.choose')}
                  variant="standard"
                  error={validation?.messages?.parentOrganization?.length > 0}
                  helperText={validation?.messages?.parentOrganization?.join(
                    ', '
                  )}
                />
              )}
            />
          )}
        </Grid2>

        <Grid2
          size={6}
          py={1}
        >
          <Typography>{t('common.timezone')}</Typography>
        </Grid2>

        <Grid2
          size={6}
          py={1}
        >
          {timezones && (
            <Autocomplete
              id="AddOrganization-Timezone"
              value={organization?.timeZone || organizationData?.timezone}
              loading={isTimezonesLoading}
              options={timezones}
              onChange={handleTimezoneChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t('common.choose')}
                  variant="standard"
                  error={validation?.messages?.timezone?.length > 0}
                  helperText={validation?.messages?.timezone?.join(', ')}
                />
              )}
            />
          )}
        </Grid2>

        <Grid2 size={12}>
          <Divider />
        </Grid2>

        <Grid2
          size={6}
          py={1}
        >
          <TextField
            id='add-organization-name'
            required
            label={t('common.name')}
            variant="standard"
            fullWidth
            onChange={handleNameChange}
            value={organizationData?.organizationName}
            error={validation?.messages?.organizationName?.length > 0}
            helperText={validation?.messages?.organizationName?.join(', ')}
          />
        </Grid2>

        <Grid2
          size={6}
          py={1}
        >
          <TextField
            label={t('organizations.vat')}
            variant="standard"
            fullWidth
            disabled={!!organization}
            value={organizationData?.vatNumber}
            onChange={handleVatNumberChange}
          />
        </Grid2>

        <Grid2
          size={6}
          py={1}
        >
          <TextField
            required
            label={t('organizations.key')}
            variant="standard"
            fullWidth
            disabled={!!organization}
            value={
              organizationData?.organizationKey
                ? organizationData.organizationKey
                : ''
            }
            onChange={handleOrganizationKeyChange}
            error={validation?.messages?.organizationKey?.length > 0}
            helperText={validation?.messages?.organizationKey?.join(', ')}
          />
        </Grid2>

        <Grid2
          size={6}
          py={1}
        >
          <TextField
            required
            label={t('organizations.domain')}
            variant="standard"
            fullWidth
            value={organizationData?.domain ? organizationData.domain : ''}
            onChange={handleDomainChange}
            error={validation?.messages?.domain?.length > 0}
            helperText={validation?.messages?.domain?.join(', ')}
          />
        </Grid2>

        <Grid2 size={12}>
          <Divider />
        </Grid2>

        <Grid2
          size={6}
          py={1}
        >
          <Typography>
            Network server:{' '}
            <strong>
              {!isNetworkServersLoading && networkServers
                ? networkServers[0].id
                : ''}
            </strong>
          </Typography>
        </Grid2>

        <Grid2
          size={6}
          py={1}
        >
          {organization ? (
            <TextField
              fullWidth={true}
              disabled
              label={t('common.choose')}
              variant="standard"
              value={organization.networkServerUsers[0]?.user || 'NewUser'}
            />
          ) : (
            <Autocomplete
              id="AddOrganization-networkServer"
              loading={isNetworkServersLoading}
              getOptionLabel={(option) => option.user}
              disableClearable={true}
              onChange={handleNetworkServerUserChange}
              isOptionEqualToValue={(o1, o2) => o1.userId == o2.userId}
              options={networkServerUserOptions}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t('common.choose')}
                  variant="standard"
                  error={validation?.messages?.networkServer?.length > 0}
                  helperText={validation?.messages?.networkServer?.join(', ')}
                />
              )}
            />
          )}
        </Grid2>

        {organizationData?.parentOrganization?.key === 'root' && (
          <>
            <Grid2
              size={6}
              py={1}
            >
              <Typography>{t('organizations.organizationProfile')}</Typography>
            </Grid2>

            <Grid2
              size={6}
              py={1}
            >
              {organizationProfiles?.length > 0 && (
                <Autocomplete
                  id="AddOrganization-profile"
                  loading={isOrganizationProfilesLoading}
                  getOptionLabel={(option) => option.name}
                  onChange={handleProfileChange}
                  options={organizationProfiles}
                  value={organizationProfiles.find(
                    (x) => x.id == organization?.orgProfileId
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t('common.choose')}
                      variant="standard"
                      error={validation?.messages?.profile?.length > 0}
                      helperText={validation?.messages?.profile?.join(', ')}
                    />
                  )}
                />
              )}
            </Grid2>

            <Grid2 size={12}>
              <Divider />
            </Grid2>
          </>
        )}

        <Grid2
          display="flex"
          justifyContent="center"
          size={12}
        >
          <Card>
            <CardHeader
              title={
                <Typography variant="title">
                  {t('organizations.loadLogo')}
                </Typography>
              }
              subheader={
                <Typography
                  component="p"
                  variant="body2"
                  color={'#626262'}
                >
                  {t('organizations.maxSize')}
                </Typography>
              }
            />
            <CardContent>
              <Stack
                direction={'row'}
                justifyContent={'center'}
                spacing={3}
                alignItems={'center'}
              >
                <Button
                  sx={{ height: '38px' }}
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadRoundedIcon />}
                >
                  {t('organizations.uploadLogo')}
                  <VisuallyHiddenInput
                    onChange={handleUploadedFiles}
                    type="file"
                  />
                </Button>
                {organizationData?.file && (
                  <Box
                    component="img"
                    sx={{ height: '100px' }}
                    src={URL.createObjectURL(organizationData.file)}
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  )
}

OrganizationForm.propTypes = {
  onChange: PropTypes.func,
  returnOrganizationData: PropTypes.func,
  organization: PropTypes.object
}

export default OrganizationForm
