import { Box, Button, Dialog, Typography, DialogActions } from '@mui/material'
import React from 'react'
import { t } from 'i18next'

const ErrorDialog = ({ isOpen, errorFrom, errorDescription, onClose }) => {
  return (
    <Dialog
      maxWidth={'sm'}
      open={isOpen}
      scroll="body"
      onClose={onClose}
      sx={{ borderRadius: 100 }}
    >
      <Box p={5}>
        <Typography
          align="center"
          variant="h1"
          color="error"
        >
          {t('common.error')}
        </Typography>

        <Typography
          align="center"
          variant="h4"
          marginBottom={3}
        >
          {errorFrom}
        </Typography>

        <Typography
          variant="body"
          align="center"
          marginBottom={3}
        >
          {errorDescription}
        </Typography>

        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginTop: 3
          }}
        >
          <Button
            onClick={onClose}
            variant="contained"
            color="error"
          >
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ErrorDialog
