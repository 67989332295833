/* eslint-disable no-unused-vars */
import { Chip, Container, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import GatewaysTable from '../Tables/GatewaysTable.jsx'
import GatewaysFilters from '../Filters/Filters.jsx'
import { t } from 'i18next'
import { sorting } from '../../../store/states/sorting.js'
import { useSearchParams } from 'react-router-dom'
import { useSearchGatewaysMutation } from '../../../store/index.js'

const DEFAULT_PAGE_SIZE = 25

const Gateways = () => {
  const [searchParams] = useSearchParams()
  const fullText = searchParams.get('search')

  const { gateways: filters } = useSelector((state) => state.filters)

  const [{sort, direction}, setSorting] = useState({
    direction: 'desc',
    sort: 'lastMeasurementAt'
  })

  const [page, setPage] = useState(1)

  const [searchGateways, { data: gateways, isLoading: isGatewaysLoading }] =
    useSearchGatewaysMutation({
      filters: { ...filters, fullText },
      page,
      pageSize: DEFAULT_PAGE_SIZE,
      sort,
      direction
    })

    useEffect(() => {
      searchGateways({
        filters: { ...filters, fullText },
        page,
        pageSize: DEFAULT_PAGE_SIZE,
        sort,
        direction
      })
    }, [filters, page, sort, direction, fullText])

    const handleChangePage = (_, value) => setPage(value)
    const handleChangeSorting = (value) => setSorting(value)

  return (
    <Container sx={{ py: 3 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h2">{t('common.gateways')}</Typography>
          <Chip
            label={gateways?.count}
            color="primary"
          />
        </Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        my={4}
      >
        <GatewaysFilters itemsCount={gateways?.count} />
      </Stack>

      <GatewaysTable gateways={gateways} page={page} sorting={sorting} onPageChange={handleChangePage} onSortingChange={handleChangeSorting} isLoading={isGatewaysLoading} />
    </Container>
  )
}

export default Gateways
