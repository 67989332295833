import React from 'react'
import {
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import { t } from 'i18next'

const TriggerRuleType = ({ onChange, isEdit, ruleType}) => {

  return (
  <>
    <InputLabel id="trigger-type-select-label">
      {t('common.typology')}
    </InputLabel>
    <Select
      labelId="trigger-type-select-label"
      id="trigger-type-select"
      value={ruleType || ''}
      label={t('common.typology')}
      onChange={onChange}
      fullWidth
      disabled={isEdit}
    >
    <MenuItem value="threshold">{t('triggers.types.threshold')}</MenuItem>
    <MenuItem value="communication">{t('triggers.types.communication')}</MenuItem>
    <MenuItem value="measure-communication">{t('triggers.types.measureCommunication')}</MenuItem>
    <MenuItem value="delta">{t('triggers.types.delta')}</MenuItem>
    <MenuItem value="trigger">{t('triggers.types.trigger')}</MenuItem>
    <MenuItem value="measure-trigger">{t('triggers.types.measureTrigger')}</MenuItem>
    <MenuItem value="scheduled-trigger">{t('triggers.types.scheduledTrigger')}</MenuItem>
  </Select>
  </>
  )
}

export default TriggerRuleType
