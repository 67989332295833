import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { t } from 'i18next'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React from 'react'
import ButtonField from './ButtonField.jsx'

const ButtonDatePicker = (props) => {
  const [open, setOpen] = React.useState(false)

  const shortcutsItems = [
    {
      label: t('common.today'),
      getValue: () => {
        return [moment().startOf('day'), moment().endOf('day')]
      }
    },
    {
      label: t('common.yesterday'),
      getValue: () => {
        return [
          moment().subtract(1, 'day').startOf('day'),
          moment().subtract(1, 'day').endOf('day')
        ]
      }
    },
    {
      label: t('common.lastWeek'),
      getValue: () => {
        return [moment().subtract(7, 'days').startOf('day'), moment()]
      }
    },
    {
      label: t('common.lastTwoWeeks'),
      getValue: () => {
        return [moment().subtract(15, 'days').startOf('day'), moment()]
      }
    },
    {
      label: t('common.lastMonth'),
      getValue: () => {
        return [moment().subtract(30, 'days').startOf('day'), moment()]
      }
    },
    {
      label: t('common.lastYear'),
      getValue: () => {
        return [moment().subtract(1, 'year').startOf('day'), moment()]
      }
    },
    {
      label: 'Reset',
      getValue: () => {
        return [moment().subtract(3, 'days').startOf('day'), moment()]
      }
    }
  ]

  return (
    <>
      <DateRangePicker
        slots={{ field: ButtonField, ...props.slots }}
        slotProps={{
          field: { value: props.value, setOpen },
          shortcuts: {
            items: shortcutsItems
          }
        }}
        {...props}
        disableFuture
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      />
    </>
  )
}

ButtonDatePicker.propTypes = {
  slots: PropTypes.any,
  value: PropTypes.array
}

export default ButtonDatePicker
