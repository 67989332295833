import { Box, Typography, Grid2 } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'

const ReviewActionDownlink = ({ downlinkConfiguration }) => {
  const downlink = downlinkConfiguration.downlink?.downlink || {}
  const selectedDevice = downlinkConfiguration.selectedDevice || {}
  const actionName = downlinkConfiguration.downlink?.name
  const sendUplink = downlinkConfiguration.downlink?.sendUplink

  return (
    <Box>
      <Box mb={6}>
        <Typography variant="body2">{selectedDevice?.model}</Typography>
        <Typography
          variant="heading"
          fontWeight="bold"
        >
          {selectedDevice.name}
        </Typography>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <Grid2
          container
          marginY={2}
          spacing={2}
        >
          <Grid2 size={6} py={1}>
            <Typography variant="h5" align="right">{t('actions.actionName')}:</Typography>
          </Grid2>
          <Grid2 size={6} py={1}>
            <Typography sx={{ lineHeight: "1.2" }}>{actionName}</Typography>
          </Grid2>

          <Grid2 size={6} py={1}>
            <Typography variant="h5" align="right">{t('common.device')}:</Typography>
          </Grid2>
          <Grid2 size={6} py={1}>
            <Typography sx={{ lineHeight: "1.2" }}>{selectedDevice.name}</Typography>
          </Grid2>

          <Grid2 size={6} py={1}>
            <Typography variant="h5" align="right">{t('actions.action')}:</Typography>
          </Grid2>
          <Grid2 size={6} py={1}>
            <Typography sx={{ lineHeight: "1.2" }}>{downlink.name}</Typography>
          </Grid2>

          <Grid2 size={6} py={1}>
            <Typography variant="h5" align="right">{t('actions.sendUplink')}:</Typography>
          </Grid2>
          <Grid2 size={6} py={1}>
            <Typography sx={{ lineHeight: "1.2" }}>{sendUplink ? 'Si' : 'No'}</Typography>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  )
}

ReviewActionDownlink.propTypes = {
  downlinkConfiguration: PropTypes.object.isRequired
}

export default ReviewActionDownlink
