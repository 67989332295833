import { createApi } from '@reduxjs/toolkit/query/react'
import qs from 'qs'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'

const filtersApi = createApi({
  reducerPath: 'filtersApi',
  baseQuery: customFetchBase,
  tagTypes: ['devices'],
  endpoints(builder) {
    return {
      fetchBrandsFilter: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch brands for filters`)
          return {
            url: `/filters/brands`,
            method: 'GET'
          }
        },
        providesTags: ['filters', 'devices'],
        invalidatesTags: ['filters']
      }),
      fetchModelsFilter: builder.query({
        query: (brandIds) => {
          logger.log(`RTK API - Fetch models for filters brandId: ${brandIds}`)
          return {
            url: `/filters/models?${qs.stringify(
              { brandId: brandIds },
              { arrayFormat: 'repeat' } // array format for api: {a: [0, 1]} -> 'a=0&a=1'
            )}`,
            method: 'GET'
          }
        },
        providesTags: ['filters', 'devices'],
        invalidatesTags: ['filters']
      }),
      fetchTagsFilter: builder.query({
        query: (tag) => {
          logger.log('RTK API - Search tags for filters')
          return {
            url: '/filters/tags',
            method: 'GET',
            params: {
              textSearch: tag
            }
          }
        },
        providesTags: ['filters', 'devices'],
        invalidatesTags: ['filters']
      }),
      fetchOrganizationsFilter: builder.query({
        query: () => {
          logger.log(`RTK API - Fetch organizations for filters`)
          return {
            url: `/filters/organizations`,
            method: 'GET'
          }
        },
        providesTags: ['filters', 'devices'],
        invalidatesTags: ['filters']
      })
    }
  }
})

export const {
  useFetchBrandsFilterQuery,
  useFetchModelsFilterQuery,
  useFetchTagsFilterQuery,
  useFetchOrganizationsFilterQuery
} = filtersApi

export { filtersApi }
