import React, { useEffect, useState } from 'react'
import ModelsSelector from './ModelsSelector'
import PropTypes from 'prop-types'
import { useGetQuantitiesQuery } from '../../../../store'

const TriggerRuleForm = ({
  applyToChildOrganizations,
  ruleType,
  selectedModels,
  quantityId,
  isEdit,
  onTriggerChanged,
  selectedOrganizationKey,
  validation
}) => {
  const { data: quantities = [], isLoading: isQuantitiesLoading } =
    useGetQuantitiesQuery()
  const [selectedQuantity, setSelectedQuantity] = useState()

  useEffect(() => {
    if (!isQuantitiesLoading && quantities) {
      const selected = quantities.find((q) => q.id === quantityId)
      setSelectedQuantity(selected)
    }
  }, quantities)

  const handleModelsChanged = (data) => {
    onTriggerChanged(data)
  }

  return (
    <>
      <ModelsSelector
        applyToChildOrganizations={applyToChildOrganizations}
        ruleType={ruleType}
        selectedModels={selectedModels}
        selectedQuantity={selectedQuantity}
        isEdit={isEdit}
        onModelsChanged={handleModelsChanged}
        selectedOrganizationKey={selectedOrganizationKey}
        validation={validation}
      />
    </>
  )
}

export default TriggerRuleForm
