import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography
} from '@mui/material'
import React, { useRef } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setFiltersDevices } from '../../../../store/slices/filters.js'

const Visibility = () => {
  const dispatch = useDispatch()

  const {
    devices: { showHidden }
  } = useSelector((state) => state.filters)

  const visible = useRef()
  const hidden = useRef()

  /**
   * updateState
   * @param {*} state the state to update
   */
  const updateState = (state) => {
    dispatch(
      setFiltersDevices({
        showHidden: state
      })
    )
  }

  /**
   * handleChange
   */
  const handleChange = () => {
    // if visible and hidden are both true or both false set the state of the filter to null
    if (
      (visible.current.checked && hidden.current.checked) ||
      (!visible.current.checked && !hidden.current.checked)
    ) {
      updateState(null)
      // if visible is set to true then update the state to false
    } else if (visible.current.checked) {
      updateState(false)
      // if hidden is set to true then update the state to true
    } else if (hidden.current.checked) {
      updateState(true)
    }
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.devices.other.visibility.label')}
        </Typography>
        <FormGroup
          aria-label="position"
          row
        >
          <FormControlLabel
            value="visible"
            control={
              <Checkbox
                inputRef={visible}
                onChange={handleChange}
                checked={showHidden !== null && !showHidden}
                disabled={showHidden !== null && showHidden}
              />
            }
            label={t('filters.devices.other.visibility.valueTrue')}
            sx={{ width: '100px' }}
          />

          <FormControlLabel
            value="hidden"
            control={
              <Checkbox
                inputRef={hidden}
                onChange={handleChange}
                checked={showHidden !== null && showHidden}
                disabled={showHidden !== null && !showHidden}
              />
            }
            label={t('filters.devices.other.visibility.valueFalse')}
            sx={{ width: '100px' }}
          />
        </FormGroup>
      </Stack>
    </>
  )
}

export default Visibility
