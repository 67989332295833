import { createSlice } from '@reduxjs/toolkit'
import { logger } from '../../helpers/logger/logger.js'
import { ruleAddActionAdd } from '../states'

const initialState = ruleAddActionAdd

const ruleAddActionAddSlice = createSlice({
  name: 'ruleAddActionAdd',
  initialState,
  reducers: {
    setSelectedAction: (state, action) => {
      state.selectedAction = action
    },
    resetActionPayload: () => {
      logger.log('ruleAddAction: reset to initial state')
      return initialState
    }
  }
})

export const { setSelectedAction, resetActionPayload: resetRulePayload } =
  ruleAddActionAddSlice.actions

export default ruleAddActionAddSlice.reducer
