import {
  Box,
  Grid2,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setShowModelSelector } from '../../../../store/slices/action-add'
import ModelSelector from './ModelSelector'
import DevicesList from './DevicesList'
import PropTypes from 'prop-types'
import { useFetchBrandsFilterQuery } from '../../../../store'

const SelectDevice = ({ onChange, selectedDevice }) => {
  const { showModelSelector } = useSelector((state) => state.actionAdd)
  const [brand, setBrand] = useState('')

  const dispatch = useDispatch()

  const { data: brands = [] } = useFetchBrandsFilterQuery()

  const handleBrandChange = (event) => {
    setBrand(event.target.value)
    onChange({
      ...selectedDevice,
      brandId: event.target.value.id,
      brandEncoded: event.target.value.name
    })
    dispatch(setShowModelSelector(true))
  }

  const handleDeviceChange = (device) => {
    onChange(device)
  }

  const handleModelChange = (device) => {
    onChange(device)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2
        container
        display="flex"
        alignItems="center"
        spacing={2}
      >
        <Grid2 size={6}>
          <FormControl fullWidth>
            <InputLabel id="brand-select-label">{t('common.brand')}</InputLabel>
            <Select
              labelId="brand-select-label"
              id="brand-select"
              value={brand}
              label={t('common.brand')}
              onChange={handleBrandChange}
            >
              {brands.map((brand, i) => (
                <MenuItem
                  key={`brand-${i}`}
                  value={brand}
                >
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>

        {showModelSelector && (
          <Grid2 size={6}>
            <ModelSelector
              onChange={handleModelChange}
              selectedDevice={selectedDevice}
            />
          </Grid2>
        )}
      </Grid2>

      <Box
        sx={{
          marginTop: 4,
          borderBottom: 2,
          borderColor: '#0f81fc',
          paddingBottom: 1
        }}
      >
        {t('actions.availableSensors')}
      </Box>

      <DevicesList
        onChange={handleDeviceChange}
        selectedDevice={selectedDevice}
      />
    </Box>
  )
}

SelectDevice.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedDevice: PropTypes.object.isRequired
}

export default SelectDevice
