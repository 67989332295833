import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'
import { useFetchModelsFilterQuery } from '../../../../store'
import PropTypes from 'prop-types'
import InlineMessage from '../../../Shared/Components/InlineMessage'

const ModelSelector = ({ onChange, selectedDevice }) => {
  const [model, setModel] = useState('')
  const { data: models = [] } = useFetchModelsFilterQuery(
    selectedDevice.brandId,
    { skip: !selectedDevice.brandId }
  )

  const handleModelChange = (event) => {
    setModel(event.target.value)
    onChange({ ...selectedDevice, modelId: event.target.value })
  }

  return models.length ? (
    <FormControl fullWidth>
      <InputLabel id="model-select-label">{t('common.model')}</InputLabel>
      <Select
        labelId="model-select-label"
        id="model-select"
        label={t('common.model')}
        onChange={handleModelChange}
        value={model}
      >
        {models.map((model, i) => (
          <MenuItem
            key={`model-${i}`}
            value={model.id}
          >
            {model.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <InlineMessage message={t('actions.modelNotFound')} />
  )
}

ModelSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedDevice: PropTypes.object.isRequired
}

export default ModelSelector
