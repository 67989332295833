import { createSlice } from '@reduxjs/toolkit'
import { logger } from '../../helpers/logger/logger.js'
import { acceptInvite } from '../states'

const initialState = acceptInvite

const acceptInviteSlice = createSlice({
  name: 'acceptInvite',
  initialState,
  reducers: {
    setFirstName: (state, action) => {
      logger.log('acceptInvite: set firstName')
      state.firstName = action.payload
    },
    setLastName: (state, action) => {
      logger.log('acceptInvite: set lastName')
      state.lastName = action.payload
    },
    setEmail: (state, action) => {
      logger.log('acceptInvite: set email')
      state.email = action.payload
    },
    setPhoneNumber: (state, action) => {
      logger.log('acceptInvite: set phoneNumber')
      state.phoneNumber = action.payload
    },
    setPassword: (state, action) => {
      logger.log('acceptInvite: set password')
      state.password = action.payload
    },
    setConfirmPassword: (state, action) => {
      logger.log('acceptInvite: set confirm password')
      state.confirmPassword = action.payload
    },
    setPrivacy: (state, action) => {
      logger.log('acceptInvite: set privacy')
      state.privacy = action.payload
    },
    setLegal: (state, action) => {
      logger.log('acceptInvite: set legacy')
      state.legal = action.payload
    },
    setMarketing: (state, action) => {
      logger.log('acceptInvite: set marketing')
      state.marketing = action.payload
    },
    setLanguageCode: (state, action) => {
      logger.log('acceptInvite: set language code')
      state.languageCode = action.payload
    },
    resetInvitePayloadToDefault: () => {
      logger.log('acceptInvite: reset to initial state')
      return initialState
    }
  }
})

export const {
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNumber,
  setPassword,
  setConfirmPassword,
  setPrivacy,
  setLegal,
  setMarketing,
  setLanguageCode,
  resetInvitePayloadToDefault
} = acceptInviteSlice.actions

export default acceptInviteSlice.reducer
