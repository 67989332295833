import {
  Box,
  Divider,
  Pagination,
  Skeleton,
  Stack
} from '@mui/material'
import React from 'react'
import Show from '../../Layout/Can/Show'

const DEFAULT_PAGE_SIZE = 25

const ZList = ({ count, entities, filters, page, onPageChange, isLoading }) => {
  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        my={4}
      >
        {filters}  
      </Stack>

      <Divider />

      <Show when={() => isLoading}>
        <Stack
          spacing={2}
          sx={{ marginBottom: '20px' }}
          >
            {[...Array(DEFAULT_PAGE_SIZE).keys()].map((index) => (
              <Skeleton
                key={`skeleton-${index}`}
                variant="rounded"
                sx={{ height: '230px' }}
              ></Skeleton>
            ))}
          </Stack>
        </Show>
        <Show when={() => !isLoading}>
          <Box mt={4}>
            {entities}
          </Box>
      </Show>

      <Stack
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Show when={() => count && count > DEFAULT_PAGE_SIZE}>
            <Pagination
              count={count ? Math.ceil(count / DEFAULT_PAGE_SIZE) : 0}
              page={page}
              onChange={onPageChange}
              showFirstButton
              showLastButton
            />
        </Show>
      </Stack>
    </>
  )
}

export default ZList
