import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'
import { t } from 'i18next'

import {
  setCurrentOrganization,
  changeOrgState
} from '../../../store/slices/general'
import { useFetchSelfOrganizationsQuery } from '../../../store'
import { devicesApi } from '../../../store/apis/devices-api'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton'
import PropTypes from 'prop-types'

import { eventsApi } from '../../../store/apis/events-api.js'
import { filtersApi } from '../../../store/apis/filters-api.js'
import { organizationsApi } from '../../../store/apis/organizations-api.js'
import { invitesApi } from '../../../store/apis/invites-api.js'
import { actionsApi } from '../../../store/apis/actions-api.js'
import { rulesApi } from '../../../store/apis/rules-api.js'
import { triggersApi } from '../../../store/apis/triggers-api.js'
import { useNavigate } from 'react-router-dom'
import { usersApi } from '../../../store/apis/users-api'

const ChangeOrganization = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { currentOrganization } = useSelector((state) => state.general)

  const {
    data: organizationsSuggestions,
    error: organizationsSuggestionsError,
    isLoading: isOrganizationsSuggestionsLoading
  } = useFetchSelfOrganizationsQuery()

  const handleChange = (_event, value) => {
    const { key: newOrganization } = value
    if (currentOrganization !== newOrganization) {
      dispatch(changeOrgState())
      dispatch(setCurrentOrganization(newOrganization))
      dispatch(organizationsApi.util.resetApiState())
      dispatch(devicesApi.util.resetApiState())
      dispatch(eventsApi.util.resetApiState())
      dispatch(invitesApi.util.resetApiState())
      dispatch(actionsApi.util.resetApiState())
      dispatch(rulesApi.util.resetApiState())
      dispatch(triggersApi.util.resetApiState())
      dispatch(filtersApi.util.resetApiState())
      dispatch(usersApi.util.resetApiState())
      setIsOpen(false)
      navigate('/')
    }
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll="body"
      >
        <DialogTitle
          variant="h4"
          display="flex"
          justifyContent="center"
        >
          {t('organizations.changeOrganization')}
        </DialogTitle>

        <CloseDialogButton onClose={handleClose} />

        <DialogContent>
          {!organizationsSuggestionsError &&
            !isOrganizationsSuggestionsLoading &&
            organizationsSuggestions?.length > 0 && (
              <Autocomplete
                id="AddOrganization-RootOrganization"
                loading={isOrganizationsSuggestionsLoading}
                options={organizationsSuggestions}
                disabled={organizationsSuggestionsError}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                value={organizationsSuggestions.find(
                  ({ key }) => key === currentOrganization
                )}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('common.choose')}
                    variant="standard"
                    helperText={t('organizations.inputOrganization')}
                  />
                )}
                disableClearable
              />
            )}
        </DialogContent>
      </Dialog>
    </>
  )
}

ChangeOrganization.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default ChangeOrganization
