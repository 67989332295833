import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { combineReducers } from 'redux'
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist'
import storage from 'redux-persist/lib/storage/index.js'
import { authenticationApi } from './apis/authentication-api.js'
import { devicesApi } from './apis/devices-api.js'
import { eventsApi } from './apis/events-api.js'
import { gatewaysApi } from './apis/gateways-api.js'
import { filtersApi } from './apis/filters-api.js'
import { invitesApi } from './apis/invites-api.js'
import { organizationsApi } from './apis/organizations-api.js'
import { provisioningSetupApi } from './apis/provisioning-setup-api.js'
import { rolesApi } from './apis/roles-api.js'
import { usersApi } from './apis/users-api.js'
import deviceAddReducer from './slices/device-add.js'
import ruleAddReducer from './slices/rule-add.js'
import ruleAddActionAddReducer from './slices/rule-add-action-add.js'
import ruleAddTriggerAddReducer from './slices/rule-add-trigger-add.js'
import triggerAddReducer from './slices/trigger-add.js'
import actionAddReducer from './slices/action-add.js'
import acceptInviteReducer from './slices/accept-invite.js'
import updateUserReducer from './slices/update-user.js'
import filtersReducer from './slices/filters.js'
import usersReducer from './slices/users.js'
import generalReducer from './slices/general.js'
import geolocationReducer from './slices/geolocation.js'
import { triggersApi } from './apis/triggers-api.js'
import { rulesApi } from './apis/rules-api.js'
import { actionsApi } from './apis/actions-api.js'
import { acceptInviteApi } from './apis/accept-invite.js'
import { userProfileApi } from './apis/user-profile-api.js'
import { globalSearchApi } from './apis/global-search-api.js'
import { MIGRATION_VERSION } from '../configs/environment.js'
import {
  general,
  acceptInvite,
  actionAdd,
  deviceAdd,
  filters,
  geolocation,
  ruleAddActionAdd,
  ruleAddTriggerAdd,
  ruleAdd,
  triggerAdd,
  updateUser,
  users
} from './states'

const migrations = {
  [MIGRATION_VERSION]: (state) => ({
    ...state,
    general: general,
    acceptInvite: acceptInvite,
    actionAdd: actionAdd,
    deviceAdd: deviceAdd,
    filters: filters,
    geolocation: geolocation,
    ruleAddActionAdd: ruleAddActionAdd,
    ruleAddTriggerAdd: ruleAddTriggerAdd,
    ruleAdd: ruleAdd,
    triggerAdd: triggerAdd,
    updateUser: updateUser,
    users: users
  })
}

const reducers = {
  general: generalReducer,
  deviceAdd: deviceAddReducer,
  filters: filtersReducer,
  users: usersReducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [devicesApi.reducerPath]: devicesApi.reducer,
  [triggersApi.reducerPath]: triggersApi.reducer,
  [rulesApi.reducerPath]: rulesApi.reducer,
  [actionsApi.reducerPath]: actionsApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [eventsApi.reducerPath]: eventsApi.reducer,
  [gatewaysApi.reducerPath]: gatewaysApi.reducer,
  [filtersApi.reducerPath]: filtersApi.reducer,
  [invitesApi.reducerPath]: invitesApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
  [provisioningSetupApi.reducerPath]: provisioningSetupApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [userProfileApi.reducerPath]: userProfileApi.reducer,
  ruleAdd: ruleAddReducer,
  ruleAddActionAdd: ruleAddActionAddReducer,
  ruleAddTriggerAdd: ruleAddTriggerAddReducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [acceptInviteApi.reducerPath]: acceptInviteApi.reducer,
  triggerAdd: triggerAddReducer,
  actionAdd: actionAddReducer,
  geolocation: geolocationReducer,
  acceptInvite: acceptInviteReducer,
  updateUser: updateUserReducer,
  [globalSearchApi.reducerPath]: globalSearchApi.reducer
}

const rootReducer = combineReducers(reducers)

const persistConfig = {
  key: 'root',
  storage,
  version: MIGRATION_VERSION,
  migrate: createMigrate(migrations, { debug: false })
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([
      actionsApi.middleware,
      authenticationApi.middleware,
      devicesApi.middleware,
      eventsApi.middleware,
      gatewaysApi.middleware,
      triggersApi.middleware,
      rulesApi.middleware,
      filtersApi.middleware,
      invitesApi.middleware,
      organizationsApi.middleware,
      provisioningSetupApi.middleware,
      rolesApi.middleware,
      usersApi.middleware,
      acceptInviteApi.middleware,
      userProfileApi.middleware,
      globalSearchApi.middleware
    ])
  }
})

setupListeners(store.dispatch)

export default store

export {
  useSearchActionsQuery,
  useSearchDownlinksQuery
} from './apis/actions-api.js'

export {
  useLoginMutation,
  useLogoutMutation,
  useResetPasswordMutation
} from './apis/authentication-api.js'

export {
  useFetchDevicesQuery,
  useSearchDevicesMutation,
  useFetchMarkersQuery,
  useFetchPlacesMutation,
  useFetchDeviceQuery,
  useDeleteDeviceMutation,
  useCreateDeviceMutation,
  useFetchDeviceMeasurementsQuery,
  useFetchDeviceStatisticsQuery,
  useFetchDeviceNetworkQuery,
  useFetchBrandsQuery,
  useFetchModelsMutation,
  useFetchDownlinksQuery,
  useFetchDeviceGroupsQuery,
  useFetchDeviceTypesQuery,
  useLazyFetchDownlinksQuery
} from './apis/devices-api.js'

export {
  useFetchEventsQuery,
  useSearchEventsMutation,
  useFetchEventsSearchSummaryQuery
} from './apis/events-api.js'

export {
  useSearchGatewaysMutation,
  useFetchGatewayTestsQuery
} from './apis/gateways-api.js'

export {
  useFetchBrandsFilterQuery,
  useFetchModelsFilterQuery,
  useFetchTagsFilterQuery,
  useFetchOrganizationsFilterQuery
} from './apis/filters-api.js'

export {
  useFetchInvitesQuery,
  useFetchInviteQuery,
  useResendInviteMutation,
  useCreateInviteMutation,
  useDeleteInviteMutation
} from './apis/invites-api.js'

export {
  useFetchSelfOrganizationsQuery,
  useFetchOrganizationsQuery,
  useFetchOrganizationsWithChildrenQuery,
  useFetchOrganizationQuery,
  useFetchOrganizationKeyQuery,
  useFetchNetworkServersQuery,
  useFetchAllNetworkServersQuery,
  useFetchTimezonesQuery,
  useFetchOrganizationProfilesQuery,
  useFetchOrganizationProfileQuery,
  useCreateRootOrganizationMutation,
  useCreateChildOrganizationMutation,
  useEditOrganizationMutation,
  useFetchOrganizationsPagedQuery,
  useFetchKeySuggestionMutation,
  useDeleteOrganizationMutation,
  useLockOrganizationMutation,
  useUnlockOrganizationMutation
} from './apis/organizations-api.js'

export { useFetchProvisioningSetupQuery } from './apis/provisioning-setup-api.js'

export {
  useFetchRolesStandardQuery,
  useFetchRolesSuperQuery
} from './apis/roles-api.js'

export {
  useCreateRuleMutation,
  useSearchRulesMutation,
  useUpdateRuleMutation,
  useDeleteRuleMutation
} from './apis/rules-api.js'

export {
  useFetchTriggersQuery,
  useGetOrganizationModelsQuery,
  useGetModelsByQuantityQuery,
  useGetQuantitiesQuery
} from './apis/triggers-api.js'

export {
  useGetInviteQuery,
  useAcceptInviteMutation
} from './apis/accept-invite.js'

export {
  useFetchUsersQuery,
  useSearchUsersQuery,
  useUpdateUserRoleAndOrganizationMutation
} from './apis/users-api.js'

export { useSearchMutation } from './apis/global-search-api.js'
