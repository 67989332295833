import { Stack, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { t } from 'i18next'
import {
  PublicRounded as PublicRoundedIcon,
  PublicOffRounded as PublicOffRoundedIcon
} from '@mui/icons-material'

const ActionHeader = ({ isPublic }) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      margin={1}
    >
      {isPublic ? (
        <Tooltip title={t('Public')}>
          <PublicRoundedIcon fontSize="small" />
        </Tooltip>
      ) : (
        <Tooltip title={t('Private')}>
          <PublicOffRoundedIcon fontSize="small" />
        </Tooltip>
      )}
    </Stack>
  )
}

ActionHeader.propTypes = {
  isPublic: PropTypes.bool
}

export default ActionHeader
