import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'

const globalSearchApi = createApi({
  reducerPath: 'globalSearchApi',
  baseQuery: customFetchBase,
  endpoints(builder) {
    return {
      search: builder.mutation({
        query: ({ query, entityType }) => {
          logger.log(`RTK API - Search devices for filter: ${query}`)
          return {
            url: `/watson/search`,
            method: 'GET',
            params: {
              query,
              entityType
            }
          }
        }
      })
    }
  }
})

export const { useSearchMutation } = globalSearchApi

export { globalSearchApi }
