import { createApi } from '@reduxjs/toolkit/query/react'
import { logger } from '../../helpers/logger/logger.js'
import customFetchBase from './custom-fetch-base.js'
import sanitizer from '../../helpers/sanitizer.js'

const gatewaysApi = createApi({
  reducerPath: 'gatewaysApi',
  baseQuery: customFetchBase,
  tagTypes: ['gateways'],
  endpoints(builder) {
    return {
      searchGateways: builder.mutation({
        query: ({ filters, page, pageSize, sort, direction }) => {
          logger.log(`RTK API - Search gateways for filter`)
          return {
            url: `/gateways/search`,
            method: 'POST',
            params: {
              page: !page ? 1 : page,
              pageSize,
              sort: sort ? `${sort}:${direction}` : undefined
            },
            body: sanitizer(filters)
          }
        },
        transformResponse: (response, meta) => {
          const count = parseInt(meta.response.headers.get('x-total-count'))
          return { data: response, count: count }
        },
        providesTags: ['gateways']
      }),
      fetchGatewayTests: builder.query({
        query: ({ serial, page, pageSize }) => {
          logger.log('RTK API - Fetch gateway tests', serial, page)
          return {
            url: `/gateways/tests/${serial}`,
            method: 'GET',
            params: {
              page: !page ? 1 : page,
              pageSize
            }
          }
        },
        transformResponse: (response, meta) => {
          const count = parseInt(meta.response.headers.get('x-total-count'))
          return { data: response, count: count }
        },
        providesTags: ['gatewayTests']
      }),
      fetchMarkers: builder.query({
        query: () => {
          logger.log('RTK API - Fetch gateway markers')
          return {
            url: '/gateways/markers',
            body: sanitizer(filters),
            method: 'POST'
          }
        },
        providesTags: ['gatewaysMarker']
      })
    }
  }
})

export const { useSearchGatewaysMutation, useFetchGatewayTestsQuery } =
  gatewaysApi

export { gatewaysApi }
