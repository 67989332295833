import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setFiltersGateways } from '../../../../store/slices/filters.js'

const GatewayStatus = () => {
  const dispatch = useDispatch()

  const {
    gateways: { technology }
  } = useSelector((state) => state.filters)

  const lorawan = useRef()
  const wmbus = useRef()
  const dual = useRef()

  /**
   * handleChange
   */
  const handleChange = (event) => {
    let newTechnology
    const { name } = event.target

    if (technology.includes(name)) {
      const index = technology.indexOf(name)
      newTechnology = technology.filter((_, id) => id !== index)
    } else {
      newTechnology = technology.concat(name)
    }

    // if (newTechnology.length === 0) {
    //   newTechnology = ['Lorawan', 'Wmnbus', 'Lorawan+Wmbus']
    // }

    dispatch(setFiltersGateways({ technology: newTechnology }))
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        spacing={2}
        mt={3}
        mb={3}
      >
        <Typography
          align="left"
          sx={{ fontWeight: 'bold' }}
        >
          {t('filters.gateways.technology.label')}
        </Typography>
        <FormGroup
          aria-label="position"
          row
        >
          <FormControlLabel
            value="Lorawan"
            control={
              <Checkbox
                name="Lorawan"
                inputRef={lorawan}
                onChange={handleChange}
                checked={technology.includes('Lorawan')}
              />
            }
            label={t('filters.gateways.technology.lorawan')}
          />
          <FormControlLabel
            value="Wmbus"
            control={
              <Checkbox
                name="Wmbus"
                inputRef={wmbus}
                onChange={handleChange}
                checked={technology.includes('Wmbus')}
              />
            }
            label={t('filters.gateways.technology.wmbus')}
          />
          <FormControlLabel
            value="Wmbus+Lorawan"
            control={
              <Checkbox
                name="Wmbus+Lorawan"
                inputRef={dual}
                onChange={handleChange}
                checked={technology.includes('Wmbus+Lorawan')}
              />
            }
            label={t('filters.gateways.technology.dual')}
          />
        </FormGroup>
      </Stack>
    </>
  )
}

GatewayStatus.propTypes = {
  onChange: PropTypes.func,
  filters: PropTypes.object
}

export default GatewayStatus
