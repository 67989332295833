const theme = {
  palette: {
    type: 'light',
    primary: {
      main: '#0F81FC',
      light: '#9EDAFE',
      dark: '#043492',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#9c27b0',
      light: '#ba68c8',
      dark: '#7b1fa2',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#d32f2f',
      light: '#ef5350',
      dark: '#c62828',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#e65100',
      contrastText: '#FFFFFF'
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b',
      contrastText: '#FFFFFF'
    },
    notSelected: {
      main: 'rgba(0, 0, 0, 0.08)',
      light: 'rgba(0, 0, 0, 0.26)',
      dark: 'rgba(0, 0, 0, 0.26)',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#FFFFFF'
    },
    contrastThreshold: 3,
    tonalOffset: 0.5,
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)'
    },
    divider: 'rgba(0, 0, 0, 0.12)'
  },
  typography: {
    htmlFontSize: 12,
    fontSize: 11,
    fontFamily: '"Noto Sans JP", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      fontSize: '2.5rem'
    },
    h2: {
      fontWeight: 400,
      fontSize: '2rem'
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.75rem'
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.5rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1rem'
    },
    h6: {
      fontWeight: 500,
      fontSize: '0.8rem'
    },
    body: {
      fontWeight: 400,
      fontSize: '0.8rem',
      lineHeight: 1.43
    },
    bodyRequired: {
      fontWeight: 400,
      fontSize: '0.8rem',
      lineHeight: 1.43,
      color: 'rgba(250, 0, 0, 1)'
    },
    body2: {
      fontWeight: 300,
      fontSize: '0.8rem',
      lineHeight: 1.43
    }
  },
  shadows: [
    'none',
    '0px 10px 20px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 25px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 30px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 35px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 40px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 45px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 50px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 55px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 60px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 65px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 70px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 70px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 70px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 70px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 75px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 75px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 75px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 75px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 80px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 80px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 80px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 80px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 80px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)',
    '0px 10px 80px 0px rgba(0, 90, 135, 0.15), 0px 0px 10px 0px rgba(0, 90, 135, 0.15)'
  ],
  shape: {
    borderRadius: 5
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          flexShrink: 0
        },
        paper: {
          position: 'unset',
          height: 'calc(100vh - 140px)',
          boxSizing: 'border-box',
          padding: '20px',
          marginTop: '60px'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          border: '1px solid #cdcdcd',
          background:
            'radial-gradient( 80px circle at 30px 30px, rgba(223, 253, 225, 0.4) 50%, transparent 51% )'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 1)!important',
          color: 'rgba(0, 0, 0, 0.87) !important'
        }
      }
    }
  }
}

export default theme
