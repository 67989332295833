import './i18n'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App.jsx'
import { MyThemeProvider as ThemeProvider } from './components/Layout/ThemeProvider/ThemeProvider.jsx'
import reportWebVitals from './reportWebVitals.js'
import store from './store/index.js'
import * as Sentry from '@sentry/react'

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    dsn: 'https://d42db833911797c469ba6d2b14518d53@o464875.ingest.us.sentry.io/4508245160361984',
    integrations: [
      Sentry.httpClientIntegration(),
      Sentry.httpContextIntegration(),
      Sentry.replayIntegration(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.VITE_SENTRY_ENV
  })
}

const persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
    >
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </PersistGate>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logger.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
