import { Box, TextField, Typography, Stack, Button, Alert } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useResetPasswordRequestMutation } from '../../../store/apis/authentication-api'
import { useDispatch } from 'react-redux'
import { useValidationEffect } from '../../../helpers/hooks.js'
import useFieldValidation from '../../../helpers/fieldValidation.js'
import Joi from 'joi'
import { setOpenDialog } from '../../../store/slices/general'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert'

const ResetPassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { requestId } = useParams()
  /* eslint-disable-next-line no-unused-vars */
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showError, setShowError] = useState(false)
  const [showLoginMessage, setShowLoginMessage] = useState(false)
  const [validation, setValidation] = useState({ isValid: false })

  const schema = Joi.object({
    password: Joi.string()
      .pattern(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/)
      .message(`La passowrd non rispetta gli standard di sicurezza`)
      .required(),
    confirmPassword: Joi.string().required()
  })

  const [
    resetPasswordRequest,
    {
      isError: isResetPasswordRequestError,
      isSuccess: isResetPasswordRequestSuccess
    }
  ] = useResetPasswordRequestMutation()

  const handlePasswordChange = ({ target: { value } }) => setPassword(value)

  const handleConfirmPasswordChange = ({ target: { value } }) => setConfirmPassword(value)

  const handleChangePassword = () => {
    if (password !== confirmPassword) {
      setShowError(true)
      return
    }
    resetPasswordRequest({ token: requestId, data: password })
  }

  const handleBackToHome = () => {
    dispatch(setOpenDialog('login'))
    navigate('/')
  }

  useValidationEffect(() => {
    const validationResults = useFieldValidation(
      { password, confirmPassword },
      schema
    )

    setValidation(validationResults)
  }, [password, confirmPassword])

  useOpenResultAlert({ error: 'Errore durante il reset della password' }, { isError: isResetPasswordRequestError, isSuccess: isResetPasswordRequestSuccess })

  useEffect(() => {
    if (isResetPasswordRequestSuccess) {
      setShowLoginMessage(true)
    }
  }, [isResetPasswordRequestSuccess])

  return (
    <>
      <Box
        marginTop={'66px'}
        height={'71vh'}
        width={'100%'}
        alignContent={'center'}
      >
        <Box
          marginTop={5}
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Typography variant="h1">RESET PASSWORD</Typography>
        </Box>
        <Box
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}
          marginTop={10}
        >
          <Stack
            display={'flex'}
            width={'30%'}
            alignContent={'center'}
            justifyContent={'center'}
            spacing={3}
          >
            <TextField
              onChange={handlePasswordChange}
              variant="standard"
              error={validation?.messages?.password?.length > 0}
              helperText={validation?.messages?.password?.join(', ')}
              type={showPassword ? 'text' : 'password'}
              placeholder={'Inserisci la password'}
            />

            <TextField
              onChange={handleConfirmPasswordChange}
              variant="standard"
              error={validation?.messages?.confirmPassword?.length > 0}
              helperText={validation?.messages?.confirmPassword?.join(', ')}
              type={'password'}
              placeholder={'Ripeti la password'}
            />

            {showError && (
              <Alert severity="error">
                {t('invites.correspondingPasswordError')}
              </Alert>
            )}

            {showLoginMessage && (
              <Typography
                variant="text"
                color={'green'}
              >
                Password cambiata con successo
              </Typography>
            )}

            {!showLoginMessage && (
              <Button onClick={handleChangePassword}>{t('common.save')}</Button>
            )}

            {showLoginMessage && (
              <Button onClick={handleBackToHome}>Torna alla home</Button>
            )}
          </Stack>
        </Box>
      </Box>
    </>
  )
}

export default ResetPassword
