import { Drawer, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { MenuRounded as MenuRoundedIcon, CloseRounded as CloseRoundedIcon, ArrowBackRounded as ArrowBackRoundedIcon } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { setCurrentOrganization } from '../../../store/slices/general.js'
import DeviceDetails from '../../Devices/Details/DeviceDetail.jsx'
import DevicesList from '../../Devices/List/List.jsx'
import FilteredPublicList from '../../Devices/List/FilteredPublicList.jsx'
import FrontMap from '../../FrontMap/FrontMap.jsx'
import { useParams, useNavigate } from 'react-router-dom'
import { devicesApi } from '../../../store/apis/devices-api.js'

const FrontPage = () => {
  const { deviceId, organizationKey } = useParams()
  const navigate = useNavigate()

  const [isOpenDrawer, setIsOpenDrawer] = useState(true)

  const dispatch = useDispatch()

  const toggleDrawer = () => {
    setIsOpenDrawer(!isOpenDrawer)
  }

  const handleBackToList = () => {
    dispatch(devicesApi.util.resetApiState())
    navigate('/')
  }

  const drawerWidth = deviceId ? 820 : 580

  const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open'
  })(({ open, theme }) => ({
    marginTop: '66px',
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  }))

  const renderPanel = () => {
    if (deviceId) {
      return <DeviceDetails deviceId={deviceId} />
    }
    if (organizationKey) {
      dispatch(setCurrentOrganization(organizationKey))
      return <FilteredPublicList />
    }

    return <DevicesList />
  }

  return (
    <>
      <Drawer
        sx={{ '& .MuiDrawer-paper': { width: drawerWidth } }}
        variant="persistent"
        anchor="left"
        open={isOpenDrawer}
      >
        {renderPanel()}
      </Drawer>
      <Main
        open={isOpenDrawer}
        sx={{ position: 'relative' }}
      >
        {deviceId ? (
          <IconButton
            variant="contained"
            aria-label="toggle drawer"
            sx={{
              position: 'absolute',
              top: '10px',
              left: '10px',
              background: '#FFFFFF',
              zIndex: (theme) => theme.zIndex.drawer - 200,
              '&:hover': {
                transition: 'unset',
                background: '#FFFFFF'
              }
            }}
            onClick={handleBackToList}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
        ) : (
          <IconButton
            variant="contained"
            aria-label="toggle drawer"
            sx={{
              position: 'absolute',
              top: '10px',
              left: '10px',
              background: '#FFFFFF',
              zIndex: (theme) => theme.zIndex.drawer - 200,
              '&:hover': {
                transition: 'unset',
                background: '#FFFFFF'
              }
            }}
            onClick={toggleDrawer}
          >
            {isOpenDrawer ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
          </IconButton>
        )}

        <FrontMap deviceId={deviceId} />
      </Main>
    </>
  )
}

export default FrontPage
