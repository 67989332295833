import moment from 'moment-timezone'

export const general = {
  count: {
    devices: 0,
    events: 0,
    gateways: 0,
    users: 0
  },
  currentDevice: null,
  currentOrganization: 'a2a',
  credentials: null,
  map: {
    center: { lat: 45.745, lng: 9.823 },
    zoom: 10
  },
  theme: 'light',
  language: 'it',
  dialogs: {
    error: {
      open: false
    },
    confirm: {
      open: false
    },
    confirmWithCheck: {
      open: false
    },
    deleteDevice: {
      open: false,
      id: null,
      name: null
    },
    eventsDevice: {
      open: false,
      id: null,
      name: null
    },
    actionsDevice: {
      open: false,
      id: null,
      name: null
    },
    sendAction: {
      open: false,
      id: null,
      name: null
    },
    login: {
      open: false
    },
    resetPassword: {
      open: false
    },
    resetPasswordSuccess: {
      open: false
    },
    addRule: {
      open: false
    },
    updateDevice: {
      open: false,
      id: null,
      name: null
    },
    searchDevices: {
      tab: 'by-model',
      model: {
        model: null
      }
    },
    successAlert: {
      open: false
    },
    errorAlert: {
      open: false,
      errorMessage: ''
    }
  },
  drawers: {
    home: true
  },
  paginators: {
    automationRules: 1,
    automationTriggers: 1,
    automationActions: 1,
    devices: 0,
    events: 0,
    gatewayTests: 1,
    home: 1,
    invitationsAccepted: 1,
    invitationsPending: 1,
    organizations: 1,
    users: 1
  },
  paginatorSize: {
    automationRules: 25,
    automationTriggers: 25,
    automationActions: 25,
    devices: 25,
    events: 25,
    gatewayTests: 5,
    gateways: 25,
    home: 7,
    invitationsAccepted: 25,
    invitationsPending: 25,
    organizations: 25,
    users: 24
  },
  tabs: {
    device: 'network',
    automations: 'rules'
  },
  filters: {
    position: {
      near: false,
      suggestions: [],
      entity: {
        address: '',
        latitude: null,
        longitude: null
      }
    }
  },
  timePicker: [
    moment().subtract(3, 'days').startOf('day').toISOString(),
    moment().endOf('day').toISOString()
  ]
}
