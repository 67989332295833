import { FormControl, FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setFiltersDevices } from '../../../store/slices/filters.js'

const DeviceAlarmedFilter = () => {
  const { devices: filters } = useSelector((state) => state.filters)

  const dispatch = useDispatch()

  const handleChange = (_, v) => {
    dispatch(
      setFiltersDevices({
        ...filters,
        isAlarm: v
      })
    )
  }

  return (
    <>
      <FormControl fullWidth>
        <FormControlLabel
          control={<Switch onChange={handleChange} />}
          label={t('filters.devices.alarmed.label')}
          labelPlacement="end"
        />
      </FormControl>
    </>
  )
}

export default DeviceAlarmedFilter
