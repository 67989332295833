import {
  Box,
  Divider,
  IconButton,
  Pagination,
  Skeleton,
  Stack,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  AddCircleOutlineRounded as AddCircleOutlineRoundedIcon,
  UploadRounded as UploadRoundedIcon,
  DeleteRounded as DeleteRoundedIcon
} from '@mui/icons-material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useDeleteDeviceMutation, useFetchDevicesQuery } from '../../../store'
import { setCount } from '../../../store/slices/general.js'
import Can from '../../Layout/Can/Can.jsx'
import DeviceCard from '../Card/DeviceCard.jsx'
import Count from '../../Shared/Components/Count.jsx'
import Actions from '../Dialogs/Actions.jsx'
import AddDevice from '../Dialogs/AddDevice.jsx'
import UpdateDevice from '../Dialogs/UpdateDevice.jsx'
import SendAction from '../Dialogs/SendAction.jsx'
import DeviceAlarmedFilter from '../Filters/AlarmedFilter.jsx'
import DeviceFilters from '../Filters/Filters.jsx'
import ImportDevices from '../Dialogs/ImportDevices'
import ConfirmWithCheckDialog from '../../Shared/Dialogs/ConfirmWithCheckDialog'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'
import useFieldValidation from '../../../helpers/fieldValidation'
import Joi from 'joi'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert'

const DEFAULT_PAGE_SIZE = 7

const filtersSchema = Joi.object({
  filters: Joi.object({
    allowMissingPosition: Joi.boolean(),
    showHidden: Joi.boolean(),
    typeIds: Joi.array().items(Joi.number())
  }).required(),
  currentPage: Joi.number().required()
})

const FilteredPublicList = () => {
  const dispatch = useDispatch()

  const [selectedDevice, setSelectedDevice] = useState(null)
  const [isAddDeviceDialogOpen, setIsAddDeviceDialogOpen] = useState(false)
  const [isImportDeviceDialogOpen, setIsImportDeviceDialogOpen] = useState(false)
  const [isEditDeviceDialogOpen, setIsEditDeviceDialogOpen] = useState(false)
  const [isActionDialogOpen, setIsActionDialogOpen] = useState(false)
  const [isSendActionOpen, setIsSendActionOpen] = useState(false)

  const [isConfirmWithCheckOpen, setIsConfirmWithCheckOpen] = useState(false)

  const [page, setPage] = useState(1)

  const { devices: filters } = useSelector(
    (state) => state.filters,
    shallowEqual
  )

  const { credentials } = useSelector((state) => state.general, shallowEqual)

  function base64ToJson(base64) {
    try {
      const binString = atob(base64)
      const byteString = Uint8Array.from(binString, (m) => m.codePointAt(0))
      const plainTextString = new TextDecoder().decode(byteString)
      return JSON.parse(plainTextString)
    } catch {
      return null
    }
  }

  function validateFilters(jsonPayload) {
    const isValid = useFieldValidation(jsonPayload, filtersSchema)

    if (isValid) {
      return jsonPayload
    }

    return null
  }

  const [searchParams] = useSearchParams()

  const filtersFromURL = validateFilters(
    base64ToJson(searchParams.get('initialFilters'))
  )

  const {
    data: devices,
    isError: isDevicesError,
    isLoading: isDevicesLoading,
    refetch: refetchDevices
  } = useFetchDevicesQuery({
    filters: filtersFromURL?.filters || filters,
    page,
    pageSize: DEFAULT_PAGE_SIZE
  })

  const [
    deleteDevice,
    {
      isSuccess: isDeleteDeviceSuccess,
      isError: isDeleteDeviceError,
      error: deleteDeviceError
    }
  ] = useDeleteDeviceMutation()

  useOpenResultAlert(deleteDeviceError, { isError: isDeleteDeviceError, isSuccess: isDeleteDeviceSuccess })

  useEffect(() => {
    setPage(1)
  }, [isDeleteDeviceSuccess, filters])

  const handleChange = (_, value) => setPage(value)

  const handleDelete = (device) => {
    setSelectedDevice(device)
    setIsConfirmWithCheckOpen(true)
  }

  const handleEditDevice = (device) => {
    setSelectedDevice(device)
    setIsEditDeviceDialogOpen(true)
  }

  const handleSetIsOpenEditDevice = (isOpen) => {
    setIsEditDeviceDialogOpen(isOpen)
    if (!isOpen) {
      setSelectedDevice(null)
    }
  }

  const handleAction = (device) => {
    setIsActionDialogOpen(true)
    setSelectedDevice(device)
  }

  const handleSetIsOpenActionDialogOpen = (isOpen) => {
    setIsActionDialogOpen(isOpen)
    if (!isOpen) {
      setSelectedDevice(null)
    }
  }

  const handleSendAction = (device) => {
    setIsActionDialogOpen(false)
    setIsSendActionOpen(true)
    setSelectedDevice(device)
  }

  const handleSendActionIsOpen = (isOpen) => {
    setIsSendActionOpen(isOpen)
    if (!isOpen) {
      setSelectedDevice(null)
    }
  }

  const handleCloseConfigrWithCheck = () => setIsConfirmWithCheckOpen(false)
  
  const handleConfirmAction = () => {
    deleteDevice(selectedDevice.id)
    setIsConfirmWithCheckOpen(false)
  }

  useEffect(() => {
    refetchDevices()
  }, [credentials])

  return (
    <>
      <Stack direction="row">
        <Box sx={{ flexGrow: 1 }} />

        <Can action="devices_c">
          <IconButton
            color="primary"
            onClick={() => setIsAddDeviceDialogOpen(true)}
          >
            <AddCircleOutlineRoundedIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => setIsImportDeviceDialogOpen(true)}
          >
            <UploadRoundedIcon />
          </IconButton>
        </Can>
      </Stack>

      <Divider />

      <Box>
        <Typography variant="h5">
          <Count itemsCount={devices?.count} />
        </Typography>
      </Box>

      <Can action="alarms_r">
        <Divider />

        <Box py={2}>
          <DeviceAlarmedFilter />
        </Box>

        <Divider />
      </Can>

      <Stack
        direction="row"
        spacing={1}
        my={4}
      >
        <DeviceFilters filters={filters} />
      </Stack>

      {isDevicesLoading && (
        <Stack
          spacing={2}
          sx={{ marginBottom: '20px' }}
        >
          {[...Array(DEFAULT_PAGE_SIZE).keys()].map((index) => (
            <Skeleton
              key={`skeleton-${index}`}
              variant="rounded"
              sx={{ height: '230px' }}
            ></Skeleton>
          ))}
        </Stack>
      )}

      {!isDevicesLoading &&
        !isDevicesError &&
        devices?.data.map((device, i) => (
          <DeviceCard
            key={`device-${i}`}
            device={device}
            onDelete={() => handleDelete(device)}
            onEdit={handleEditDevice}
            onAction={handleAction}
          />
        ))}

      <Stack
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        {!isDevicesLoading && !!devices?.count && (
          <Pagination
            count={Math.ceil(devices.count / DEFAULT_PAGE_SIZE)}
            page={page}
            onChange={handleChange}
            showFirstButton
            showLastButton
          />
        )}
      </Stack>

      <Can action="devices_c">
        {isAddDeviceDialogOpen && (
          <AddDevice
            isOpen={isAddDeviceDialogOpen}
            onClose={() => setIsAddDeviceDialogOpen(false)}
          />
        )}
        {isImportDeviceDialogOpen && (
          <ImportDevices
            isOpen={isImportDeviceDialogOpen}
            setIsOpen={setIsImportDeviceDialogOpen}
          />
        )}
      </Can>

      <Can expression={() => !!selectedDevice} action="devices_d">
        <ConfirmWithCheckDialog
          icon={<DeleteRoundedIcon size={60} color="#ef5350"/>}
          isOpen={isConfirmWithCheckOpen}
          onClose={handleCloseConfigrWithCheck}
          onConfirmAction={handleConfirmAction}
          questionText={t('devices.deleteDevice.title')}
          subQuestionText={selectedDevice.name}
          copyText={selectedDevice.id}
          bodyText={t('devices.deleteDevice.subtitle')}
          inputSuggestionText={t('devices.deleteDevice.helper')}
        />
      </Can>

      <Can action="devices_u">
        {isActionDialogOpen && (
          <Actions
            onSendAction={handleSendAction}
            isOpen={isActionDialogOpen}
            setIsOpen={handleSetIsOpenActionDialogOpen}
            device={selectedDevice}
          />
        )}
        {isEditDeviceDialogOpen && (
          <UpdateDevice
            deviceId={selectedDevice.id}
            isOpen={isEditDeviceDialogOpen}
            setIsOpen={handleSetIsOpenEditDevice}
          />
        )}
        {isSendActionOpen && (
          <SendAction
            device={selectedDevice}
            isOpen={isSendActionOpen}
            setIsOpen={handleSendActionIsOpen}
          />
        )}
      </Can>
    </>
  )
}

export default FilteredPublicList
