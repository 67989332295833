import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Dialog,
  Typography
} from '@mui/material'
import React from 'react'
import { Mail as MailIcon } from '@mui/icons-material'
import { t } from 'i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setCloseDialog, setOpenDialog } from '../../../store/slices/general.js'

const ResetPasswordSuccess = () => {
  const dispatch = useDispatch()

  const {
    dialogs: { resetPasswordSuccess: { open: resetPasswordSuccessDialog } = {} }
  } = useSelector((state) => state.general, shallowEqual)

  const handleCloseResetPasswordSuccess = () => dispatch(setCloseDialog('resetPassword'))

  const returnLoginPageHandler = () => {
    dispatch(setCloseDialog('resetPasswordSuccess'))
    dispatch(setOpenDialog('login'))
  }

  return (
    <Dialog
      id="resetPasswordSuccessDialog"
      maxWidth="sm"
      scroll="body"
      open={resetPasswordSuccessDialog}
      onClose={handleCloseResetPasswordSuccess}
    >
      <DialogTitle
        display="flex"
        justifyContent="center"
      >
        <Box
          sx={{
            background:
              'radial-gradient( 180px circle at 50% 50%, #ceeffe 50%, transparent 51% )'
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="baseline"
            sx={{
              position: 'relative',
              top: 'calc(50% - 30px)'
            }}
          >
            <MailIcon
              size={60}
              color="#0e81fc"
            />
          </Box>
          <Box mt={18}>
            <Typography
              variant="h3"
              align="center"
            >
              <strong>{t('users.resetPasswordLinkSent')}</strong>
            </Typography>
            <Typography
              variant="body2"
              align="center"
              sx={{ m: 2 }}
            >
              {t('users.checkYourInboxText')}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogActions sx={{ justifyContent: 'center', p: '16px' }}>
        <Button
          sx={{ mt: 4 }}
          variant="contained"
          onClick={returnLoginPageHandler}
        >
          {t('users.returnToLogin')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResetPasswordSuccess
