import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material'
import React from 'react'
import CloseDialogButton from './CloseDialogButton.jsx'
import Show from '../../Layout/Can/Show.jsx'

const actionsAlight = {
  left: { display: 'flex', justifyContent: 'left', alignItems: 'left' },
  center: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
  right: { display: 'flex', justifyContent: 'right', alignItems: 'right' }
}

const ZDialog = ({
  id,
  isOpen,
  title,
  customTitle = <></>,
  content,
  actions,
  onClose,
  fullWidth = true,
  maxWidth = 'lg',
  actionsAlign = 'right'
}) => {
  return (
    <Dialog
      id={id}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      scroll="body"
      open={isOpen}
      onClose={onClose}
      aria-labelledby={`${id}-modal-title`}
      aria-describedby={`${id}-modal-description`}
    >
      <DialogTitle
        display="flex"
        justifyContent="center"
        variant="h4"
      >
        {title}
      </DialogTitle>
      {customTitle}

      <CloseDialogButton onClose={onClose} />

      <DialogContent dividers={true}>{content}</DialogContent>
      <Show when={() => !!actions}>
        <DialogActions sx={actionsAlight[actionsAlign]}>
          {actions}
        </DialogActions>
      </Show>
    </Dialog>
  )
}

export default ZDialog
