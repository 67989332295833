import { Box } from '@mui/material'
import React from 'react'
import { useFetchRolesStandardQuery } from '../../../store'
import Card from '../Card/Card.jsx'

const RolesList = () => {
  const { data: roles = [] } = useFetchRolesStandardQuery()

  return (
    <Box mt={4}>
      {roles?.map((role) => (
        <Card
          role={role}
          isSuper={false}
          key={`role-${role.name}`}
        />
      ))}
    </Box>
  )
}

export default RolesList
