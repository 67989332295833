export default (payload) => {
  return Object.keys(payload)
    .filter((key) =>
      Object.keys(payload)
        .filter((key) => {
          if (typeof payload[key] === undefined) return false
          if (payload[key] === null) return false
          else if (Array.isArray(payload[key]) && payload[key].length === 0)
            return false
          else if (
            typeof payload[key] === 'object' &&
            Object.keys(payload[key]).length === 0
          )
            return false
          else if (payload[key] === null) return false
          else return true
        })
        .includes(key)
    )
    .reduce((obj, key) => {
      obj[key] = payload[key]
      return obj
    }, {})
}

export const sanitizeFilters = (payload) => {
  return Object.keys(payload)
    .filter((key) =>
      Object.keys(payload)
        .filter((key) => typeof payload[key] !== undefined)
        .filter((key) => payload[key] !== null)
        .filter(
          (key) =>
            !Array.isArray(payload[key]) ||
            (Array.isArray(payload[key]) && payload[key].length !== 0)
        )
        .filter(
          (key) =>
            !(typeof payload[key] === 'object') ||
            (typeof payload[key] === 'object' &&
              Object.keys(payload[key]).length !== 0)
        )
        .includes(key)
    )
    .reduce((obj, key) => {
      let value
      if (typeof payload[key] === 'object' && !Array.isArray(payload[key])) {
        value = sanitizeFilters(payload[key])
      }

      value = Array.isArray(payload[key])
        ? payload[key].filter((x) => x)
        : payload[key]

      if (Array.isArray(value) && value.length === 0) {
        return obj
      }

      return { ...obj, [key]: value }
    }, {})
}
