import { FormControl, FormControlLabel, Switch } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'

const AlarmedFilter = ({ filters, onChange = () => {} }) => {
  const handleChange = (_, value) => {
    onChange({ ...filters, isAlarm: value ? value : undefined })
  }

  return (
    <>
      <FormControl fullWidth>
        <FormControlLabel
          control={<Switch onChange={handleChange} />}
          label={t('filters.events.alarmed.label')}
          labelPlacement="end"
        />
      </FormControl>
    </>
  )
}

AlarmedFilter.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func
}

export default AlarmedFilter
