import React, { useEffect, useState } from 'react'
import { t } from 'i18next'

import TriggerForm from '../Form/Trigger/TriggerForm.jsx'
import {
  useGetTriggerQuery,
  useUpdateTriggerMutation
} from '../../../store/apis/triggers-api.js'
import PropTypes from 'prop-types'
import { useFetchDeviceQuery } from '../../../store/index.js'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useValidationEffect } from '../../../helpers/hooks.js'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert.js'

const TriggerDetail = ({ triggerId, open, setIsOpen, onClose }) => {
  const {
    refetch: refetchTrigger,
    data: trigger
  } = useGetTriggerQuery(triggerId)

  const [
    updateTrigger,
    {
      isError: isUpdateTriggerError,
      isSuccess: isUpdateTriggerSuccess,
      error: updateTriggerError
    }
  ] = useUpdateTriggerMutation()

  const [triggerInfo, setTriggerInfo] = useState({})

  useValidationEffect(() => {
    firstTimeRender.current = false
    refetchTrigger()
  }, [triggerId])

  useOpenResultAlert(updateTriggerError, { isError: isUpdateTriggerError, isSuccess: isUpdateTriggerSuccess })

  useEffect(() => {
    if (isUpdateTriggerSuccess) {
      setIsOpen(false)
      handleClose()
    }
  }, [isUpdateTriggerSuccess])

  const { data: deviceData } = useFetchDeviceQuery(trigger?.deviceId, {
    skip: !trigger?.deviceId
  })

  useEffect(() => {
    if (trigger) {
      setTriggerInfo({
        name: trigger.name,
        isForSuperAdmin: trigger.isForSuperAdmin,
        applyToChildOrganizations: trigger.applyToChildOrganizations,
        models: trigger.models.map((m) => ({ id: m.id, measures: m.measures })),
        ruleType: trigger.type,
        schedulerType: trigger.schedulerType,
        scheduler: trigger.scheduler,
        sendReminder: trigger.sendReminder,
        thresholdsCounter: trigger.thresholdsCounter,
        trigger: trigger.trigger,
        quantityId: trigger.quantityId,
        quantity: trigger.quantity,
        triggerType: trigger.deviceId ? 'device' : 'quantity',
        device: deviceData
          ? { ...deviceData.device, quantities: deviceData.quantities }
          : undefined,
        revision: trigger.revision,
        missingComunicationQuantity: trigger.missingComunicationQuantity,
        organizationKey: trigger.organizationKey
      })
    }
  }, [trigger, deviceData])

  const handleClose = () => {
    setTriggerInfo({})
    onClose()
  }

  const handleSaveTrigger = (trigger) => {
    if (trigger.type === 'communication' || trigger.type === 'trigger') {
      onClose()
      return
    }

    const updatePayload = {
      type: trigger.type,
      name: trigger.name,
      isForSuperAdmin: trigger.isForSuperAdmin,
      applyToChildOrganizations: trigger.applyToChildOrganizations,
      models: trigger.models?.map((m) => ({
        id: m.id,
        measures: m.measures?.map((m) => ({ name: m.name }))
      })),
      trigger: trigger.trigger,
      schedulerType: trigger.schedulerType,
      version: trigger.revision.version,
      sendReminder: trigger.sendReminder,
      thresholdsCounter: trigger.thresholdsCounter,
      ...(trigger.schedulerType === 'scheduled'
        ? { scheduler: trigger.scheduler }
        : {})
    }
    updateTrigger({ payload: updatePayload, triggerId })
  }

  return (<ZDialog 
    id={'trigger-detail-dialog'}
    isOpen={open} 
    title={t('triggers.edit')} 
    content={<TriggerForm
              triggerData={triggerInfo}
              isEdit={true}
              onSave={handleSaveTrigger}
              onTriggerChange={() => {}}
            />} 
    onClose={handleClose}/>)
}

TriggerDetail.propTypes = {
  triggerId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default TriggerDetail
