import {
  Divider
} from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import Scheduler from './Scheduler.jsx'
import Show from '../../../Layout/Can/Show.jsx'
import { schedulerTypes } from '../../utils/utilis.js'
import ZRadioGroup from './ZRadioGroup.jsx'

const SchedulerHoursSelectionForm = ({ ruleType, schedulerType, scheduler, onChangeType, onChangeScheduler }) => {
  return (
    <>
      <Show when={() => ruleType !== 'scheduled-trigger' && ruleType !== 'communication' && ruleType !== 'trigger' }>
          <Divider orientation="horizontal" sx={{ my: 5 }}/>
          <ZRadioGroup 
            id={'scheduler-hours-selection-radio-group'} 
            values={[
              { id:'trigger-form-scheduler-type-default-radio', label: t('triggers.default24'), value: schedulerTypes.DEFAULT },
              { id:'trigger-form-scheduler-type-scheduled-radio', label: t('triggers.selectWeeklyTimes'), value: schedulerTypes.SCHEDULED }
            ]} 
            defaultValue={schedulerTypes.DEFAULT} 
            selectedValue={schedulerType} 
            onChange={({ target: { value } }) => onChangeType(value)} 
          />
      </Show>
      <Show when={() => schedulerType === schedulerTypes.SCHEDULED && ruleType !== 'communication' && ruleType !== 'trigger'}>
        <Scheduler
          hideEndHour={ruleType === 'scheduled-trigger'}
          defaultScheduler={scheduler}
          onChange={onChangeScheduler}
        />
    </Show>
  </>)
}

export default SchedulerHoursSelectionForm