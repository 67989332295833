import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'
import {
  setErrorAlertMessage,
  setOpenDialog
} from '../../../store/slices/general.js'
import {
  useFetchOrganizationQuery,
  useEditOrganizationMutation
} from '../../../store/index.js'
import PropTypes from 'prop-types'
import OrganizationForm from '../Form/OrganizationForm.jsx'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'

const OrganizationEdit = ({ organizationId, isOpen, setIsOpen }) => {
  const dispatch = useDispatch()

  const [orgData, setOrgData] = useState(null)
  const [isValid, setIsValid] = useState(false)

  const { data: organization, isFetching: isOrganizationDataFetching } =
    useFetchOrganizationQuery(organizationId)

  const [editOrganization, { isSuccess, isError, error }] =
    useEditOrganizationMutation()

  useEffect(() => {
    if (isSuccess) {
      handleClose()
      dispatch(setOpenDialog('successAlert'))
    }
  }, [isSuccess])

  useEffect(() => {
    if (!error) return
    if (error.data) {
      dispatch(setOpenDialog('errorAlert'))

      if (error.data.error) {
        dispatch(
          setErrorAlertMessage(`${error.data.statusCode} ${error.data.message}`)
        )
      }
    } else {
      dispatch(setErrorAlertMessage(error.error))
    }
  }, [isError])

  const handleClose = () => {
    setIsOpen(false)
    setOrgData(null)
  }

  const handleOrganizationFormChange = (orgData, isValid) => {
    setIsValid(isValid)
    setOrgData(orgData)
  }

  const handleSaveOrganization = () => {
    const payload = {
      data: {
        name: orgData.organizationName,
        domain: orgData.domain,
        timeZone: orgData.timezone,
        orgProfileId: orgData.profile?.id || null
      },
      logo: orgData.file,
      organizationId: organizationId
    }
    editOrganization(payload)
  }

  return (
    <Dialog
      maxWidth="md"
      scroll="body"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="add-device-modal-title"
      aria-describedby="add-device-modal-description"
    >
      <CloseDialogButton onClose={handleClose} />

      <DialogTitle
        variant="h4"
        display="flex"
        justifyContent="center"
      >
        {t('organizations.edit')}: {organization?.name}
      </DialogTitle>

      <DialogContent>
        {!isOrganizationDataFetching && (
          <OrganizationForm
            onChange={handleOrganizationFormChange}
            organization={organization}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          id='updateOrganization-confirm-save-button'
          variant="contained"
          onClick={handleSaveOrganization}
          disabled={!isValid}
        >
          {t('common.confirmAndSave')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

OrganizationEdit.propTypes = {
  organizationId: PropTypes.number,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default OrganizationEdit
