import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { useLoginMutation } from '../../../store/apis/authentication-api.js'
import {
  setCloseDialog,
  setCredentials,
  setCurrentOrganization,
  setOpenDialog
} from '../../../store/slices/general.js'
import CloseDialogButton from '../../Shared/Dialogs/CloseDialogButton.jsx'

import Logo from '../../Layout/Logo/Logo.jsx'
import { devicesApi } from '../../../store/apis/devices-api'
import { organizationsApi } from '../../../store/apis/organizations-api.js'
import { eventsApi } from '../../../store/apis/events-api.js'
import { invitesApi } from '../../../store/apis/invites-api.js'
import { actionsApi } from '../../../store/apis/actions-api.js'
import { rulesApi } from '../../../store/apis/rules-api.js'
import { triggersApi } from '../../../store/apis/triggers-api.js'
import LoginForm from '../../Shared/Forms/LoginForm.jsx'

const Login = () => {
  const {
    dialogs: {
      login: { open: loginDialog = false }
    }
  } = useSelector((state) => state.general, shallowEqual)

  const dispatch = useDispatch()

  const [isValid, setIsValid] = useState(false)
  const [form, setForm] = useState({ email: '', password: '' })

  const [
    loginUser,
    { data: loginData = {}, error: loginError, isSuccess: isLoginSuccess }
  ] = useLoginMutation()

  const handleLoginFormChange = (loginForm, isValid) => {
    setForm(loginForm)
    setIsValid(isValid)
  }

  const handleLogin = () => loginUser(form)

  const handleLoginFormLogin = () => {
    if (!isValid) {
      loginUser()
    }
  }

  const handleCloseLogin = () => dispatch(setCloseDialog('login'))

  const handleResetPassword = () => {
    dispatch(setCloseDialog('login'))
    dispatch(setOpenDialog('resetPassword'))
  }

  useEffect(() => {
    if (!isLoginSuccess) {
      return
    }
    const {
      permissions: { permissions, isSuperAdmin },
      preferences
    } = loginData
    const orgs = isSuperAdmin
      ? Object.keys(permissions).filter((key) => key === 'a2a')
      : Object.keys(permissions)
    const orgKey = orgs[0] ? orgs[0] : null
    dispatch(setCurrentOrganization(preferences?.defaultOrganization || orgKey))
    dispatch(setCredentials(loginData))
    dispatch(devicesApi.util.resetApiState())
    dispatch(organizationsApi.util.resetApiState())
    dispatch(eventsApi.util.resetApiState())
    dispatch(invitesApi.util.resetApiState())
    dispatch(actionsApi.util.resetApiState())
    dispatch(rulesApi.util.resetApiState())
    dispatch(triggersApi.util.resetApiState())
    handleCloseLogin()
  }, [isLoginSuccess])

  return (
    <Dialog
      maxWidth="sm"
      scroll="body"
      fullWidth={true}
      open={loginDialog}
      onClose={() => handleCloseLogin()}
    >
      <CloseDialogButton onClose={handleCloseLogin} />

      <DialogTitle
        display="flex"
        justifyContent="center"
      >
        <Stack
          direction="column"
          spacing={2}
          paddingTop={3}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Logo />
          </Box>

          <Typography
            variant="h3"
            align="center"
          >
            <strong>{t('users.loginToCityEye')}</strong>
          </Typography>
          <Typography
            variant="body2"
            align="center"
          >
            {t('users.handleYourDevices')}
          </Typography>
        </Stack>
      </DialogTitle>

      <DialogContent
        align="center"
        validation="center"
      >
        <Stack
          direction="column"
          maxWidth="70%"
          py={2}
          spacing={2}
        >
          <LoginForm
            onChange={handleLoginFormChange}
            onLogin={handleLoginFormLogin}
          />
          {loginError && <Alert severity="error">{t('users.loginFail')}</Alert>}
          <FormControl variant="standard">
            <Button
              id="login-dialog-button-login"
              sx={{ mt: 4 }}
              variant="contained"
              onClick={handleLogin}
              disabled={!isValid}
            >
              {t('common.login')}
            </Button>
          </FormControl>

          <Button
            variant="text"
            onClick={handleResetPassword}
            size="small"
          >
            <Typography variant="body2">
              <>{t('users.forgotPassword')}</>
            </Typography>
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default Login
