import React, { useEffect, useState } from 'react'
import {
  Button,
  Container,
  Stack
} from '@mui/material'
import { t } from 'i18next'
import { Save as SaveIcon } from '@mui/icons-material'
import { useFetchDeviceQuery } from '../../../store/index.js'
import DeviceSummary from '../Form/DeviceSummary.jsx'
import UpdateDeviceInfo from '../Form/UpdateDeviceInfo.jsx'
import UpdateNetwork from '../Form/UpdateNetwork.jsx'
import UpdatePosition from '../Form/UpdatePosition.jsx'
import UpdateProvisioning from '../Form/UpdateProvisioning.jsx'
import UpdatePublication from '../Form/UpdatePublication.jsx'
import { useUpdateDeviceMutation } from '../../../store/apis/devices-api.js'
import ErrorDialog from '../../Shared/Dialogs/ErrorDialog.jsx'
import PropTypes from 'prop-types'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert.js'

const UpdateDevice = ({ deviceId, isOpen, setIsOpen }) => {
  const [deviceInfo, setDeviceInfo] = useState(null)
  const [positionInfo, setPositionInfo] = useState(null)
  const [publicationInfo, setPublicationInfo] = useState(null)
  const [networkInfo, setNetworkInfo] = useState(null)
  const [provisioningInfo, setProvisioningInfo] = useState({})

  const [errorDescription, setErrorDescription] = useState(null)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)

  const [deviceInfoValid, setDeviceInfoValid] = useState(false)
  const [provisioningInfoValid, setProvisioningInfoValid] = useState(false)

  const {
    data: deviceData,
    isError: deviceError,
    isLoading: isDeviceLoading
  } = useFetchDeviceQuery(deviceId, { skip: !deviceId })

  const [
    updateDevice,
    {
      isError: isUpdateDeviceError,
      isSuccess: isUpdateDeviceSuccess,
      error: updateDeviceError
    }
  ] = useUpdateDeviceMutation()

  useOpenResultAlert(updateDeviceError, { isError: isUpdateDeviceError, isSuccess: isUpdateDeviceSuccess })

  const handleError = () => {
    setErrorDescription(`${updateDeviceError.data.statusCode} - ${updateDeviceError.data.error}: ${updateDeviceError.data.message}`)
    setIsErrorDialogOpen(true)
  }

  useEffect(() => {
    if (isUpdateDeviceSuccess) {
      handleClose()
    } 

    if (isUpdateDeviceError) {
      handleError()
    }
  }, [isUpdateDeviceSuccess, isUpdateDeviceError])

  const handleClose = () => setIsOpen(false)

  const handleSave = async () => {
    const payload = {
      device: {
        organizationKey: deviceInfo.organization,
        name: deviceInfo.name,
        description: deviceInfo.description,
        modelId: parseInt(deviceData.device.modelId),
        position: positionInfo.position,
        public: publicationInfo.isPublic,
        hidden: false, //2024-03-05 per ora lascerei così se entro 3 mesi nessuno chiede di ripristinarlo lo eliminiamo anche lato BE
        serial: deviceInfo.serial,
        tags: deviceInfo.tags,
        odlCode: deviceData.device.odlCode,
        locationCode: deviceData.device.locationCode
      },
      sourceData:
        deviceData.device.source.type === 'lorawan'
          ? {
            ...deviceData.sourceData,
            device: {
              ...networkInfo.device,
              user:
                networkInfo.device.user ||
                deviceData.sourceData.device.username
            }
          }
          : { ...networkInfo },
      parsingData: provisioningInfo
    }

    updateDevice({ id: deviceId, payload })
  }

  const updateDeviceInfo = (info, isValid) => {
    setDeviceInfoValid(isValid)
    setDeviceInfo(info)
  }

  const updatePositionInfo = (info) => setPositionInfo(info)

  const updatePublicationInfo = (info) => setPublicationInfo(info)

  const updateNetworkInfo = (info) => setNetworkInfo(info)

  const handleErrorDialogClose = () => setIsErrorDialogOpen(false)

  const updateProvisioningInfo = (info, isValid) => {
    setProvisioningInfoValid(isValid)
    setProvisioningInfo(info)
  }

  return (
    <>
      <ZDialog 
        id={'device-update-dialog'}
        isOpen={isOpen}
        onClose={handleClose}
        title={t('devices.update')}
        content={
        <Container maxWidth="md">
          {!deviceError && !isDeviceLoading && deviceData &&
          <Stack
            direction="column"
            spacing={10}
          >
            <DeviceSummary
              model={deviceData.device.model}
              brand={deviceData.device.brand}
              type={deviceData.device.type}
              sourceType={deviceData.device.source.type}
              measurements={deviceData.quantities}
            />

            <UpdateDeviceInfo
              deviceData={deviceData}
              onChange={updateDeviceInfo}
            />
            <UpdatePosition
              deviceData={deviceData}
              onChange={updatePositionInfo}
            />
            <UpdateNetwork
              deviceData={deviceData}
              onChange={updateNetworkInfo}
            />
            <UpdateProvisioning
              deviceData={deviceData}
              onChange={updateProvisioningInfo}
            />
            <UpdatePublication
              deviceData={deviceData}
              onChange={updatePublicationInfo}
            />
          </Stack>
          }
        </Container>}
        actions={<Button
          id="update-device-dialog-button-save"
          variant="contained"
          endIcon={<SaveIcon />}
          onClick={handleSave}
          disabled={!provisioningInfoValid || !deviceInfoValid}
          >
            {t('common.save')}
          </Button>}
      />

      <ErrorDialog
        isOpen={isErrorDialogOpen}
        errorFrom="Update Device"
        errorDescription={errorDescription}
        onClose={handleErrorDialogClose}
      />
    </>
  )
}

UpdateDevice.propTypes = {
  deviceId: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func
}

export default UpdateDevice
