import {
  Button,
  Chip,
  Container,
  Grid2,
  Stack,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'
import OrganizationsList from '../List/List.jsx'

const Organizations = () => {
  const [isAddOrganizationDialogOpen, setIsAddOrganizationDialogOpen] = useState(false)

  const handleOpenOrganizationAddDialog = () => {
    setIsAddOrganizationDialogOpen(true)
  }

  const handleOrganizationAddDialogClose = () => {
    setIsAddOrganizationDialogOpen(false)
  }

  const [organizationsCount, setOrganizationsCount] = useState(0)

  const handleOrganizationsCountUpdate = (count) => {
    setOrganizationsCount(count)
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ py: 3 }}
    >
      <Grid2
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid2>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Typography
              id="organization-header"
              variant="h2">{t('common.organizations')}</Typography>
            <Chip
              label={organizationsCount}
              color="primary"
            />
          </Stack>
        </Grid2>

        <Grid2>
          <Button
            id="create-new-organization-button"
            onClick={handleOpenOrganizationAddDialog}
            variant="contained"
            color="primary"
          >
            {t('organizations.add')}
          </Button>
        </Grid2>
      </Grid2>

      <OrganizationsList
        isAddOrganizationDialogOpen={isAddOrganizationDialogOpen}
        onOrganizationAddDialogClose={handleOrganizationAddDialogClose}
        onOrganizationsCountUpdate={handleOrganizationsCountUpdate}
      />

    </Container>
  )
}

export default Organizations
