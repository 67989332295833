import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import EnFlag from '../../../images/en.svg'
import ItFlag from '../../../images/it.svg'
import { setLanguage } from '../../../store/slices/general.js'

const LanguageMenu = ({ anchorEl, handleClose }) => {
  const dispatch = useDispatch()

  const open = Boolean(anchorEl)

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={{ top: '20px' }}
    >
      <MenuItem onClick={() => dispatch(setLanguage('it'))}>
        <ListItemIcon>
          <Box
            component="img"
            sx={{ width: '32px', height: '16px' }}
            src={ItFlag}
          />
        </ListItemIcon>
        <ListItemText>Italiano</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => dispatch(setLanguage('en'))}>
        <ListItemIcon>
          <Box
            component="img"
            sx={{ width: '32px', height: '16px' }}
            src={EnFlag}
          />
        </ListItemIcon>
        <ListItemText>English</ListItemText>
      </MenuItem>
    </Menu>
  )
}

LanguageMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func.isRequired
}

export default LanguageMenu
