export const actionAdd = {
  brand: '',
  model: '',
  showModelSelector: false,
  selectedDevice: {
    brandEncoded: '',
    modelEncoded: ''
  },
  action: null,
  currentPage: 0,
  notificationConfig: {
    notifyAdmin: false,
    notifyEditor: false,
    notifyViewer: false,
    emailsToNotify: []
  },
  webhookConfig: {
    urlMeasures: null,
    urlDelete: null,
    urlAction: null,
    httpHeaders: null
  },
  downlink: null,
  actionName: '',
  sendUplink: false
}
