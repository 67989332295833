import {
  Button,
  Container,
  DialogTitle,
  Stack,
  Step,
  StepLabel,
  Stepper
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  ChevronRightRounded as ChevronRightRoundedIcon,
  ChevronLeftRounded as ChevronLeftRoundedIcon,
  SaveRounded as SaveRoundedIcon
} from '@mui/icons-material'
import { t } from 'i18next'
import BrandModelSearch from '../Form/BrandModelSearch.jsx'
import Info from '../Form/Info.jsx'
import Network from '../Form/Network.jsx'
import Position from '../Form/Position.jsx'
import ProvisioningSetup from '../Form/ProvisioningSetup.jsx'
import {
  useCreateDeviceMutation,
  useFetchProvisioningSetupQuery
} from '../../../store/index.js'
import Publication from '../Form/Publication.jsx'
import { useOpenResultAlert } from '../../../helpers/hooks/useOpenResultAlert.js'
import ZDialog from '../../Shared/Dialogs/ZDialog.jsx'

const AddDevice = ({ isOpen, onClose }) => {
  const [isValid, setIsValid] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const [deviceInfo, setDeviceInfo] = useState(null)
  const [modelInfo, setModelInfo] = useState(null)
  const [positionInfo, setPositionInfo] = useState(null)
  const [publicationInfo, setPublicationInfo] = useState(null)
  const [networkInfo, setNetworkInfo] = useState(null)
  const [provisioningInfo, setProvisioningInfo] = useState({})

  const { data: provisioningSetup } = useFetchProvisioningSetupQuery(
    {
      brandEncoded: modelInfo?.brandNameEncoded,
      modelEncoded: modelInfo?.nameEncoded
    },
    { skip: !modelInfo }
  )

  const handleModelChange = (model) => {
    setModelInfo(model)
    setNetworkInfo(null)
    setProvisioningInfo(null)
  }

  const handleOnProvisioningSetupChange = (provisioningSetup) => setProvisioningInfo(provisioningSetup)

  const handleInfoChange = (info) => setDeviceInfo(info)

  const handleOnChangePosition = (position) => setPositionInfo(position)

  const handleOnPublicationChange = (info) => setPublicationInfo(info)

  const handleOnChangeNetwork = (info) => setNetworkInfo(info)

  const handleNextStep = () => setCurrentStep(currentStep + 1)

  const handlePreviousStep = () => setCurrentStep(currentStep - 1)

  const showNetworkStep = () => ['lorawan', 'wmbus', 'nbiot'].includes(modelInfo?.sourceType)

  const showParsingStep = () => !!provisioningSetup && provisioningSetup?.fields?.length

  let lastStepIndex = 2

  const showCurrentStep = () => {
    const activeSteps = [
      <BrandModelSearch
        key={0}
        setIsValid={setIsValid}
        onChange={handleModelChange}
      />,
      <Info
        key={1}
        setIsValid={setIsValid}
        onChange={handleInfoChange}
      />,
      <Position
        key={2}
        setIsValid={setIsValid}
        onChange={handleOnChangePosition}
      />
    ]

    if (showNetworkStep())
      activeSteps.push(
        <Network
          model={modelInfo}
          key={activeSteps.length}
          setIsValid={setIsValid}
          onChange={handleOnChangeNetwork}
        />
      )

    if (showParsingStep()) {
      activeSteps.push(
        <ProvisioningSetup
          key={activeSteps.length}
          setIsValid={setIsValid}
          onChange={handleOnProvisioningSetupChange}
          provisioningSetup={provisioningSetup}
        />
      )
    }

    activeSteps.push(
      <Publication
        key={activeSteps.length}
        onChange={handleOnPublicationChange}
      />
    )

    lastStepIndex = activeSteps.length - 1

    return activeSteps[currentStep]
  }

  const handleClose = () => {
    setCurrentStep(0)
    onClose()
  }

  const [
    createDevice,
    {
      isSuccess: isCreateDeviceSuccess,
      isError: isCreateDeviceError,
      error: createDeviceError
    }
  ] = useCreateDeviceMutation()

  useOpenResultAlert(createDeviceError, { isError: isCreateDeviceError, isSuccess: isCreateDeviceSuccess })

  useEffect(() => {
    if (isCreateDeviceSuccess) {
      handleClose()
    }
  }, [isCreateDeviceSuccess])

  const handleSave = () => {
    const sourceType = modelInfo.sourceType

    //TODO: rivedere tutta questa parte prchè non mi piace

    const payload = {
      device: {
        organizationKey: deviceInfo.organization.key,
        name: deviceInfo.name,
        description: deviceInfo.description,
        modelId: modelInfo.id,
        position: {
          latitude: positionInfo?.position?.latitude || null,
          longitude: positionInfo?.position?.longitude || null
        },
        serial: deviceInfo.serial,
        public: publicationInfo ? publicationInfo.isPublic : false,
        hidden: false, //2024-03-05 per ora lascerei così se entro 3 mesi nessuno chiede di ripristinarlo lo eliminiamo anche lato BE
        tags: deviceInfo.tags,
        odlCode: publicationInfo?.odlCode || null,
        locationCode: publicationInfo?.locationCode || null
      },
      ...(['lorawan', 'wmbus', 'nbiot'].includes(sourceType)
        ? {
          sourceData: {
            ...(sourceType === 'lorawan'
              ? {
                networkServerId: networkInfo.networkServer,
                device: {
                  userId: networkInfo.userId,
                  appId: networkInfo.appId,
                  devEui: networkInfo.devEui?.toLowerCase(),
                  appKey: networkInfo.appKey?.toLowerCase(),
                  nwkSKey: networkInfo.nwkSKey?.toLowerCase(),
                  appSKey: networkInfo.appSKey?.toLowerCase(),
                  devaddr: networkInfo.devaddr?.toLowerCase(),
                  joinEui: networkInfo.joinEui?.toLowerCase(),
                  type: networkInfo.networkType === 'OTAA' ? 128 : 0,
                  profile: modelInfo.netServerProfileId,
                  info: {
                    model: modelInfo.name,
                    firmware: '',
                    position: {
                      altitude: null
                    }
                  }
                }
              }
              : {}),
            ...(sourceType === 'wmbus'
              ? { wmbusId: networkInfo.wmbusId }
              : {}),
            ...(sourceType === 'nbiot' ? { imsi: networkInfo.imsi } : {})
          }
        }
        : {}),
      parsingData: provisioningInfo ? provisioningInfo : {}
    }

    createDevice(payload)
  }

  return (
    <ZDialog 
      id='add-device' 
      isOpen={isOpen}
      title={t('devices.add')} 
      customTitle={
      <DialogTitle marginBottom={5}>
        <Stepper
          activeStep={currentStep}
          alternativeLabel
        >
          <Step key="brandModelSearch">
            <StepLabel>{t('devices.selectModel')}</StepLabel>
          </Step>
          <Step key="deviceData">
            <StepLabel>{t('devices.addInfo')}</StepLabel>
          </Step>
          <Step key="devicePosition">
            <StepLabel>{t('devices.addPosition')}</StepLabel>
          </Step>
          {showNetworkStep() && (
            <Step key="networkData">
              <StepLabel>{t('devices.addNetwork')}</StepLabel>
            </Step>
          )}
          {showParsingStep() && (
            <Step key="parsingData">
              <StepLabel>{t('devices.addParsing')}</StepLabel>
            </Step>
          )}
          <Step key="extraInfo">
            <StepLabel>{t('devices.addPublication')}</StepLabel>
          </Step>
        </Stepper>
      </DialogTitle>}
        content={
          <Container
            sx={{ mt: 4 }}
            maxWidth="md"
          >
            {showCurrentStep()}
          </Container>
        }
        actions={
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} justifyItems={"center"}>
            <Button
              onClick={handlePreviousStep}
              disabled={currentStep === 0}
              startIcon={<ChevronLeftRoundedIcon />}
            >
              {t('common.back')}
            </Button>

          {lastStepIndex > currentStep ? (
            <Button
              id="add-device-dialog-button-next"
              onClick={handleNextStep}
              disabled={!isValid}
              endIcon={<ChevronRightRoundedIcon />}
            >
              {t('common.next')}
            </Button>
          ) : (
            <Button
              id="add-device-dialog-button-save"
              variant="contained"
              onClick={handleSave}
              disabled={!isValid}
              endIcon={<SaveRoundedIcon />}
            >
              {t('common.save')}
            </Button>
          )}
        </Stack>
      }
      onClose={handleClose} />
  )
}

export default AddDevice
