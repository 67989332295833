import { Stack } from '@mui/material'
import React, { useState, useEffect } from 'react'
import Can from '../../Layout/Can/Can.jsx'
import Filter from '../../Layout/Dialogs/Filter.jsx'
import FilterGatewayStatus from '../../Shared/Filters/Filters/GatewayStatus.jsx'
import FilterGatewayTechnology from '../../Shared/Filters/Filters/GatewayTechnology.jsx'
import FilterGatewayArea from '../../Shared/Filters/Filters/GatewayArea.jsx'
import { useSelector, useDispatch } from 'react-redux'
import { setFiltersGateways } from '../../../store/slices/filters.js'
import {
  setFiltersDefaultNear,
  setFiltersDefaultPosition
} from '../../../store/slices/general.js'
import { t } from 'i18next'
import Count from '../../Shared/Components/Count.jsx'

const Filters = ({ itemsCount }) => {
  const dispatch = useDispatch()

  const [selectedArea, setSelectedArea] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(false)
  const [selectedTechnology, setSelectedTechnology] = useState(false)

  const { credentials } = useSelector((state) => state.general)
  const { gateways: filters } = useSelector((state) => state.filters)

  const isSuperAdmin = credentials?.permissions?.isSuperAdmin || false

  const handleOnDeleteArea = () => {
    dispatch(setFiltersDefaultNear(false))
    dispatch(setFiltersDefaultPosition({}))
    dispatch(setFiltersGateways({ nearby: null }))
    setSelectedArea(false)
  }

  const handleOnDeleteStatus = () => {
    dispatch(setFiltersGateways({ status: [] }))
    setSelectedStatus(false)
  }

  const handleOnDeleteTechnology = () => {
    dispatch(setFiltersGateways({ technology: [] }))
    setSelectedTechnology(false)
  }

  useEffect(() => {
    const { status, technology, nearby } = filters

    setSelectedArea(!!nearby)
    setSelectedStatus(!!(status?.length > 0))
    setSelectedTechnology(!!(technology?.length > 0))
  }, [filters])

  const filterUnits = [
    {
      label: t('filters.gateways.status.label'),
      id: 'chip-filters-status',
      selected: selectedStatus,
      onDelete: handleOnDeleteStatus,
      hidden: !isSuperAdmin,
      content: <FilterGatewayStatus />,
      count: <Count itemsCount={itemsCount} />
    },
    {
      label: t('filters.gateways.technology.label'),
      id: 'chip-filters-technology',
      selected: selectedTechnology,
      onDelete: handleOnDeleteTechnology,
      hidden: !isSuperAdmin,
      content: <FilterGatewayTechnology />,
      count: <Count itemsCount={itemsCount} />
    },
    {
      label: t('filters.gateways.area.label'),
      id: 'chip-filters-area',
      selected: selectedArea,
      onDelete: handleOnDeleteArea,
      hidden: !isSuperAdmin,
      content: <FilterGatewayArea />,
      count: <Count itemsCount={itemsCount} />
    }
  ]

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      {[...filterUnits].map((e, i) => (
        <Can
          action="devices_r"
          key={`device-filter-${i}`}
        >
          <Filter
            key={`device-filter-${i}`}
            id={e.id}
            onClick={e.onClick}
            onDelete={e.onDelete}
            selected={e.selected}
            title={e.label}
            hidden={e.hidden || false}
            content={e.content}
            count={e.count}
          />
        </Can>
      ))}
    </Stack>
  )
}

export default Filters
