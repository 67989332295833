import { Box, Typography, Divider, Grid2 } from '@mui/material'
import React from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { CheckRounded as CheckRoundedIcon } from '@mui/icons-material'

const ReviewAction = ({ downlink, error }) => {
  return (
    <Box>
      <Box mb={6}>
        {error && (
          <>
            <Grid2
              container
              marginY={2}
              style={{ backgroundColor: '#FA4C4E' }}
              padding={1}
            >
              <Grid2>
                <Typography variant="h5" align="right">{t('common.error')}</Typography>
              </Grid2>
              <Grid2>
                <Typography>
                  {error?.data?.message || error?.data?.error?.error}
                </Typography>
              </Grid2>
            </Grid2>
            <Divider />
          </>
        )}

        <Box sx={{ flexGrow: 1 }}>
          <Grid2
            container
            marginY={2}
            spacing={2}
          >
            {downlink?.savePreset && (
              <>
                <Grid2 size={6} py={1}>
                  <Typography variant="h5" align="right">{t('actions.savePreset')}</Typography>
                </Grid2>
                <Grid2 size={6} py={1}>
                  <Typography>{downlink?.savePreset ? <CheckRoundedIcon color="success" /> : ''}</Typography>
                </Grid2>
                <Grid2 size={6} py={1}>
                  <Typography variant="h5" align="right">{t('actions.actionName')}</Typography>
                </Grid2>
                <Grid2 size={6} py={1}>
                  <Typography>{downlink?.name}</Typography>
                </Grid2>
              </>
            )}

            <Grid2 size={6} py={1}>
              <Typography variant="h5" align="right">{t('actions.action')}</Typography>
            </Grid2>
            <Grid2 size={6} py={1}>
              <Typography>{downlink.downlink?.name}</Typography>
            </Grid2>

            <Grid2 size={6} py={1}>
              <Typography variant="h5" align="right">{t('actions.sendUplink')}</Typography>
            </Grid2>
            <Grid2 size={6} py={1}>
              <Typography>
                {downlink?.sendUplink ? t('common.yes') : t('common.no')}
              </Typography>
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    </Box>
  )
}

ReviewAction.propTypes = {
  downlink: PropTypes.object.isRequired,
  error: PropTypes.object
}

export default ReviewAction
