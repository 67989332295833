import { Avatar, Box, Button, Divider, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import {
  MoreVertRounded as MoreVertRoundedIcon,
  AccountCircleRounded as AccountCircleRoundedIcon
} from '@mui/icons-material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { jwtDecode } from 'jwt-decode'

import { useFetchSelfOrganizationsQuery } from '../../../store'
import { setOpenDialog } from '../../../store/slices/general.js'
import ProfileMenu from '../ProfileMenu/ProfileMenu.jsx'

const ProfileBadge = () => {
  const { credentials, currentOrganization } = useSelector(
    (state) => state.general,
    shallowEqual
  )

  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const [name, setName] = useState(null)
  const [initials, setInitials] = useState(null)
  const [organization, setOrganization] = useState(null)

  const {
    data: organizationsSuggestions,
    error: organizationsSuggestionsError,
    isLoading: isOrganizationsSuggestionsLoading
  } = useFetchSelfOrganizationsQuery({ skip: !credentials })

  const handleClick = (event) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  useEffect(() => {
    const { accessToken = null } = credentials || {}
    if (accessToken) {
      const { firstName, lastName } = jwtDecode(accessToken)

      if (firstName && lastName) {
        setInitials(`${firstName[0]}${lastName[0]}`)
        setName(`${firstName} ${lastName}`)
      }
    }
  }, [credentials])

  useEffect(() => {
    if (organizationsSuggestions && organizationsSuggestions.length > 0) {
      setOrganization(organizationsSuggestions.find((o) => o?.key === currentOrganization))
    }
  }, [organizationsSuggestions, currentOrganization])

  return (
    <>
      {credentials && (
        <>
          <Button
            id="profile-badge-username"
            variant="text"
            onClick={handleClick}
            sx={{ textTransform: 'unset' }}
          >
            <Box
              alignItems="center"
              display="flex"
            >
              <Box px={2}>
                <Typography
                  component="div"
                  variant="caption"
                >
                  <strong>{name}</strong>
                </Typography>
                {!organizationsSuggestionsError &&
                  !isOrganizationsSuggestionsLoading &&
                  !credentials.permissions.isSuperAdmin && (
                    <Typography
                      component="div"
                      variant="caption"
                    >
                      <i>{organization?.name}</i>
                    </Typography>
                  )}
              </Box>
              <Divider orientation="vertical" />
              <Avatar>{initials}</Avatar>
              <MoreVertRoundedIcon fontSize="small" />
            </Box>
          </Button>
          <ProfileMenu
            handleClose={handleClose}
            setAnchorEl={setAnchorEl}
            anchorEl={anchorEl}
            open={open}
          />
        </>
      )}

      {!credentials && (
        <Button
          id="profile-badge-button-login"
          onClick={() => dispatch(setOpenDialog('login'))}>
          <Box
            alignItems="center"
            display="flex"
          >
            <Box px="20px">
              <Typography
                component="div"
                variant="caption"
              >
                {t('users.signIn')}
              </Typography>
            </Box>
            <Avatar>
              <AccountCircleRoundedIcon fontSize="large" />
            </Avatar>
          </Box>
        </Button>
      )}
    </>
  )
}

export default ProfileBadge
