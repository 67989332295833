import { Divider } from '@mui/material'
import React from 'react'
import { BrandsFilter, ModelsFilter, TriggerTypeFilter, ActionTypeFilter, IsEnabledFilter } from './index.js'
import PropTypes from 'prop-types'

const RulesOthersFilters = ({ filters, onChange = () => {} }) => {
  return (
    <>
      <IsEnabledFilter
        filters={filters}
        onChange={(value) => onChange({ ...filters, enabled: value })}
      />
      <Divider />
      <BrandsFilter
        filters={filters}
        onChange={(value) => onChange({ ...filters, brandIds: value })}
      />
      <Divider />
      <ModelsFilter
        filters={filters}
        onChange={(value) => onChange({ ...filters, modelIds: value })}
      />
      <Divider />
      <TriggerTypeFilter
        filters={filters}
        onChange={(value) => onChange({ ...filters, triggerTypes: value })}
      />
      <Divider />
      <ActionTypeFilter
        filters={filters}
        onChange={(value) => onChange({ ...filters, actionTypes: value })}
      />
    </>
  )
}

RulesOthersFilters.propTypes = {
  filters: PropTypes.object,
  onChange: PropTypes.func
}

export default RulesOthersFilters
