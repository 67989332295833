import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  InputAdornment,
  ListItem,
  ListItemText,
  ListItemIcon,
  Stack,
  Tab,
  TextField
} from '@mui/material'
import React, { useState } from 'react'
import {
  CloseRounded as CloseRoundedIcon,
  WifiTetheringRounded as WifiTetheringRoundedIcon,
  SearchRounded as SearchRoundedIcon
} from '@mui/icons-material'
import { t } from 'i18next'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  useFetchBrandsQuery,
  useFetchModelsMutation,
  useSearchDevicesMutation
} from '../../../store/index.js'
import { useFetchAutomationDevicesMutation } from '../../../store/apis/devices-api.js'
import InlineMessage from '../../Shared/Components/InlineMessage.jsx'

const SearchDevices = ({ onSelected, isOpen, onClose }) => {
  const [open, setOpen] = useState(false)
  const [tab, setTab] = useState('by-model')
  const [model, setModel] = useState({ model: null })

  const { data: brands = [], isLoading: isBrandsLoading } = useFetchBrandsQuery()

  const [searchDevices, { data: searchedDevices = [], isLoading: isSearchDevicesLoading }] = useSearchDevicesMutation()

  const [fetchModels, { data: models = [], isLoading: isModelsLoading }] = useFetchModelsMutation()

  const [fetchDevices, { data: devices, isLoading: isDevicesLoading }] = useFetchAutomationDevicesMutation()

  const handleChange = (_event, value) => setTab(value)

  const handleClose = () => onClose()

  const onChangeBrand = (_, value) => {
    setModel({
      ...model,
      brand: value,
      model: null,
      provisioningSetup: null
    })

    if (value) {
      fetchModels(value.id)
    }
  }

  const onChangeModel = (_, value) => {
    setModel({
      ...model,
      model: value
    })

    if (value) {
      fetchDevices({
        filters: { modelIds: [value.id] },
        page: 1,
        pageSize: 99999
      })
    }
  }

  const handleSelectedDevice = (value) => {
    onSelected(value)
    handleClose()
  }

  const handleSearchByNameInputChange = (_, value) => searchDevices({ payload: { fullText: value } })

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      onClose={() => handleClose()}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogActions sx={{ justifyContent: 'right', p: '16px' }}>
        <CloseRoundedIcon
          onClick={() => {
            handleClose()
          }}
          color="rgba(105, 105, 105, .2)"
        />
      </DialogActions>

      <DialogTitle
        display="flex"
        justifyContent="center"
        variant="h4"
      >
        {t('actions.findSensor')}
      </DialogTitle>

      <DialogContent
        align="center"
        valign="center"
        sx={{
          padding: '10px 10px'
        }}
      >
        <TabContext value={tab}>
          <TabList
            sx={{ marginTop: 4, borderBottom: 1, borderColor: 'divider' }}
            onChange={handleChange}
            aria-label="searchDevices"
          >
            <Tab
              label={t('actions.searchByBrandAndModel')}
              value="by-model"
            />
            <Tab
              label={t('actions.searchByName')}
              value="by-name"
            />
          </TabList>
          <TabPanel
            value="by-model"
            sx={{ px: 0 }}
          >
            <Stack
              sx={{
                marginTop: '15px'
              }}
              direction="row"
              spacing={2}
              justifyContent="space-between"
            >
              <Autocomplete
                id="chooseModel-Brand"
                name="brand"
                fullWidth
                loading={isBrandsLoading}
                options={brands}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={model?.brand}
                onChange={onChangeBrand}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('devices.chooseBrand')}
                    variant="standard"
                  />
                )}
              />

              <Autocomplete
                id="chooseModel-Model"
                name="model"
                fullWidth
                disabled={!models.length}
                loading={isModelsLoading}
                options={models}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option && value && option.id === value.id
                }
                value={model?.model}
                onChange={onChangeModel}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('devices.chooseModel')}
                    variant="standard"
                  />
                )}
              />
            </Stack>
            <Box sx={{ marginTop: '20px' }}>
              {!isDevicesLoading && !devices?.length && (
                <InlineMessage
                  message={
                    'Nessun device trovato per marca e modello selezionati'
                  }
                />
              )}
              {!isDevicesLoading &&
                devices?.map((device) => (
                  <ListItem
                    divider
                    key={device.id}
                    onClick={() => handleSelectedDevice(device)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <ListItemIcon>
                      <WifiTetheringRoundedIcon fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: '0.8rem',
                        fontWeight: 500
                      }}
                      primary={device.name}
                      secondary={device.address}
                    />
                  </ListItem>
                ))}
            </Box>
          </TabPanel>
          <TabPanel
            value="by-name"
            sx={{ px: 0 }}
          >
            <Autocomplete
              sx={{
                marginTop: '15px',
                flexGrow: 1,
                '& .MuiAutocomplete-inputRoot': {
                  paddingRight: '0 !important'
                }
              }}
              options={searchedDevices}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              filterOptions={(x) => x}
              onInputChange={handleSearchByNameInputChange}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              loading={isSearchDevicesLoading}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option) => (
                <ListItem
                  {...props}
                  divider
                  key={option.id}
                  onClick={() => handleSelectedDevice(option)}
                  sx={{ cursor: 'pointer' }}
                >
                  <ListItemIcon>
                    <WifiTetheringRoundedIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '0.8rem',
                      fontWeight: 500
                    }}
                    primary={option.name}
                    secondary={option.address}
                  />
                </ListItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('common.search')}
                  variant="standard"
                  slotProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          color="inherit"
                          edge="end"
                        >
                          {isSearchDevicesLoading && (
                            <CircularProgress
                              edge="end"
                              size={20}
                            />
                          )}
                          {!isSearchDevicesLoading && (
                            <SearchRoundedIcon
                              edge="end"
                              size={20}
                            />
                          )}
                        </Icon>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </TabPanel>
        </TabContext>
      </DialogContent>
    </Dialog>
  )
}

export default SearchDevices
