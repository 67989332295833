import { createSlice } from '@reduxjs/toolkit'
import { logger } from '../../helpers/logger/logger.js'
import { actionAdd } from '../states'

const initialState = actionAdd

const actionAddSlice = createSlice({
  name: 'actionAdd',
  initialState,
  reducers: {
    setBrand: (state, action) => {
      logger.log('actionAdd: set brand')
      state.brand = action.payload
    },
    setModel: (state, action) => {
      logger.log('actionAdd: set model')
      state.model = action.payload
    },
    setShowModelSelector: (state, action) => {
      logger.log('actionAdd: set showModelSelector')
      state.showModelSelector = action.payload
    },
    setSelectedDevice: (state, action) => {
      logger.log('actionAdd: set showModelSelector')
      state.selectedDevice = action.payload
    },
    setAction: (state, action) => {
      logger.log('actionAdd: set action')
      state.action = action.payload
    },
    setCurrentPage: (state, action) => {
      logger.log('actionAdd: set currentPage')
      state.currentPage = action.payload
    },
    setNotificationConfig: (state, action) => {
      logger.log('actionAdd: set notificationConfig')
      state.notificationConfig = action.payload
    },
    setWebhookConfig: (state, action) => {
      logger.log('actionAdd: set webhookConfig')
      state.webhookConfig = action.payload
    },
    setDownlink: (state, action) => {
      logger.log('actionAdd: set downlink')
      state.downlink = action.payload
    },
    setActionName: (state, action) => {
      logger.log('actionAdd: set downlink')
      state.actionName = action.payload
    },
    setSendUplink: (state, action) => {
      logger.log('actionAdd: set downlink')
      state.sendUplink = action.payload
    },
    resetActionPayload: () => {
      logger.log('actionAdd: reset to initial state')
      return { ...initialState }
    }
  }
})

export const {
  setBrand,
  setModel,
  resetActionPayload,
  setShowModelSelector,
  setSelectedDevice,
  setAction,
  setCurrentPage,
  setDownlink,
  setActionName,
  setSendUplink,
  setNotificationConfig,
  setWebhookConfig
} = actionAddSlice.actions

export default actionAddSlice.reducer
