import { Drawer } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React from 'react'
import ManagementMenu from '../ManagementMenu/ManagementMenu.jsx'

const ManagementTemplate = ({ main: MainComponent }) => {
  const drawerWidth = 300

  const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open'
  })(({ open, theme }) => ({
    marginTop: '66px',
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  }))

  return (
    <>
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            height: 'calc(100% - 80px)'
          }
        }}
        variant="persistent"
        anchor="left"
        open
      >
        <ManagementMenu />
      </Drawer>
      <Main
        open
        sx={{ position: 'relative' }}
      >
        {MainComponent}
      </Main>
    </>
  )
}

ManagementTemplate.propTypes = {
  main: PropTypes.element
}

export default ManagementTemplate
