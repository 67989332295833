import { Stack } from '@mui/material'
import React, { useState } from 'react'
import { t } from 'i18next'
import Can from '../../Layout/Can/Can.jsx'
import Filter from '../../Layout/Dialogs/Filter.jsx'
import FilterOrganizations from './Filters/Organizations.jsx'
import DateRangeFilter from './Filters/DateRange.jsx'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import FiltersOthers from './Filters/Others.jsx'

const Filters = ({ filters, onChange }) => {
  const [selectedOrganizations, setSelectedOrganizations] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState(false)
  const [selectedOther, setSelectedOther] = useState(false)

  const { credentials, currentOrganization } = useSelector(
    (state) => state.general
  )

  const isSuperAdmin = credentials?.permissions?.isSuperAdmin || false
  const orgPermissions = currentOrganization
    ? credentials?.permissions?.permissions[currentOrganization] || []
    : []

  const handleDateFilterChange = (data) => {
    setSelectedDateRange(true)
    onChange({ ...filters, ...data })
  }

  const handleOrganizationsChange = (data) => {
    onChange({ ...filters, ...data })
  }

  const handleOnDeleteDateRange = () => {
    setSelectedDateRange(true)
    onChange({
      ...filters,
      fromTo: {
        from: dayjs().add(-8, 'days').toISOString(),
        to: dayjs().toISOString()
      }
    })
  }

  const handleOnDeleteOrganizations = () => {
    setSelectedOrganizations(false)
  }

  const handleOnDeleteOther = () => {
    setSelectedOther(false)
  }

  const handleOthersFiltersChange = (data) => {
    onChange({ ...filters, ...data })
  }

  const filterUnits = [
    {
      label: `${t('common.from')}: ${dayjs(filters.fromTo.from).format(
        'DD/MM/YYYY'
      )} - ${t('common.to')}: ${dayjs(filters.fromTo.to).format('DD/MM/YYYY')}`,
      id: 'chip-filters-events-fromto',
      selected: selectedDateRange,
      onDelete: handleOnDeleteDateRange,
      hidden: false,
      content: (
        <DateRangeFilter
          data={{ ...filters }}
          onChange={handleDateFilterChange}
        />
      )
    },
    {
      label: t('filters.devices.organizations.label'),
      id: 'chip-filters-events-organizations',
      selected: selectedOrganizations,
      onDelete: handleOnDeleteOrganizations,
      hidden: !orgPermissions.includes('organizations_r') && !isSuperAdmin,
      content: (
        <FilterOrganizations
          onChange={handleOrganizationsChange}
          filters={filters}
        />
      )
    },
    {
      label: t('common.filters.other'),
      id: 'chip-filters-events-other',
      selected: selectedOther,
      onDelete: handleOnDeleteOther,
      content: (
        <FiltersOthers
          onChange={handleOthersFiltersChange}
          filters={filters}
        />
      )
    }
  ]

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
    >
      {[...filterUnits].map((e, i) => (
        <Can
          action="devices_r"
          key={`device-filter-${i}`}
        >
          <Filter
            key={`device-filter-${i}`}
            id={e.id}
            onClick={e.onClick}
            onDelete={e.onDelete}
            selected={e.selected}
            title={e.label}
            hidden={e.hidden || false}
            content={e.content}
            count={e.count}
          />
        </Can>
      ))}
    </Stack>
  )
}

export default Filters
