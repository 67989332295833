import React, { useState } from 'react'
import { t } from 'i18next'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import InlineMessage from '../../../Shared/Components/InlineMessage'
import Show from '../../../Layout/Can/Show'

const EnumValuesSelector = ({ quantity, trigger, onTriggerChange, isEdit }) => {

  const { type: { values = [] }} = quantity

  const { values: selectedValues } = trigger ? trigger : { values: [] }

  const [showAllValuesSelectionError, setShowAllValuesSelectionError] = useState(false)

  const valuesCount = Object.entries(values).length

  const handleChange = (checked, value) => {
    const newValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter((v) => v !== value)

    if (newValues.length === valuesCount) {
      setShowAllValuesSelectionError(true)
      return
    }

    setShowAllValuesSelectionError(false)
    onTriggerChange({
      trigger: {
        operator: 'equal',
        values: newValues
      }
    })
  }

  return (
    <Box>
        <Show when={() => valuesCount > 0 && valuesCount <= 2}>
          <Typography variant={`${selectedValues.length > 0 ? 'body' : 'bodyRequired'}`}>
            {t('rules.selectOneValue')}
          </Typography>
        </Show>
        <Show when={() => valuesCount > 2}>
          <Typography variant={`${selectedValues.length > 0 ? 'body' : 'bodyRequired'}`}>
            {t('rules.selectAtLeastOneValue')}
          </Typography>
        </Show>
      <Box>
        {Object.entries(values).map(([value, label], index) => (
          <FormControlLabel
            key={`measure-${index}`}
            control={
              <Checkbox
                id={`trigger-enum-values-measure-${index}`}
                checked={
                  selectedValues.find((v) => `${v}` === value) !== undefined
                }
                disabled={isEdit}
                onChange={(_event, checked) => handleChange(checked, value)}
              />
            }
            label={
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {label}
              </Typography>
            }
          />
        ))}
      </Box>
      <Show when={() => showAllValuesSelectionError}>
        <InlineMessage message={t('automations.errors.cantSelectAllValues')} />
      </Show>
    </Box>
  )
}

EnumValuesSelector.propTypes = {
  quantity: PropTypes.object,
  trigger: PropTypes.object,
  onTriggerChange: PropTypes.func
}

export default EnumValuesSelector
