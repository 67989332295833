import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Container, Tab, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { t } from 'i18next'
import AutomationActionsList from './Lists/ActionsList.jsx'
import AutomationRulesList from './Lists/RulesList.jsx'
import AutomationTriggersList from './Lists/TriggersList.jsx'
import { useParams } from 'react-router-dom'

const Automations = () => {
  const { tab } = useParams()
  const [activeTab, setActiveTab] = useState('rules')

  const handleChange = (_event, value) => setActiveTab(value)

  useEffect(() => {
    if (!tab) return
    setActiveTab(tab)
  }, [tab])

  return (
    <Container
      maxWidth="lg"
      sx={{ py: 3 }}
    >
      <Typography variant="h2">{t('common.automations')}</Typography>

      <TabContext value={activeTab}>
        <Box sx={{ marginTop: 4, borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            aria-label="automations"
          >
            <Tab
              aria-label="tab-rules"
              label={t('automations.rules')}
              value="rules"
            />
            <Tab
              aria-label="tab-triggers"
              label={t('automations.triggers')}
              value="triggers"
            />
            <Tab
              aria-label="tab-actions"
              label={t('automations.actions')}
              value="actions"
            />
          </TabList>
        </Box>
        <TabPanel
          value="rules"
          sx={{ px: 0 }}
        >
          {<AutomationRulesList />}
        </TabPanel>
        <TabPanel
          value="triggers"
          sx={{ px: 0 }}
        >
          {<AutomationTriggersList />}
        </TabPanel>
        <TabPanel
          value="actions"
          sx={{ px: 0 }}
        >
          {<AutomationActionsList />}
        </TabPanel>
      </TabContext>
    </Container>
  )
}

export default Automations
